import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../shared';

import {
  SanPhamComponent,
  sanPhamRoute
} from './';

import {GallerizeModule} from '@ngx-gallery/gallerize';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GalleryModule} from '@ngx-gallery/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {QRCodeModule} from "angularx-qrcode";
import { SanPhamDeleteDialogComponent, SanPhamDeletePopupDialogComponent } from './san-pham-delete-dialog/san-pham-delete-dialog.component';
import { SanPhamDetailComponent } from './san-pham-detail/san-pham-detail.component';
import { SanPhamDeleteHistoryDialogComponent, SanPhamDeleteHistoryPopupComponent } from './san-pham-delete-history-dialog/san-pham-delete-history-dialog.component';
import { SanPhamWarehouseStorageDialogComponent, ImportSanPhamWarehouseStoragePopupComponent } from './san-pham-warehouse-storage-dialog/san-pham-warehouse-storage-dialog.component';

const ENTITY_STATES = [...sanPhamRoute];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
    GallerizeModule,
    LightboxModule,
    GalleryModule,
    QRCodeModule,
    MatSlideToggleModule,
  ],
  declarations: [
    SanPhamComponent,
    SanPhamDeleteDialogComponent,
    SanPhamDeletePopupDialogComponent,
    SanPhamDetailComponent,
    SanPhamDeleteHistoryDialogComponent,
    SanPhamDeleteHistoryPopupComponent,
    SanPhamWarehouseStorageDialogComponent,
    ImportSanPhamWarehouseStoragePopupComponent
  ],
  entryComponents: [
    SanPhamComponent,
    SanPhamDeleteDialogComponent,
    SanPhamDeletePopupDialogComponent,
    SanPhamDeleteHistoryDialogComponent,
    SanPhamDeleteHistoryPopupComponent,
    SanPhamWarehouseStorageDialogComponent,
    ImportSanPhamWarehouseStoragePopupComponent
  ]
})
export class SanPhamModule {}
