export interface ISearch {
  page?: number;
  size?: number;
  sort?: string;
  dir?: string;
  query?: string;
  queryParams?: {key: string, value: string}[];
  pageOptions?: number[];
  totalCount?: number;
}

export class Search implements ISearch {
  constructor(
    public page?: number,
    public size?: number,
    public sort?: string,
    public dir?: string,
    public query?: string,
    public queryParams?: any[],
    public pageOptions?: number[],
    public totalCount?: number,
  ) {
    this.page = page ? page : 1;
    this.size = size ? size : 10;
    this.sort = sort ? sort : '';
    this.dir = dir ? dir : '';
    this.query = query ? query : '';
    this.queryParams = queryParams ? queryParams : [];
    this.pageOptions = pageOptions ? pageOptions : [5, 10, 25, 100];
    this.totalCount = totalCount ? totalCount : 0;
  }
}
