import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TieuChuan} from '../tieu-chuan.model';
import {TieuChuanService} from '../tieu-chuan.service';
import {Principal} from "../../../core";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DonViService} from "../../don-vi";

@Component({
  selector: 'app-tieu-chuan-dialog',
  templateUrl: './tieu-chuan-dialog.component.html',
  styleUrls: ['./tieu-chuan-dialog.component.scss'],
})
export class TieuChuanDialogComponent implements OnInit, OnDestroy {
  isSaving: boolean;
  standard: TieuChuan;
  customerId = null;
  customerData: any = [];
  private unsubscribeAll = new Subject();
  constructor(
    private dialogRef: MatDialogRef<TieuChuanDialogComponent>,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private tieuchuanService: TieuChuanService,
    private principal: Principal
  ) {
    this.isSaving = false;
    this.standard = new TieuChuan();
  }

  ngOnInit() {
    this.principal
        .getAuthenticationState()
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((user) => {
          if (user) {
            this.customerId = user.customerId;
            if (user.customerId) {
              this.standard.tbCustomerId = this.standard.tbCustomerId || user.customerId;
            }
          }
        });
    if (this.standard.id !== null) {
      this.titleService.setTitle('Sửa quy trình');
    } else {
      this.titleService.setTitle('Thêm quy trình');
    }
  }

  save() {
    this.isSaving = true;
    if (this.standard.id !== null) {
      this.tieuchuanService.update(this.standard).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    } else {
      this.tieuchuanService.create(this.standard).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }
  }

  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'tieuChuanListModification',
      content: 'TieuChuan',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next('');
    this.unsubscribeAll.complete();
  }
}

@Component({
  selector: 'app-tieu-chuan-create',
  template: '',
})
export class TieuChuanPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<TieuChuanDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
    private donViService: DonViService,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({tieuchuan}: Data) => {
      setTimeout(() => {
        this.donViService.dropdown().subscribe((data) => {
          this.dialogRef = this.dialog.open(TieuChuanDialogComponent, {
            disableClose: true,
            width: '380px',
          });
          this.dialogRef.componentInstance.customerData = data.body;
          if (tieuchuan) {
            this.dialogRef.componentInstance.standard = tieuchuan;
          }
          this.dialogRef.afterClosed().subscribe(
              (result) => {
                this.router.navigate([{outlets: {popup: null}}], {
                  replaceUrl: true,
                  queryParamsHandling: 'merge',
                });
              },
              (reason) => {
                this.router.navigate([{outlets: {popup: null}}], {
                  replaceUrl: true,
                  queryParamsHandling: 'merge',
                });
              },
          );
        });
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách quy trình');
    this.dialogRef = null;
  }
}
