import { HttpParams, HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Search} from '../model/search.model';

/**
 * Criteria Search
 */
export class CriteriaSearch {

  /**
   * Request data
   */
  protected req = new Search();

  /**
   * Init
   */
  constructor() {}

  /**
   * Get filter params match with server structure
   */
  getParams(): HttpParams {
    let params = new HttpParams();

    if (this.req.query) {
      params = params.set('q', this.req.query.toString());
    }

    if (this.req.page) {
      params = params.set('page', this.req.page.toString());
    }

    if (this.req.size) {
      params = params.set('size', this.req.size.toString());
    }

    if (this.req.sort) {
      params = params.set('sort', `${this.req.sort},${this.req.dir}`);
    }

    this.addQueryParams();
    if (this.req.queryParams.length > 0) {
      for (const queryParam of this.req.queryParams) {
        if (queryParam.value !== '') {
          params = params.set(queryParam.key, queryParam.value);
        } else {
          params = params.delete(queryParam.key);
        }
      }
    }

    // TODO: server accept page 0 for first page
    const page: number = params.has('page') ? Number(params.get('page')) : null;
    if (page && page > 0) {
      params = params.set('page', (page - 1).toString());
    }

    return params;
  }

  /**
   * Override this function to include custom query params
   * This func run before getting filter params
   */
  protected addQueryParams(): void { }

  /**
   * Set single query param
   * @param key - key of query param
   * @param value - value of query param
   */
  protected setQueryParam(key: string, value: any): void {
    const item = this.req.queryParams.find(param => param.key === key);
    if (item) {
      item.value = value;
    } else {
      this.req.queryParams.push({key, value});
    }
  }

  public setQueryParams(params: any): void {
    for (const i in params) {
      if (params.hasOwnProperty(i)) {
        this.setQueryParam(i, params[i]);
      }
    }
  }

  /**
   * Get current page
   */
  getCurrentPage(): number {
    return this.req.page;
  }

  /**
   * Get page size;
   */
  getPageSize(): number {
    return this.req.size;
  }

  /**
   * Get page options
   */
  getPageOptions(): number[] {
    return this.req.pageOptions;
  }

  /**
   * Get total count of items
   */
  getTotalCount(): number {
    return this.req.totalCount;
  }

  /**
   * Page change
   * @param page - page index
   */
  setPage(page: number): void {
    this.req.page = page;
  }

  /**
   * Page size change
   * @param pageSize - page size
   */
  setPageSize(pageSize: number): void {
    this.req.size = pageSize;
  }

  /**
   * Set order option
   * @param sort - field to sort
   * @param dir - sort direction
   */
  setOrder(sort: string, dir: string): void {
    this.req.sort = sort;
    this.req.dir = dir;
  }

  setQuery(query: string): void {
    this.req.query = query;
  }

  /**
   * Set activated route snapshot response from server
   * @param res - Response
   */
  setResponse(route: ActivatedRouteSnapshot, res?: HttpResponse<any>): void {
    if (res) {
      let total = res.headers.get('X-Total-Count');
      if (!total) {
        total = res.headers.get('x-vqdapp-total-count');
      }
      this.req.totalCount = Number(total) || res.body.total;
    }

    const size = route.queryParamMap.get('size');
    const page = route.queryParamMap.get('page');
    const query = route.queryParamMap.get('query');

    if (page) {
      this.req.page = Number(page);
    }
    if (size) {
      this.req.size = Number(size);
    }
    if (query) {
      this.req.query = query;
    }
  }

  /**
   * Set activated route data
   * @param route ActivatedRoute
   * @param data snapshot data
   */
  setActivatedRouteData(route: ActivatedRouteSnapshot, data?: any): void {
    for (const param in route.queryParams) {
      if (route.queryParams.hasOwnProperty(param)) {
        const value = route.queryParamMap.get(param);
        this.setQueryParam(param, value);
      }
    }
  }

  /**
   * Get query param with filter type
   *
   * @param {string[]} [only] specific attributes
   * @returns {{[p: string]: any}}
   * @memberof CriteriaSearch
   */
  getFilterParams(only?: string[]): {[p: string]: any} {
    const params = {};
    let p: string;
    for (const attr in this) {
      if (this.hasOwnProperty(attr)) {
        if (typeof this[attr] === 'function' || !attr
          || typeof this[attr] === 'undefined' || (Array.isArray(only) && !only.includes(attr))) {
          continue;
        }
        // @ts-ignore
        p = this[attr].filterParamStr;
        if (p) {
          params[p] = this.formatFilterValue(this[attr]);
        }
      }
    }
    return params;
  }

  /**
   * Format query param with filter type
   *
   * @param {*} field
   * @returns {*}
   * @memberof CriteriaSearch
   */
  formatFilterValue(field: any): any { return field.filterValue; }


  /**
   * Set FormGroup controls value
   *
   * @param {*} [controls]
   * @memberof CriteriaSearch
   */
  setFilterForm(controls?: any): void { }
}
