import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {GiongService} from '../giong.service';
import {Giong} from '../giong.model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-giong-mgmt-delete-dialog',
  templateUrl: './giong-delete-dialog.component.html',
})
export class GiongMgmtDeleteDialogComponent {
  giong: Giong;

  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }

  constructor(
    private dialogRef: MatDialogRef<GiongMgmtDeleteDialogComponent>,
    private giongService: GiongService,
    private eventManager: JhiEventManager,
  ) {}

  confirmDelete(giongId) {
    this.giongService.delete(giongId).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'giongListModification',
        content: 'Giong',
      });
      this.dialogRef.close(true);
    });
  }
}

@Component({
  selector: 'app-giong-mgmt-delete-popup',
  template: '',
  styleUrls: ['./giong-delete-dialog.component.scss'],
})
export class GiongMgmtDeletePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<GiongMgmtDeleteDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa giống');
    this.activatedRoute.data.subscribe(({giong}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(GiongMgmtDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        if (giong) {
          this.dialogRef.componentInstance.giong = giong.tbSuppliesDTO;
        }
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách giống');
    this.dialogRef = null;
  }
}
