export const EntityTypes = {
    BLOCK: 1,
    HOUSE: 2,
    WAREHOUSE: 3,
};

export interface ILoaiKhuVuc {
    id?: number;
    name?: string;
}

export class LoaiKhuVuc implements ILoaiKhuVuc {
    constructor(
        public id?: number,
        public name?: string
    ) {
        this.id = id ? id : null;
        this.name = name ? name : null;
    }
}
