import {PageEvent} from '@angular/material';
import {VatTuService} from './../vat-tu.service';
import {VatTuBlockDetailsSearch} from './vat-tu-block-details.search';
import {Subscription} from 'rxjs';
import {ISupplyBlockDetails} from './../vat-tu.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'app-vat-tu-block-details',
  templateUrl: './vat-tu-block-details.component.html',
  styleUrls: ['./vat-tu-block-details.component.scss']
})
export class VatTuBlockDetailsComponent implements OnInit, OnDestroy {
  dataSource: ISupplyBlockDetails[];
  eventSubscriber: Subscription;
  searchService = new VatTuBlockDetailsSearch();
  displayedColumns: string[] = ['tbWarehouseName', 'code', 'quantity', 'mfgDate', 'expDate', 'action'];
  supplyInWarehouseUrlPrefix: string;
  supplyId: any;
  constructor(
    private readonly route: ActivatedRoute,
    private eventManager: JhiEventManager,
    private service: VatTuService,
  ) {
    this.dataSource = [];
    this.searchService.setPageSize(10);
    this.supplyInWarehouseUrlPrefix = '';
  }

  ngOnInit() {
    this.eventSubscriber = this.eventManager.subscribe(
      'vaiTroBlockDetailsModification',
      () => this.search(),
    );
    this.supplyId = this.route.snapshot.paramMap.get('vattu');
    const supplyIdGiong = this.route.snapshot.paramMap.get('giong');
    if (supplyIdGiong || this.supplyId) {
      if (supplyIdGiong) {
        this.supplyId = supplyIdGiong;
        this.supplyInWarehouseUrlPrefix = 'cap-nhat-giong-luu-kho';
      } else if (this.supplyId) {
        this.supplyInWarehouseUrlPrefix = 'cap-nhat-vat-tu-luu-kho';
      }
      this.searchService.supplyId.filterValue = this.supplyId;
      this.search();
    }
  }

  ngOnDestroy() {
    if (this.eventSubscriber) {
      this.eventManager.destroy(this.eventSubscriber);
    }
  }

  search(newQueryParams?: any): void {
    this.getList(newQueryParams);
  }

  getList(params?: any): void {
    const filterParams = this.searchService.getFilterParams();
    this.service.queryBlockDetails(filterParams)
      .subscribe((res: HttpResponse<ISupplyBlockDetails[]>) => {
        if (res) {
          this.dataSource = res.body;
        }
      }, (res: HttpResponse<ISupplyBlockDetails[]>) => console.log(res));
  }

  onPageChange(event: PageEvent) {
    this.searchService.setPage(event.pageIndex + 1);
    this.searchService.setPageSize(event.pageSize);
    this.search();
  }
}
