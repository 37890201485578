import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {DATE_FORMAT} from 'src/app/shared/constants/input.constants';
import {Title} from '@angular/platform-browser';
import {NotificationService} from '../../shared/services/notification.service';
import {
  INotification,
  Notification,
} from '../../shared/model/notification.model';
import {ITEMS_PER_PAGE} from '../../shared';
// @ts-ignore
import {InfiniteScroll} from 'ngx-infinite-scroll';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationDialogComponent implements OnInit {
  dateFormat: string = DATE_FORMAT;
  notificationData: INotification;
  selector = '.mat-dialog-content';
  checked = false;
  page = 0;
  loading = true;
  constructor(
    private dialogRef: MatDialogRef<NotificationDialogComponent>,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.notificationData = new Notification();
  }

  ngOnInit() {
    this.titleService.setTitle('Thông báo');
    this.getListNotification(false);
  }
  readAll() {
    if (this.checked) {
      this.notificationService.readAllNotification(1).subscribe(() => {
        this.page = 0;
        this.getListNotification(true);
        this.eventManager.broadcast({
          name: 'notificationModification',
          content: null,
        });
      });
    }
  }
  getListNotification(isRead: boolean) {
    this.loading = true;
    const params = {
      page: this.page,
      size: ITEMS_PER_PAGE,
      sort: ['sendDate,DESC'],
    };
    this.notificationService
      .getListNotification(params)
      .subscribe((res: any) => {
        this.loading = false;
        if (isRead) {
          this.notificationData.tbNotificationDTOS =
            res.body.tbNotificationDTOS;
        } else {
          this.notificationData.tbNotificationDTOS = [
            ...this.notificationData.tbNotificationDTOS,
            ...res.body.tbNotificationDTOS,
          ];
        }
      });
  }
  redirectTo(data: any) {
    this.dialogRef.close(true);
    this.notificationService
      .readNotification({
        id: data.id,
        isRead: 1,
      })
      .subscribe(() => {
        this.eventManager.broadcast({
          name: 'notificationModification',
          content: 'notification',
        });
        if (data.contents === 'PIC_UPDATE') {
          this.router.navigate(['/chi-tiet-lo', data.tbCropId]);
        } else if (['ACTIVITY_CREATE', 'ACTIVITY_UPDATE'].includes(data.contents)) {
          this.router.navigate(['/chi-tiet-hanh-dong', data.activityTypeId, data.externalId]);
        } else {
          this.router.navigate([{ outlets: { popup: ['chi-so-va-dieu-khien', data.tbCropId, data.tbEntityId] }}]);
        }
      });
  }
  onScrollDown() {
    this.page += 1;
    this.getListNotification(false);
  }
}

@Component({
  selector: 'app-notification-popup',
  template: '',
})
export class NotificationPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<NotificationDialogComponent>;
  dateFormat: string = DATE_FORMAT;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({}) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(NotificationDialogComponent, {
          disableClose: true,
          width: '600px',
        });

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Smart Farm');
    this.dialogRef = null;
  }
}
