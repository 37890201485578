export interface ILatLng {
    lat?: number;
    lng?: number;
}

interface IProperties {
    name?: string;
    popupContent?: string;
}

interface IGeometry {
    type?: string;
    coordinates?: number[][];
}

interface IFeature {
    geometry?: IGeometry;
    properties?: IProperties;
    type?: string;
}

interface ILayer {
    defaultOptions?: any;
    feature?: IFeature;
    options?: any;

    _leaflet_id?: number;

    getLatLngs(): ILatLng[][];
    getLatLng(): ILatLng;
    setLatLngs(latLngs: ILatLng[]): void;
    setLatLng(latLng: ILatLng): void;
}

export interface ILeafletLayer {
    layer?: ILayer;
    latLngs?: ILatLng[];
    latLng?: ILatLng;
    getFeatureName(): void;
    updateFeaturePoints(): void;
}

export class LeafletLayer implements ILeafletLayer {
    constructor(
        public layer?: ILayer,
        public latLngs?: ILatLng[],
        public latLng?: ILatLng,
    ) {
        this.layer = layer ? layer : null;
        this.latLngs = latLngs ? latLngs : [];
        this.latLng = latLng ? latLng : null;

        if (this.layer) {
            const feature = this.layer.feature;
            const geometry = feature.geometry;
            switch (feature.type) {
                case 'Feature':
                switch (geometry.type) {
                    case 'Polygon':
                    this.latLngs = this.layer.getLatLngs().find(() => true);
                    break;
                    case 'Point':
                    this.latLng = this.layer.getLatLng();
                    break;
                }
                break;
            }
        }
    }

    assign(props?: ILayer): void {
        if (props) {
            for (const i in props) {
                if (typeof this[i] !== 'undefined' && typeof this[i] !== 'function') {
                    this[i] = props[i];
                }
            }
        }
    }

    getFeatureName() {
        return this.layer ? this.layer.feature.properties.name : '';
    }

    updateFeaturePoints() {
        if (this.layer) {
            const feature = this.layer.feature;
            const geometry = feature.geometry;
            switch (feature.type) {
                case 'Feature':
                switch (geometry.type) {
                    case 'Polygon':
                    this.layer.setLatLngs(this.latLngs);
                    break;
                    case 'Point':
                    this.layer.setLatLng(this.latLng);
                    break;
                }
                break;
            }
        }
    }
}
