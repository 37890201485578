import {Permission, IVaiTro} from './../../vai-tro/vai-tro.model';
import {IPermission, IPermissionGroup} from '../../vai-tro';
import {HttpResponse} from '@angular/common/http';
import {Component, Input, OnChanges, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs';
import {VaiTroService} from '../../vai-tro/vai-tro.service';

@Component({
  selector: 'app-don-vi-rule',
  templateUrl: './don-vi-rule.component.html',
  styleUrls: ['./don-vi-rule.component.scss']
})
export class DonViRuleComponent implements OnInit, OnChanges, OnDestroy {
  dataSource: IPermissionGroup[];
  eventSubscriber: Subscription;
  @Input() selectedRole: IVaiTro;
  selected: IPermission;

  constructor(
    private readonly route: ActivatedRoute,
    private eventManager: JhiEventManager,
    private service: VaiTroService,
  ) {
    this.selected = new Permission();
  }

  ngOnInit() {
    this.eventSubscriber = this.eventManager.subscribe(
      'khuVucModification',
      () => this.search(),
    );

  }

  ngOnChanges() {
    this.search();
  }

  ngOnDestroy() {
    if (this.eventSubscriber) {
      this.eventManager.destroy(this.eventSubscriber);
    }
  }

  search(newQueryParams?: any): void {
    this.getList(newQueryParams);
  }

  getList(params?: any): void {
    const donViId = this.route.snapshot.paramMap.get('donVi');
    if (!(this.selectedRole && this.selectedRole.id && donViId)) {
      return;
    }
    const req = {};
    this.service.permissionByRole(this.selectedRole.id)
      .subscribe((res: HttpResponse<IPermissionGroup[]>) => {
        const result = res.body;
        if (result) {
          this.dataSource = result.map(perGroup => {
            perGroup.permissionDTOList.map((per: IPermission) => {
              const rule = per.casbinDTOList.find(item => item.tbRoleId === this.selectedRole.id);
              per.hasPermission = rule ? (rule.v4 === '*' || rule.v4 === String(true)) : false;
              per.hasPerRuleId = rule ? `hasPerRuleR${rule.tbRoleId}F${rule.tbFunctionId}` : '';
              per.slug = rule ? rule.v5 : '';
              return per;
            });
            perGroup.isSelected = perGroup.permissionDTOList.filter(i => i.hasPermission).length > 0;
            return perGroup;
          });
        }
      },
        (res: HttpResponse<IPermissionGroup>) => console.log(res),
      );
  }

  parentCheck(parentObj: IPermissionGroup) {
    parentObj.permissionDTOList.forEach(i => {
      i.hasPermission = parentObj.isSelected;
    });
  }

  childCheck(parentObj: IPermissionGroup, childObj: IPermission[]) {
    parentObj.isSelected = childObj.every(function (itemChild: any) {
      return itemChild.hasPermission === true;
    });
  }

  savePermission() {
    const checkedIds = [];
    this.dataSource.forEach((element: IPermissionGroup) => {
      element.permissionDTOList.forEach((subItem: IPermission) => {
        const defaultRule = subItem.casbinDTOList[0];
        if (subItem.hasPermission && defaultRule) {
          checkedIds.push(defaultRule.id);
        }
      });
    });
    const promises = [];
    if (checkedIds.length > 0) {
      promises.push(this.service.updatePermissionByRole({listId: checkedIds, v4: true}).toPromise());
    }
    if (!promises.length) {
      return;
    }
    Promise.all(promises).then(values => {
      const [res1, res2] = values;
      if (res1) {
        console.log(res1);
      }
      if (res2) {
        console.log(res2);
      }
      this.search();
    }).catch(reason => console.warn(reason));
  }
}
