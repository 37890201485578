import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Principal} from '../../core';
import {LocalStorageService} from 'ngx-webstorage';
import {Permission} from 'src/app/core/user/permisions.service';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *   <some-element *appHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *   <some-element *appHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
@Directive({
  selector: '[appHasAnyAuthority]',
})
export class HasAnyAuthorityDirective {
  private authorities: string[];

  constructor(
    private permission: Permission,
    private localStorage: LocalStorageService,
    private principal: Principal,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  @Input()
  set appHasAnyAuthority(value: string | string[]) {
    this.authorities = typeof value === 'string' ? [value] : value;
    this.updateView();
    // Get notified each time authentication state changes.
    this.principal
      .getAuthenticationState()
      .subscribe((identity) => this.updateView());
  }

  private updateView(): void {
    const roleId = this.localStorage.retrieve('account.roleId');
    if (roleId) {
      this.permission.permissionUser(roleId);
      this.principal
        .hasAnyAuthority(this.authorities, this.permission.listPermission)
        .then((result) => {
          this.viewContainerRef.clear();
          if (result) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        });
    }
  }
}
