import {FilterOperator} from 'src/app/shared';
import {DashboardTypeService} from './dashboard-type/dashboard-type.service';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import {IDonViUser} from '../entities/don-vi/don-vi.model';
import {IEntityLocation} from './../entities/entity-location/entity-location.model';
import {EntityLocationService} from './../entities/entity-location/entity-location.service';
import {KhuVucService} from './../entities/khu-vuc/khu-vuc.service';
import {HomeService} from './home.service';
import {IKhuVuc} from './../entities/khu-vuc/khu-vuc.model';
import {IDashboardType} from './dashboard-type/dashboard-type';

@Injectable({providedIn: 'root'})
export class HomeResolve implements Resolve<{
  customer: IDonViUser,
  entities: IKhuVuc[],
  entityLocations: IEntityLocation[],
  dashboardTypes: IDashboardType[],
}> {
  constructor(
    private homeService: HomeService,
    private entityService: KhuVucService,
    private entityLocationService: EntityLocationService,
    private dashboardTypeService: DashboardTypeService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state?: RouterStateSnapshot, ): Promise<{
    customer: IDonViUser,
    entities: IKhuVuc[],
    entityLocations: IEntityLocation[],
    dashboardTypes: IDashboardType[],
    houses: IKhuVuc[],
  }> {
    const customer =  await this.homeService.DetailDonVi().pipe(map((donvi) => donvi.body)).toPromise();
    if (!customer) {
      return Promise.reject('Customer is empty.');
    }
    const req1 = {sort: ['tbEntityTypeId,asc', 'name,asc']};

    const res1 = await this.entityService.queryByCustomerId(customer.id, req1).toPromise();
    const res2 = await this.entityLocationService.findId(customer.id).toPromise();
    const res3 = await this.dashboardTypeService.query().toPromise();
    const res4 = await this.homeService.ListNhaMang(customer.id, {exceptAlert: true}).toPromise();

    return {customer, entities: res1.body, entityLocations: res2.body, dashboardTypes: res3.body, houses: res4.body};
  }
}
