import {plainToClass} from 'class-transformer';
import {IDashboardType} from '../../../home/dashboard-type/dashboard-type';
import {VaiTro} from './../../vai-tro/vai-tro.model';
import {IVaiTro} from 'src/app/entities/vai-tro/vai-tro.model';
import { Component, OnInit, OnDestroy, ViewChild, AfterContentInit } from '@angular/core';
import { DonViUser, IDonViUser } from '../don-vi.model';
import {ActivatedRoute} from '@angular/router';
import { JhiEventManager } from 'ng-jhipster';
import { DonViService } from '../don-vi.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-don-vi-detail',
  templateUrl: './don-vi-detail.component.html',
  styleUrls: ['./don-vi-detail.component.scss']
})
export class DonViDetailComponent implements OnInit, OnDestroy {
  donVi: IDonViUser;
  donViId: any;
  eventSubscriber: Subscription;
  selectedRole: IVaiTro;
  dashboardTypes: IDashboardType[];

  constructor(
    private eventManager: JhiEventManager,
    private activatedRoute: ActivatedRoute,
    private service: DonViService,
  ) {
    this.donVi = new DonViUser();
    this.selectedRole = new VaiTro();
    this.dashboardTypes = [];
  }

  ngOnInit() {
    this.donViId = this.activatedRoute.snapshot.paramMap.get('donVi');
    this.activatedRoute.data.subscribe((data: any) => {
      const {donVi, dashboardTypes}: {
        donVi: IDonViUser, dashboardTypes: IDashboardType[],
      } = data;
      this.donVi = donVi;
      if (dashboardTypes) {
        this.dashboardTypes = dashboardTypes;
        this.donVi.tbDashboardType = dashboardTypes.find(i => i.id === this.donVi.tbDashboardTypeId);
      }
    });
    this.eventSubscriber = this.eventManager.subscribe(
      'donViModification',
      (eventContent) => this.getDetail(eventContent),
    );
  }

  getDetail(eventContent?: any) {
    this.service
    .find(this.donViId).subscribe(res => {
      this.donVi = plainToClass(DonViUser, res.body);
      this.donVi.tbDashboardType = this.dashboardTypes.find(i => i.id === this.donVi.tbDashboardTypeId);
    });
  }

  ngOnDestroy() {
    // this.eventManager.destroy(this.eventSubscriber);
  }

  selectRole(item: IVaiTro) {
    this.selectedRole = {...item};
  }
}
