import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {SanPhamService} from '../san-pham.service';
import {JhiEventManager} from 'ng-jhipster';

@Component({
  selector: 'app-san-pham-delete-history-dialog',
  templateUrl: './san-pham-delete-history-dialog.component.html',
})
export class SanPhamDeleteHistoryDialogComponent implements OnInit {
  historyId: any;

  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }

  constructor(
    private dialogRef: MatDialogRef<SanPhamDeleteHistoryDialogComponent>,
    private sanPhamService: SanPhamService,
    private eventManager: JhiEventManager,
  ) {}

  confirmDelete() {
    this.sanPhamService.deleteStockUpdateHistories(this.historyId).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'sanPhamListModification',
        content: 'SanPham',
      });
      // this.eventManager.broadcast({
      //   name: 'vaiTroBlockDetailsModification',
      //   content: this.historyId,
      // });
      this.dialogRef.close(true);
    });
  }

  ngOnInit() {
  }

}

@Component({
  selector: 'app-san-pham-delete-history',
  template: '',
  styleUrls: ['./san-pham-delete-history-dialog.component.scss'],
})
export class SanPhamDeleteHistoryPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<SanPhamDeleteHistoryDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa lịch sử lô sản phẩm');
    this.activatedRoute.params.subscribe((history) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(SanPhamDeleteHistoryDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        this.dialogRef.componentInstance.historyId = history.id;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.dialogRef = null;
    this.titleService.setTitle('Danh sách lịch sử lô sản phẩm');
  }
}
