import {HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {VaiTroService} from './vai-tro.service';
import {IVaiTro, VaiTro} from './vai-tro.model';
import {Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class VaiTroResolve implements Resolve<IVaiTro> {
  constructor(private service: VaiTroService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((role: HttpResponse<VaiTro>) => role.body));
    }
    return of(new VaiTro());
  }
}
