import {ICrops} from './../../canh-tac/canh-tac.model';
import {Component, OnDestroy, OnInit, RendererStyleFlags2} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
// import {IInWareHouse, InWareHouse, ISupplyBlockDetails, SupplyBlockDetails, SupplyStockUpdateAction, VatTu} from '../vat-tu.model';
import {
  IProduct,
  Product,
  IProductBatches,
  ProductBatches,
  IWarehouseProduct,
  WarehouseProduct,
  IImportWarehouseProduct,
  ImportWarehouseProduct,
  SupplyStockUpdateAction
} from '../san-pham.model';
import { ILo, Lo } from '../../canh-tac/canh-tac.model';
import {SanPhamService} from '../san-pham.service';
import {CanhTacService} from '../../canh-tac/canh-tac.service';
import {GiongService} from '../../giong/giong.service';
import {VatTuService} from '../../vat-tu/vat-tu.service';
// @ts-ignore
import moment from 'moment';
import {JhiEventManager} from 'ng-jhipster';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-san-pham-warehouse-storage-dialog',
  templateUrl: './san-pham-warehouse-storage-dialog.component.html',
  styleUrls: ['./san-pham-warehouse-storage-dialog.component.scss']
})
export class SanPhamWarehouseStorageDialogComponent implements OnInit {
  isSaving: boolean;
  sanPham: IProduct;
  currentAction$: any;
  listWareHouse$: any;
  inputWarehouse: IWarehouseProduct;
  inputImportWarehouse: IImportWarehouseProduct;
  maxData: any;
  listLo$: ICrops[];
  listUnit$: any;
  actions = SupplyStockUpdateAction;
  listLoaiSanPham: number[] = [1, 2, 3];
  constructor(
    private dialogRef: MatDialogRef<SanPhamWarehouseStorageDialogComponent>,
    private sanPhamService: SanPhamService,
    private canhTacService: CanhTacService,
    private giongService: GiongService,
    private eventManager: JhiEventManager,
  ) {
    this.isSaving = false;
    this.listWareHouse$ = [];
    this.inputWarehouse = new WarehouseProduct();
    this.inputImportWarehouse = new ImportWarehouseProduct();
    this.maxData = null;
  }

  minExpDate() {
    return moment(this.inputWarehouse.harvestDate).add(1, 'day');
  }

  maxHarvestDate() {
    return moment(this.inputWarehouse.expDate).subtract(1, 'day');
  }

  isValidForm(ngForm: NgForm) {
    let isValidExpDate = this.inputWarehouse.expDate > this.inputWarehouse.updateDate;

    // const isValidExpDate = moment(this.sanPham.expDate,'YYYY-MM-DDTHH:mm:ss') > moment(this.inputWarehouse.updateDate,'YYYY-MM-DDTHH:mm:ss');
    if (this.currentAction$ === this.actions.EXPORT) {
      console.log(moment(this.sanPham.expDate, 'YYYY-MM-DDTHH:mm:ss'));
      console.log(this.inputWarehouse.updateDate);
      isValidExpDate = moment(this.sanPham.expDate, 'YYYY-MM-DDTHH:mm:ss') > this.inputWarehouse.updateDate;
      if (this.inputWarehouse.quantity > this.sanPham.quantity) {
          return false;
      }
    }
    console.log(ngForm.form.valid);
    console.log(this.isSaving);
    console.log(isValidExpDate);
    return ngForm.form.valid && !this.isSaving && isValidExpDate;
  }

  save() {
    this.isSaving = true;
    if (this.currentAction$ === this.actions.EXPORT) {
      this.inputWarehouse.productBlockDetailsId = this.sanPham.id;
      this.inputWarehouse.cropId = this.sanPham.tbCropId;
      this.inputWarehouse.action = 1;
      this.inputWarehouse.productBlockDetailsId = this.sanPham.id;
      this.inputWarehouse.cropId = this.sanPham.tbCropId;
      // this.inputWarehouse.expDate = moment(this.sanPham.expDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      // this.inputWarehouse.harvestDate = moment(this.sanPham.harvestDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      this.inputWarehouse.productLevel = this.sanPham.productLevel;
      this.inputWarehouse.productUnitId = this.sanPham.tbProductUnitId;
      this.inputWarehouse.warehouseId = this.sanPham.tbWarehouseId;
      this.inputWarehouse.entityId = this.sanPham.tbEntityId;
    } else {
      this.inputWarehouse.action = 0;
      this.inputWarehouse.expDate = moment(this.inputWarehouse.expDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      this.inputWarehouse.harvestDate = moment(this.inputWarehouse.harvestDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }
    this.inputWarehouse.updateDate = moment(this.inputWarehouse.updateDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    console.log(this.inputWarehouse);
    setTimeout(() => {
      this.sanPhamService.importAndExport(this.inputWarehouse).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }, 500);
  }
  loadForm() {
    console.log(this.sanPham);
  }
  private onSaveError() {
    this.isSaving = false;
  }
  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'productListModification',
      content: 'Vattu',
    });
    this.eventManager.broadcast({
      name: 'sanPhamListModification',
      content: this.sanPham,
    });
    this.dialogRef.close(true);
  }
  ngOnInit() {
  }
}

@Component({
  selector: 'app-san-pham-warehouse-storage-popup',
  template: '',
})
export class ImportSanPhamWarehouseStoragePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<SanPhamWarehouseStorageDialogComponent>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private sanPhamService: SanPhamService,
    private canhTacService: CanhTacService,
    private giongService: GiongService,
  ) {}



  ngOnInit(): void {
    this.titleService.setTitle('Nhập kho sản phẩm');
    this.activatedRoute.data.subscribe(({sanPham}: Data) => {
      setTimeout(() => {
        // if (type) {
          const promises: Promise<any>[] = [
            this.canhTacService.listLo().toPromise(),
            this.giongService.listWarehouse().toPromise(),
            this.sanPhamService.listProductUnits().toPromise(),
          ];
          Promise.all(promises).then(values => {
            const [res1, res2, res3] = values;
            this.dialogRef = this.dialog.open(SanPhamWarehouseStorageDialogComponent, {
              disableClose: true,
              width: '800px',
            });
            if (sanPham) {
              this.dialogRef.componentInstance.sanPham = sanPham;
              this.dialogRef.componentInstance.currentAction$ = SupplyStockUpdateAction.EXPORT;
            } else {
              this.dialogRef.componentInstance.currentAction$ = SupplyStockUpdateAction.IMPORT;
              this.dialogRef.componentInstance.listLo$ = res1;
              this.dialogRef.componentInstance.listWareHouse$ = res2;
              this.dialogRef.componentInstance.listUnit$ = res3;
              console.log(this.dialogRef.componentInstance.listLo$);
              console.log(this.dialogRef.componentInstance.listWareHouse$);
              console.log(this.dialogRef.componentInstance.listUnit$);
            }


            this.dialogRef.componentInstance.loadForm();
            this.dialogRef.afterClosed().subscribe(
              (result) => this.previousState(result),
              (reason) => this.previousState(reason),
            );
          }).catch(reason => console.warn(reason));
        // }
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.dialogRef = null;
    this.titleService.setTitle('Danh sách vật tư');
  }

  previousState(result?: any): void {
    const params: any = {
      blockDetailsId: null,
    };
    this.router.navigate([{outlets: {popup: null}}], {
      relativeTo: this.activatedRoute.parent,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
