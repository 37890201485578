import {Injectable, isDevMode} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import {Principal} from '../auth/principal.service';
import {StateStorageService} from './state-storage.service';
import {Permission} from '../user/permisions.service';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({providedIn: 'root'})
export class UserRouteAccessService implements CanActivate {
  constructor(
    private router: Router,
    private principal: Principal,
    private stateStorageService: StateStorageService,
    private permission: Permission,
    private localStorage: LocalStorageService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Promise<boolean> {
    const authorities = route.data['authorities'];
    // We need to call the checkLogin / and so the principal.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.

    return this.checkLogin(authorities, state.url);
  }

  checkLogin(authorities: string[], url: string): Promise<boolean> {
    const principal = this.principal;
    const permission = this.permission;
    return Promise.resolve(
      principal.identity().then((account) => {
        if (account) {
          this.localStorage.clear('account.roleId');
          this.localStorage.store('account.roleId', account.roleId);
          permission.permissionUser(account.roleId);
        }
        if (!authorities || authorities.length === 0) {
          return true;
        }

        if (account) {
          return principal
            .hasAnyAuthority(authorities, permission.listPermission)
            .then((response) => {
              if (response) {
                return true;
              }
              if (isDevMode()) {
                console.error(
                  'User has not any of required authorities: ',
                  authorities,
                );
              }
              return false;
            });
        }

        this.stateStorageService.storeUrl(url);
        this.router.navigate(['accessdenied']).then(() => {
          // only show the login dialog, if the user hasn't logged in yet
          if (!account) {
            this.router.navigate(['login']);
          }
        });
        return false;
      }),
    );
  }
}
