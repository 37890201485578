import {LoaiKhuVucModule} from './../loai-khu-vuc/loai-khu-vuc.module';
import {khuVucRoute} from './khu-vuc.route';
import {RouterModule} from '@angular/router';
import {SharedModule} from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KhuVucComponent } from './khu-vuc/khu-vuc.component';
import {KhuVucDialogComponent, KhuVucPopupComponent} from './khu-vuc-dialog/khu-vuc-dialog.component';
import {KhuVucDeleteDialogComponent, KhuVucDeletePopupComponent} from './khu-vuc-delete-dialog/khu-vuc-delete-dialog.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    KhuVucComponent,
    KhuVucDialogComponent,
    KhuVucPopupComponent,
    KhuVucDeleteDialogComponent,
    KhuVucDeletePopupComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule.forChild(khuVucRoute),
    LoaiKhuVucModule,
  ],
  entryComponents: [
    KhuVucPopupComponent,
    KhuVucDialogComponent,
    KhuVucDeletePopupComponent,
    KhuVucDeleteDialogComponent,
  ],
  exports: [
    KhuVucComponent
  ],
})
export class KhuVucModule { }
