import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import {ThietBiComponent} from './thiet-bi/thiet-bi.component';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {ThietBiPopupComponent} from './thiet-bi-dialog/thiet-bi-dialog.component';
import {ThietBiDeletePopupComponent} from './thiet-bi-delete-dialog/thiet-bi-delete-dialog.component';
import {DanhMucThietBiComponent} from './danh-muc-thiet-bi/danh-muc-thiet-bi.component';
import {DanhMucThietBiPopUpComponent} from './danh-muc-thiet-bi-dialog/danh-muc-thiet-bi-dialog.component';
import {DanhMucThietBiDeletePopupComponent} from './danh-muc-thiet-bi-delete-dialog/danh-muc-thiet-bi-delete-dialog.component';
import {
  CategoryDevice,
  ICategoryDevice,
  IThietbi,
  ThietBi,
} from './thiet-bi.model';
import {ThietBiService} from './thiet-bi.service';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {HistoryActivityThietBiComponent} from './history-activity-thiet-bi/history-activity-thiet-bi.component';
import {
  StorageDanhMucThietBiDialogComponent,
  StorageDanhMucThietBiPopupComponent,
} from './storage-danh-muc-thiet-bi-dialog/storage-danh-muc-thiet-bi-dialog.component';

@Injectable({providedIn: 'root'})
export class ThietBiManagementResolve implements Resolve<IThietbi> {
  constructor(private service: ThietBiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['thietbi'] ? route.params['thietbi'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((thietbi: HttpResponse<IThietbi>) => thietbi.body));
    }
    return of(new ThietBi());
  }
}

@Injectable({providedIn: 'root'})
export class CategoryThietBiManagementResolve
  implements Resolve<ICategoryDevice> {
  constructor(private service: ThietBiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['category'] ? route.params['category'] : null;
    if (id) {
      return this.service
        .findCategoryDevice(id)
        .pipe(map((category: HttpResponse<ICategoryDevice>) => category.body));
    }
    return of(new CategoryDevice());
  }
}

@Injectable({providedIn: 'root'})
export class StorageThietBiManagementResolve implements Resolve<any> {
  constructor(private service: ThietBiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['category'] ? route.params['category'] : null;
    if (id) {
      return this.service
        .warehouseEquipment(id)
        .pipe(map((category: HttpResponse<any>) => category.body));
    }
    return;
  }
}

export const thietBiRoute: Routes = [
  {
    path: 'danh-sach-thiet-bi',
    component: ThietBiComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_list_devices'],
      pageTitle: 'Danh sách thiết bị',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'danh-muc-thiet-bi',
    component: DanhMucThietBiComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_list_category_devices'],
      pageTitle: 'Danh mục thiết bị',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'lich-su-hoat-dong',
    component: HistoryActivityThietBiComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_list_devices'],
      pageTitle: 'Lịch sử hoạt động thiết bị',
    },
    canActivate: [UserRouteAccessService],
  },
];

export const thietBiPopupRoute: Routes = [
  {
    path: 'them-thiet-bi',
    component: ThietBiPopupComponent,
    data: {
      authorities: ['can_create_devices'],
      pageTitle: 'Thêm thiết bị',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'sua-thiet-bi/:thietbi',
    component: ThietBiPopupComponent,
    resolve: {
      thietbi: ThietBiManagementResolve,
    },
    data: {
      authorities: ['can_update_devices'],
      pageTitle: 'Sửa thiết bị',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-thiet-bi/:thietbi',
    component: ThietBiDeletePopupComponent,
    data: {
      authorities: ['can_delete_devices'],
      pageTitle: 'Xóa thiết bị',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'them-danh-muc-thiet-bi',
    component: DanhMucThietBiPopUpComponent,
    data: {
      authorities: ['can_create_category_devices'],
      pageTitle: 'Thêm danh mục thiết bị',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'sua-danh-muc-thiet-bi/:category',
    component: DanhMucThietBiPopUpComponent,
    data: {
      authorities: ['can_update_category_devices'],
      pageTitle: 'Sửa danh mục thiết bị',
    },
    resolve: {
      category: CategoryThietBiManagementResolve,
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xóa-danh-muc-thiet-bi/:category',
    component: DanhMucThietBiDeletePopupComponent,
    data: {
      authorities: ['can_delete_category_devices'],
      pageTitle: 'Xóa mục thiết bị',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'kho-thiet-bi/:category',
    component: StorageDanhMucThietBiPopupComponent,
    resolve: {
      warehouse: StorageThietBiManagementResolve,
    },
    data: {
      authorities: [],
      pageTitle: 'Kho thiết bị',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
];
