import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'src/app/app.constants';
import {Observable, of} from 'rxjs';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {createRequestOption} from 'src/app/shared/util/request-util';
import {map} from 'rxjs/operators';
import {ITieuChuan} from '../../entities/tieu-chuan/tieu-chuan.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private resourceUrl = SERVER_API_URL + 'api/notifications-current-user';
  private token: string;
  constructor(private http: HttpClient) {
    this.token = 'null';
  }
  getListNotification(req?: any): Observable<HttpResponse<any[]>> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, {
      params: options,
      observe: 'response',
    });
  }
  registerNotification(token: string): Observable<HttpResponse<any[]>> {
    return this.http
      .put<any[]>(`${SERVER_API_URL}api/update-fcmToken`, token, {
        observe: 'response',
      })
      .pipe(
        map((res: HttpResponse<any>) => {
          this.token = res.body ? token : 'null';
          return res;
        }),
      );
  }
  unRegisterNotification(): Observable<HttpResponse<any[]>> {
    return this.http.put<any[]>(
      `${SERVER_API_URL}api/delete-fcmToken`,
      this.token,
      {
        observe: 'response',
      },
    );
  }
  readNotification(data: any): Observable<HttpResponse<any[]>> {
    return this.http.put<any[]>(
      `${SERVER_API_URL}api/notifications/update`,
      data,
      {
        observe: 'response',
      },
    );
  }
  readAllNotification(number: number): Observable<HttpResponse<any[]>> {
    return this.http.put<any[]>(
      `${SERVER_API_URL}api/notifications/update-all`,
      number,
      {
        observe: 'response',
      },
    );
  }
  exceedLimitNotification(data: number): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      `${SERVER_API_URL}api/notification/exceed-limit`,
      data,
      {
        observe: 'response',
      },
    );
  }
}
