import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {TieuChuan} from '../tieu-chuan.model';
import {JhiEventManager} from 'ng-jhipster';
import {TieuChuanService} from '../tieu-chuan.service';

@Component({
  selector: 'app-tieu-chuan-delete-date-dialog',
  templateUrl: './tieu-chuan-delete-date-dialog.component.html',
})
export class TieuChuanDeleteDateDialogComponent {
  standard: TieuChuan;

  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }
  constructor(
    private dialogRef: MatDialogRef<TieuChuanDeleteDateDialogComponent>,
    private eventManager: JhiEventManager,
    private tieuchuanService: TieuChuanService,
  ) {}

  confirmDelete(id: any) {
    if (id) {
      this.tieuchuanService.deleteDate(id).subscribe((response) => {
        this.eventManager.broadcast({
          name: 'tieuChuanDetailModification',
          content: 'TieuChuan',
        });
        this.dialogRef.close(true);
      });
    } else {
      this.eventManager.broadcast({
        name: 'tieuChuanDetailModification',
        content: 'TieuChuan',
      });
      this.dialogRef.close(true);
    }
  }
}

@Component({
  selector: 'app-tieu-chuan-delete-date-popup',
  template: '',
  styleUrls: ['./tieu-chuan-delete-date-dialog.component.scss'],
})
export class TieuChuanDeleteDatePopupDiaglogComponent
  implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<TieuChuanDeleteDateDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa chi tiết quy trình theo ngày tuổi');
    this.activatedRoute.data.subscribe(({tieuchuan}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(TieuChuanDeleteDateDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        this.dialogRef.componentInstance.standard = tieuchuan;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }
  ngOnDestroy() {
    this.titleService.setTitle('Danh sách quy trình');
    this.dialogRef = null;
  }
}
