import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared';
import {QrCodeComponent} from './qr-code/qr-code.component';
import {QrCodeRoute} from './qr-code.route';
import {RouterModule, Routes} from '@angular/router';
import {
  QrCodeDialogComponent,
  QrCodePopupComponent,
} from './qr-code-dialog/qr-code-dialog.component';
import {
  QrCodeDialogExportPdfComponent,
  QrCodePopupExportPdfComponent,
} from './qr-code-dialog-export-pdf/qr-code-dialog-export-pdf.component';
import {QrCodeDetailComponent} from './qr-code-detail/qr-code-detail.component';
import {
  QrCodeDeleteDialogComponent,
  QrCodeDeletePopupComponent
} from "./qr-code-delete-dialog/qr-code-delete-dialog.component";
import {
  QrCodeDialogUpdateActivityComponent,
  QrCodePopupUpdateActivityComponent
} from './qr-code-dialog-update-activity/qr-code-dialog-update-activity.component';
import {QrCodeDetailActivityComponent} from './qr-code-detail-activity/qr-code-detail-activity.component';

const ENTITY_STATES = [...QrCodeRoute];

@NgModule({
  declarations: [
    QrCodeComponent,
    QrCodeDialogComponent,
    QrCodePopupComponent,
    QrCodeDialogExportPdfComponent,
    QrCodePopupExportPdfComponent,
    QrCodeDetailComponent,
    QrCodeDeletePopupComponent,
    QrCodeDeleteDialogComponent,
    QrCodeDialogUpdateActivityComponent,
    QrCodePopupUpdateActivityComponent,
    QrCodeDetailActivityComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
  ],
  entryComponents: [QrCodeDialogComponent, QrCodeDialogExportPdfComponent, QrCodeDeleteDialogComponent, QrCodeDialogUpdateActivityComponent],
})
export class QrCodeModule {}
