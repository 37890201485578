import {Routes} from '@angular/router';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {BaoCaoComponent} from './bao-cao/bao-cao.component';

export const baoCaoRoute: Routes = [
  {
    path: 'bao-cao-thong-ke',
    component: BaoCaoComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_report_monthly_activity', 'can_report_garden', 'can_report_seedusage', 'can_report_exportwarehouse', 'can_report_importmaterial'],
      pageTitle: 'Báo cáo thống kê',
    },
    canActivate: [UserRouteAccessService],
  },
];
