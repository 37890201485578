import {Moment} from 'moment';

export interface ITask {
  name?: string;
  completed?: boolean;
  subtasks?: ITask[];
}

export interface ITieuChuan {
  id?: number;
  code?: string;
  name?: string;
  duration?: number;
  description?: string;
  createdDate?: Moment;
  modifiedDate?: Moment;
  deletedDate?: Moment;
  createdById?: number;
  modifiedById?: any;
  deletedById?: any;
  tbCustomerId?: any;
  tbSupplies?: any;
}
export class TieuChuan implements ITieuChuan {
  constructor(
    public id?: number,
    public code?: string,
    public name?: string,
    public duration?: number,
    public description?: string,
    public createdDate?: Moment,
    public modifiedDate?: Moment,
    public deletedDate?: Moment,
    public createdById?: number,
    public modifiedById?: any,
    public deletedById?: any,
    public tbCustomerId?: any,
    public tbSupplies?: any,
  ) {
    this.id = id ? id : null;
    this.code = code ? code : null;
    this.name = name ? name : null;
    this.duration = duration ? duration : null;
    this.description = description ? description : null;
    this.createdDate = createdDate ? createdDate : null;
    this.modifiedDate = modifiedDate ? modifiedDate : null;
    this.deletedDate = deletedDate ? deletedDate : null;
    this.createdById = createdById ? createdById : null;
    this.modifiedById = modifiedById ? modifiedById : null;
    this.deletedById = deletedById ? deletedById : null;
    this.tbCustomerId = tbCustomerId ? tbCustomerId : null;
    this.tbSupplies = tbSupplies ? tbSupplies : [];
  }
}

export class IStandardDate {
  id?: number;
  ageDayStart?: number;
  ageDayEnd?: number;
  tbGuidelineId?: number;
  tbGuidelinePeriodId?: number;
}

export interface ILimit {
  upperLimit?: number;
  lowerLimit?: number;
  tbEnvironmentalParameterId?: number;
}

export interface IEnvironment {
  id?: number;
  tbEnvironmentalParameterId?: number;
  key?: number;
  name?: string;
  unit?: any;
  upperLimit?: any;
  lowerLimit?: any;
  description?: string;
  createdDate?: Moment;
  modifiedDate?: Moment;
  deletedDate?: Moment;
  deletedById?: any;
  createdById?: any;
  modifiedById?: any;
  tbCustomerId?: any;
}

export interface IGrow {
  density?: string;
  description?: string;
  activityTypeName?: string;
  suppliesUsing?: [];
}

export class Grow implements IGrow {
  constructor(
    public density?: string,
    public description?: string,
    public activityTypeName?: string,
    public suppliesUsing?: [],
  ) {
    this.density = density ? density : null;
    this.description = description ? description : null;
    this.activityTypeName = 'ACTIVE_TYPE_STATUS_GROW';
    this.suppliesUsing = suppliesUsing ? suppliesUsing : [];
  }
}

export interface INursery {
  substratesId?: number;
  seedLength?: number;
  seedIncubationTime?: number;
  description?: string;
  activityTypeName?: string;
}

export class Nursery implements INursery {
  constructor(
    public substratesId?: number,
    public seedLength?: number,
    public seedIncubationTime?: number,
    public description?: string,
    public activityTypeName?: string,
  ) {
    this.substratesId = substratesId ? substratesId : null;
    this.seedLength = seedLength ? seedLength : null;
    this.seedIncubationTime = seedIncubationTime ? seedIncubationTime : null;
    this.description = description ? description : null;
    this.activityTypeName = 'ACTIVE_TYPE_NURSERY';
  }
}

export interface IManuring {
  quarantinePeriod?: number;
  weatherConditions?: string;
  description?: string;
  purpose?: string;
  activityTypeName?: string;
  suppliesUsing?: [];
}

export class Manuring implements IManuring {
  constructor(
    public quarantinePeriod?: number,
    public weatherConditions?: string,
    public description?: string,
    public purpose?: string,
    public activityTypeName?: string,
    public suppliesUsing?: [],
  ) {
    this.quarantinePeriod = quarantinePeriod ? quarantinePeriod : null;
    this.weatherConditions = weatherConditions ? weatherConditions : null;
    this.description = description ? description : null;
    this.purpose = purpose ? purpose : null;
    this.activityTypeName = 'ACTIVE_TYPE_MANURING';
    this.suppliesUsing = suppliesUsing ? suppliesUsing : [];
  }
}

export interface ISpray {
  quarantinePeriod?: number;
  resultAfter?: number;
  weatherConditions?: string;
  purpose?: string;
  description?: string;
  activityTypeName?: string;
  suppliesUsing?: [];
}

export class Spray implements ISpray {
  constructor(
    public quarantinePeriod?: number,
    public resultAfter?: number,
    public weatherConditions?: string,
    public purpose?: string,
    public description?: string,
    public activityTypeName?: string,
    public suppliesUsing?: [],
  ) {
    this.quarantinePeriod = quarantinePeriod ? quarantinePeriod : null;
    this.resultAfter = resultAfter ? resultAfter : null;
    this.weatherConditions = weatherConditions ? weatherConditions : null;
    this.purpose = purpose ? purpose : null;
    this.description = description ? description : null;
    this.activityTypeName = 'ACTIVE_TYPE_SPRAY';
    this.suppliesUsing = suppliesUsing ? suppliesUsing : [];
  }
}

export interface IWater {
  waterAt?: Moment;
  amount?: number;
  description?: string;
  activityTypeName?: string;
}
export class Water implements IWater {
  constructor(
    public waterAt?: Moment,
    public amount?: number,
    public description?: string,
    public activityTypeName?: string,
  ) {
    this.waterAt = waterAt ? waterAt : null;
    this.amount = amount ? amount : null;
    this.description = description ? description : null;
    this.activityTypeName = 'ACTIVE_TYPE_USE_WATER';
  }
}

export interface IOther {
  name?: string;
  description?: string;
  activityTypeName?: string;
}

export class Other implements IOther {
  constructor(
    public name?: string,
    public nameTMP?: string,
    public description?: string,
    public activityTypeName?: string,
  ) {
    this.name = name ? name : null;
    this.nameTMP = nameTMP ? name : null;
    this.description = description ? description : null;
    this.activityTypeName = 'ACTIVE_TYPE_OTHER';
  }
}

export interface ISuppliesUsingEquipment {
  dosage?: string;
  howToUse?: string;
  tbSuppliesInWarehouseId?: any;
  tbEquipmentOfCustomerId?: any;
}

export class SuppliesUsingEquipment implements ISuppliesUsingEquipment {
  constructor(
    public dosage?: string,
    public howToUse?: string,
    public tbSuppliesInWarehouseId?: any,
    public tbEquipmentOfCustomerId?: any,
  ) {
    this.dosage = dosage ? dosage : null;
    this.howToUse = howToUse ? howToUse : null;
    this.tbSuppliesInWarehouseId = tbSuppliesInWarehouseId
      ? tbSuppliesInWarehouseId
      : '';
    this.tbEquipmentOfCustomerId = tbEquipmentOfCustomerId
      ? tbEquipmentOfCustomerId
      : '';
  }
}

export interface ISuppliesUsing {
  dosage?: string;
  tbSuppliesInWarehouseId?: any;
}

export class SuppliesUsing implements ISuppliesUsing {
  constructor(public dosage?: string, public tbSuppliesInWarehouseId?: any) {
    this.dosage = dosage ? dosage : null;
    this.tbSuppliesInWarehouseId = tbSuppliesInWarehouseId
      ? tbSuppliesInWarehouseId
      : '';
  }
}

export class IStandardDateDetail {
  id?: number;
  ageDayStart?: number;
  ageDayEnd?: number;
  tbGuidelineId?: number;
  activityGuidelines?: any;
  limits?: any;
  tbGuidelinePeriodId?: number;
}
export class StandardDateDetail implements IStandardDateDetail {
  constructor(
    public id?: number,
    public ageDayStart?: number,
    public ageDayEnd?: number,
    public tbGuidelineId?: number,
    public activityGuidelines?: any,
    public limits?: any,
    public tbGuidelinePeriodId?: number,
  ) {
    this.id = id ? id : null;
    this.ageDayStart = ageDayStart ? ageDayStart : null;
    this.ageDayEnd = ageDayEnd ? ageDayEnd : null;
    this.tbGuidelineId = tbGuidelineId ? tbGuidelineId : null;
    this.tbGuidelinePeriodId = tbGuidelinePeriodId ? tbGuidelinePeriodId : null;
    this.activityGuidelines = activityGuidelines ? activityGuidelines : [];
    this.limits = limits ? limits : [];
  }
}
export const other_activity = {
  ACTIVE_TYPE_CLEAN_THE_GARDEN: 'Vệ sinh vườn',
  ACTIVE_TYPE_PREPARE_THE_GARDEN: 'Chuẩn bị vườn',
  ACTIVE_TYPE_HANG_SECONDS: 'Treo dây',
  ACTIVE_TYPE_POLLINATION: 'Thụ phấn',
  ACTIVE_TYPE_CLEAN_HARVESTING_TOOLS: 'Vệ sinh dụng cụ thu hoạch',
  ACTIVE_TYPE_CLEAN_HANDS_BEFORE_HARVEST: 'Vệ sinh tay trước thu hoạch',
  ACTIVE_TYPE_OTHER: 'Các công việc khác',
};

export class IPeriod {
  id?: number;
  ageDayStart?: number;
  ageDayEnd?: number;
  name?: string;
  tbGuidelineId?: number;
}
export class Period implements IPeriod {
  constructor(
      public id?: number,
      public ageDayStart?: number,
      public ageDayEnd?: number,
      public name?: string,
      public tbGuidelineId?: number,
  ) {
    this.id = id ? id : null;
    this.ageDayStart = ageDayStart ? ageDayStart : null;
    this.ageDayEnd = ageDayEnd ? ageDayEnd : null;
    this.name = name ? name : null;
    this.tbGuidelineId = tbGuidelineId ? tbGuidelineId : null;
  }
}

export class IGuidelineDetail {
  id?: number;
  tbGuidelineActivityDTO?: IGuidelineActivityDTO;
  tbGDObjectUpdateDTOList?: any;
  tbSuppliesUsingDetailsDTOs?: any;
}

export class IGuidelineActivityDTO {
  tbGuidelineDetailsId?: number;
  tbActivityTypeId?: number;
  activityTypeName?: string;
  externalTable?: string;
  id?: any;
}

export class GuidelineActivityDTO implements IGuidelineActivityDTO {
  constructor(
      public tbGuidelineDetailsId?: number,
      public tbActivityTypeId?: number,
      public activityTypeName?: string,
      public externalTable?: string,
      public id?: any,
  ) {
    this.tbGuidelineDetailsId = tbGuidelineDetailsId ? tbGuidelineDetailsId : null;
    this.tbActivityTypeId = tbActivityTypeId ? tbActivityTypeId : null;
    this.activityTypeName = activityTypeName ? activityTypeName : null;
    this.externalTable = externalTable ? externalTable : null;
    this.id = id ? id : null;
  }
}
export class GuidelineDetail implements IGuidelineDetail {
  constructor(
      public id?: number,
      public tbGuidelineActivityDTO?: IGuidelineActivityDTO,
      public tbGDObjectUpdateDTOList?: any,
      public tbSuppliesUsingDetailsDTOs?: any,
  ) {
    this.id = id ? id : null;
    this.tbGuidelineActivityDTO = tbGuidelineActivityDTO ? tbGuidelineActivityDTO : new GuidelineActivityDTO();
    this.tbGDObjectUpdateDTOList = tbGDObjectUpdateDTOList ? tbGDObjectUpdateDTOList : [];
    this.tbSuppliesUsingDetailsDTOs = tbSuppliesUsingDetailsDTOs ? tbSuppliesUsingDetailsDTOs : [];
  }
}
