import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from './../../app.constants';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {ILoaiKhuVuc} from './loai-khu-vuc.model';
import { Injectable } from '@angular/core';

interface Page {
  data: ILoaiKhuVuc[];
}

type EntityResponseType = HttpResponse<ILoaiKhuVuc>;
type EntityPageResponseType = HttpResponse<Page>;
type EntityArrayResponseType = HttpResponse<ILoaiKhuVuc[]>;
type EntityType = ILoaiKhuVuc;

@Injectable({
  providedIn: 'root'
})
export class LoaiKhuVucService {
  private resourceUrl = SERVER_API_URL + 'api/tb-entity-types';
  constructor(
    private http: HttpClient,
  ) { }

  create(entity: EntityType): Observable<EntityResponseType> {
    const copy = this.toResourceEntity(entity);
    return this.http.post<EntityType>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.toEntity(res)));
  }

  update(entity: EntityType): Observable<EntityResponseType> {
    const copy = this.toResourceEntity(entity);
    return this.http.put<EntityType>(this.resourceUrl + '/' + entity.id, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.toEntity(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<EntityType[]>(this.resourceUrl, {params: req, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.toEntities(res)));
  }

  delete(id: string): Observable<EntityResponseType> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  find(id: string): Observable<EntityResponseType> {
    return this.http.get<EntityType>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.toEntity(res)));
  }

  private toResourceEntity(entity: EntityType): EntityType {
    return entity;
  }

  private toEntity(res: EntityResponseType): EntityResponseType {
    return res;
  }

  private toEntities(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body.length > 0) {
    }
    return res;
  }
}
