import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JhiEventManager} from 'ng-jhipster';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {ThietBiService} from '../thiet-bi.service';
import {DanhMucThietBi, IDanhMucThietbi} from '../thiet-bi.model';

@Component({
  selector: 'app-danh-muc-thiet-bi-dialog',
  templateUrl: './danh-muc-thiet-bi-dialog.component.html',
  styleUrls: ['./danh-muc-thiet-bi-dialog.component.scss'],
})
export class DanhMucThietBiDialogComponent implements OnInit {
  isSaving: boolean;
  typeDevice$: any;
  listFile$: any[] = [];
  warningFile$: any[] = [];
  editFile$: any[] = [];
  deleteFile$: any[] = [];
  categoryDevice: DanhMucThietBi;
  constructor(
    private dialogRef: MatDialogRef<DanhMucThietBiDialogComponent>,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private thietBiService: ThietBiService,
  ) {
    this.categoryDevice = new DanhMucThietBi();
  }

  ngOnInit() {
    this.titleService.setTitle('Tạo danh much thiết bị');
  }

  save() {
    const data = new FormData();
    data.append('tbEquipment', JSON.stringify(this.categoryDevice));
    if (this.listFile$) {
      this.listFile$.map((file) => {
        data.append('files', file, file.name);
      });
    }
    if (this.categoryDevice.id) {
      this.thietBiService.updateCategoryDevice(data).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    } else {
      this.thietBiService.createCategoryDevice(data).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }
  }

  upload(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size / 1024 / 1024 < 20) {
        this.listFile$.push(e.target.files[i]);
      } else {
        this.warningFile$.push(e.target.files[i]);
      }
    }
  }

  removeFile(i) {
    this.listFile$.splice(i, 1);
  }
  removeWarningFile(i) {
    this.warningFile$.splice(i, 1);
  }
  removeUpdate(i: number, file: any) {
    this.editFile$.splice(i, 1);
    this.categoryDevice.guideDel.push(file);
  }

  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'categoryDeviceModification',
      content: 'ThietBi',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-them-danh-muc-thiet-bi',
  template: '',
})
export class DanhMucThietBiPopUpComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<DanhMucThietBiDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
    private thietBiService: ThietBiService,
  ) {}
  ngOnInit() {
    this.activatedRoute.data.subscribe(({category}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(DanhMucThietBiDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        if (category) {
          this.dialogRef.componentInstance.editFile$ = category.guide;
          this.dialogRef.componentInstance.categoryDevice = {
            id: category.id,
            sku: category.sku,
            manufacturer: category.manufacturer,
            tbEquipmentTypeId: category.tbEquipmentTypeId,
            specs: category.specs,
            name: category.name,
            guideDel: [],
          };
        }
        this.thietBiService.typeDevice().subscribe((res: any) => {
          this.dialogRef.componentInstance.typeDevice$ = res.body;
        });

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh mục thiết bị');
    this.dialogRef = null;
  }
}
