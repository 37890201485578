import {Moment} from 'moment';


export interface ILocation {
  id?: number;
  name?: string;
  type?: string;
}

export class Location implements ILocation {
  constructor(public id?: number, public name?: string, public type?: string) {
    this.id = id ? id : null;
    this.name = name ? name : null;
    this.type = type ? type : null;
  }
}
export interface IDevice {
  id?: number;
  qrCode?: string;
  name?: string;
  status?: any;
  mode?: number;
  serial?: any;
  createdDate?: Moment;
  modifiedDate?: Moment;
  deletedDate?: Moment;
  tbEquipmentId?: number;
  tbEquipmentName?: string;
  tbWarehouseId?: number;
  tbEntityId?: number;
  createdById?: number;
  modifiedById?: any;
  deletedById?: any;
  equipmentTypeName?: string;
  equipmentTypeId?: any;
  manufacturer?: string;
  sku?: string;
  location?: string;
  tbCustomerId?: any;
}

export class Device implements IDevice {
  constructor(
    public id?: number,
    public qrCode?: string,
    public name?: string,
    public status?: any,
    public mode?: number,
    public serial?: any,
    public createdDate?: Moment,
    public modifiedDate?: Moment,
    public deletedDate?: Moment,
    public tbEquipmentId?: number,
    public tbEquipmentName?: string,
    public tbWarehouseId?: number,
    public tbEntityId?: number,
    public createdById?: number,
    public modifiedById?: any,
    public deletedById?: any,
    public equipmentTypeName?: string,
    public equipmentTypeId?: any,
    public manufacturer?: string,
    public sku?: string,
    public location?: string,
    public tbCustomerId?: any,
  ) {
    this.id = id ? id : null;
    this.qrCode = qrCode ? qrCode : null;
    this.name = name ? name : null;
    this.status = status ? status : null;
    this.mode = mode ? mode : null;
    this.serial = serial ? serial : null;
    this.createdDate = createdDate ? createdDate : null;
    this.modifiedDate = modifiedDate ? modifiedDate : null;
    this.deletedDate = deletedDate ? deletedDate : null;
    this.tbEquipmentId = tbEquipmentId ? tbEquipmentId : null;
    this.tbEquipmentName = tbEquipmentName ? tbEquipmentName : null;
    this.tbWarehouseId = tbWarehouseId ? tbWarehouseId : 1;
    this.tbEntityId = tbEntityId ? tbEntityId : null;
    this.createdById = createdById ? createdById : null;
    this.modifiedById = modifiedById ? modifiedById : null;
    this.deletedById = deletedById ? deletedById : null;
    this.equipmentTypeName = equipmentTypeName ? equipmentTypeName : null;
    this.equipmentTypeId = equipmentTypeId ? equipmentTypeId : null;
    this.manufacturer = manufacturer ? manufacturer : null;
    this.sku = sku ? sku : null;
    this.location = location ? location : null;
    this.tbCustomerId = tbCustomerId ? tbCustomerId : null;
  }
}

export interface ICategoryDevice {
  id?: number;
  sku?: string;
  name?: string;
  manufacturer?: string;
  specs?: any;
  createdDate?: Moment;
  modifiedDate?: Moment;
  deletedDate?: Moment;
  tbEquipmentTypeId?: number;
  tbEquipmentTypeName?: string;
  inventory?: number;
  createdById?: number;
  modifiedById?: any;
  deletedById?: any;
  tbCustomerId?: any;
  guide?: any;
}

export class CategoryDevice implements ICategoryDevice {
  constructor(
    public id?: number,
    public sku?: string,
    public name?: string,
    public manufacturer?: string,
    public specs?: any,
    public createdDate?: Moment,
    public modifiedDate?: Moment,
    public deletedDate?: Moment,
    public tbEquipmentTypeId?: number,
    public tbEquipmentTypeName?: string,
    public inventory?: number,
    public createdById?: number,
    public modifiedById?: any,
    public deletedById?: any,
    public tbCustomerId?: any,
    public guide?: any,
  ) {
    this.id = id ? id : null;
    this.sku = sku ? sku : null;
    this.name = name ? name : null;
    this.manufacturer = manufacturer ? manufacturer : null;
    this.specs = specs ? specs : null;
    this.createdDate = createdDate ? createdDate : null;
    this.modifiedDate = modifiedDate ? modifiedDate : null;
    this.deletedDate = deletedDate ? deletedDate : null;
    this.tbEquipmentTypeId = tbEquipmentTypeId ? tbEquipmentTypeId : null;
    this.tbEquipmentTypeName = tbEquipmentTypeName ? tbEquipmentTypeName : null;
    this.inventory = inventory ? inventory : null;
    this.createdById = createdById ? createdById : null;
    this.modifiedById = modifiedById ? modifiedById : null;
    this.deletedById = deletedById ? deletedById : null;
    this.tbCustomerId = tbCustomerId ? tbCustomerId : null;
    this.guide = guide ? guide : null;
  }
}
// dùng trong create/ update
export interface IThietbi {
  id?: number;
  name?: string;
  tbEquipmentId?: any;
  equipmentTypeId?: any;
  status?: number;
  serial?: string;
  location?: ILocation;
}

export class ThietBi implements IThietbi {
  constructor(
    public id?: number,
    public name?: string,
    public tbEquipmentId?: any,
    public equipmentTypeId?: any,
    public status?: number,
    public serial?: string,
    public location?: ILocation,
  ) {
    this.id = id ? id : null;
    this.name = name ? name : null;
    this.tbEquipmentId = tbEquipmentId ? tbEquipmentId : null;
    this.equipmentTypeId = equipmentTypeId ? equipmentTypeId : null;
    this.status = status ? status : null;
    this.serial = serial ? serial : null;
    this.location = location ? location : new Location();
  }
}

export interface IDanhMucThietbi {
  id?: number;
  sku?: string;
  name?: string;
  manufacturer?: string;
  specs?: string;
  tbEquipmentTypeId?: number;
  tbEquipmentTypeName?: string;
  guideDel?: any[];
}

export class DanhMucThietBi implements IDanhMucThietbi {
  constructor(
    public id?: number,
    public sku?: string,
    public name?: string,
    public manufacturer?: string,
    public specs?: string,
    public tbEquipmentTypeId?: number,
    public tbEquipmentTypeName?: string,
    public guideDel?: any[],
  ) {
    this.id = id ? id : null;
    this.sku = sku ? sku : null;
    this.name = name ? name : null;
    this.manufacturer = manufacturer ? manufacturer : null;
    this.specs = specs ? specs : null;
    this.tbEquipmentTypeId = tbEquipmentTypeId ? tbEquipmentTypeId : null;
    this.tbEquipmentTypeName = tbEquipmentTypeName ? tbEquipmentTypeName : null;
    this.guideDel = guideDel ? guideDel : [];
  }
}

export interface IEquipmentHistory {
  id?: number;
  event?: number;
  dateOccurred?: Moment;
  status?: number;
  tbCommandId?: number;
  tbDetailUserId?: number;
  tbEquipmentOfCustomerId?: number;
}

export class EquipmentHistory implements IEquipmentHistory {
  constructor(
    public id?: number,
    public event?: number,
    public dateOccurred?: Moment,
    public status?: number,
    public tbCommandId?: number,
    public tbDetailUserId?: number,
    public tbEquipmentOfCustomerId?: number,
  ) {
    this.id = id ? id : null;
    this.event = event ? event : null;
    this.dateOccurred = dateOccurred ? dateOccurred : null;
    this.status = status ? status : null;
    this.tbCommandId = tbCommandId ? tbCommandId : null;
    this.tbDetailUserId = tbDetailUserId ? tbDetailUserId : null;
    this.tbEquipmentOfCustomerId = tbEquipmentOfCustomerId
      ? tbEquipmentOfCustomerId
      : null;
  }
}
