import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {errorRoute, notificationRoute, controlEnvRoute} from './layouts';
import {DEBUG_INFO_ENABLED} from './app.constants';
import {LoginComponent} from './shared';
import {AuthGuardServiceLogin} from './core/auth/auth-guard-service-login';

const LAYOUT_ROUTES = [notificationRoute, controlEnvRoute, ...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        ...LAYOUT_ROUTES,
        {
          path: 'login',
          component: LoginComponent,
          data: {
            authorities: [],
            pageTitle: 'Đăng nhập',
          },
          canActivate: [AuthGuardServiceLogin],
        },
        {
          path: '',
          // TODO: redirect dashboard to danhsachlo until there is request from dashboard
          redirectTo: 'danh-sach-lo',
          pathMatch: 'full',
        },
      {
          path: 'policy',
          loadChildren: './policy/policy.module#PolicyModule',
          data: {
              authorities: [],
              pageTitle: 'Chính sách bảo mật',
          }
      },
      {
          path: 'support',
          loadChildren: './support/support.module#SupportModule',
          data: {
              authorities: [],
              pageTitle: 'Hỗ trợ',
          }
      },
      ],
      {
         enableTracing: DEBUG_INFO_ENABLED,
         anchorScrolling: 'enabled'
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
