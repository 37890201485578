import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared';
import {RouterModule, Routes} from '@angular/router';
import {thietBiPopupRoute, thietBiRoute} from './thiet-bi.routes';
import {ThietBiComponent} from './thiet-bi/thiet-bi.component';
import {
  ThietBiDeleteDialogComponent,
  ThietBiDeletePopupComponent,
} from './thiet-bi-delete-dialog/thiet-bi-delete-dialog.component';
import {
  ThietBiDialogComponent,
  ThietBiPopupComponent,
} from './thiet-bi-dialog/thiet-bi-dialog.component';
import {DanhMucThietBiComponent} from './danh-muc-thiet-bi/danh-muc-thiet-bi.component';
import {
  DanhMucThietBiDeleteDialogComponent,
  DanhMucThietBiDeletePopupComponent,
} from './danh-muc-thiet-bi-delete-dialog/danh-muc-thiet-bi-delete-dialog.component';
import {
  DanhMucThietBiDialogComponent,
  DanhMucThietBiPopUpComponent,
} from './danh-muc-thiet-bi-dialog/danh-muc-thiet-bi-dialog.component';
import {HistoryActivityThietBiComponent} from './history-activity-thiet-bi/history-activity-thiet-bi.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {
  StorageDanhMucThietBiDialogComponent,
  StorageDanhMucThietBiPopupComponent,
} from './storage-danh-muc-thiet-bi-dialog/storage-danh-muc-thiet-bi-dialog.component';

const ENTITY_STATES = [...thietBiRoute, ...thietBiPopupRoute];

@NgModule({
  declarations: [
    ThietBiComponent,
    ThietBiDeleteDialogComponent,
    ThietBiDialogComponent,
    ThietBiPopupComponent,
    ThietBiDeletePopupComponent,
    DanhMucThietBiComponent,
    DanhMucThietBiDeleteDialogComponent,
    DanhMucThietBiDialogComponent,
    DanhMucThietBiPopUpComponent,
    DanhMucThietBiDeletePopupComponent,
    HistoryActivityThietBiComponent,
    StorageDanhMucThietBiDialogComponent,
    StorageDanhMucThietBiPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
    MatSlideToggleModule,
  ],
  entryComponents: [
    ThietBiDialogComponent,
    ThietBiDeleteDialogComponent,
    DanhMucThietBiDialogComponent,
    DanhMucThietBiDeleteDialogComponent,
    StorageDanhMucThietBiDialogComponent,
  ],
})
export class ThietBiModule {}
