import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router, Data} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {MatDialogRef} from '@angular/material';
import {Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import {IQrcode} from "../qr-code.model";
import {QrCodeService} from "../qr-code.service";
import {IKhuVuc, KhuVuc} from '../../khu-vuc';

@Component({
  selector: 'app-qr-code-delete-dialog',
  templateUrl: './qr-code-delete-dialog.component.html',
  styleUrls: ['./qr-code-delete-dialog.component.scss']
})
export class QrCodeDeleteDialogComponent implements OnInit {
  entity: IQrcode;
  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }
  constructor(
    private dialogRef: MatDialogRef<QrCodeDeleteDialogComponent>,
    private eventManager: JhiEventManager,
    private service: QrCodeService,
  ) { }

  ngOnInit() {
  }


  confirmDelete(id: any) {
    this.service.update(id).subscribe((res) => {
      this.eventManager.broadcast({
        name: 'qrcodeModification',
        content: this.entity,
      });
      this.dialogRef.close(true);
    });
  }
}


@Component({
  selector: 'app-qr-code-delete-popup',
  template: '',
})
export class QrCodeDeletePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<QrCodeDeleteDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({khuVuc}: Data) => {
      setTimeout(() => {
        const donViId = this.activatedRoute.snapshot.paramMap.get('donViId');
        this.dialogRef = this.dialog.open(QrCodeDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        const entity: IKhuVuc = khuVuc || new KhuVuc();
        entity.tbCustomerId = Number(donViId);
        this.dialogRef.componentInstance.entity = entity;
        this.dialogRef.afterClosed().subscribe(
          (result) => this.previousState(result),
          (reason) => this.previousState(reason),
        );
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.dialogRef = null;
  }

  previousState(result?: any): void {
    const params: any = {
      id: null,
    };
    this.router.navigate([{outlets: {popup: null}}], {
      relativeTo: this.activatedRoute.parent,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
