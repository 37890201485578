export interface IActivityType {
  id?: any;
  name?: string;
  description?: string;
  urlLogo?: any;
  isGuidelineUsing?: boolean;

  afterHarvest?: boolean;
}

export class ActivityType implements IActivityType {
  constructor(
    public id?: any,
    public name?: string,
    public description?: string,
    public urlLogo?: any,
    public isGuidelineUsing?: boolean,

    public afterHarvest?: boolean,
  ) {
    this.id = id ? id : null;
    this.description = description ? description : null;
    this.urlLogo = urlLogo ? urlLogo : null;
    this.name = name ? name : null;
    this.isGuidelineUsing = isGuidelineUsing ? isGuidelineUsing : false;
    this.afterHarvest = afterHarvest || false;
  }
}

export interface IDonViUser {
  id?: number;
  name?: string;
  phone?: string;
  email?: string;
  areaM2?: number;
  address?: string;
  wardId?: number;
  ward?: string;
  districtId?: number;
  district?: string;
  cityId?: number;
  city?: string;
  countryId?: number;
  country?: string;
  isActivated?: number;
  createdDate?: any;
  modifiedDate?: any;
  deletedDate?: any;
  tbAddressId?: number;
  createdById?: number;
  modifiedById?: any;
  deletedById?: any;
}
export interface IFormChildList {
  name?: string;
  externalTable?: string;
  objectType?: number;
  condition?: string;
  value?: string;
  isGuidelineUsing?: number;
}
export interface IParam {
  name?: string;
  unit?: string;
  description?: string;
  tbControlTypeId?: number;
  tbActivityExtendTypeDTOList?: IFormChildList[];
  isGuidelineUsing?: number;
}
export interface IActivitySetting {
  tbActivityTypeId?: any;
  formChildList?: IFormChildList[];
  tbObjectParameterList?: IParam[];
}

export class ActivitySetting implements IActivitySetting {
  constructor(
      public tbActivityTypeId?: any,
      public formChildList?: IFormChildList[],
      public tbObjectParameterList?: IParam[],
  ) {
    this.tbActivityTypeId = tbActivityTypeId ? tbActivityTypeId : null;
    this.formChildList = formChildList ? formChildList : [];
    this.tbObjectParameterList = tbObjectParameterList ? tbObjectParameterList : [];
  }
}
export class FormChildList implements IFormChildList {
  constructor(
      public name?: string,
      public externalTable?: string,
      public objectType?: number,
      public condition?: string,
      public value?: string,
      public isGuidelineUsing?: number,
  ) {
    this.name = name ? name : null;
    this.externalTable = externalTable ? externalTable : null;
    this.objectType = objectType ? objectType : null;
    this.condition = condition ? condition : null;
    this.value = value ? value : null;
    this.isGuidelineUsing = isGuidelineUsing ? isGuidelineUsing : 0;
  }
}

export class ParamSetting implements IParam {
  constructor(
      public name?: string,
      public unit?: string,
      public description?: string,
      public tbControlTypeId?: number,
      public tbActivityExtendTypeDTOList?: IFormChildList[],
      public isGuidelineUsing?: number,
  ) {
    this.name = name ? name : null;
    this.unit = unit ? unit : null;
    this.description = description ? description : null;
    this.tbControlTypeId = tbControlTypeId ? tbControlTypeId : null;
    this.tbActivityExtendTypeDTOList = tbActivityExtendTypeDTOList ? tbActivityExtendTypeDTOList : [];
    this.isGuidelineUsing = isGuidelineUsing ? isGuidelineUsing : 0;
  }
}

export interface IActivityParam {
  activityTypeId?: string;
  unit?: string;
  description?: string;
  tbControlTypeId?: number;
  isGuidelineUsing?: boolean;
  isMandatory?: boolean;
  name?: string;
  id?: any;
}

export class ActivityParam implements IActivityParam {
  constructor(
      public id?: any,
      public name?: string,
      public unit?: string,
      public description?: string,
      public tbControlTypeId?: number,
      public isMandatory?: boolean,
      public isGuidelineUsing?: boolean,
      public activityTypeId?: string,
  ) {
    this.id = id ? id : null;
    this.name = name ? name : null;
    this.unit = unit ? unit : null;
    this.description = description ? description : null;
    this.tbControlTypeId = tbControlTypeId ? tbControlTypeId : null;
    this.isMandatory = isMandatory ? isMandatory : false;
    this.isGuidelineUsing = isGuidelineUsing ? isGuidelineUsing : false;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
  }
}

export interface IActivityExtendType {
  condition?: string;
  externalTable?: string;
  id?: any;
}

export class ActivityExtendType implements IActivityExtendType {
  constructor(
      public condition?: string,
      public externalTable?: string,
      public id?: any,
  ) {
    this.condition = condition ? condition : null;
    this.externalTable = externalTable ? externalTable : null;
    this.id = id ? id : null;
  }
}
export interface IActivityObject {
  tbObjectParameterDTO?: IActivityParam;
  tbActivityExtendTypeDTO?: IActivityExtendType;
}

export class ActivityObject implements IActivityObject {
  constructor(
      public tbObjectParameterDTO?: IActivityParam,
      public tbActivityExtendTypeDTO?: IActivityExtendType,
  ) {
    this.tbObjectParameterDTO = tbObjectParameterDTO ? tbObjectParameterDTO : new ActivityParam();
    this.tbActivityExtendTypeDTO = tbActivityExtendTypeDTO ? tbActivityExtendTypeDTO : new ActivityExtendType();
  }
}

export interface IActivityChild {
  isGuidelineUsing?: boolean;
  externalTable?: string;
  id?: any;
  activityTypeId?: any;
  objectType?: number;
}

export class ActivityChild implements IActivityChild {
  constructor(
      public isGuidelineUsing?: boolean,
      public externalTable?: string,
      public id?: any,
      public activityTypeId?: any,
      public objectType?: number,
  ) {
    this.isGuidelineUsing = isGuidelineUsing ? isGuidelineUsing : false;
    this.externalTable = externalTable ? externalTable : null;
    this.id = id ? id : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.objectType = objectType ? objectType : 1;
  }
}
