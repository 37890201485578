import {Moment} from 'moment';

export interface IThuHoach {
  id?: any;
  maTruyXuat?: string;
  ngayThuHoach?: Moment;
  ngayXuatBan?: Moment;
  soLuong?: any;
  giaBan?: any;
  soLuongLoaiBo?: any;
  donViMua?: string;
  soHoaDon?: string;
  userId?: any;
  categoryCode?: string;
  image?: any;
  itemTimeLineId?: any;
  ykienKTV?: string;
  thuHoachSoLuongLoaiBo?: any;
  thuHoachSoLuongTonKho?: any;
  thuHoachSoLuongXuatBan?: any;
  thuHoachTyLeHaoHut?: any;
}

export class ThuHoach implements IThuHoach {
  constructor(
    public id?: any,
    public maTruyXuat?: string,
    public ngayThuHoach?: Moment,
    public ngayXuatBan?: Moment,
    public soLuong?: any,
    public giaBan?: any,
    public soLuongLoaiBo?: any,
    public donViMua?: string,
    public soHoaDon?: string,
    public userId?: any,
    public categoryCode?: string,
    public image?: any,
    public itemTimeLineId?: any,
    public ykienKTV?: string,
    public thuHoachSoLuongLoaiBo?: any,
    public thuHoachSoLuongTonKho?: any,
    public thuHoachSoLuongXuatBan?: any,
    public thuHoachTyLeHaoHut?: any,
  ) {
    this.id = id ? id : null;
    this.maTruyXuat = maTruyXuat ? maTruyXuat : null;
    this.ngayThuHoach = ngayThuHoach ? ngayThuHoach : null;
    this.ngayXuatBan = ngayXuatBan ? ngayXuatBan : null;
    this.soLuong = soLuong ? soLuong : null;
    this.giaBan = giaBan ? giaBan : null;
    this.soLuongLoaiBo = soLuongLoaiBo ? soLuongLoaiBo : null;
    this.donViMua = donViMua ? donViMua : null;
    this.soHoaDon = soHoaDon ? soHoaDon : null;
    this.userId = userId ? userId : null;
    this.categoryCode = categoryCode ? categoryCode : null;
    this.image = image ? image : null;
    this.itemTimeLineId = itemTimeLineId ? itemTimeLineId : null;
    this.ykienKTV = ykienKTV ? ykienKTV : null;
    this.thuHoachSoLuongLoaiBo = thuHoachSoLuongLoaiBo
      ? thuHoachSoLuongLoaiBo
      : null;
    this.thuHoachSoLuongTonKho = thuHoachSoLuongTonKho
      ? thuHoachSoLuongTonKho
      : null;
    this.thuHoachSoLuongXuatBan = thuHoachSoLuongXuatBan
      ? thuHoachSoLuongXuatBan
      : null;
    this.thuHoachTyLeHaoHut = thuHoachTyLeHaoHut ? thuHoachTyLeHaoHut : null;
  }
}

export interface ISauThuHoach {
  id?: any;
  ngay?: Moment;
  title?: any;
  soLuong?: any;
  soLuongLoaiBo?: string;
  nguoiThucHien?: string;
  khachHang?: any;
  categoryCode?: string;
  itemTimeLineId?: any;
}

export class SauThuHoach implements ISauThuHoach {
  constructor(
    public id?: any,
    public ngay?: Moment,
    public title?: any,
    public soLuong?: any,
    public soLuongLoaiBo?: string,
    public nguoiThucHien?: string,
    public khachHang?: any,
    public categoryCode?: string,
    public itemTimeLineId?: any,
  ) {
    this.id = id ? id : null;
    this.ngay = ngay ? ngay : null;
    this.title = title ? title : null;
    this.soLuong = soLuong ? soLuong : null;
    this.soLuongLoaiBo = soLuongLoaiBo ? soLuongLoaiBo : null;
    this.nguoiThucHien = nguoiThucHien ? nguoiThucHien : null;
    this.khachHang = khachHang ? khachHang : null;
    this.categoryCode = categoryCode ? categoryCode : null;
    this.itemTimeLineId = itemTimeLineId ? itemTimeLineId : null;
  }
}
