import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {MatDialog, MatDialogRef} from '@angular/material';
import {VaiTroService} from 'src/app/entities/vai-tro/vai-tro.service';
import {DonViService} from '../../../entities//don-vi/don-vi.service';
import {IVaiTro, VaiTro} from 'src/app/entities/vai-tro/vai-tro.model';
import {IDonVi, DonVi} from '../../../entities//don-vi/don-vi.model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-user-role-update-dialog',
  templateUrl: './user-role-update.component.html',
  styleUrls: ['./user-role-update.component.scss'],
})
export class UserRoleUpdateDialogComponent implements OnInit {
  role: IVaiTro;
  isSaving: boolean;
  isAllCustomer: boolean;
  listAllCustomers$: any;
  constructor(
    private dialogRef: MatDialogRef<UserRoleUpdateDialogComponent>,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private vaiTroService: VaiTroService,
  ) {
    this.isSaving = false;
    this.role = new VaiTro();
    this.role.isAllCrop = false;
    this.role.isAllCustomer = true;
    this.role.tbCustomerId = 0;
  }

  ngOnInit() {
    if (this.role.id !== null) {
      this.titleService.setTitle('Sửa người dùng');
    } else {
      this.titleService.setTitle('Thêm người dùng');
    }
  }

  clear() {
    this.dialogRef.close('cancel');
  }

  save() {
    this.isSaving = true;
    if (this.role.tbCustomerId === 0) {
      this.role.isAllCustomer = true;
    }
    if (this.role.isAllCustomer) {
      this.role.tbCustomerId = null;
    }
    if (this.role.id !== null) {
      console.log('----update----');
      console.log(this.role);
      this.vaiTroService.update(this.role).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError(),
      );
    } else {
      console.log('----create----');
      console.log(this.role);
      this.vaiTroService.create(this.role).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError(),
      );
    }
  }

  onSelectCustomer(event) {
    if (event.value !== 0) {
      this.role.isAllCustomer = false;
      this.role.tbCustomerId = event.value;
    } else {
      this.role.isAllCustomer = true;
    }
    console.log(this.role);
  }

  private onSaveSuccess() {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'userRoleModification',
      content: 'User',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-user-role-update-popup',
  template: '',
})
export class UserRolePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<UserRoleUpdateDialogComponent>;

  constructor(
    private vaiTroService: VaiTroService,
    private donViService: DonViService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: JhiAlertService,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({role}: Data) => {
      setTimeout(() => {
        const promises: Promise<any>[] = [
          this.donViService.getListAllCustomers().toPromise(),
        ];
        Promise.all(promises).then(values => {
          const [res] = values;
          this.dialogRef = this.dialog.open(UserRoleUpdateDialogComponent, {
            disableClose: true,
            width: '500px',
          });
          if (!role.tbCustomerId) {
            role.tbCustomerId = 0;
          }
          this.dialogRef.componentInstance.role = role;
          this.dialogRef.componentInstance.listAllCustomers$ = res;
          this.dialogRef.afterClosed().subscribe(
            // (result) => {
            //   this.router.navigate([{outlets: {popup: null}}], {
            //     replaceUrl: true,
            //     queryParamsHandling: 'merge',
            //   });
            //   this.dialogRef = null;
            // },
            // (reason) => {
            //   this.router.navigate([{outlets: {popup: null}}], {
            //     replaceUrl: true,
            //     queryParamsHandling: 'merge',
            //   });
            //   this.dialogRef = null;
            // },
            (result) => this.previousState(result),
            (reason) => this.previousState(reason),
          );
        }).catch(reason => console.warn(reason));

      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.titleService.setTitle('Phân quyền người dùng');
    this.dialogRef = null;
  }

  previousState(result?: any): void {
    const params: any = {
      blockDetailsId: null,
    };
    this.router.navigate([{outlets: {popup: null}}], {
      relativeTo: this.activatedRoute.parent,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
