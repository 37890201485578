import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AccountService} from './account.service';
import {TrackerService} from '../tracker/tracker.service';
import {User} from '..';

@Injectable({providedIn: 'root'})
export class Principal {
  private userIdentity: any;
  private authenticated = false;
  private authenticationState = new BehaviorSubject<any>(null);
  constructor(
    private account: AccountService,
    private trackerService: TrackerService,
  ) {}
  authenticate(identity: any) {
    this.userIdentity = identity;
    this.authenticated = identity !== null;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(
    authorities: string[],
    permissions: string[],
  ): Promise<boolean> {
    return Promise.resolve(
      this.hasAnyAuthorityDirect(authorities, permissions),
    );
  }

  hasAnyAuthorityDirect(authorities: string[], permissions: string[]): boolean {
    // if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
    //   return false;
    // }

    if (
      !this.authenticated ||
      !this.userIdentity ||
      !this.userIdentity.roleId
    ) {
      return false;
    }

    for (let i = 0; i < authorities.length; i++) {
      if (permissions.includes(authorities[i])) {
        return true;
      }
      // if (this.userIdentity.roleId == authorities[i]) {
      //   return true;
      // }
    }
    return false;
  }

  // async hasAuthority(authority: string): Promise<boolean> {
  //   if (!this.authenticated) {
  //     return Promise.resolve(false);
  //   }

  //   try {
  //     const id = await this.identity();
  //     // return Promise.resolve(id.authorities && id.authorities.includes(authority));
  //     return Promise.resolve(id.roleId && authority.includes(id.roleId));
  //   }
  //   catch (e) {
  //     return Promise.resolve(false);
  //   }
  // }

  hasAuthority(authority: string): Promise<boolean> {
    if (!this.authenticated) {
      return Promise.resolve(false);
    }

    return this.identity().then(
      (id) => {
        return Promise.resolve(id.roleId && authority.includes(id.roleId));
      },
      () => {
        return Promise.resolve(false);
      },
    );
  }

  // async identity(force?: boolean): Promise<any> {
  //   if (force === true) {
  //     this.userIdentity = undefined;
  //   }

  //   // check and see if we have retrieved the userIdentity data from the server.
  //   // if we have, reuse it by immediately resolving
  //   if (this.userIdentity) {
  //     return Promise.resolve(this.userIdentity);
  //   }

  //   // retrieve the userIdentity data from the server, update the identity object, and then resolve.
  //   try {
  //     const response = await this.account
  //       .get()
  //       .toPromise();
  //     const account = response.body;
  //     if (account) {
  //       this.userIdentity = account;
  //       this.authenticated = true;
  //       this.trackerService.connect();
  //     }
  //     else {
  //       this.userIdentity = null;
  //       this.authenticated = false;
  //     }
  //     this.authenticationState.next(this.userIdentity);
  //     return this.userIdentity;
  //   }
  //   catch (err) {
  //     if (this.trackerService.stompClient && this.trackerService.stompClient.connected) {
  //       this.trackerService.disconnect();
  //     }
  //     this.userIdentity = null;
  //     this.authenticated = false;
  //     this.authenticationState.next(this.userIdentity);
  //     return null;
  //   }
  // }

  identity(force?: boolean): Promise<any> {
    if (force === true) {
      this.userIdentity = undefined;
    }

    // check and see if we have retrieved the userIdentity data from the server.
    // if we have, reuse it by immediately resolving
    if (this.userIdentity) {
      return Promise.resolve(this.userIdentity);
    }

    // retrieve the userIdentity data from the server, update the identity object, and then resolve.
    return this.account
      .get()
      .toPromise()
      .then((response) => {
        const account: User = response.body;
        if (account) {
          this.userIdentity = account;
          this.authenticated = true;
          // this.trackerService.connect();
        } else {
          this.userIdentity = null;
          this.authenticated = false;
        }
        this.authenticationState.next(this.userIdentity);
        return this.userIdentity;
      })
      .catch((err) => {
        if (
          this.trackerService.stompClient &&
          this.trackerService.stompClient.connected
        ) {
          // this.trackerService.disconnect();
        }
        this.userIdentity = null;
        this.authenticated = false;
        this.authenticationState.next(this.userIdentity);
        return null;
      });
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isIdentityResolved(): boolean {
    return this.userIdentity !== undefined;
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  getImageUrl(): string {
    return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
  }
}
