import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ILoaiLo} from './loai-lo.model';
import {LoaiLoService} from './loai-lo.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaiLoListResolver implements Resolve<any> {
  constructor(
    private service: LoaiLoService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ILoaiLo[]> | Promise<ILoaiLo[]> | ILoaiLo[] {
    const req = {size: 30};
    return this.service.query(req)
      .pipe(map((res: HttpResponse<ILoaiLo[]>) => res.body));
  }
}
