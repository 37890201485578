import {EntityTypes} from './../loai-khu-vuc/loai-khu-vuc.model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from './../../app.constants';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {IKhuVuc} from './khu-vuc.model';
import { Injectable } from '@angular/core';
import {Area} from "../../shared/model/area.model";

interface Page {
  data: IKhuVuc[];
}

type EntityResponseType = HttpResponse<IKhuVuc>;
type EntityPageResponseType = HttpResponse<Page>;
type EntityArrayResponseType = HttpResponse<IKhuVuc[]>;
type EntityType = IKhuVuc;

@Injectable({
  providedIn: 'root'
})
export class KhuVucService {
  private resourceUrl = SERVER_API_URL + 'api/tb-entities';
  private resourceUrlCustomer = SERVER_API_URL + 'api/tb-entities-by-customer/area';
  private resourceCustomerUrl = SERVER_API_URL + 'api/tb-entities-by-customer-id';
  constructor(
    private http: HttpClient,
  ) { }

  create(entity: EntityType): Observable<EntityResponseType> {
    const copy = this.toResourceEntity(entity);
    return this.http.post<EntityType>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.toEntity(res)));
  }

  update(entity: EntityType): Observable<EntityResponseType> {
    const copy = this.toResourceEntity(entity);
    return this.http.put<EntityType>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.toEntity(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<EntityType[]>(this.resourceUrl, {params: req, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.toEntities(res)));
  }

  queryByCustomerId(customerId, req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<EntityType[]>(`${this.resourceCustomerUrl}/${customerId}`, {params: req, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.toEntities(res)));
  }

  queryByTopLevel(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<EntityType[]>(`${this.resourceUrl}/area`, {params: req, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.toEntities(res)));
  }
  listAreaByCustomer(id: number): Observable<HttpResponse<EntityType[]>> {
    return this.http.get<EntityType[]>(`${this.resourceUrlCustomer}/${id}`, {
      observe: 'response',
    });
  }
  delete(id: string): Observable<EntityResponseType> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  find(id: string): Observable<EntityResponseType> {
    return this.http.get<EntityType>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.toEntity(res)));
  }

  private toResourceEntity(entity: EntityType): EntityType {
    const {
      name, parentId, status, tbEntityTypeId, tbCustomerId,
      qrCode, areaM2, id,
    } = entity;
    let {
      level
    } = entity;
    if (!parentId && tbEntityTypeId === EntityTypes.BLOCK) {
      level = 0;
    } else {
      level = 1;
    }
    return {
      name, parentId, status, tbEntityTypeId, tbCustomerId,
      level, qrCode, areaM2, id,
    };
  }

  private toEntity(res: EntityResponseType): EntityResponseType {
    return res;
  }

  private toEntities(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body.length > 0) {
    }
    return res;
  }
}
