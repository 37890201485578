import {Moment} from 'moment';

export interface IUser {
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  address?: string;
  birthday?: Moment;
  phone?: string;
  email?: string;
  activated?: boolean;
  langKey?: string;
  role?: string;
  scope?: string;
  authorities?: any[];
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  password?: string;
  userDetailId?: string;
  avartar?: string;
  roleId?: any;
  customerName?: string;
  customerId?: number;
  tenVaiTro?: string;
  countryId?: number;
  cityId?: number;
  districtId?: number;
  wardId?: number;
}

export class User implements IUser {
  constructor(
    public id?: any,
    public login?: string,
    public firstName?: string,
    public lastName?: string,
    public fullName?: string,
    public address?: string,
    public birthday?: Moment,
    public phone?: string,
    public email?: string,
    public activated?: boolean,
    public langKey?: string,
    public role?: string,
    public scope?: string,
    public authorities?: any[],
    public createdBy?: string,
    public createdDate?: Date,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Date,
    public password?: string,
    public userDetailId?: string,
    public avartar?: string,
    public roleId?: any,
    public customerName?: string,
    public customerId?: number,
    public tenVaiTro?: string,
    public countryId?: number,
    public cityId?: number,
    public districtId?: number,
    public wardId?: number,
  ) {
    this.id = id ? id : null;
    this.login = login ? login : null;
    this.firstName = firstName ? firstName : null;
    this.lastName = lastName ? lastName : null;
    this.fullName = fullName ? fullName : null;
    this.address = address ? address : null;
    this.birthday = birthday ? birthday : null;
    this.phone = phone ? phone : null;
    this.email = email ? email : null;
    this.activated = activated ? activated : false;
    this.langKey = langKey ? langKey : null;
    this.authorities = authorities ? authorities : null;
    this.createdBy = createdBy ? createdBy : null;
    this.createdDate = createdDate ? createdDate : null;
    this.lastModifiedBy = lastModifiedBy ? lastModifiedBy : null;
    this.lastModifiedDate = lastModifiedDate ? lastModifiedDate : null;
    this.password = password ? password : null;
    this.userDetailId = userDetailId ? userDetailId : null;
    this.avartar = avartar ? avartar : null;
    this.roleId = roleId ? roleId : null;
    this.customerName = customerName ? customerName : null;
    this.customerId = customerId ? customerId : null;
    this.tenVaiTro = tenVaiTro ? tenVaiTro : null;
    this.countryId = countryId ? countryId : null;
    this.cityId = cityId ? cityId : null;
    this.districtId = districtId ? districtId : null;
    this.wardId = wardId ? wardId : null;
  }
}

export interface IPermission {
  id?: any;
  hasPermission?: boolean;
  samsFunctionId?: any;
  typeRequest?: string;
  path?: string;
  method?: string;
  roleId?: any;
  tenVaiTro?: string;
}

export class Permission implements IPermission {
  constructor(
    public id?: any,
    public hasPermission?: boolean,
    public samsFunctionId?: string,
    public typeRequest?: string,
    public path?: string,
    public method?: string,
    public roleId?: any,
    public tenVaiTro?: string,
  ) {
    this.id = id ? id : null;
    this.hasPermission = hasPermission ? hasPermission : false;
    this.samsFunctionId = samsFunctionId ? samsFunctionId : null;
    this.typeRequest = typeRequest ? typeRequest : null;
    this.path = path ? path : null;
    this.method = method ? method : null;
    this.roleId = roleId ? roleId : null;
    this.tenVaiTro = tenVaiTro ? tenVaiTro : null;
  }
}

export interface IRule {
  id?: number;
  pType?: string;
  // role ID
  v0?: string;
  // path
  v1?: string;
  // request method
  v2?: string;
  // request type
  v3?: string;
  // has permission
  v4?: string|boolean;
  // slug rule
  v5?: string|string[];
  createdDate?: any;
  modifiedDate?: any;
  deletedDate?: any;
  tbRoleId?: number;
  tbFunctionId?: number;
  createdById?: number;
  modifiedById?: number;
  deletedById?: number;
}

export class Rule implements IRule {
  constructor(
    public id?: number,
    public pType?: string,
    public v0?: string,
    public v1?: string,
    public v2?: string,
    public v3?: string,
    public v4?: string,
    public v5?: string,
    public createdDate?: any,
    public updatedDate?: any,
    public deletedDate?: any,
    public tbRoleId?: number,
    public tbFunctionId?: number,
    public createdById?: number,
    public modifiedById?: number,
    public deletedById?: number,
  ) {
    this.id = id ? id : null;
    this.pType = pType ? pType : null;
    this.v0 = v0 ? v0 : null;
    this.v1 = v1 ? v1 : null;
    this.v2 = v2 ? v2 : null;
    this.v3 = v3 ? v3 : null;
    this.v4 = v4 ? v4 : null;
    this.v5 = v5 ? v5 : null;
    this.createdDate = createdDate ? createdDate : null;
    this.updatedDate = updatedDate ? updatedDate : null;
    this.deletedDate = deletedDate ? deletedDate : null;
    this.tbRoleId = tbRoleId ? tbRoleId : null;
    this.tbFunctionId = tbFunctionId ? tbFunctionId : null;
    this.createdById = createdById ? createdById : null;
    this.modifiedById = modifiedById ? modifiedById : null;
    this.deletedById = deletedById ? deletedById : null;
  }
}
