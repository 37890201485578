import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
@Injectable({providedIn: 'root'})
export class AuthGuardServiceLogin implements CanActivate {
  constructor(
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    public router: Router,
  ) {}
  canActivate(): boolean {
    const token =
      this.localStorage.retrieve('authenticationToken') ||
      this.sessionStorage.retrieve('authenticationToken');
    if (!!token) {
      this.router.navigate(['/dashboard']);
    }
    return true;
  }
}
