import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {SharedLibsModule} from './shared-libs.module';
import {SharedCommonModule} from './shared-common.module';
import {LoginComponent} from './login/login.component';

import {HasAnyAuthorityDirective} from './auth/has-any-authority.directive';
import {HasAnyAuthorityDisablementDirective} from './auth/has-any-authority-disablement.directive';
import {GalleryModule} from '@ngx-gallery/core';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GallerizeModule} from '@ngx-gallery/gallerize';

@NgModule({
  imports: [
    RouterModule,
    SharedLibsModule,
    SharedCommonModule,
    GallerizeModule,
    LightboxModule,
    GalleryModule
  ],
  declarations: [
    LoginComponent,
    HasAnyAuthorityDirective,
    HasAnyAuthorityDisablementDirective,
  ],
  providers: [],
  exports: [
    SharedCommonModule,
    LoginComponent,
    HasAnyAuthorityDirective,
    HasAnyAuthorityDisablementDirective,
  ],
})
export class SharedModule {}
