import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ThietBi} from '../thiet-bi.model';
import {Title} from '@angular/platform-browser';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {ThietBiService} from '../thiet-bi.service';

@Component({
  selector: 'app-thiet-bi-dialog',
  templateUrl: './thiet-bi-dialog.component.html',
  styleUrls: ['./thiet-bi-dialog.component.scss'],
})
export class ThietBiDialogComponent implements OnInit {
  typeDevice$: any;
  listDevice$: any;
  location$: any;
  manufacturer: any;
  thietbi: ThietBi;
  isSaving: boolean;

  loaiThietBi: any;

  constructor(
    private dialogRef: MatDialogRef<ThietBiDialogComponent>,
    private thietBiService: ThietBiService,
    private eventManager: JhiEventManager,
  ) {
    this.thietbi = new ThietBi();
    this.isSaving = false;
    this.manufacturer = null;
  }

  ngOnInit() {
    this.isSaving = false;
    if (this.thietbi.id !== null) {
      this.getListDevice(true, this.thietbi.equipmentTypeId);
    }
  }

  save() {
    this.isSaving = true;
    if (this.thietbi.id !== null) {
      this.thietBiService.update(this.thietbi).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    } else {
      this.thietBiService.create(this.thietbi).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }
  }

  saveManufacturer(name: any) {
    this.manufacturer = name;
  }

  getListDevice(isStatus, TypeId: number) {
    if (!isStatus) {
      this.thietbi.status = null;
    }
    this.thietBiService
      .listCategoryDevice({
        q: '',
        equipmentTypeId: TypeId,
      })
      .subscribe((res: any) => {
        this.listDevice$ = res.body;
      });
  }

  filterLocation(locate: any) {
    const convert = {...locate};
    delete convert.name;
    return convert;
  }

  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'deviceListModification',
      content: 'ThietBi',
    });
    this.dialogRef.close(true);
  }
  objectComparisonFunction(option, value) {
    if (option.id && option.type) {
      return option.id === value.id && option.type === value.type;
    }
  }
  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-them-thiet-bi',
  template: '',
})
export class ThietBiPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ThietBiDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private alertService: JhiAlertService,
    private titleService: Title,
    private thietBiService: ThietBiService,
  ) {}
  ngOnInit() {
    this.titleService.setTitle('Danh sách thiết bị');
    this.activatedRoute.data.subscribe(({thietbi}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(ThietBiDialogComponent, {
          disableClose: true,
          width: '380px',
        });

        this.thietBiService.typeDevice().subscribe((res: any) => {
          this.dialogRef.componentInstance.typeDevice$ = res.body;
        });
        this.thietBiService.locationDevice().subscribe((res: any) => {
          this.dialogRef.componentInstance.location$ = res.body;
        });
        if (thietbi) {
          if (thietbi.tbEntityId !== null) {
            thietbi.location = {
              id: thietbi.tbEntityId,
              type: 'entity',
            };
          } else if (thietbi.tbWarehouseId !== null) {
            thietbi.location = {
              id: thietbi.tbWarehouseId,
              type: 'warehouse',
            };
          }
          this.dialogRef.componentInstance.thietbi = thietbi;
          this.dialogRef.componentInstance.manufacturer = thietbi.manufacturer;
        }

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {}
}
