import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {BaoCaoService} from '../bao-cao.service';
import {ILabelData, LabelData} from '../bao-cao.model';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import saveAs from 'file-saver';
import moment from 'moment';
import {HttpResponse} from '@angular/common/http';
import {ICrops} from '../../canh-tac/canh-tac.model';
import {Subscription} from 'rxjs';
import {Area, netHouses} from '../../../shared/model/area.model';

@Component({
  selector: 'app-bao-cao',
  templateUrl: './bao-cao.component.html',
  styleUrls: ['./bao-cao.component.scss'],
})
export class BaoCaoComponent implements OnInit {
  typeExport: any;
  minDate: Date;
  maxDate: Date;
  check: boolean;
  startDate: Date;
  endDate: Date;
  month: any;
  year: any;
  isSaving: boolean;
  displayedColumns: string[] = [
    'maLo',
    'khuVuc',
    'nhaMang',
    'tenGiong',
    'dienTich',
    'ngayTrong',
    'trangThai',
    'phuTrach',
    'action',
  ];
  dataSource: any;
  pageSizeOptions: number[];
  ELEMENT_DATA: ICrops[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  currentSearch: string;
  routeData: any;
  eventSubscriber: Subscription;
  areaId: any = '';
  noData: boolean;
  constructor(private baocaoService: BaoCaoService) {
    this.typeExport = null;
    this.year = new Date().getFullYear();
    this.month = new Date().getMonth() + 1;
    this.isSaving = false;
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear);
    this.maxDate = new Date();
    this.check = true;
  }

  ngOnInit() {
    this.loadALl();
  }


  loadALl() {
    // this.baocaoService.reportSeedUseage().subscribe((res) => {
    //
    // });
    // this.baocaoService.reportMonthly(this.month, this.year).subscribe((res) => {
    //
    // });
  }
  save() {
    const param = {
      startDate: moment(this.startDate).format('DD-MM-YYYY'),
      endDate: moment(this.endDate).format('DD-MM-YYYY'),
    };
    if (this.typeExport === 'giamSatVuon') {
      this.baocaoService.reportGardenSupervisor(param).subscribe(
        (resp: HttpResponse<Blob>) => this.onSaveSuccess(resp),
        () => this.onSaveError(),
      );
    } else if (this.typeExport === 'nhapXuatKho') {
      this.baocaoService.reportExportWareHouse(param).subscribe(
        (resp: HttpResponse<Blob>) => this.onSaveSuccess(resp),
        () => this.onSaveError(),
      );
    } else if (this.typeExport === 'vatLieuDauVao') {
      this.baocaoService.reportImportMaterial(param).subscribe(
        (resp: HttpResponse<Blob>) => this.onSaveSuccess(resp),
        () => this.onSaveError(),
      );
    } else if (this.typeExport === 'nhapXuatTonVatTu') {
      if (!this.endDate) {
        delete param.endDate;
      }
      this.baocaoService.reportImportExportSuppliesInWarehouse(param).subscribe(
        (resp: HttpResponse<Blob>) => this.onSaveSuccess(resp),
        () => this.onSaveError(),
      );
    }
  }
  startDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minDate = new Date(event.value);
    this.minDate.setDate(this.minDate.getDate());
    this.startDate = this.minDate;
    this.check = false;
  }

  endDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = new Date(event.value);
  }

  private onSaveError() {
    this.isSaving = false;
  }

  private onSaveSuccess(result) {
    const contentDisposition = result.headers.get('content-disposition');
    const filename = contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .trim();
    if (filename) {
      saveAs(result.body, filename);
    } else {
      alert(' Không tìm thấy filename');
    }
    this.isSaving = false;
  }
}
