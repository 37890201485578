import {JhiEventManager} from 'ng-jhipster';
import {MatDialog} from '@angular/material/dialog';
import {VaiTro} from 'src/app/entities/vai-tro/vai-tro.model';
import {IVaiTro} from './../../vai-tro/vai-tro.model';
import {ActivatedRoute, Router, Data} from '@angular/router';
import {VaiTroService} from './../../vai-tro/vai-tro.service';
import {MatDialogRef} from '@angular/material';
import {Component, OnInit, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-don-vi-role-dialog',
  templateUrl: './don-vi-role-dialog.component.html',
  styleUrls: ['./don-vi-role-dialog.component.scss']
})
export class DonViRoleDialogComponent implements OnInit {
  entity: IVaiTro;
  constructor(
    private dialogRef: MatDialogRef<DonViRoleDialogComponent>,
    private eventManager: JhiEventManager,
    private vaiTroService: VaiTroService,
  ) {
    this.entity = new VaiTro();
  }

  ngOnInit() {
  }

  clear() {
    this.dialogRef.close('cancel');
  }

  save() {
    if (this.entity.id !== null) {
      this.vaiTroService.update(this.entity).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError(),
      );
    } else {
      this.vaiTroService.create(this.entity).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError(),
      );
    }
  }

  private onSaveSuccess() {
    this.eventManager.broadcast({
      name: 'donViRoleModification',
      content: this.entity,
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
  }
}


@Component({
  selector: 'app-don-vi-role-popup',
  template: '',
})
export class DonViRolePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<DonViRoleDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({role}: Data) => {
      setTimeout(() => {
        const donViId = this.activatedRoute.snapshot.paramMap.get('donViId');
        this.dialogRef = this.dialog.open(DonViRoleDialogComponent, {
          disableClose: true,
          width: '500px',
        });
        const entity = role || new VaiTro();
        entity.tbCustomerId = Number(donViId);
        this.dialogRef.componentInstance.entity = entity;
        this.dialogRef.afterClosed().subscribe(
          (result) => this.previousState(result),
          (reason) => this.previousState(reason),
        );
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.dialogRef = null;
  }

  previousState(result?: any): void {
    const params: any = {
      id: null,
    };
    this.router.navigate([{outlets: {popup: null}}], {
      relativeTo: this.activatedRoute.parent,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
