import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ILoaiHoatDong} from '../../loai-hoat-dong/loai-hoat-dong.model';
import {CanhTacService} from '../../canh-tac/canh-tac.service';
import {HttpResponse} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {IWaterType} from '../../../shared/model/water-type.model';
import {IUser, UserService} from 'src/app/core';
import {Title} from '@angular/platform-browser';
import * as moment from 'moment';

@Component({
    selector: 'app-thu-hanh-dong',
    templateUrl: './hanh-dong.component.html',
    styleUrls: ['./hanh-dong.component.scss'],
})
export class HanhDongComponent implements OnInit {
    objectData: any;
    action: any;
    loId: any;
    waterType$: IWaterType[];
    user: IUser;
    currentAction: ILoaiHoatDong;
    titlePage: string;
    public moment: any = moment;

    constructor(
        private activatedRoute: ActivatedRoute,

        private canhTacService: CanhTacService,
        private localStorage: LocalStorageService,
    ) {
        this.loId = null;
    }

    ngOnInit() {
        const code = this.activatedRoute.snapshot.paramMap.get('code');
        const id = this.activatedRoute.snapshot.paramMap.get('hd');
        this.canhTacService.getDetailActivityType(parseInt(code, 10)).subscribe(
            (res: HttpResponse<ILoaiHoatDong>) => {
                this.currentAction = res.body;
                this.loadHanhDong(this.currentAction, id);
            },
            (res: HttpResponse<any>) =>
                this.onError()
        );
        this.loId = this.localStorage.retrieve('lo.LoId');
    }

    loadHanhDong(action: ILoaiHoatDong, id: any) {
        this.canhTacService.getDetailCommonActivity(action.name, id).subscribe(
            (res: HttpResponse<any>) => {
                console.log(res);
                this.titlePage = action.description;
                this.onSuccess(res);
            },
            (res: HttpResponse<any>) =>
                    this.onError(),
        );
    }
    private onSuccess(data: any) {
        this.objectData = data;
    }

    private onError() {
        this.objectData = null;
    }
}
