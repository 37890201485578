import {
  Component, Input, OnInit, OnChanges, HostBinding,
  ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef
} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import { ShapeType, ILocation } from './shape';

@Component({
  selector: 'app-shape',
  templateUrl: './shape.component.html',
  styleUrls: ['./shape.component.scss']
})
export class ShapeComponent implements OnInit, OnChanges {
  @Input() locations: ILocation[];
  @Input() type = ShapeType.MAT_BUTTON;
  @HostBinding('attr.title')
  @Input() title: string;
  @Input() color = 'primary';
  @Input() stage?: {x?: any, y?: any};
  @Output() ngOnClick: EventEmitter<any> = new EventEmitter();

  types = ShapeType;

  firstCssTop: string;
  firstCssLeft: string;
  cssDisplay: string;
  svgWidth: number;
  svgHeight: number;
  pxLocations: any[];
  pxLocationString: string;
  svgLocations: any[];
  svgLocationsString: string;

  constructor(
  ) {
    this.locations = [];
    this.firstCssLeft = null;
    this.firstCssTop = null;
    this.cssDisplay = null;
    this.title = '';
    this.stage = {};
  }

  @HostBinding('style.top') get top() { return this.firstCssTop; }
  @HostBinding('style.left') get left() { return this.firstCssLeft; }

  get visible() { return this.firstCssTop && this.firstCssLeft; }

  @ViewChild('polygon') polygon: ElementRef;

  ngOnInit() {
    switch (this.type) {
      case ShapeType.POLYGON:
        setTimeout(() => {
          this.drawPolygon();
        });
        break;
    }
  }

  ngOnChanges() {
    if (!this.locations) {
      console.error('Shape locations are required.');
    }

    for (let i = 0; i < this.locations.length; i++) {
      const item = this.locations[i];
      if (i === 0 && typeof item !== 'undefined') {
        const x = item.latitude || item.pixelX || item.x || 0;
        const y = item.longitude || item.pixelY || item.y || 0;
        this.firstCssTop = `${y}%`;
        this.firstCssLeft = `${x}%`;
      }
    }

    switch (this.type) {
      case ShapeType.POLYGON:
        setTimeout(() => {
          this.drawPolygon();
        });

        break;
    }
  }

  drawPolygon() {
    if (this.locations.length > 2) {
      this.locations = this.locations.map(i => {
        i.latitude = i.latitude || i.pixelX || i.x || 0;
        i.longitude = i.longitude || i.pixelY || i.y || 0;
        return i;
      });

      const stageX = this.stage.x;
      const stageY = this.stage.y;

      this.pxLocations = this.locations.map(i => {
        return {
          x: stageX * (i.latitude / 100),
          y: stageY * (i.longitude / 100),
        };
      });

      const minMax = this.pxLocations.reduce((collect, current) => {
        return {
          minX: collect.minX > current.x ? current.x : collect.minX,
          maxX: collect.maxX < current.x ? current.x : collect.maxX,
          minY: collect.minY > current.y ? current.y : collect.minY,
          maxY: collect.maxY < current.y ? current.y : collect.maxY,
        };
      }, {
        minX: this.pxLocations[0].x,
        maxX: this.pxLocations[0].x,
        minY: this.pxLocations[0].y,
        maxY: this.pxLocations[0].y,
      });

      this.svgWidth = minMax.maxX - minMax.minX;
      this.svgHeight = minMax.maxY - minMax.minY;
      this.pxLocationString = this.pxLocations.map(i => `${i.x},${i.y}`).join(' ');

      this.svgLocations = this.pxLocations.map(i => {
        return {
          x: this.svgWidth - (minMax.maxX - i.x),
          y: this.svgHeight - (minMax.maxY - i.y),
        };
      });
      this.svgLocationsString = this.svgLocations
      .map(i => `${i.x},${i.y}`)
      .join(' ');
    }
  }

  click(event) {
    this.ngOnClick.emit(event);
  }
}
