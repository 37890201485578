import {HomeResolve} from './../home.resolve';
import {IEntityLocation} from './../../entities/entity-location/entity-location.model';
import {DashboardLayout} from './../../entities/don-vi/don-vi.model';
import {EntityTypes} from './../../entities/loai-khu-vuc/loai-khu-vuc.model';
import {IKhuVuc} from './../../entities/khu-vuc/khu-vuc.model';
import {Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {IDonViUser, DonViUser} from 'src/app/entities/don-vi/don-vi.model';
import {HttpResponse} from '@angular/common/http';
import {HomeService} from '../home.service';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {IDashboardType} from '../dashboard-type/dashboard-type';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  DonViUser: DonViUser;
  eventSubscriber: Subscription;
  listKhuVuc: IKhuVuc[];
  listNhaMang: IKhuVuc[];
  listKho: IKhuVuc[];
  entities: IKhuVuc[];
  entityLocations: IEntityLocation[];
  layout: string|number;
  layoutTypes = DashboardLayout;

  @ViewChild('dashboardItem') private elem: ElementRef;
  isFullscreen = false;
  constructor(
    protected resolve: HomeResolve,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) {
    this.titleService.setTitle('Dashboard');
    this.DonViUser = new DonViUser();
    this.listNhaMang = [];
    this.layout = DashboardLayout.LIST;
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({}: Data) => {
      this.resolve.resolve(this.activatedRoute.snapshot, this.router.routerState.snapshot)
      .then(dashboard => {
        const {customer, houses, entityLocations, dashboardTypes, entities}: {
          customer: IDonViUser, houses: IKhuVuc[],
          entityLocations: IEntityLocation[], dashboardTypes: IDashboardType[],
          entities: IKhuVuc[],
        } = dashboard;

        if (customer) {
          const dashboardType = dashboardTypes.find(i => i.id === customer.tbDashboardTypeId);
          if (dashboardType) {
            customer.tbDashboardType = dashboardType;
            this.layout = dashboardType.name.toUpperCase();
          }
          this.onSuccess(customer);

          const entitiesHasCrops = houses.filter(i => i.tbCropDTOS);

          this.entities = entities.map(i => {
            i.locations = entityLocations.filter(f => f.tbEntityId === i.id && i.tbCustomerId === f.tbCustomerId);
            switch (i.tbEntityTypeId) {
              case EntityTypes.BLOCK:
                i.houses = entitiesHasCrops.filter(iLv1 => iLv1.parentId === i.id);
                break;
              case EntityTypes.HOUSE:
                const entityHasCrops = entitiesHasCrops.find(iLv1 => iLv1.id === i.id);
                if (entitiesHasCrops) {
                  i.tbCropDTOS = entityHasCrops.tbCropDTOS;
                }
                break;
            }
            return i;
          });
          this.listNhaMang = entities.filter(i => i.tbEntityTypeId === EntityTypes.HOUSE);
          this.listKhuVuc = entities.filter(i => i.tbEntityTypeId === EntityTypes.BLOCK);
          this.listKho = entities.filter(i => i.tbEntityTypeId === EntityTypes.WAREHOUSE);
          this.entityLocations = entityLocations;
        }
      }).catch(() => {

      });
    });
    this.changedDonViUser();
  }

  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }

  changedDonViUser() {
    this.eventSubscriber = this.eventManager.subscribe(
      'donViListModification',
      () => this.statictisDonViUser(),
    );
  }
  statictisDonViUser() {
    this.homeService.DetailDonVi().subscribe(
      (res: HttpResponse<IDonViUser>) => this.onSuccess(res.body, 'DonVi'),
      (res: HttpResponse<any[]>) => this.onError(res.body),
    );
  }

  private onSuccess(data: any, type?: any) {
    this.DonViUser = data;
    this.DonViUser.address = [this.DonViUser.address, this.DonViUser.ward, this.DonViUser.district, this.DonViUser.city].filter(Boolean).join(", ");

  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
