import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import {EMAIL_NOT_FOUND_TYPE} from '../../../shared';
import {PasswordResetInitService} from './password-reset-init.service';
import {isPlatformBrowser} from '@angular/common';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-password-reset-init',
  templateUrl: './password-reset-init.component.html',
  styleUrls: ['./password-reset-init.scss'],
})
export class PasswordResetInitComponent implements OnInit, AfterViewInit {
  error: string;
  errorEmailNotExists: string;
  resetAccount: any;
  success: string;

  constructor(
    private passwordResetInitService: PasswordResetInitService,
    private elementRef: ElementRef,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Quên mật khẩu');
    this.resetAccount = {};
  }

  ngAfterViewInit() {
    // this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#email'), 'focus', []);
    const eleRef = this.elementRef.nativeElement.querySelector('#email');
    if (isPlatformBrowser(this.platformId)) {
      eleRef.nativeElement['focus']();
    }
  }

  requestReset() {
    this.error = null;
    this.errorEmailNotExists = null;

    this.passwordResetInitService.save(this.resetAccount.email).subscribe(
      () => {
        this.success = 'OK';
      },
      (response) => {
        this.success = null;
        if (
          response.status === 400 &&
          response.error.type === EMAIL_NOT_FOUND_TYPE
        ) {
          this.errorEmailNotExists = 'ERROR';
        } else {
          this.error = 'ERROR';
        }
      },
    );
  }
}
