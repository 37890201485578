import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {IPeriod} from '../tieu-chuan.model';
import {TieuChuanService} from '../tieu-chuan.service';

@Component({
  selector: 'app-tieu-chuan-dialog',
  templateUrl: './giai-doan-dialog.component.html',
  styleUrls: ['./giai-doan-dialog.component.scss'],
})
export class GiaiDoanDialogComponent implements OnInit {
  isSaving: boolean;
  period: IPeriod;
  constructor(
    private dialogRef: MatDialogRef<GiaiDoanDialogComponent>,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private tieuchuanService: TieuChuanService,
  ) {
    this.isSaving = false;
  }

  ngOnInit() {
    if (this.period.id !== null) {
      this.titleService.setTitle('Sửa giai đoạn');
    } else {
      this.titleService.setTitle('Thêm giai đoạn');
    }
  }

  save() {
    this.isSaving = true;
    if (this.period.id !== null) {
      this.tieuchuanService.updatePeriod(this.period).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    } else {
      this.tieuchuanService.createPeriod(this.period).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }
  }

  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'tieuChuanDetailModification',
      content: 'period',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-tieu-chuan-create',
  template: '',
})
export class GiaiDoanPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<GiaiDoanDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({giaidoan}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(GiaiDoanDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        this.dialogRef.componentInstance.period = giaidoan;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách quy trình');
    this.dialogRef = null;
  }
}
