import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NotifierModule} from 'angular-notifier';
import {NgJhipsterModule} from 'ng-jhipster';
import {CookieModule} from 'ngx-cookie';

import {NOTIFIER_OPTIONS} from './constants/notifier.constant';
import {MaterialModule} from './material.module';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    NgJhipsterModule.forRoot({
      // set below to true to make alerts look like toast
      alertAsToast: false,
      alertTimeout: 5000,
    }),
    CookieModule.forRoot(),
    NotifierModule.withConfig(NOTIFIER_OPTIONS),
    FlexLayoutModule,
    MaterialModule,
    MatIconModule,
  ],
  exports: [
    FormsModule,
    CommonModule,
    NgJhipsterModule,
    NotifierModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    OverlayModule,
    MatIconModule,
  ],
})
export class SharedLibsModule {}
