import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {GiongService} from '../giong.service';
import {JhiEventManager} from 'ng-jhipster';

@Component({
  selector: 'app-giong-delete-history-dialog',
  templateUrl: './giong-delete-history-dialog.component.html',
})
export class GiongDeleteHistoryDialogComponent {
  historyId: any;
  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }
  constructor(
    private dialogRef: MatDialogRef<GiongDeleteHistoryDialogComponent>,
    private giongService: GiongService,
    private eventManager: JhiEventManager,
  ) {}

  confirmDelete() {
    this.giongService.deleteHistory(this.historyId).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'giongListModification',
        content: 'Giong',
      });
      this.eventManager.broadcast({
        name: 'vaiTroBlockDetailsModification',
        content: this.historyId,
      });
      this.dialogRef.close(true);
    });
  }
}

@Component({
  selector: 'app-giong-delete-history',
  template: '',
  styleUrls: ['./giong-delete-history-dialog.component.scss'],
})
export class GiongDeleteHistoryPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<GiongDeleteHistoryDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa lịch sử vật tư');
    this.activatedRoute.params.subscribe((param) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(GiongDeleteHistoryDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        this.dialogRef.componentInstance.historyId = param.giong;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.dialogRef = null;
    this.titleService.setTitle('Danh sách vật tư');
  }
}
