import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {DATE_FORMAT} from '../../shared/constants/input.constants';
import {SERVER_API_URL} from '../../app.constants';
import {createRequestOption} from '../../shared/util/request-util';
import {IUser, IPermission} from './user.model';

type EntityResponseType = HttpResponse<IUser>;
type EntityArrayResponseType = HttpResponse<IUser[]>;

@Injectable({providedIn: 'root'})
export class UserService {
  private resourceUrl = SERVER_API_URL + 'api/users';
  private resourceSearchUrl = SERVER_API_URL + 'api/_search/users';
  private resourceDetailUrl = SERVER_API_URL + 'api/tb-detail-users';
  private resourceUrlProfile = SERVER_API_URL + 'api/update-my-profile';


  constructor(private http: HttpClient) {}

  create(user: IUser): Observable<HttpResponse<IUser>> {
    const copy = this.convertDateFromClient(user);
    return this.http
      .post<IUser>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(user: IUser): Observable<HttpResponse<IUser>> {
    const copy = this.convertDateFromClient(user);
    return this.http
      .put<IUser>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }
  updateMyProfile(user: IUser): Observable<HttpResponse<IUser>> {
    const copy = this.convertDateFromClient(user);
    return this.http
      .put<IUser>(this.resourceUrlProfile, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }
  find(login: string): Observable<HttpResponse<IUser>> {
    return this.http
      .get<IUser>(`${this.resourceUrl}/${login}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findById(userId: any): Observable<HttpResponse<IUser>> {
    return this.http
      .get<IUser>(`api/usersbyid/${userId}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<HttpResponse<IUser[]>> {
    const options = createRequestOption(req);
    return this.http
      .get<IUser[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(
        map((res: EntityArrayResponseType) =>
          this.convertDateArrayFromServer(res),
        ),
      );
  }

  search(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IUser[]>(this.resourceSearchUrl, {
      params: options,
      observe: 'response',
    });
  }

  delete(login: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${login}`, {
      observe: 'response',
    });
  }

  authorities(): Observable<string[]> {
    return this.http.get<string[]>(SERVER_API_URL + 'api/users/authorities');
  }

  private convertDateFromClient(user: IUser): IUser {
    const copy: IUser = Object.assign({}, user, {
      birthday:
        user.birthday != null && user.birthday.isValid()
          ? user.birthday.format(DATE_FORMAT)
          : null,
    });
    return copy;
  }

  private convertDateFromServer(res: EntityResponseType): EntityResponseType {
    res.body.birthday =
      res.body.birthday != null ? moment(res.body.birthday) : null;
    return res;
  }

  private convertDateArrayFromServer(
    res: EntityArrayResponseType,
  ): EntityArrayResponseType {
    res.body.forEach((user: IUser) => {
      user.birthday = user.birthday != null ? moment(user.birthday) : null;
    });
    return res;
  }

  permission(roleId?: any): Observable<HttpResponse<IPermission[]>> {
    return this.http.get<IPermission[]>(SERVER_API_URL + 'api/rules/permissions-by-current-user', {
      observe: 'response',
    });
  }
  getListDetailUser(): Observable<HttpResponse<IUser[]>> {
    return this.http.get<IUser[]>(this.resourceDetailUrl, {
      observe: 'response',
    });
  }
}
