import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../shared';

import {
  DocsComponent,
  docsRoute,
  userManagementPopupRoute,
  userManagementRoute,
  UserMgmtComponent,
} from './';
import {
  UserManagementUpdateDialogComponent,
  UserManagementUpdatePopupComponent,
} from './user-management/user-management-update/user-management-update.component';
import {
  UserRolePopupComponent,
  UserRoleUpdateDialogComponent,
} from './user-management/user-role-update/user-role-update.component';
import {
  UserRoleDeleteDialogComponent,
  UserRoleDeletePopupComponent,
} from './user-management/user-role-delete-dialog/user-role-delete-dialog.component';
import {
  UserMgmtDeleteDialogComponent,
  UserMgmtDeletePopupComponent,
} from './user-management/user-management-delete-dialog/user-management-delete-dialog.component';
import {CommonModule} from '@angular/common';
import {UserRoleComponent} from './user-management/user-role/user-role.component';

const ADMIN_ROUTES = [
  docsRoute,
  ...userManagementRoute,
  ...userManagementPopupRoute,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ADMIN_ROUTES as Routes),
  ],
  declarations: [
    UserMgmtComponent,
    UserMgmtDeleteDialogComponent,
    UserMgmtDeletePopupComponent,
    UserManagementUpdateDialogComponent,
    UserManagementUpdatePopupComponent,
    DocsComponent,
    UserRoleComponent,
    UserRolePopupComponent,
    UserRoleUpdateDialogComponent,
    UserRoleDeleteDialogComponent,
    UserRoleDeletePopupComponent,
  ],
  entryComponents: [
    UserMgmtDeleteDialogComponent,
    UserMgmtDeletePopupComponent,
    UserManagementUpdateDialogComponent,
    UserManagementUpdatePopupComponent,
    UserRolePopupComponent,
    UserRoleUpdateDialogComponent,
    UserRoleDeleteDialogComponent,
    UserRoleDeletePopupComponent,
  ],
})
export class AdminModule {}
