import {Component, OnInit} from '@angular/core';
import {IQrcreate, statusConvert} from '../qr-code.model';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from '../../../shared';
import {MatTableDataSource} from '@angular/material/table';
import {PageEvent} from '@angular/material/paginator';
import {exportFileData} from '../../../shared/util/export-file';
import {QrCodeService} from '../qr-code.service';
import {ActivatedRoute, Data} from '@angular/router';
import {DialogService} from '../../../shared/confirm-dialog/dialog.service';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {IProduct} from '../../san-pham/san-pham.model';

@Component({
  selector: 'app-qr-code-detail',
  templateUrl: './qr-code-detail.component.html',
  styleUrls: ['./qr-code-detail.component.scss'],
})
export class QrCodeDetailComponent implements OnInit {
  displayedColumns: string[] = [
    'code',
    'lanQuetCuoi',
    'solanQuet',
    'trangThai',
    'action',
  ];
  ELEMENT_DATA: IQrcreate[];
  dataSource: any;
  totalItems: any;
  itemsPerPage: any;
  pageSizeOptions: number[];
  queryParams: any = null;
  page = 1;
  noData: boolean;
  qrCodeDetail: any;
  statusConvert = statusConvert;

  constructor(private qrCodeService: QrCodeService,
              private activatedRoute: ActivatedRoute,
              private confirmService: DialogService,
              ) {
            this.ELEMENT_DATA = [];
            this.dataSource = null;
            this.itemsPerPage = ITEMS_PER_PAGE;
            this.pageSizeOptions = PAGE_SIZE_OPTIONS;
  }

  ngOnInit() {
    this.loadAll();
  }

  loadAll() {
    this.activatedRoute.data.subscribe(({ data }: Data) => {
      this.fillData(data);
    });
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }

  exportFile(item: any, isPdf: boolean): void {
    if (isPdf) {
      this.qrCodeService.exportPdfQuery(item.id).subscribe((res) => {
        exportFileData(res.body, res.headers);
      });
    } else {
      this.qrCodeService.exportQuery(item.code).subscribe((res) => {
        exportFileData(res.body, res.headers);
      });
    }
  }

  changeStatusCode(qrCode: any, status: string) {
    this.confirmService.confirm(`${status === "ACTIVE" ? "Kích hoạt" : "Hủy"} mã QR `, `${status === "ACTIVE" ? "Kích hoạt" : "Hủy"} QR Code: ${qrCode.code}?`)
        .subscribe(res => {
          if (res) {
            this.qrCodeService.updateTBCode(qrCode.id, status ).subscribe((response) => {
              this.fillData(response.body);
            });
          }
        });
  }

  fillData(data: any): void {
    this.qrCodeDetail = data;
    this.totalItems = data.tbCodeDetails.length;
    const startIndex = this.itemsPerPage * (this.page - 1);
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.totalItems);
    this.ELEMENT_DATA = data.tbCodeDetails.slice(startIndex, endIndex);
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.noData = data.tbCodeDetails.length === 0;
  }
}
