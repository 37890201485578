export interface IUnitRegister {
  hoTen?: string;
  email?: string;
  telephone?: string;
  tenDonVi?: string;
  address?: string;
  dienTichDat?: any;
  ghiChu?: string;
}

export class UnitRegister implements IUnitRegister {
  constructor(
    public hoTen?: string,
    public email?: string,
    public telephone?: string,
    public tenDonVi?: string,
    public address?: string,
    public dienTichDat?: any,
    public ghiChu?: string,
  ) {
    this.hoTen = hoTen ? hoTen : null;
    this.email = email ? email : null;
    this.telephone = telephone ? telephone : null;
    this.tenDonVi = tenDonVi ? tenDonVi : null;
    this.address = address ? address : null;
    this.dienTichDat = dienTichDat ? dienTichDat : null;
    this.ghiChu = ghiChu ? ghiChu : null;
  }
}
