import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {createRequestOption} from '../../shared';
import {SERVER_API_URL} from '../../app.constants';
import {
  ICategoryDevice,
  IDevice,
  IEquipmentHistory,
  IThietbi,
} from './thiet-bi.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThietBiService {
  private resourceUrl = SERVER_API_URL + 'api/devicesOfUserCustomers';

  constructor(private http: HttpClient) {}

  query(req?: any): Observable<HttpResponse<IDevice[]>> {
    const options = createRequestOption(req);
    return this.http.get<IDevice[]>(this.resourceUrl, {
      params: options,
      observe: 'response',
    });
  }

  delete(thietbiId: number): Observable<HttpResponse<any>> {
    return this.http.delete(
      SERVER_API_URL + `api/tb-equipment-of-customers/${thietbiId}`,
      {
        observe: 'response',
      },
    );
  }

  create(thietbi: any): Observable<HttpResponse<any>> {
    return this.http.post(
      SERVER_API_URL + `api/create-tb-equipment-of-customers`,
      thietbi,
      {
        observe: 'response',
      },
    );
  }

  changeStatusDevice(
    id: number,
    status: number,
  ): Observable<HttpResponse<any>> {
    return this.http.put(
      SERVER_API_URL + `api/equipment-of-customers-on-off/${id}/${status}`,
      '',
      {
        observe: 'response',
      },
    );
  }

  update(thietbi: any): Observable<HttpResponse<any>> {
    return this.http.put(
      SERVER_API_URL + `api/update-tb-equipment-of-customers`,
      thietbi,
      {
        observe: 'response',
      },
    );
  }

  find(thietBiId: number): Observable<HttpResponse<IThietbi>> {
    return this.http
      .get<IThietbi>(
        SERVER_API_URL + `/api/tb-equipment-of-customers/${thietBiId}`,
        {observe: 'response'},
      )
      .pipe(map((res: HttpResponse<IThietbi>) => res));
  }

  findCategoryDevice(
    categoryId: number,
  ): Observable<HttpResponse<ICategoryDevice>> {
    return this.http
      .get<ICategoryDevice>(
        SERVER_API_URL + `api/tb-equipments/${categoryId}`,
        {observe: 'response'},
      )
      .pipe(map((res: HttpResponse<ICategoryDevice>) => res));
  }

  typeDevice(): Observable<HttpResponse<any>> {
    return this.http.get<any>(SERVER_API_URL + 'api/tb-equipment-types', {
      observe: 'response',
    });
  }

  listCategoryDevice(req?: any): Observable<HttpResponse<any>> {
    const options = createRequestOption(req);
    return this.http.get<any>(SERVER_API_URL + 'api/tb-equipments', {
      params: options,
      observe: 'response',
    });
  }

  createCategoryDevice(categoryDevice: any): Observable<HttpResponse<any>> {
    return this.http.post(
      SERVER_API_URL + `api/createTBEquipments`,
      categoryDevice,
      {
        observe: 'response',
      },
    );
  }

  updateCategoryDevice(categoryDevice: any): Observable<HttpResponse<any>> {
    return this.http.post(
      SERVER_API_URL + `api/updateTBEquipments`,
      categoryDevice,
      {
        observe: 'response',
      },
    );
  }

  deleteCategoryDevice(deviceId: any): Observable<HttpResponse<any>> {
    return this.http.delete(SERVER_API_URL + `api/tb-equipments/${deviceId}`, {
      observe: 'response',
    });
  }

  listWarehouse(): Observable<HttpResponse<any>> {
    return this.http.get<any>(SERVER_API_URL + 'api/tb-warehouses', {
      observe: 'response',
    });
  }

  locationDevice(): Observable<HttpResponse<any>> {
    return this.http.get<any>(SERVER_API_URL + 'api/locationDevices', {
      observe: 'response',
    });
  }

  historyDevice(req?: any): Observable<HttpResponse<IEquipmentHistory[]>> {
    const options = createRequestOption(req);
    return this.http.get<IEquipmentHistory[]>(
      SERVER_API_URL + 'api/tb-equipment-histories',
      {
        params: options,
        observe: 'response',
      },
    );
  }
  warehouseEquipment(deviceId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      SERVER_API_URL + `api/tb-equipments-in-warehouse/${deviceId}`,
      {
        observe: 'response',
      },
    );
  }
}
