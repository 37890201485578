import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared';
import {accountRoute} from './account.route';
import {PersonalSettingComponent} from './personal-setting/personal-setting.component';

const ENTITY_STATES = [...accountRoute];

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule.forChild(ENTITY_STATES)],
  declarations: [PersonalSettingComponent],
})
export class AccountModule {}
