import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from '../../../shared';
import {PageEvent, MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {CanhTacService} from '../canh-tac.service';
import moment from 'moment';

@Component({
  selector: 'app-hoat-dong-canh-tac',
  templateUrl: './hoat-dong-canh-tac.component.html',
  styleUrls: ['./hoat-dong-canh-tac.component.scss']
})
export class HoatDongCanhTacComponent implements OnInit,  AfterViewInit  {
  displayedColumns: string[] = [
    'title',
    'detail',
    'deadline',
    'completed',
  ];
  ELEMENT_DATA = [];
  dataSource: any;
  totalItems: any;
  itemsPerPage: any;
  pageSizeOptions: number[];
  page = 1;
  noData: boolean;
  config = {
    page: 0,
    size: 10,
    sort: ['deadline,asc']
  };
  payloadData = {
    title: null,
    detail: null,
    deadline: null,
    crop_id: null,
    completed: null,
  };
  completeData = [
    {
      value: 1,
      display: 'Hoàn thành'
    },
    {
      value: 2,
      display: 'Chưa hoàn thành'
    }
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private activatedRoute: ActivatedRoute,
              private canhTacService: CanhTacService ) {
    this.dataSource = new MatTableDataSource([]);
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.payloadData.crop_id = params.get('crop-id');
      this.loadAll();
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  loadAll() {
    const payload: any = {};
    if (this.payloadData.title) {
      payload.title = this.payloadData.title;
    }
    if (this.payloadData.deadline) {
      payload.deadline = this.payloadData.deadline.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }
    if (this.payloadData.crop_id) {
      payload.crop_id = Number(this.payloadData.crop_id);
    }
    if (this.payloadData.completed) {
      payload.completed = [(this.payloadData.completed === 1)];
    }
   this.canhTacService.getFarmingActivity(this.config, payload).subscribe(data => {
      this.fillData(data.body);
      this.totalItems = data.headers.get('X-Total-Count');
    });
  }

  loadPage(event: PageEvent) {
    this.config.page = event.pageIndex;
    this.config.size = event.pageSize;
    this.loadAll();
  }

  fillData(data: any): void {
    this.dataSource = new MatTableDataSource(data);
    this.noData = data.length === 0;
  }

  filter(): void {
    this.loadAll();
  }

  onSelectionChange(event: any): void {
    this.payloadData.completed = [];
    const selectedValue = event.value;
    this.payloadData.completed.push(selectedValue);
  }

  formatDateToISO(date: Date): string {
    return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
  }
}
