import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-qr-code-dialog-update-activity',
  templateUrl: './qr-code-dialog-update-activity.component.html',
  styleUrls: ['./qr-code-dialog-update-activity.component.scss'],
})
export class QrCodeDialogUpdateActivityComponent implements OnInit {
  isSaving: boolean;

  constructor(private dialogRef: MatDialogRef<QrCodeDialogUpdateActivityComponent>) {
    this.isSaving = false;
  }

  ngOnInit() {}

  save() {
    this.isSaving = true;
    this.dialogRef.close(true);
  }
}

@Component({
  selector: 'app-qr-code-update-activity',
  template: '',
})
export class QrCodePopupUpdateActivityComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<QrCodeDialogUpdateActivityComponent>;
  constructor(private router: Router, private dialog: MatDialog) {}

  ngOnInit() {
    setTimeout(() => {
      this.dialogRef = this.dialog.open(QrCodeDialogUpdateActivityComponent, {
        disableClose: true,
        width: '380px',
      });

      this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
      );
    }, 0);
  }

  ngOnDestroy() {}
}
