import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'src/app/shared';
import {ThongSoMoiTruongComponent} from './thong-so-moi-truong.component';
import {RouterModule, Routes} from '@angular/router';
import {thongSoMT} from './thong-so-moi-truong.route';

@NgModule({
  declarations: [ThongSoMoiTruongComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(thongSoMT as Routes),
  ],
})
export class ThongSoMoiTruongModule {}
