import {Injectable} from '@angular/core';
import {ConfirmDialogComponent} from './confirm-dialog.component';
import {MatDialog} from '@angular/material';
import {Observable} from 'rxjs';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}

  confirm(title: string, message: string): Observable<any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    return dialogRef.afterClosed();
  }
}
