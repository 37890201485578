import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {ICrops} from '../canh-tac.model';
import {CanhTacService} from '../canh-tac.service';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {ActivatedRoute} from '@angular/router';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS,} from 'src/app/shared/constants/pagination.constants';
import {HttpResponse} from '@angular/common/http';
import {Area, netHouses} from '../../../shared/model/area.model';
import {AreaService} from '../../../shared/services/area.service';
import {Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {GiongService} from '../../../shared/services/giong.service';

@Component({
  selector: 'app-quan-ly-canh-tac',
  templateUrl: './canh-tac.component.html',
  styleUrls: ['./canh-tac.component.scss'],
})
export class CanhTacComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'maLo',
    'khuVuc',
    'nhaMang',
    'tenGiong',
    'dienTich',
    'ngayTrong',
    'trangThai',
    'phuTrach',
    'action',
  ];
  dataSource: any;
  pageSizeOptions: number[];
  ELEMENT_DATA: ICrops[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  currentSearch: string;
  routeData: any;
  eventSubscriber: Subscription;
  areaId: any = '';
  netHouseId: any = '';
  suppliesId: any = '';
  areaData: Area[] = [];
  netHousesData: netHouses[] = [];
  suppliesData: any[] = [];
  noData: boolean;
  constructor(
    private canhTacService: CanhTacService,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private parseLinks: JhiParseLinks,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private area: AreaService,
    private giongService$: GiongService
  ) {
    this.dataSource = null;
    this.ELEMENT_DATA = [];
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.routeData = this.activatedRoute.data.subscribe(({pagingParams}) => {
      console.log(pagingParams);
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
    });

    this.currentSearch =
      this.activatedRoute.snapshot &&
      this.activatedRoute.snapshot.params['search']
        ? this.activatedRoute.snapshot.params['search']
        : '';
  }

  ngOnInit() {
    this.titleService.setTitle('Danh sách lô');
    this.getArea();
    this.getNetHouses(0);
    this.loadAll();
    this.changedListLo();
    this.getSupplies();
  }

  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }
  statusConvert(status: any) {
    const statusName = {
      STATUS_ARE_ACTIVE: 'Đanh canh tác',
      STATUS_FINISHED: 'Đã thu hoạch',
    };
    return statusName.hasOwnProperty(status)
      ? statusName[status]
      : 'Chưa cập nhật';
  }
  changedListLo() {
    this.eventSubscriber = this.eventManager.subscribe(
      'loListModification',
      () => this.loadAll(),
    );
  }

  loadAll() {
    const param: any = {};
    param.page = this.page - 1;
    param.size = this.itemsPerPage;
    param.sort = this.sort();
    const body: any = {};
    if (this.currentSearch) {
      body.code = this.currentSearch;
    }
    if (this.areaId) {
      body.areaIds = [this.areaId];
    }
    if (this.netHouseId) {
      body.netHouseIds = [this.netHouseId];
    }
    if (this.suppliesId) {
      body.tbSuppliesIds = [this.suppliesId];
    }
    this.canhTacService.search(param, body).subscribe(
      (res: HttpResponse<ICrops[]>) =>
        this.onSuccess(res.body, res.headers, 'lo'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }
  private sort() {
    return [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }

  clear() {
    this.page = 1;
    this.currentSearch = '';

    this.loadAll();
  }

  search(query: string) {
    if (!query) {
      return this.clear();
    }

    this.page = 1;
    this.currentSearch = query;

    this.loadAll();
  }
  getArea() {
    this.area.listArea().subscribe(
      (res: HttpResponse<Area[]>) =>
        this.onSuccess(res.body, res.headers, 'area'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getSupplies() {
    this.giongService$.queryInfo().subscribe(
        (res: HttpResponse<any[]>) =>
            this.onSuccess(res.body, res.headers, 'supplies'),
        (res: HttpResponse<any>) => this.onError(res.body),
    );
  }
  getNetHouses(id: number) {
    if (id) {
      this.area.listNetHousesByArea(id).subscribe(
        (res: HttpResponse<netHouses[]>) =>
          this.onSuccess(res.body, res.headers, 'netHouses'),
        (res: HttpResponse<any>) => this.onError(res.body),
      );
    } else {
      this.area.listNetHousesAll().subscribe(
          (res: HttpResponse<netHouses[]>) =>
              this.onSuccess(res.body, res.headers, 'netHouses'),
          (res: HttpResponse<any>) => this.onError(res.body),
      );
    }
  }
  onChangeArea(e) {
    this.areaId = e.value;
    this.netHouseId = '';
    if (e.value) {
      this.netHousesData = [];
      this.getNetHouses(e.value);
    } else {
      this.netHousesData = [];
      this.getNetHouses(0);
    }
    this.loadAll();
  }
  onChangeNetHouses(e) {
    this.netHouseId = e.value;
    this.loadAll();
  }

  onChangeSupplies(e) {
    this.suppliesId = e.value;
    this.loadAll();
  }
  private onSuccess(data: any, headers: any, type: string) {
    if (type === 'lo') {
      this.links = this.parseLinks.parse(headers.get('link'));
      this.totalItems = headers.get('X-Total-Count');
      this.queryCount = this.totalItems;
      this.ELEMENT_DATA = data;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      if (data.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    } else if (type === 'area') {
      this.areaData = data;
    } else if (type === 'netHouses') {
      this.netHousesData = data;
    } else if (type === 'supplies') {
      this.suppliesData = data;
    }
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
