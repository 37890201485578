import {Routes} from '@angular/router';

import {passwordResetFinishRoute} from './password-reset/finish/password-reset-finish.route';
import {passwordResetInitRoute} from './password-reset/init/password-reset-init.route';

const ACCOUNT_ROUTES = [passwordResetFinishRoute, passwordResetInitRoute];

export const accountState: Routes = [
  {
    path: '',
    children: ACCOUNT_ROUTES,
  },
];
