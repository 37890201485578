export interface IVaiTro {
  id?: number;
  name?: string;
  description?: string;
  isAllCustomer?: boolean;
  isAllCrop?: boolean;
  tbCustomerId?: number;
}

export class VaiTro implements IVaiTro {
  constructor(
    public id?: any,
    public tenVaiTro?: string,
    public description?: string,
    public isAllCustomer?: boolean,
    public isAllCrop?: boolean,
    public tbCustomerId?: number,
  ) {
    this.id = id ? id : null;
    this.tenVaiTro = tenVaiTro ? tenVaiTro : null;
    this.description = description ? description : null;
    this.isAllCustomer = isAllCustomer ? isAllCustomer : false;
    this.isAllCrop = isAllCrop ? isAllCrop : false;
    this.tbCustomerId = tbCustomerId ? tbCustomerId : null;
  }
}


import {IRule} from '../../core/user/user.model';
export interface IPermissionGroup {
    id?: number;
    name?: string;
    permissionDTOList?: IPermission[];
    constantName?: string;
    status?: boolean;
    isSelected?: boolean;
}

export class PermissionGroup implements IPermissionGroup {
    constructor(
        public id?: number,
        public name?: string,
        public permissionDTOList?: IPermission[],
        public constantName?: string,
        public status?: boolean,
        public isSelected?: boolean,
    ) {
        this.id = id ? id : null;
        this.name = name ? name : null;
        this.permissionDTOList = permissionDTOList ? permissionDTOList : [];
        this.constantName = constantName ? constantName : null;
        this.status = status ? status : false;
        this.isSelected = isSelected ? isSelected : false;
    }
}

export interface IPermission {
    id?: number;
    name?: string;
    tbFunctionId?: number;
    tbRoleId?: number;
    casbinDTOList?: IRule[];
    hasPermission?: boolean;
    hasPerRuleId?: string;
    slug?: string | string[];
}
export class Permission implements IPermission {
    constructor(
        public id?: number,
        public name?: string,
        public tbFunctionId?: number,
        public tbRoleId?: number,
        public casbinDTOList?: IRule[],
        public hasPermission?: boolean,
        public hasPerRuleId?: string,
        public slug?: string,
    ) {
        this.id = id ? id : null;
        this.name = name ? name : null;
        this.tbFunctionId = tbFunctionId ? tbFunctionId : null;
        this.tbRoleId = tbRoleId ? tbRoleId : null;
        this.casbinDTOList = casbinDTOList ? casbinDTOList : [];
        this.hasPermission = hasPermission ? hasPermission : null;
        this.hasPerRuleId = hasPerRuleId ? hasPerRuleId : null;
        this.slug = slug ? slug : null;
    }
}
