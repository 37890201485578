export interface IPasswordChange {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

export class PasswordChange implements IPasswordChange {
  constructor(
    public currentPassword?: string,
    public newPassword?: string,
    public confirmPassword?: string,
  ) {}
}
