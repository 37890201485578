import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {JhiAlertService} from 'ng-jhipster';
import {forkJoin, Observable} from 'rxjs';
import {IUser, User} from '../../../core/user/user.model';
import {AccountService} from '../../../core/auth/account.service';
import {
  IPasswordChange,
  PasswordChange,
} from '../../../shared/model/password-change';
import {UserService} from 'src/app/core/user/user.service';
import {Title} from '@angular/platform-browser';
import {LocationService} from '../../../shared/services/location.service';
import {
  City,
  Country,
  District,
  SubDistrict,
} from '../../../shared/model/location.model';

@Component({
  selector: 'app-personal-setting',
  templateUrl: './personal-setting.component.html',
  styleUrls: ['./personal-setting.component.scss'],
})
export class PersonalSettingComponent implements OnInit, AfterViewInit {
  account: IUser;
  passwordChange: IPasswordChange;
  isSaving: boolean;
  isSavingPass: boolean;
  listCountry: Country[] = [];
  listCity: City[] = [];
  listDistrict: District[] = [];
  listSubDistrict: SubDistrict[] = [];

  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private location: LocationService,
    private alertService: JhiAlertService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    this.account = new User();
    this.passwordChange = new PasswordChange();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Cài đặt');
    this.activatedRoute.data.subscribe(({account}) => {
      setTimeout(() => {
        this.account = account;
        this.onSuccessDonViUser(this.account);
      }, 0);
    });
  }

  ngAfterViewInit() {}

  isCurrentPasswordRequired(): boolean {
    return (
      this.passwordChange.newPassword !== null &&
      this.passwordChange.newPassword !== ''
    );
  }

  isNewPasswordRequired(): boolean {
    return (
      this.passwordChange.currentPassword !== null &&
      this.passwordChange.currentPassword !== ''
    );
  }

  isConfirmPasswordRequired(): boolean {
    return (
      (this.passwordChange.currentPassword !== null &&
        this.passwordChange.currentPassword !== '') ||
      (this.passwordChange.newPassword !== null &&
        this.passwordChange.newPassword !== '')
    );
  }

  isConfirmPasswordMatched(): boolean {
    return (
      (this.passwordChange.newPassword !== null ||
        this.passwordChange.newPassword !== '') &&
      this.passwordChange.newPassword === this.passwordChange.confirmPassword
    );
  }

  private onSaveSuccess(msg: string) {
    this.alertService.success(msg, null, null);
  }

  private onSaveError(msg: string) {
    this.alertService.error(msg, null, null);
  }

  private subscribeToSaveResponse(result: Observable<any[]>) {
    result.subscribe(
      ([accountHttpResponse]) =>
        setTimeout(() => {
          this.isSaving = false;
          this.onSaveSuccess("Success to save your're information!");
        }, 0),
      (error: HttpErrorResponse) =>
        setTimeout(() => {
          this.isSaving = false;
          this.onSaveError(error.error.title);
        }, 0),
    );
  }

  saveInfo() {
    this.isSaving = true;
    this.subscribeToSaveResponse(
      forkJoin([this.userService.updateMyProfile(this.account)]),
    );
  }

  savePassword() {
    this.isSavingPass = true;
    if (
      this.passwordChange.currentPassword &&
      this.passwordChange.newPassword
    ) {
      this.accountService.changePassword(this.passwordChange).subscribe(
        (response) => this.onSavePassSuccess(response),
        () => this.onSavePassError(),
      );
    }
  }

  private onSavePassSuccess(response: any) {
    this.isSavingPass = false;
    this.alertService.success(
      "Success to save your're information!",
      null,
      'right',
    );
  }

  private onSavePassError() {
    this.isSavingPass = false;
  }

  getCountry() {
    this.location.listCountry().subscribe(
      (res: HttpResponse<Country[]>) => this.onSuccess(res.body, 'country'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getCity(id) {
    this.location.listCity(id).subscribe(
      (res: HttpResponse<City[]>) => this.onSuccess(res.body, 'city'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getDistrict(id) {
    this.location.listDistrict(id).subscribe(
      (res: HttpResponse<District[]>) => this.onSuccess(res.body, 'district'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getSubDistrict(id) {
    this.location.listSubDistrict(id).subscribe(
      (res: HttpResponse<SubDistrict[]>) =>
        this.onSuccess(res.body, 'subdistrict'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  countryEffect(country) {
    if (country) {
      this.getCity(country);
      this.listDistrict = [];
      this.listSubDistrict = [];
      this.account.cityId = null;
      this.account.districtId = null;
      this.account.wardId = null;
    } else {
      this.listCity = [];
      this.listDistrict = [];
      this.listSubDistrict = [];
      this.account.cityId = null;
      this.account.districtId = null;
      this.account.wardId = null;
    }
  }

  cityEffect(city) {
    if (city) {
      this.getDistrict(city);
      this.account.districtId = null;
      this.account.wardId = null;
      this.listSubDistrict = [];
    } else {
      this.account.districtId = null;
      this.account.wardId = null;
      this.listSubDistrict = [];
      this.listDistrict = [];
    }
  }

  districtEffect(district) {
    if (district) {
      this.getSubDistrict(district);
    } else {
      this.account.wardId = null;
      this.account.districtId = null;
      this.listSubDistrict = [];
    }
  }

  private onSuccess(data: any, type: string) {
    if (type === 'country') {
      this.listCountry = data;
    } else if (type === 'city') {
      this.listCity = data;
    } else if (type === 'district') {
      this.listDistrict = data;
    } else if (type === 'subdistrict') {
      this.listSubDistrict = data;
    }
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }

  private onSuccessDonViUser(data: any) {
    this.getCountry();
    if (data.countryId) {
      this.getCity(data.countryId);
    }
    if (data.cityId) {
      this.getDistrict(data.cityId);
    }
    if (data.districtId) {
      this.getSubDistrict(data.districtId);
    }
  }
}
