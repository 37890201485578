import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'padNumber',
})
export class PadNumberPipe implements PipeTransform {
  transform(num: number, size: any): any {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }
}
