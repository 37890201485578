import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {IActivityType} from '../activity.model';
import {ActivityService} from '../activity.service';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {ActivatedRoute} from '@angular/router';
import {
  ITEMS_PER_PAGE,
  PAGE_SIZE_OPTIONS,
} from 'src/app/shared/constants/pagination.constants';
import {HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-don-vi-list',
  templateUrl: './activity-type.component.html',
  styleUrls: ['./activity-type.component.scss'],
})
export class ActivityTypeComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'name',
    'description',
    'isGuidelineUsing',
    'afterHarvest',
    'action',
  ];
  dataSource: any;
  pageSizeOptions: number[];
  ELEMENT_DATA: IActivityType[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  currentSearch: string;
  routeData: any;
  eventSubscriber: Subscription;
  constructor(
    private activityService: ActivityService,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private parseLinks: JhiParseLinks,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    this.dataSource = null;
    this.ELEMENT_DATA = [];
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.routeData = this.activatedRoute.data.subscribe(({pagingParams}) => {
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
    });

    this.currentSearch =
      this.activatedRoute.snapshot &&
      this.activatedRoute.snapshot.params['search']
        ? this.activatedRoute.snapshot.params['search']
        : '';
  }

  ngOnInit() {
    this.titleService.setTitle('Danh sách hoạt động');
    this.loadAll();
    this.changedListType();
  }

  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }

  changedListType() {
    this.eventSubscriber = this.eventManager.subscribe(
      'activityTypeListModification',
      (response) => this.loadAll(),
    );
  }

  loadAll() {
    if (this.currentSearch) {
      this.activityService
        .search({
          page: this.page - 1,
          query: this.currentSearch,
          size: this.itemsPerPage,
          sort: this.sort(),
        })
        .subscribe(
          (res: HttpResponse<IActivityType[]>) =>
            this.onSuccess(res.body, res.headers),
          (res: HttpResponse<any>) => this.onError(res.body),
        );
      return;
    }
    this.activityService
      .query({
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: this.sort(),
      })
      .subscribe(
        (res: HttpResponse<IActivityType[]>) => this.onSuccess(res.body, res.headers),
        (res: HttpResponse<any>) => this.onError(res.body),
      );
  }

  private sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.transition();
  }

  clear() {
    this.page = 1;
    this.currentSearch = '';
    this.loadAll();
  }

  search(query: any) {
    if (!query) {
      return this.clear();
    }
    this.page = 1;
    this.currentSearch = query;
    this.loadAll();
  }

  private transition() {
    this.loadAll();
  }

  private onSuccess(data: any, headers: any) {
    this.links = this.parseLinks.parse(headers.get('link'));
    this.totalItems = headers.get('X-Total-Count');
    this.queryCount = this.totalItems;
    this.ELEMENT_DATA = data;
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
