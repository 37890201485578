import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LoadingScreenService} from '../../shared/loading-screen/loading-screen.service';

export class LoadingScreenInterceptor implements HttpInterceptor {
  private loadingScreenService: LoadingScreenService;
  constructor(private injector: Injector) {
    this.loadingScreenService = injector.get(LoadingScreenService);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!request.url.includes('disabel_interceptor_loading')) {
      this.showLoader();
    }
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (!request.url.includes('disabel_interceptor_loading')) {
              this.onEnd();
            }
          }
        },
        (err: any) => {
          if (!request.url.includes('disabel_interceptor_loading')) {
            this.onEnd();
          }
        },
      ),
    );
  }

  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loadingScreenService.startLoading();
  }
  private hideLoader(): void {
    this.loadingScreenService.stopLoading();
  }
}
