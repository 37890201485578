import {DashboardType, IDashboardType} from 'src/app/home/dashboard-type/dashboard-type';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDonViUser, DonViUser } from '../don-vi.model';
import { DonViService } from '../don-vi.service';
import {JhiEventManager} from 'ng-jhipster';
import { Title } from '@angular/platform-browser';
import {City, Country, District, SubDistrict} from '../../../shared/model/location.model';
import {LocationService} from '../../../shared/services/location.service';
import {HttpResponse} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import {DOMAIN_IMAGE_URL} from '../../../shared/constants/domain';
import { DashboardTypeService } from 'src/app/home/dashboard-type/dashboard-type.service';


@Component({
  selector: 'app-don-vi-dialog',
  templateUrl: './don-vi-dialog.component.html',
  styleUrls: ['./don-vi-dialog.component.scss']
})
export class DonViDialogComponent implements OnInit {
  donVi: IDonViUser;
  isSaving: boolean;
  listCountry: Country[] = [];
  listCity: City[] = [];
  listDistrict: District[] = [];
  listSubDistrict: SubDistrict[] = [];
  listDashboardType: IDashboardType[];
  fileIcon: any;
  fileDisplay = null;


  constructor(
    private dialogRef: MatDialogRef<DonViDialogComponent>,
    private donViService: DonViService,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private location: LocationService,
    private notifierService: NotifierService,
    private dashboardTypeService: DashboardTypeService,
  ) {
    this.donVi = new DonViUser;
    this.isSaving = false;
  }

  ngOnInit() {
    if (this.donVi.id !== null) {
      this.titleService.setTitle('Sửa đơn vị');
    } else {
      this.titleService.setTitle('Thêm đơn vị');
    }
    this.onSuccessdonVi(this.donVi);
  }

  getCountry() {
    this.location.listCountry().subscribe(
        (res: HttpResponse<Country[]>) => this.onSuccess(res.body, 'country')
    );
  }

  getCity(id) {
    this.location.listCity(id).subscribe(
        (res: HttpResponse<City[]>) => this.onSuccess(res.body, 'city')
    );
  }

  getDistrict(id) {
    this.location.listDistrict(id).subscribe(
        (res: HttpResponse<District[]>) => this.onSuccess(res.body, 'district')
    );
  }

  getSubDistrict(id) {
    this.location.listSubDistrict(id).subscribe(
        (res: HttpResponse<SubDistrict[]>) =>
            this.onSuccess(res.body, 'subdistrict')
    );
  }
  countryEffect(country) {
    if (country) {
      this.getCity(country);
      this.listDistrict = [];
      this.listSubDistrict = [];
      this.donVi.cityId = null;
      this.donVi.districtId = null;
      this.donVi.wardId = null;
    } else {
      this.listCity = [];
      this.listDistrict = [];
      this.listSubDistrict = [];
      this.donVi.cityId = null;
      this.donVi.districtId = null;
      this.donVi.wardId = null;
    }
  }

  cityEffect(city) {
    if (city) {
      this.getDistrict(city);
      this.donVi.districtId = null;
      this.donVi.wardId = null;
      this.listSubDistrict = [];
    } else {
      this.donVi.districtId = null;
      this.donVi.wardId = null;
      this.listSubDistrict = [];
      this.listDistrict = [];
    }
  }

  districtEffect(district) {
    if (district) {
      this.getSubDistrict(district);
    } else {
      this.donVi.wardId = null;
      this.donVi.districtId = null;
      this.listSubDistrict = [];
    }
  }

  private onSuccess(data: any, type: string) {
    if (type === 'country') {
      this.listCountry = data;
    } else if (type === 'city') {
      this.listCity = data;
    } else if (type === 'district') {
      this.listDistrict = data;
    } else if (type === 'subdistrict') {
      this.listSubDistrict = data;
    }
  }

  private onSuccessdonVi(data: any) {
    this.getCountry();
    if (data.countryId) {
      this.getCity(data.countryId);
    }
    if (data.cityId) {
      this.getDistrict(data.cityId);
    }
    if (data.districtId) {
      this.getSubDistrict(data.districtId);
    }
    this.dashboardTypeService.query()
    .subscribe(
      (res: HttpResponse<DashboardType[]>) =>
        this.listDashboardType = res.body)
  }
  save() {
    this.isSaving = true;
    const data = new FormData();
    let requestObservable;

    if (this.fileIcon) {
      data.append('file', this.fileIcon, this.fileIcon.name);
    }

    if (this.donVi.id !== null) {
      requestObservable = this.donViService.update(this.donVi);
    } else {
      requestObservable = this.donViService.create(this.donVi);
    }

    requestObservable.subscribe(response => {
      if (data.has('file')) {
        this.uploadFile(response.body.id, data);
      } else {
        this.onSaveSuccess(response);
      }
    }, () => this.onSaveError());
  }

  private onSaveSuccess(result: any) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'donViListModification',
      content: 'DonVi'
    });
    this.eventManager.broadcast({
      name: 'donViModification',
      content: this.donVi,
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }

  upload(e) {
    this.fileDisplay = null;
    const file = e.target.files[0];
    if (!this.checkFileSize(file, 2 * 1024 * 1024)) {
      this.notifierService.notify('warning', "File ảnh tải lên phải nhỏ hơn 2MB");
      return;
    }
    if (!this.isImage(file)) {
      this.notifierService.notify('warning', "Chỉ cho phép tải lên các tệp tin ảnh (jpg, jpeg, png, gif)");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileDisplay = reader.result;
      this.fileIcon = file;
    };
  }

  checkFileSize(file, maxSizeInBytes) {
    // default 2MB
    const maxSize = maxSizeInBytes || (2 * 1024 * 1024);
    return file.size <= maxSize;
  }

  isImage(file) {
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
    const extension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(extension);
  }

  uploadFile(customerId: any, formData: any) {
    this.donViService.uploadFile(customerId, formData).subscribe(response => this.onSaveSuccess(response), () => this.onSaveError());
  }
}

@Component({
  selector: 'app-don-vi-popup',
  template: ''
})
export class DonViPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<DonViDialogComponent>;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ donVi, dashboardTypes }) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(DonViDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        if (donVi !== undefined) {
          this.dialogRef.componentInstance.donVi = donVi;
          this.dialogRef.componentInstance.listDashboardType = dashboardTypes;
          if (donVi.certificationImage != null) {
            this.dialogRef.componentInstance.fileDisplay =  DOMAIN_IMAGE_URL +  donVi.certificationImage;
          }
        }

        this.dialogRef.afterClosed().subscribe(
          result => {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
          },
          reason => {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
          }
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách đơn vị');
    this.dialogRef = null;
  }
}
