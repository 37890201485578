import { saveAs } from 'file-saver';

export const exportFileData = (data: any, headers: any): void => {
  const contentDisposition = headers.get('content-disposition');
  const filename = contentDisposition
    .split(';')[1]
    .split('filename')[1]
    .split('=')[1]
    .trim();
  if (filename) {
    saveAs(data, filename);
  } else {
    alert(' Không tìm thấy filename');
  }
};
