import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes} from '@angular/router';
import {QrCodeComponent} from './qr-code/qr-code.component';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {QrCodePopupComponent} from './qr-code-dialog/qr-code-dialog.component';
import {QrCodePopupExportPdfComponent} from './qr-code-dialog-export-pdf/qr-code-dialog-export-pdf.component';
import {QrCodeDetailComponent} from './qr-code-detail/qr-code-detail.component';
import {Injectable} from '@angular/core';
import {IProduct, Product} from '../san-pham/san-pham.model';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {QrCodeService} from './qr-code.service';
import {QrCodePopupUpdateActivityComponent} from './qr-code-dialog-update-activity/qr-code-dialog-update-activity.component';
import {QrCodeDetailActivityComponent} from './qr-code-detail-activity/qr-code-detail-activity.component';

@Injectable({providedIn: 'root'})

export class QrCodeDetailResolve implements Resolve<IProduct> {
  constructor(private service: QrCodeService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['code'] ? route.params['code'] : null;

    if (id) {
      return this.service
          .find(id)
          .pipe(map((response: HttpResponse<IProduct>) => response.body));
    }
    return of(new Product());
  }
}

@Injectable({providedIn: 'root'})
export class QrCodeTimelineResolve implements Resolve<IProduct> {
  constructor(private service: QrCodeService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['code'] ? route.params['code'] : null;

    if (id) {
      return this.service
          .findTimeline(id)
          .pipe(map((response: HttpResponse<IProduct>) => response.body));
    }
    return of(new Product());
  }
}

export const QrCodeRoute: Routes = [
  {
    path: 'danh-sach-qr-code',
    component: QrCodeComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      pageTitle: 'Danh sách Qr code',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'tao-ma-qr-code',
    component: QrCodePopupComponent,
    data: {
      pageTitle: 'Tạo Qr code',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'export-pdf',
    component: QrCodePopupExportPdfComponent,
    data: {
      pageTitle: 'Export pdf Qr code',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'chi-tiet-qr-code/:code',
    component: QrCodeDetailComponent,
    data: {
      pageTitle: 'Chi tiết Qr code',
    },
    canActivate: [UserRouteAccessService],
    resolve: {
      data: QrCodeDetailResolve,
    },
  },
  {
    path: 'xem-hanh-dong-qr-code/:code',
    component: QrCodeDetailActivityComponent,
    data: {
      pageTitle: 'Chi tiết Qr code',
    },
    canActivate: [UserRouteAccessService],
    resolve: {
      data: QrCodeTimelineResolve,
    },
  },
  {
    path: 'update-activity',
    component: QrCodePopupUpdateActivityComponent,
    data: {
      pageTitle: 'Update Activity ',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
];
