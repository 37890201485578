import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {JhiAlertService, JhiParseLinks, JhiEventManager} from 'ng-jhipster';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from '../../../shared';
import {IUser, Principal, User, UserService} from '../../../core';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-mgmt',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserMgmtComponent implements OnInit, OnDestroy {
  currentAccount: IUser;
  error: any;
  success: any;
  displayedColumns: string[] = [
    'login',
    'fullName',
    'telephone',
    'email',
    'tenVaiTro',
    'action',
  ];
  dataSource: any;
  pageSizeOptions: number[];
  ELEMENT_DATA: IUser[];
  users: IUser[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  currentSearch: string;
  routeData: any;
  eventSubscriber: Subscription;
  noData: boolean;
  constructor(
    private userService: UserService,
    private alertService: JhiAlertService,
    private principal: Principal,
    private parseLinks: JhiParseLinks,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private eventManager: JhiEventManager,
  ) {
    this.dataSource = null;
    this.ELEMENT_DATA = [];
    this.users = [];
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.routeData = this.activatedRoute.data.subscribe(({pagingParams}) => {
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
    });

    this.currentSearch =
      this.activatedRoute.snapshot &&
      this.activatedRoute.snapshot.params['search']
        ? this.activatedRoute.snapshot.params['search']
        : '';
  }

  ngOnInit() {
    this.titleService.setTitle('Danh sách người dùng');
    this.principal.identity().then((account) => {
      this.currentAccount = account;
      this.loadAll();
    });
    this.changedUser();
  }

  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }

  changedUser() {
    this.eventSubscriber = this.eventManager.subscribe(
      'userListModification',
      (response) => this.loadAll(),
    );
  }
  loadAll() {
    if (this.currentSearch) {
      this.userService
        .search({
          page: this.page - 1,
          size: this.itemsPerPage,
          sort: this.sort(),
          query: this.currentSearch,
        })
        .subscribe(
          (res: HttpResponse<User[]>) => this.onSuccess(res.body, res.headers),
          (res: HttpResponse<any>) => this.onError(res.body),
        );
      return;
    }
    this.userService
      .query({
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: this.sort(),
      })
      .subscribe(
        (res: HttpResponse<User[]>) => this.onSuccess(res.body, res.headers),
        (res: HttpResponse<any>) => this.onError(res.body),
      );
  }

  sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }

  clear() {
    this.page = 1;
    this.currentSearch = '';

    this.loadAll();
  }

  search(query: string) {
    if (!query) {
      return this.clear();
    }
    this.page = 1;
    this.currentSearch = query;
    this.loadAll();
  }

  private onSuccess(data: any, headers: any) {
    this.links = this.parseLinks.parse(headers.get('link'));
    this.totalItems = headers.get('X-Total-Count');
    this.queryCount = this.totalItems;
    this.dataSource = new MatTableDataSource(data);
    if (data.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
  }

  private onError(Error: any) {
    this.alertService.error(Error.error, Error.message, null);
  }
}
