import {HttpResponse} from '@angular/common/http';
import {IKhuVuc, KhuVuc} from './../../khu-vuc/khu-vuc.model';
import {EntityLocationSearch} from './../entity-location.search';
import {Subscription} from 'rxjs';
import {EntityLocationService} from './../entity-location.service';
import {EntityLocation, IEntityLocation} from './../entity-location.model';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router, Data} from '@angular/router';
import {MatDialogRef} from '@angular/material';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {isObject} from 'util';

@Component({
  selector: 'app-entity-location-dialog',
  templateUrl: './entity-location-dialog.component.html',
  styleUrls: ['./entity-location-dialog.component.scss']
})
export class EntityLocationDialogComponent implements OnInit {
  entity: IKhuVuc;
  dataSource: IEntityLocation[];
  eventSubscriber: Subscription;
  searchService = new EntityLocationSearch();
  displayedColumns: string[] = ['id', 'latlng', 'action'];
  constructor(
    private dialogRef: MatDialogRef<EntityLocationDialogComponent>,
    private service: EntityLocationService,
    private router: Router,
    private readonly route: ActivatedRoute,
  ) {
    this.dataSource = [];
    this.entity = new KhuVuc();
  }

  ngOnInit() {
  }

  clear() {
    this.dialogRef.close('cancel');
  }

  save() {

  }

  /**
   * Search list
   *
   * @param {*} [eventRes]
   * @memberof RoleListComponent
   */
   search() {
    this.searchService = new EntityLocationSearch();
    this.searchService.entityId.filterValue = this.entity.id;
    this.searchService.setActivatedRouteData(this.route.snapshot);
    const filterParams = this.searchService.getFilterParams();
    this.searchService.setQueryParams(filterParams);
    const req = this.searchService.getParams();
    this.service.query(req).subscribe((res: HttpResponse<IEntityLocation[]>) => {
      if (res && res.body) {
        this.dataSource = res.body;
      }
    });
  }

  addNewData() {
    const item = new EntityLocation({});
    item.tbEntityId = this.entity.id;
    item.tbCustomerId = this.entity.tbCustomerId;
    item.dirty = true;
    this.dataSource.push(item);
    this.dataSource = [...this.dataSource];
  }

  clearData(item: IEntityLocation) {
    if (item.id) {
      item.dirty = false;
      item = new EntityLocation(item.old);
    } else {
      this.dataSource.pop();
    }
    this.dataSource = [...this.dataSource];
  }

  updateData(item: IEntityLocation) {
    if (item.id) {
      item.old = new EntityLocation(item);
      item.dirty = true;
    }
  }

  confirmSaveData(item: IEntityLocation) {
    if (item.id) {

      this.service.update(item).subscribe(() => {
        item.dirty = false;
        item.old = null;
        this.search();
      });
    } else {
      this.service.create(item).subscribe((res) => {
        item.dirty = false;
        this.search();
      });
    }
  }

  confirmDeleteData(item: IEntityLocation) {
    if (item.deleted) {
      return this.service.delete(item.id.toString()).subscribe(() => {
        this.search();
      });
    }
  }
}



@Component({
  selector: 'app-entity-location-popup',
  template: '',
})
export class EntityLocationPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<EntityLocationDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({entityLocations, entity}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(EntityLocationDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        if (entityLocations && entity) {
          this.dialogRef.componentInstance.dataSource = entityLocations || [];
          this.dialogRef.componentInstance.entity = entity || new KhuVuc();
        }

        this.dialogRef.afterClosed().subscribe(
          (result) => this.previousState(result),
          (reason) => this.previousState(reason),
        );
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.dialogRef = null;
  }

  previousState(result?: any): void {
    const params: any = {};
    if (result && isObject(result)) {
      Object.assign(params, result);
    }
    this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent, queryParams: params, queryParamsHandling: 'merge'});
  }
}
