import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {SharedModule} from '../shared';
import {HOME_ROUTE} from './home.route';
import {DashboardComponent} from './dashboard/dashboard.component';
import {
  DialogUpdateUnitComponent,
  DialogUpdateUnitPopupComponent,
} from './dialog-update-unit/dialog-update-unit.component';
import {UPDATE_ROUTE} from './home.route';
import {BlockComponent} from './dashboard/block/block.component';
import { LegendComponent } from './legend/legend.component';
import { HouseComponent } from './dashboard/house/house.component';
import { DashboardCropTileComponent } from './dashboard/dashboard-crop-tile/dashboard-crop-tile.component';
import { DashboardHouseDetailDialogComponent } from './dashboard-house-detail-dialog/dashboard-house-detail-dialog.component';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { DashboardMapComponent } from './dashboard-map/dashboard-map.component';
import { DashboardImageComponent } from './dashboard-image/dashboard-image.component';

@NgModule({
  imports: [SharedModule, RouterModule.forChild([HOME_ROUTE, UPDATE_ROUTE])],
  declarations: [
    DashboardComponent,
    DialogUpdateUnitComponent,
    DialogUpdateUnitPopupComponent,
    BlockComponent,
    HouseComponent,
    LegendComponent,
    DashboardCropTileComponent,
    DashboardHouseDetailDialogComponent,
    DashboardLayoutComponent,
    DashboardMapComponent,
    DashboardImageComponent,
  ],
  entryComponents: [
    DialogUpdateUnitComponent,
    DashboardHouseDetailDialogComponent,
  ],
})
export class HomeModule {}
