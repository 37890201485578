import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {HttpResponse} from '@angular/common/http';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {VatTuService} from '../vat-tu.service';
import {ISupply, IVatTu} from '../vat-tu.model';
import {ActivatedRoute} from '@angular/router';
import {
  ITEMS_PER_PAGE,
  PAGE_SIZE_OPTIONS,
} from 'src/app/shared/constants/pagination.constants';
import {Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {LoaiVatTuService} from '../../loai-vat-tu/loai-vat-tu.service';
import {ILoaiVatTu} from '../../loai-vat-tu/loai-vat-tu.model';
import {netHouses} from '../../../shared/model/area.model';

@Component({
  selector: 'app-vat-tu',
  templateUrl: './vat-tu.component.html',
  styleUrls: ['./vat-tu.component.scss'],
})
export class VatTuComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'sku',
    'tenThuongMai',
    'loaiVatTu',
    'thanhPhan',
    'quyCach',
    'nhaSanXuat',
    'tonKho',
    'action',
  ];
  dataSource: any;
  pageSizeOptions: number[];
  ELEMENT_DATA: IVatTu[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  currentSearch: string;
  routeData: any;
  eventSubscriber: Subscription;
  suppliesTypeData: ILoaiVatTu[];
  suppliesTypeId: any;
  noData: boolean;
  constructor(
    private vatTuService: VatTuService,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private parseLinks: JhiParseLinks,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    this.dataSource = null;
    this.currentSearch = '';
    this.suppliesTypeId = '';
    this.ELEMENT_DATA = [];
    this.suppliesTypeData = [];
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.routeData = this.activatedRoute.data.subscribe(({pagingParams}) => {
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Danh sách vật tư');
    this.getSuppliesType();
    this.loadAll();
    this.changeListSupply();
  }

  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }

  changeListSupply() {
    this.eventSubscriber = this.eventManager.subscribe(
      'vattuListModification',
      () => this.loadAll(),
    );
  }
  getSuppliesType() {
    this.vatTuService
      .supplyType()
      .subscribe((res: any) =>
        this.onSuccess(res.body, res.headers, 'suppliesType'),
      );
  }

  search(query: string) {
    this.page = 1;
    this.currentSearch = query;

    this.loadAll();
  }
  onChangeSuppliesType(e) {
    this.suppliesTypeId = e.value;
    this.loadAll();
  }
  loadAll() {
    this.vatTuService
      .query({
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: this.sort(),
        suppliesTypeId: this.suppliesTypeId,
        q: this.currentSearch,
      })
      .subscribe((res: HttpResponse<IVatTu[]>) =>
        this.onSuccess(res.body, res.headers, 'vatTu'),
      );
  }

  private onSuccess(data: any, headers: any, type: string) {
    if (type === 'vatTu') {
      this.links = this.parseLinks.parse(headers.get('link'));
      this.totalItems = headers.get('X-Total-Count');
      this.queryCount = this.totalItems;
      this.ELEMENT_DATA = data;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      if (data.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    }
    if (type === 'suppliesType') {
      this.suppliesTypeData = data;
    }
  }

  private sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }
  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
