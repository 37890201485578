import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IDonVi, IDonViUser} from '../entities/don-vi/don-vi.model';
import {createRequestOption} from '../shared/util/request-util';
import {SERVER_API_URL} from '../app.constants';
import {IHoatDongServer} from './statictis.model';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private resourceUrl =
    SERVER_API_URL + 'api/thong-ke/so-hoat-dong-trong-thang';
  constructor(private http: HttpClient) {}

  DetailDonVi(): Observable<HttpResponse<IDonViUser>> {
    return this.http.get<IDonViUser>(
      SERVER_API_URL + 'api/tb-customers-current-user',
      {observe: 'response'},
    );
  }
  UpdateDonVi(DonViUser: any): Observable<HttpResponse<any>> {
    return this.http.put(SERVER_API_URL + 'api/tb-customers', DonViUser, {
      observe: 'response',
    });
  }
  ListNhaMang(id?: any, req?: any): Observable<HttpResponse<any>> {
    return this.http.get<any[]>(SERVER_API_URL + 'api/tb-entities/customer/' + id, {
      observe: 'response', params: req,
    });
  }
}
