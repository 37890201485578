import {map} from 'rxjs/operators';
import {ILoaiKhuVuc} from './../../loai-khu-vuc/loai-khu-vuc.model';
import {LoaiKhuVucService} from './../../loai-khu-vuc/loai-khu-vuc.service';
import {FormGroup} from '@angular/forms';
import {PageEvent} from '@angular/material';
import {HttpResponse} from '@angular/common/http';
import {KhuVucService} from './../khu-vuc.service';
import {JhiEventManager} from 'ng-jhipster';
import {ActivatedRoute} from '@angular/router';
import {KhuVucSearch} from './khu-vuc.search';
import {Subscription, Observable} from 'rxjs';
import {IKhuVuc} from './../khu-vuc.model';
import {Component, Input, OnInit, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-khu-vuc',
  templateUrl: './khu-vuc.component.html',
  styleUrls: ['./khu-vuc.component.scss']
})
export class KhuVucComponent implements OnInit, OnDestroy {
  @Input() donViId: number;
  dataSource: IKhuVuc[];
  eventSubscriber: Subscription;
  searchService = new KhuVucSearch();
  displayedColumns: string[] = ['tbEntityTypeName', 'name', 'parentName', 'action'];
  // filterForm: FormGroup;
  entryTypes: ILoaiKhuVuc[];
  topLevelEntries: IKhuVuc[];
  constructor(
    private readonly route: ActivatedRoute,
    private eventManager: JhiEventManager,
    private service: KhuVucService,
    private entryTypeService: LoaiKhuVucService,

  ) {
    this.dataSource = [];
    this.searchService.setPageSize(50);
  }

  ngOnInit() {
    this.route.data.subscribe(({searchResponse}) => {
      // this.searchService = new RoleListSearch();
      this.searchService.setResponse(this.route.snapshot, searchResponse);
      if (searchResponse) {
        this.dataSource = searchResponse.body || searchResponse.body.data;
      }
      // const patchObject: any = {};
      // this.filterForm.patchValue(patchObject, { emitEvent: false });
    });
    this.eventSubscriber = this.eventManager.subscribe(
      'khuVucModification',
      () => this.search(),
    );
    const donViId = this.route.snapshot.paramMap.get('donVi');
    if (donViId) {
      this.search();
    }
  }

  ngOnDestroy() {
    if (this.eventSubscriber) {
      this.eventManager.destroy(this.eventSubscriber);
    }
  }

  search(newQueryParams?: any): void {
    const filterParams = this.searchService.getFilterParams();
    newQueryParams = Object.assign(newQueryParams || {}, filterParams);

    Promise.all([
      this.entryTypeService.query().toPromise(),
      this.service.queryByTopLevel().toPromise(),
    ]).then(values => {
      const [res1, res2] = values;
      if (res1) {
        this.entryTypes = res1.body;
      }
      if (res2) {
        this.topLevelEntries = res2.body;
      }
      this.getList();
    }).catch(reason => console.warn(reason));
  }

  getList(params?: any): void {
    const req = this.searchService.getParams();
    const donViId = this.route.snapshot.paramMap.get('donVi');
    this.service.queryByCustomerId(donViId || this.donViId, req)
      .pipe(
        map(project => {
          if (project.body) {
            project.body.map(i => {
              const entityType = this.entryTypes.find(i2 => i2.id === i.tbEntityTypeId);
              if (entityType) {
                i.tbEntityTypeName = entityType.name || null;
              }
              const topLevelEntry = this.topLevelEntries.find(i2 => i2.id === i.parentId);
              if (topLevelEntry) {
                i.parentName = topLevelEntry.name || null;
              }
              return i;
            });
          }
          return project;
        }),
      )
      .subscribe((res: HttpResponse<IKhuVuc[]>) => {
        if (res) {
          this.dataSource = res.body;
          this.searchService.setResponse(this.route.snapshot, res);
        }
      },
        (res: HttpResponse<IKhuVuc>) => console.log(res),
      );
  }

  onPageChange(event: PageEvent) {
    this.searchService.setPage(event.pageIndex + 1);
    this.searchService.setPageSize(event.pageSize);
    this.search();
  }
}
