import {LoaiKhuVucService} from './../../loai-khu-vuc/loai-khu-vuc.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router, Data} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {IKhuVuc, KhuVuc} from './../khu-vuc.model';
import {KhuVucService} from './../khu-vuc.service';
import {MatDialogRef} from '@angular/material';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {ILoaiKhuVuc} from '../../loai-khu-vuc/loai-khu-vuc.model';

@Component({
  selector: 'app-khu-vuc-dialog',
  templateUrl: './khu-vuc-dialog.component.html',
  styleUrls: ['./khu-vuc-dialog.component.scss']
})
export class KhuVucDialogComponent implements OnInit {
  entity: IKhuVuc;
  entityTypes$: Observable<ILoaiKhuVuc[]>;
  topLevelEntries$: Observable<IKhuVuc[]>;
  constructor(
    private dialogRef: MatDialogRef<KhuVucDialogComponent>,
    private eventManager: JhiEventManager,
    private service: KhuVucService,
    private entityTypeService: LoaiKhuVucService,
  ) {
    this.entity = new KhuVuc();
  }

  ngOnInit() {
    this.entityTypes$ = this.entityTypeService.query().pipe(map(res => res.body || []));
    this.topLevelEntries$ = this.service.listAreaByCustomer(this.entity.tbCustomerId).pipe(map(res => res.body || []));
  }

  clear() {
    this.dialogRef.close('cancel');
  }

  save() {
    if (this.entity.id !== null) {
      this.service.update(this.entity).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError(),
      );
    } else {
      this.service.create(this.entity).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError(),
      );
    }
  }

  private onSaveSuccess() {
    this.eventManager.broadcast({
      name: 'khuVucModification',
      content: this.entity,
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
  }
}


@Component({
  selector: 'app-khu-vuc-popup',
  template: '',
})
export class KhuVucPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<KhuVucDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({khuVuc}: Data) => {
      setTimeout(() => {
        const donViId = this.activatedRoute.snapshot.paramMap.get('donViId');
        this.dialogRef = this.dialog.open(KhuVucDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        const entity: IKhuVuc = khuVuc || new KhuVuc();
        if (donViId) {
          entity.tbCustomerId = Number(donViId);
        }
        this.dialogRef.componentInstance.entity = entity;
        this.dialogRef.afterClosed().subscribe(
          (result) => this.previousState(result),
          (reason) => this.previousState(reason),
        );
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.dialogRef = null;
  }

  previousState(result?: any): void {
    const params: any = {
      id: null,
    };
    this.router.navigate([{outlets: {popup: null}}], {
      relativeTo: this.activatedRoute.parent,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
