export class FilterCriteria {
  constructor() {}
  getFilterParams(only?: string[]) {
    const params = {};
    let p: string;
    for (const attr in this) {
      if (this.hasOwnProperty(attr)) {
        if (
          typeof this[attr] === 'function' ||
          !attr ||
          typeof this[attr] === 'undefined' ||
          (Array.isArray(only) && !only.includes(attr))
        ) {
          continue;
        }
        p = this[attr]['filterParamStr'];
        if (p) {
          params[p] = this.formatFilterValue(this[attr]);
        }
      }
    }
    return params;
  }

  formatFilterValue(field: any): any {
    return field.filterValue;
  }
}
