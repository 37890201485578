import {map} from 'rxjs/operators';
import {IEntityLocation} from './entity-location.model';
import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {EntityLocationService} from './entity-location.service';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {EntityLocationSearch} from './entity-location.search';

@Injectable({
    providedIn: 'root'
  })
  export class EntityLocationsResolve implements Resolve<any[]> {
    private searchService: EntityLocationSearch;
    constructor(
      private service: EntityLocationService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
      const entityId = route.params['id'] ? route.params['id'] : null;

      this.searchService = new EntityLocationSearch();
      this.searchService.entityId.filterValue = entityId;
      this.searchService.setActivatedRouteData(route);

      const filterParams = this.searchService.getFilterParams();
      this.searchService.setQueryParams(filterParams);

      const req = this.searchService.getParams();

      return this.service.query(req)
      .pipe(map((res: HttpResponse<IEntityLocation[]>) => res.body));
    }
  }
