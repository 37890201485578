import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {ThietBiService} from '../thiet-bi.service';
import {JhiEventManager} from 'ng-jhipster';

@Component({
  selector: 'app-storage-danh-muc-thiet-bi-dialog',
  templateUrl: './storage-danh-muc-thiet-bi-dialog.component.html',
  styleUrls: ['./storage-danh-muc-thiet-bi-dialog.component.scss'],
})
export class StorageDanhMucThietBiDialogComponent implements OnInit {
  storageDevice: any;
  constructor(
    private dialogRef: MatDialogRef<StorageDanhMucThietBiDialogComponent>,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private thietBiService: ThietBiService,
  ) {
    this.storageDevice = [];
  }

  ngOnInit() {
  }
  save() {
    return;
  }
}

@Component({
  selector: 'app-kho-thiet-bi',
  template: '',
})
export class StorageDanhMucThietBiPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<StorageDanhMucThietBiDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
    private thietBiService: ThietBiService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Thiết bị tồn kho');
    this.activatedRoute.data.subscribe(({warehouse}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(
          StorageDanhMucThietBiDialogComponent,
          {
            disableClose: true,
            width: '380px',
          },
        );
        if (warehouse) {
          this.dialogRef.componentInstance.storageDevice = warehouse;
        }
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }
  ngOnDestroy() {
    this.titleService.setTitle('Danh mục thiết bị');
    this.dialogRef = null;
  }
}
