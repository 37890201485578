import {map} from 'rxjs/operators';
import {IKhuVuc} from './../../entities/khu-vuc/khu-vuc.model';
import {Component, OnInit, OnChanges} from '@angular/core';

@Component({
  selector: 'app-dashboard-house-detail-dialog',
  templateUrl: './dashboard-house-detail-dialog.component.html',
  styleUrls: ['./dashboard-house-detail-dialog.component.scss']
})
export class DashboardHouseDetailDialogComponent implements OnInit, OnChanges {
  data: IKhuVuc;
  dataDisplay: {
    code: string,
    periodName: string,
    status?: number|string,
    workers?: string,
  }[];
  constructor() {
    this.dataDisplay = [];
  }

  ngOnInit() {
    if (this.data && this.data.tbCropDTOS) {
      this.dataDisplay = this.data.tbCropDTOS
      .filter(i => i.status === 'STATUS_ARE_ACTIVE')
      .map(i => {
        const {code, tbGuidelinePeriodName, status, tbDetailUsers} = i;
        const workers = tbDetailUsers ? tbDetailUsers.map(u => u.fullName || u.name).join(', ') : '';
        return {
          code,
          periodName: tbGuidelinePeriodName,
          status,
          workers,
        };
      });
    }
  }

  ngOnChanges() {

  }
}
