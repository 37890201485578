import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import {Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {
  UPDATE_SUCCESS,
  CREATE_SUCCESS,
  DELETE_SUCCESS,
} from 'src/app/shared/constants/messages.constants';

export class NotificationInterceptor implements HttpInterceptor {
  private alertService: JhiAlertService;
  private eventManager: JhiEventManager;

  // tslint:disable-next-line: no-unused-variable
  constructor(private injector: Injector) {
    setTimeout(() => {
      this.alertService = injector.get(JhiAlertService);
      this.eventManager = injector.get(JhiEventManager);
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const exceptAlert = request.params.get('exceptAlert');
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && !exceptAlert) {
            const arr = event.headers.keys();
            let alertNotify = null,
              alertType = 'success';
            arr.forEach((entry) => {
              if (entry.toLowerCase().endsWith('app-alert')) {
                alertNotify = event.headers.get(entry);
              } else if (entry.toLowerCase().endsWith('rest-alert')) {
                alertNotify = event.headers.get(entry);
                alertNotify = decodeURIComponent(escape(alertNotify));
              } else if (entry.toLowerCase().endsWith('alert-type')) {
                alertType = event.headers.get(entry);
              }
            });
            if (alertNotify) {
              if (typeof alertNotify === 'string') {
                let msgNotify = alertNotify.toString();
                if (this.alertService) {
                  if (msgNotify.indexOf('updated') > 1) {
                    msgNotify = UPDATE_SUCCESS;
                    this.alertService.success(UPDATE_SUCCESS, null, null);
                  } else if (msgNotify.indexOf('created') > 1) {
                    msgNotify = CREATE_SUCCESS;
                    this.alertService.success(CREATE_SUCCESS, null, null);
                  } else if (msgNotify.indexOf('deleted') > 1) {
                    msgNotify = DELETE_SUCCESS;
                    this.alertService.success(DELETE_SUCCESS, null, null);
                  }
                }
                if (this.eventManager !== undefined) {
                  this.eventManager.broadcast({
                    name: 'pmwebApp.app-notifier',
                    content: {message: msgNotify, type: alertType},
                  });
                }
              }
            }
          }
        },
        (err: any) => {},
      ),
    );
  }
}
