import {IKhuVuc} from './../../../entities/khu-vuc/khu-vuc.model';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
})
export class BlockComponent implements OnInit {
  @Input() data: IKhuVuc;

  ngOnInit() {
    const test = 2;
  }

  findColor(color: number) {
    if (color === 0) {
      return 'red';
    } else if (color === 1) {
      return 'purple';
    } else if (color === 2) {
      return '#ffc000';
    } else if (color === 3) {
      return 'blue';
    } else if (color === 4) {
      return 'green';
    } else {
      return 'purple';
    }
  }
}
