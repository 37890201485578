import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {ISupply, VatTu} from '../vat-tu.model';
import {VatTuService} from '../vat-tu.service';
import {JhiEventManager, JhiAlertService} from 'ng-jhipster';
import {LoaiVatTuService} from '../../loai-vat-tu/loai-vat-tu.service';
import {ILoaiVatTu} from '../../loai-vat-tu/loai-vat-tu.model';
import {HttpResponse} from '@angular/common/http';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-vat-tu-dialog',
  templateUrl: './vat-tu-dialog.component.html',
  styleUrls: ['./vat-tu-dialog.component.scss'],
})
export class VatTuDialogComponent implements OnInit {
  vattu: VatTu;
  listLoaiVatTu: ILoaiVatTu[];
  listSupply: ISupply[];
  listUnit$: any;
  isSaving: boolean;
  constructor(
    private dialogRef: MatDialogRef<VatTuDialogComponent>,
    private vattuService: VatTuService,
    private eventManager: JhiEventManager,
    private titleService: Title,
  ) {
    this.isSaving = false;
    this.vattu = new VatTu();
    this.listLoaiVatTu = [];
    this.listSupply = [];
    this.listUnit$ = [];
  }

  ngOnInit() {
    this.vattuService.supplyType().subscribe((res: any) => {
      this.listSupply = res.body ? res.body : [];
    });
    if (this.vattu.id !== null) {
      this.titleService.setTitle('Sửa vật tư');
    } else {
      this.titleService.setTitle('Thêm vật tư');
    }
  }

  save() {
    this.isSaving = true;
    if (this.vattu.id !== null) {
      this.vattuService.update(this.vattu).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    } else {
      this.vattuService.create(this.vattu).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }
  }

  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'vattuListModification',
      content: 'VatTu',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-vat-tu-popup',
  template: '',
})
export class VatTuPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<VatTuDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private alertService: JhiAlertService,
    private titleService: Title,
    private vatTuService: VatTuService,
  ) {}

  ngOnInit() {
    this.activatedRouteVatTu();
  }

  ngOnDestroy() {
    this.dialogRef = null;
    this.titleService.setTitle('Danh sách vật tư');
  }

  activatedRouteVatTu() {
    this.activatedRoute.data.subscribe(({vattu}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(VatTuDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        if (vattu) {
          this.dialogRef.componentInstance.vattu = vattu;
        }
        this.vatTuService.listUnit().subscribe((res: any) => {
          this.dialogRef.componentInstance.listUnit$ = res;
        });

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }
}
