import {IEntityLocation} from './../entity-location/entity-location.model';
import {ICrops} from './../canh-tac/canh-tac.model';
export interface IKhuVuc {
    id?: number;
    qrCode?: string;
    name?: string;
    areaM2?: number;
    color?: number;
    level?: number;
    parentId?: number;
    parentName?: string;
    status?: string;
    tbEntityTypeId?: number;
    tbEntityTypeName?: string;
    tbCustomerId?: number;
    tbCropDTOS?: ICrops[];
    houses?: IKhuVuc[];
    locations?: IEntityLocation[];
}

export class KhuVuc implements IKhuVuc {
    constructor(
        public id?: number,
        public qrCode?: string,
        public color?: number,
        public name?: string,
        public areaM2?: number,
        public level?: number,
        public parentId?: number,
        public parentName?: string,
        public status?: string,
        public tbEntityTypeId?: number,
        public tbEntityTypeName?: string,
        public tbCustomerId?: number,
        public tbCropDTOS?: ICrops[],
        public houses?: IKhuVuc[],
        public locations?: IEntityLocation[],
    ) {
        this.id = id ? id : null;
        this.qrCode = qrCode ? qrCode : null;
        this.color = color ? color : null;
        this.name = name ? name : null;
        this.areaM2 = areaM2 ? areaM2 : null;
        this.level = level ? level : 1;
        this.parentId = parentId ? parentId : null;
        this.parentName = parentName ? parentName : null;
        this.status = status ? status : null;
        this.tbEntityTypeId = tbEntityTypeId ? tbEntityTypeId : null;
        this.tbEntityTypeName = tbEntityTypeName ? tbEntityTypeName : null;
        this.tbCustomerId = tbCustomerId ? tbCustomerId : null;
        this.tbCropDTOS = tbCropDTOS ? tbCropDTOS : null;
        this.houses = houses ? houses : [];
        this.locations = locations ? locations : null;
    }
}
