import {LoaiKhuVucService} from './../../loai-khu-vuc/loai-khu-vuc.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router, Data} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {IKhuVuc, KhuVuc} from './../khu-vuc.model';
import {KhuVucService} from './../khu-vuc.service';
import {MatDialogRef} from '@angular/material';
import {Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import {ILoaiKhuVuc} from '../../loai-khu-vuc/loai-khu-vuc.model';

@Component({
  selector: 'app-khu-vuc-delete-dialog',
  templateUrl: './khu-vuc-delete-dialog.component.html',
  styleUrls: ['./khu-vuc-delete-dialog.component.scss']
})
export class KhuVucDeleteDialogComponent implements OnInit {
  entity: IKhuVuc;
  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }
  constructor(
    private dialogRef: MatDialogRef<KhuVucDeleteDialogComponent>,
    private eventManager: JhiEventManager,
    private service: KhuVucService,
  ) { }

  ngOnInit() {
  }


  confirmDelete(id: any) {
    this.service.delete(id).subscribe((res) => {
      this.eventManager.broadcast({
        name: 'khuVucModification',
        content: this.entity,
      });
      this.dialogRef.close(true);
    });
  }
}


@Component({
  selector: 'app-khu-vuc-delete-popup',
  template: '',
})
export class KhuVucDeletePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<KhuVucDeleteDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({khuVuc}: Data) => {
      setTimeout(() => {
        const donViId = this.activatedRoute.snapshot.paramMap.get('donViId');
        this.dialogRef = this.dialog.open(KhuVucDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        const entity: IKhuVuc = khuVuc || new KhuVuc();
        entity.tbCustomerId = Number(donViId);
        this.dialogRef.componentInstance.entity = entity;
        this.dialogRef.afterClosed().subscribe(
          (result) => this.previousState(result),
          (reason) => this.previousState(reason),
        );
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.dialogRef = null;
  }

  previousState(result?: any): void {
    const params: any = {
      id: null,
    };
    this.router.navigate([{outlets: {popup: null}}], {
      relativeTo: this.activatedRoute.parent,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
