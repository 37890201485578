import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'src/app/app.constants';
import {Observable} from 'rxjs';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {Country, City, District, SubDistrict} from '../model/location.model';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private resourceUrlCountry = SERVER_API_URL + '/api/tb-countries';
  private resourceUrlCity = SERVER_API_URL + '/api/tb-cities-by-country';
  private resourceUrlDistrict = SERVER_API_URL + '/api/tb-districts-by-city';
  private resourceUrlSubDistrict = SERVER_API_URL + '/api/tb-wards-by-district';

  constructor(private http: HttpClient) {}
  listCountry(): Observable<HttpResponse<Country[]>> {
    return this.http.get<Country[]>(this.resourceUrlCountry, {
      params: null,
      observe: 'response',
    });
  }
  listCity(id: number): Observable<HttpResponse<City[]>> {
    return this.http.get<City[]>(
      `${this.resourceUrlCity}/${id}?page=0&size=100`,
      {observe: 'response'},
    );
  }
  listDistrict(id: number): Observable<HttpResponse<District[]>> {
    return this.http.get<District[]>(`${this.resourceUrlDistrict}/${id}`, {
      observe: 'response',
    });
  }
  listSubDistrict(id: number): Observable<HttpResponse<SubDistrict[]>> {
    return this.http.get<SubDistrict[]>(
      `${this.resourceUrlSubDistrict}/${id}`,
      {observe: 'response'},
    );
  }
}
