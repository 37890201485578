import {IDashboardType} from "src/app/home/dashboard-type/dashboard-type";

export enum DashboardLayout {
  LIST = 'DANHSACH',
  MAP = 'MAP',
  IMAGE = 'IMAGE',
}

export enum CustomerSlug {
  DEFAULT = 'default',
  OXANH = 'Oxanh',
}

export interface IDonVi {
  id?: any;
  address?: string;
  canBoHTKT?: string;
  dienTichDat?: any;
  maSo?: string;
  telephone?: string;
  name?: string;
  soLoHienTai?: any;
  isDraft?: boolean;
}

export class DonVi implements IDonVi {
  constructor(
    public id?: any,
    public address?: string,
    public canBoHTKT?: string,
    public dienTichDat?: any,
    public maSo?: any,
    public telephone?: string,
    public name?: string,
    public soLoHienTai?: any,
    public isDraft?: boolean,
  ) {
    this.id = id ? id : null;
    this.address = address ? address : null;
    this.canBoHTKT = canBoHTKT ? canBoHTKT : null;
    this.dienTichDat = dienTichDat ? dienTichDat : null;
    this.maSo = maSo ? maSo : null;
    this.telephone = telephone ? telephone : null;
    this.name = name ? name : null;
    this.soLoHienTai = soLoHienTai ? soLoHienTai : null;
    this.isDraft = isDraft ? isDraft : false;
  }
}

export interface IDonViUser {
  id?: number;
  name?: string;
  phone?: string;
  email?: string;
  areaM2?: number;
  address?: string;
  wardId?: number;
  ward?: string;
  districtId?: number;
  district?: string;
  cityId?: number;
  city?: string;
  countryId?: number;
  country?: string;
  isActivated?: number;
  createdDate?: any;
  modifiedDate?: any;
  deletedDate?: any;
  tbAddressId?: number;
  createdById?: number;
  modifiedById?: any;
  deletedById?: any;
  tbDashboardTypeId?: number;
  tbDashboardType?: IDashboardType;
  slugName?: string;

  assign(props?: IDonViUser|any): IDonViUser;
  getFullAddress(): string;
}

export class DonViUser implements IDonViUser {
  constructor(
    public id?: number,
    public name?: string,
    public phone?: string,
    public email?: string,
    public areaM2?: number,
    public address?: string,
    public wardId?: number,
    public ward?: string,
    public districtId?: number,
    public district?: string,
    public cityId?: number,
    public city?: string,
    public countryId?: number,
    public country?: string,
    public isActivated?: number,
    public createdDate?: any,
    public modifiedDate?: any,
    public deletedDate?: any,
    public tbAddressId?: number,
    public createdById?: number,
    public modifiedById?: any,
    public deletedById?: any,
    public tbDashboardTypeId?: number,
    public tbDashboardType?: IDashboardType,
    public slugName?: string,
  ) {
    this.id = id ? id : null;
    this.name = name ? name : null;
    this.phone = phone ? phone : null;
    this.email = email ? email : null;
    this.areaM2 = areaM2 ? areaM2 : null;
    this.address = address ? address : null;
    this.wardId = wardId ? wardId : null;
    this.ward = ward ? ward : null;
    this.districtId = districtId ? districtId : null;
    this.district = district ? district : null;
    this.cityId = cityId ? cityId : null;
    this.city = city ? city : null;
    this.countryId = countryId ? countryId : null;
    this.country = country ? country : null;
    this.isActivated = isActivated ? isActivated : null;
    this.createdDate = createdDate ? createdDate : null;
    this.modifiedDate = modifiedDate ? modifiedDate : null;
    this.deletedDate = deletedDate ? deletedDate : null;
    this.tbAddressId = tbAddressId ? tbAddressId : null;
    this.createdById = createdById ? createdById : null;
    this.modifiedById = modifiedById ? modifiedById : null;
    this.deletedById = deletedById ? deletedById : null;
    this.tbDashboardTypeId = tbDashboardTypeId ? tbDashboardTypeId : null;
    this.tbDashboardType = tbDashboardType ? tbDashboardType : null;
    this.slugName = slugName ? slugName : null;
  }

  assign(props?: IDonViUser) {
    if (props) {
      for (const i in props) {
        if (typeof this[i] !== 'undefined') {
          this[i] = props[i];
        }
      }
    }
    return this;
  }

  getFullAddress() {
    return  [this.address, this.ward, this.district, this.city, this.country].filter(Boolean).join(", ");
  }
}
