import {ILoaiHoatDongCheckerList} from './../loai-hoat-dong/loai-hoat-dong.model';
export interface ILoaiLo {
    id?: number;
    name?: string;
    tbCropTypeId?: number;
    tbCropTypeName?: string;
    tbCropTypeChecked?: boolean;
    tbActivityTypeIds?: number[];
    tbActivityTypeGetList?: ILoaiHoatDongCheckerList;
}

export class LoaiLo implements ILoaiLo {
    tbCropTypeId: number;
    tbCropTypeName: string;
    tbActivityTypeGetList: ILoaiHoatDongCheckerList;
    constructor(
        public id?: number,
        public name?: string,
        public tbCropTypeChecked?: boolean,
        public tbActivityTypeIds?: number[],
    ) {
        this.id = id ? id : null;
        this.tbCropTypeId = id ? id : null;
        this.name = name ? name : null;
        this.tbCropTypeName = name ? name : null;
        this.tbCropTypeChecked = tbCropTypeChecked ? tbCropTypeChecked : false;
        this.tbActivityTypeIds = tbActivityTypeIds ? tbActivityTypeIds : [];
        this.tbActivityTypeGetList = {};
    }
}
