import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, patten?: any, format?: any): any {
    const pattern = patten ? patten : 'dd/MM/yyyy';
    const datePipe = new DatePipe('vi-VN');

    if (typeof value === 'number') {
      return datePipe.transform(value * 1000, pattern);
    } else if (Array.isArray(value)) {
      const date = new Date(value[0], value[1] - 1, value[2]);
      return datePipe.transform(date.getTime(), pattern);
    }
    return datePipe.transform(value, pattern);
  }
}
