import * as moment from 'moment';

export enum SupplyStockUpdateAction {
  IMPORT,
  EXPORT
}
export interface IVatTu {
  id?: number;
  name?: string;
  ingredient?: any;
  use?: any;
  sku?: string;
  description?: string;
  manufacturer?: string;
  quarantinePeriod?: any;
  dosage?: any;
  packaging?: any;
  jhiSize?: any;
  unit?: string;
  createdDate?: string;
  modifiedDate?: string;
  deletedDate?: string;
  tbSuppliesTypeId?: number;
  tbSuppliesTypeName?: string;
  createdById?: number;
  modifiedById?: any;
  deletedById?: any;
  tbCustomerId?: number;
  inventory?: number;
  tbGuidelines?: any[];
}

export class VatTu implements IVatTu {
  constructor(
    public id?: number,
    public name?: string,
    public ingredient?: any,
    public use?: any,
    public sku?: string,
    public description?: string,
    public manufacturer?: string,
    public quarantinePeriod?: any,
    public osage?: any,
    public packaging?: any,
    public jhiSize?: any,
    public unit?: string,
    public createdDate?: string,
    public modifiedDate?: string,
    public deletedDate?: string,
    public tbSuppliesTypeId?: number,
    public tbSuppliesTypeName?: string,
    public createdById?: number,
    public modifiedById?: any,
    public deletedById?: any,
    public tbCustomerId?: number,
    public inventory?: number,
    public tbGuidelines?: any[],
  ) {
    this.id = id ? id : null;
    this.name = name ? name : null;
    this.ingredient = ingredient ? ingredient : null;
    this.use = use ? use : null;
    this.sku = sku ? sku : null;
    this.description = description ? description : null;
    this.manufacturer = manufacturer ? manufacturer : null;
    this.quarantinePeriod = quarantinePeriod ? quarantinePeriod : null;
    this.osage = osage ? osage : null;
    this.packaging = packaging ? packaging : null;
    this.jhiSize = jhiSize ? jhiSize : null;
    this.unit = unit ? unit : null;
    this.createdDate = createdDate ? createdDate : null;
    this.modifiedDate = modifiedDate ? modifiedDate : null;
    this.deletedDate = deletedDate ? deletedDate : null;
    this.tbSuppliesTypeId = tbSuppliesTypeId ? tbSuppliesTypeId : null;
    this.tbSuppliesTypeName = tbSuppliesTypeName ? tbSuppliesTypeName : null;
    this.createdById = createdById ? createdById : null;
    this.modifiedById = modifiedById ? modifiedById : null;
    this.deletedById = deletedById ? deletedById : null;
    this.tbCustomerId = tbCustomerId ? tbCustomerId : null;
    this.inventory = inventory ? inventory : null;
    this.tbGuidelines = tbGuidelines ? tbGuidelines : null;
  }
}
export interface IVatTuDetail {
  tbSuppliesDTO?: IVatTu;
  tbSuppliesInWarehouseDTOS?: any[];
}
export class VatTuDetail implements IVatTuDetail {
  constructor(
    public tbSuppliesDTO?: IVatTu,
    public tbSuppliesInWarehouseDTOS?: any[] | InWareHouse[],
  ) {
    this.tbSuppliesDTO = tbSuppliesDTO ? tbSuppliesDTO : new VatTu();
    this.tbSuppliesInWarehouseDTOS = tbSuppliesInWarehouseDTOS
      ? tbSuppliesInWarehouseDTOS
      : [];
  }
}
export interface ISupply {
  id?: number;
  name?: string;
  description?: string;
}

export class Supply implements ISupply {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
  ) {
    this.id = id ? id : null;
    this.name = name ? name : null;
    this.description = description ? description : null;
  }
}
export interface IHistorySupplies {
  id?: number;
  updateDate?: string;
  action?: any;
  quantity?: any;
  description?: string;
  tbSuppliesOrderId?: any;
  tbSuppliesInWarehouseId?: any;
  tbActivityId?: any;
  tbWarehouseName?: any;
  tbEntityName?: any;
}

export class HistorySupplies implements IHistorySupplies {
  constructor(
    public id?: number,
    public updateDate?: string,
    public action?: any,
    public quantity?: any,
    public description?: string,
    public tbSuppliesOrderId?: any,
    public tbSuppliesInWarehouseId?: any,
    public tbActivityId?: any,
    public tbWarehouseName?: any,
    public tbEntityName?: any,
  ) {
    this.id = id ? id : null;
    this.updateDate = updateDate ? updateDate : null;
    this.action = action ? action : null;
    this.quantity = quantity ? quantity : null;
    this.description = description ? description : null;
    this.tbSuppliesOrderId = tbSuppliesOrderId ? tbSuppliesOrderId : null;
    this.tbSuppliesInWarehouseId = tbSuppliesInWarehouseId
      ? tbSuppliesInWarehouseId
      : null;
    this.tbActivityId = tbActivityId ? tbActivityId : null;
    this.tbWarehouseName = tbWarehouseName ? tbWarehouseName : null;
    this.tbEntityName = tbEntityName ? tbEntityName : null;
  }
}

export interface IInWareHouse {
  tbSuppliesId?: number;
  tbWarehouseId?: number;
  tbEntityId?: number;
  tbEntityName?: string;
  code?: string;
  updateDate?: any;
  mfgDate?: any;
  expDate?: any;
  action?: number;
  description?: string;
  quantity?: number;
}

export class InWareHouse implements IInWareHouse {
  constructor(
    public tbSuppliesId?: number,
    public tbWarehouseId?: number,
    public tbEntityId?: number,
    public tbEntityName?: string,
    public code?: string,
    public updateDate?: any,
    public mfgDate?: any,
    public expDate?: any,
    public action?: number,
    public description?: string,
    public quantity?: number,
  ) {
    this.tbSuppliesId = tbSuppliesId ? tbSuppliesId : null;
    this.tbWarehouseId = tbWarehouseId ? tbWarehouseId : null;
    this.tbEntityId = tbEntityId ? tbEntityId : null;
    this.tbEntityName = tbEntityName ? tbEntityName : null;
    this.code = code ? code : moment().format('YYMMDDHH');
    this.updateDate = updateDate ? updateDate : new Date();
    this.mfgDate = mfgDate ? mfgDate : new Date();
    this.expDate = expDate ? expDate : moment(this.mfgDate).add(7, 'days').toDate();
    this.action = action ? action : SupplyStockUpdateAction.IMPORT;
    this.description = description ? description : null;
    this.quantity = quantity ? quantity : null;
  }
}

export interface ISupplyBlockDetails {
  id?: number;
  quantity?: number;
  code?: string;
  mfgDate?: any;
  expDate?: any;
  tbSuppliesInWarehouseId?: number;
  tbWarehouseName?: string;
  tbWarehouseId?: number;
  tbEntityName?: string;
  tbEntityId?: number;
}

export class SupplyBlockDetails implements ISupplyBlockDetails {
  constructor(
    public id?: number,
    public quantity?: number,
    public code?: string,
    public mfgDate?: any,
    public expDate?: any,
    public tbSuppliesInWarehouseId?: number,
    public tbWarehouseName?: string,
    public tbWarehouseId?: number,
    public tbEntityName?: string,
    public tbEntityId?: number,
  ) {
    this.id = id ? id : null;
    this.quantity = quantity ? quantity : null;
    this.code = code ? code : moment().format('YYMMDDHH');
    this.mfgDate = mfgDate ? mfgDate : null;
    this.expDate = expDate ? expDate : null;
    this.tbSuppliesInWarehouseId = tbSuppliesInWarehouseId ? tbSuppliesInWarehouseId : null;
    this.tbWarehouseName = tbWarehouseName ? tbWarehouseName : null;
    this.tbWarehouseId = tbWarehouseId ? tbWarehouseId : null;
    this.tbEntityName = tbEntityName ? tbEntityName : null;
    this.tbEntityId = tbEntityId ? tbEntityId : null;
  }

  assign(props?: ISupplyBlockDetails) {
    if (typeof props === 'object') {
      this.id = props.id ? props.id : null;
      this.quantity = props.quantity ? props.quantity : null;
      this.code = props.code ? props.code : moment().format('YYMMDDHH');
      this.mfgDate = props.mfgDate ? props.mfgDate : null;
      this.expDate = props.expDate ? props.expDate : null;
      this.tbSuppliesInWarehouseId = props.tbSuppliesInWarehouseId ? props.tbSuppliesInWarehouseId : null;
      this.tbWarehouseName = props.tbWarehouseName ? props.tbWarehouseName : null;
      this.tbWarehouseId = props.tbWarehouseId ? props.tbWarehouseId : null;
      this.tbEntityName = props.tbEntityName ? props.tbEntityName : null;
      this.tbEntityId = props.tbEntityId ? props.tbEntityId : null;
    }
    return this;
  }
}
