import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';
import {createRequestOption} from '../../shared/util/request-util';
import {map} from 'rxjs/operators';
import {IVaiTro, IPermission} from './vai-tro.model';

@Injectable({
  providedIn: 'root',
})
export class VaiTroService {
  private resourceUrl = SERVER_API_URL + 'api/tb-roles';
  private resourcePermissionUrl = SERVER_API_URL + '/api/rules/permissions';
  private resourceDropdownUrl = SERVER_API_URL + '/api/tb-roles-dropdown-list';
  constructor(private http: HttpClient) {}

  query(req?: any): Observable<HttpResponse<IVaiTro[]>> {
    const options = createRequestOption(req);
    return this.http.get<IVaiTro[]>(SERVER_API_URL + 'api/vai-tros', {
      params: options,
      observe: 'response',
    });
  }

  find(roleId: number): Observable<HttpResponse<IVaiTro>> {
    return this.http
      .get<IVaiTro>(`${this.resourceUrl}/${roleId}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<IVaiTro>) => res));
  }

  search(req?: any): Observable<HttpResponse<IVaiTro[]>> {
    const options = createRequestOption(req);
    return this.http.get<IVaiTro[]>(SERVER_API_URL + 'api/_search/vai-tros', {
      params: options,
      observe: 'response',
    });
  }

  delete(roleId: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${roleId}`, {
      observe: 'response',
    });
  }

  create(vaiTro: any): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceUrl, vaiTro, {
      observe: 'response',
    });
  }

  update(vaiTro: any): Observable<HttpResponse<any>> {
    return this.http.put(this.resourceUrl, vaiTro, {
      observe: 'response',
    });
  }

  roles(req?: any): Observable<HttpResponse<any>> {
    return this.http.get(SERVER_API_URL + 'api/tb-roles', {
      observe: 'response', params: req,
    });
  }
  rolesDropdown(): Observable<HttpResponse<any>> {
    return this.http.get(this.resourceDropdownUrl, {
      observe: 'response',
    });
  }
  permissionByRole(roleId: number): Observable<HttpResponse<any>> {
    return this.http.get<IPermission[]>(
      `${this.resourcePermissionUrl}/${roleId} `,
      {
        observe: 'response',
      },
    );
  }
  updatePermissionByRole(roles: any): Observable<HttpResponse<any>> {
    return this.http.put(SERVER_API_URL + 'api/update-list-rules', roles, {
      observe: 'response',
    });
  }
}
