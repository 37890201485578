import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {Title} from '@angular/platform-browser';
import {GiongService} from '../giong.service';

@Component({
  selector: 'app-giong-import',
  templateUrl: './giong-import.component.html',
  styleUrls: ['./giong-import.component.scss'],
})
export class GiongImportComponent implements OnInit {
  fileToUpload: File;
  error: string;
  isSaving: boolean;
  constructor(
    private dialogRef: MatDialogRef<GiongImportComponent>,
    private giongService: GiongService,
    private eventManager: JhiEventManager,
    private titleService: Title,
  ) {
    this.isSaving = true;
    this.fileToUpload = null;
    this.error = '';
  }

  ngOnInit() {
    this.titleService.setTitle('Import giống');
  }

  handleFileInput(files: FileList) {
    if (files.length > 0) {
      this.isSaving = false;
      this.fileToUpload = files.item(0);
    } else {
      this.isSaving = true;
    }
  }

  checkValidFileImport() {
    const extension = this.fileToUpload.name.split('.').pop();
    if (extension === 'csv' || extension === 'xlsx') {
      return true;
    } else {
      return false;
    }
  }
  uploadFileToActivity() {
    if (this.checkValidFileImport() === true) {
      this.giongService.import(this.fileToUpload).subscribe(
        (data) => {
          this.onSaveSuccess(data);
        },
        (error) => {
          this.onSaveError();
        },
      );
    } else {
      this.isSaving = true;
      this.error = 'Định dạng file không đúng!';
    }
  }

  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'giongListModification',
      content: 'Giong',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-giong-popup',
  template: '',
})
export class GiongImportPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<GiongImportComponent>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.activatedRouteVatTu();
  }

  ngOnDestroy() {
    this.dialogRef = null;
    this.titleService.setTitle('Danh sách giống');
  }

  activatedRouteVatTu() {
    setTimeout(() => {
      this.dialogRef = this.dialog.open(GiongImportComponent, {
        disableClose: true,
        width: '380px',
      });
      this.dialogRef.afterClosed().subscribe(
        (result) => {
          this.router.navigate([{outlets: {popup: null}}], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        },
        (reason) => {
          this.router.navigate([{outlets: {popup: null}}], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        },
      );
    }, 0);
  }
}
