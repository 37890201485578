import {InWareHouse} from './../vat-tu/vat-tu.model';
import {Moment} from 'moment';

export interface IGiong {
  id?: number;
  name?: string;
  ingredient?: string;
  use?: string;
  sku?: string;
  description?: string;
  manufacturer?: string;
  quarantinePeriod?: number;
  dosage?: any;
  packaging?: string;
  jhiSize?: number;
  unit?: string;
  createdDate?: Moment;
  modifiedDate?: Moment;
  deletedDate?: Moment;
  tbSuppliesTypeId?: number;
  tbSuppliesTypeName?: string;
  createdById?: number;
  modifiedById?: any;
  deletedById?: any;
  tbCustomerId?: any;
  inventory?: any;
  isSelected?: boolean;
}

export class Giong implements IGiong {
  constructor(
    public id?: number,
    public name?: string,
    public ingredient?: string,
    public use?: string,
    public sku?: string,
    public description?: string,
    public manufacturer?: string,
    public quarantinePeriod?: number,
    public dosage?: any,
    public packaging?: string,
    public jhiSize?: number,
    public unit?: string,
    public createdDate?: Moment,
    public modifiedDate?: Moment,
    public deletedDate?: Moment,
    public tbSuppliesTypeId?: number,
    public tbSuppliesTypeName?: string,
    public createdById?: number,
    public modifiedById?: any,
    public deletedById?: any,
    public tbCustomerId?: any,
    public inventory?: any,
    public isSelected?: boolean,
  ) {
    this.id = id ? id : null;
    this.name = name ? name : null;
    this.ingredient = ingredient ? ingredient : null;
    this.use = use ? use : null;
    this.sku = sku ? sku : null;
    this.description = description ? description : null;
    this.manufacturer = manufacturer ? manufacturer : null;
    this.quarantinePeriod = quarantinePeriod ? quarantinePeriod : null;
    this.dosage = dosage ? dosage : null;
    this.packaging = packaging ? packaging : null;
    this.jhiSize = jhiSize ? jhiSize : null;
    this.unit = unit ? unit : null;
    this.createdDate = createdDate ? createdDate : null;
    this.modifiedDate = modifiedDate ? modifiedDate : null;
    this.deletedDate = deletedDate ? deletedDate : null;
    this.tbSuppliesTypeId = tbSuppliesTypeId ? tbSuppliesTypeId : null;
    this.tbSuppliesTypeName = tbSuppliesTypeName ? tbSuppliesTypeName : null;
    this.createdById = createdById ? createdById : null;
    this.modifiedById = modifiedById ? modifiedById : null;
    this.deletedById = deletedById ? deletedById : null;
    this.tbCustomerId = tbCustomerId ? tbCustomerId : null;
    this.inventory = inventory ? inventory : null;
    this.isSelected = isSelected ? isSelected : false;
  }
}

export interface IGiongDetail {
  tbSuppliesDTO?: IGiong;
  tbSuppliesInWarehouseDTOS?: any[];
}
export class GiongDetail implements IGiongDetail {
  constructor(
    public tbSuppliesDTO?: IGiong,
    public tbSuppliesInWarehouseDTOS?: any[] | InWareHouse[],
  ) {
    this.tbSuppliesDTO = tbSuppliesDTO ? tbSuppliesDTO : new Giong();
    this.tbSuppliesInWarehouseDTOS = tbSuppliesInWarehouseDTOS
      ? tbSuppliesInWarehouseDTOS
      : [];
  }
}

export interface IHistoryGiong {
  id?: number;
  updateDate?: Moment;
  action?: number;
  quantity?: number;
  description?: string;
  tbSuppliesOrderId?: any;
  tbSuppliesInWarehouseId?: number;
  tbActivityId?: any;
  tbWarehouseName?: string;
  tbCustomerId?: any;
  tbBlockDetailsId?: number;
  tbBlockDetailsCode?: string;
}

export class HistoryGiong implements IHistoryGiong {
  constructor(
    public id?: number,
    public updateDate?: Moment,
    public action?: number,
    public quantity?: number,
    public description?: string,
    public tbSuppliesOrderId?: any,
    public tbSuppliesInWarehouseId?: number,
    public tbActivityId?: any,
    public tbWarehouseName?: string,
    public tbCustomerId?: any,
    public tbBlockDetailsId?: number,
    public tbBlockDetailsCode?: string,
  ) {
    this.id = id ? id : null;
    this.updateDate = updateDate ? updateDate : null;
    this.action = action ? action : null;
    this.quantity = quantity ? quantity : null;
    this.description = description ? description : null;
    this.tbSuppliesOrderId = tbSuppliesOrderId ? tbSuppliesOrderId : null;
    this.tbSuppliesInWarehouseId = tbSuppliesInWarehouseId
      ? tbSuppliesInWarehouseId
      : null;
    this.tbActivityId = tbActivityId ? tbActivityId : null;
    this.tbWarehouseName = tbWarehouseName ? tbWarehouseName : null;
    this.tbCustomerId = tbCustomerId ? tbCustomerId : null;
    this.tbBlockDetailsId = tbBlockDetailsId ? tbBlockDetailsId : null;
    this.tbBlockDetailsCode = tbBlockDetailsCode ? tbBlockDetailsCode : null;
  }
}
