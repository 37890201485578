import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from 'src/app/app.constants';
import {Observable} from 'rxjs';
import {Giong, IGiong, IGiongDetail, IHistoryGiong} from '../../entities/giong/giong.model';
import {map} from 'rxjs/operators';
import {createRequestOption} from 'src/app/shared/util/request-util';
import {DATE_FORMAT} from 'src/app/shared/constants/input.constants';

@Injectable({
  providedIn: 'root',
})
export class GiongService {
  dateFormat: string = DATE_FORMAT;
  private resourceUrl = SERVER_API_URL + 'api/tb-supplies/seedling';
  private resourceUrlDropdown = SERVER_API_URL + 'api/tb-supplies/seedling-dropdown-list';
  private resourceUrInfo = SERVER_API_URL + 'api/tb-supplies/by-login-info';
  private resourceDetail = SERVER_API_URL + 'api/seed-detail';
  private resourceDeleteHistory = SERVER_API_URL + 'api/tb-stock-updates';
  constructor(private http: HttpClient) {}

  query(req?: any): Observable<HttpResponse<Giong[]>> {
    const options = createRequestOption(req);
    return this.http.get<Giong[]>(this.resourceUrl, {
      params: options,
      observe: 'response',
    });
  }

  queryInfo(req?: any): Observable<HttpResponse<Giong[]>> {
    const options = createRequestOption(req);
    return this.http.get<Giong[]>(this.resourceUrInfo, {
      params: options,
      observe: 'response',
    });
  }
  queryDropdownList(req?: any): Observable<HttpResponse<Giong[]>> {
    const options = createRequestOption(req);
    return this.http.get<Giong[]>(this.resourceUrInfo, {
      params: options,
      observe: 'response',
    });
  }
  count(): Observable<HttpResponse<any>> {
    return this.http.get<any>(SERVER_API_URL + 'api/giongs/count', {
      observe: 'response',
    });
  }

  find(giongId: number): Observable<HttpResponse<IGiongDetail>> {
    return this.http
      .get<IGiongDetail>(`${this.resourceDetail}/${giongId}`, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<IGiongDetail>) => res));
  }

  search(req?: any): Observable<HttpResponse<Giong[]>> {
    const options = createRequestOption(req);
    return this.http.get<Giong[]>(
      SERVER_API_URL + 'api/tb-supplies/seedling',
      {
        params: options,
        observe: 'response',
      },
    );
  }

  delete(giongId: number): Observable<HttpResponse<any>> {
    return this.http.delete(SERVER_API_URL + `/api/tb-supplies/${giongId}`, {
      observe: 'response',
    });
  }
  create(giong: any): Observable<HttpResponse<any>> {
    return this.http.post(SERVER_API_URL + 'api/tb-supplies', giong, {
      observe: 'response',
    });
  }

  update(giong: any): Observable<HttpResponse<any>> {
    return this.http.put(SERVER_API_URL + 'api/tb-supplies', giong, {
      observe: 'response',
    });
  }

  import(fileToUpload: File): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();
    formData.append('xlsxCsvFile', fileToUpload, fileToUpload.name);
    return this.http.post(SERVER_API_URL + 'api/giongs/import', formData, {
      observe: 'response',
    });
  }

  vatTuHistory(
    giongId: number,
    req?: any,
  ): Observable<HttpResponse<IHistoryGiong[]>> {
    const options = createRequestOption(req);
    return this.http.get<IHistoryGiong[]>(
      SERVER_API_URL + `api/historySupplies/${giongId}`,
      {
        params: options,
        observe: 'response',
      },
    );
  }

  listWarehouse(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(SERVER_API_URL + 'api/tb-warehouses', {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res.body));
  }

  deleteHistory(giongId: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceDeleteHistory}/${giongId}`, {
      observe: 'response',
    });
  }

  listUnit(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(SERVER_API_URL + 'api/unit/all', {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res.body));
  }
}
