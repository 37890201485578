import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-qr-code-dialog-export-pdf',
  templateUrl: './qr-code-dialog-export-pdf.component.html',
  styleUrls: ['./qr-code-dialog-export-pdf.component.scss'],
})
export class QrCodeDialogExportPdfComponent implements OnInit {
  isSaving: boolean;
  exportpdf = {
    pagesize: '',
    patterncode: '',
  };
  constructor(private dialogRef: MatDialogRef<QrCodeDialogExportPdfComponent>) {
    this.isSaving = false;
  }

  ngOnInit() {}

  save() {
    this.isSaving = true;
    this.dialogRef.close(true);
  }
}

@Component({
  selector: 'app-qr-code-pdf',
  template: '',
})
export class QrCodePopupExportPdfComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<QrCodeDialogExportPdfComponent>;
  constructor(private router: Router, private dialog: MatDialog) {}

  ngOnInit() {
    setTimeout(() => {
      this.dialogRef = this.dialog.open(QrCodeDialogExportPdfComponent, {
        disableClose: true,
        width: '380px',
      });

      this.dialogRef.afterClosed().subscribe(
        (result) => {
          this.router.navigate([{outlets: {popup: null}}], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        },
        (reason) => {
          this.router.navigate([{outlets: {popup: null}}], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        },
      );
    }, 0);
  }

  ngOnDestroy() {}
}
