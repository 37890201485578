import {ILoaiLo} from './../loai-lo/loai-lo.model';

export interface IDonViSetting {
    tbCustomerId?: number;
    activityTypeByCropTypeDTOList?: ILoaiLo[];
}

export class DonViSetting implements IDonViSetting {
    tbCustomerId?: number;
    activityTypeByCropTypeDTOList?: ILoaiLo[];
    constructor(
        public fields?: IDonViSetting,
    ) {
        if (typeof fields === 'object') {
            this.tbCustomerId = fields.tbCustomerId ? fields.tbCustomerId : null;
            this.activityTypeByCropTypeDTOList = fields.activityTypeByCropTypeDTOList ? fields.activityTypeByCropTypeDTOList : [];
        }
    }
}
