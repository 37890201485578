import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {SharedModule} from '../shared';

import {accountState} from './account.route';
import {PasswordResetInitComponent} from './password-reset/init/password-reset-init.component';
import {PasswordResetFinishComponent} from './password-reset/finish/password-reset-finish.component';

@NgModule({
  declarations: [PasswordResetInitComponent, PasswordResetFinishComponent],
  imports: [SharedModule, RouterModule.forChild(accountState)],
  entryComponents: [PasswordResetInitComponent, PasswordResetFinishComponent],
})
export class AccountModule {}
