import {KhuVucDeletePopupComponent} from './khu-vuc-delete-dialog/khu-vuc-delete-dialog.component';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {KhuVucService} from './khu-vuc.service';
import {IKhuVuc, KhuVuc} from './khu-vuc.model';
import {Injectable} from '@angular/core';
import {KhuVucPopupComponent} from './khu-vuc-dialog/khu-vuc-dialog.component';
import {UserRouteAccessService} from './../../core/auth/user-route-access-service';
import {Routes, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

@Injectable({providedIn: 'root'})
export class KhuVucResolve implements Resolve<IKhuVuc> {
  constructor(private service: KhuVucService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((khuVuc: HttpResponse<IKhuVuc>) => khuVuc.body));
    }
    return of(new KhuVuc());
  }
}

export const khuVucRoute: Routes = [
      {
        path: 'them-khu-vuc/:donViId',
        component: KhuVucPopupComponent,
        data: {
          pageTitle: 'Tạo khu vực',
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup',
      },
      {
        path: 'sua-khu-vuc/:id',
        component: KhuVucPopupComponent,
        resolve: {
          khuVuc: KhuVucResolve
        },
        data: {
          pageTitle: 'Tạo khu vực',
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup',
      },
      {
        path: 'xoa-khu-vuc/:id',
        component: KhuVucDeletePopupComponent,
        resolve: {
          khuVuc: KhuVucResolve
        },
        data: {
          pageTitle: 'Xóa khu vực',
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup',
      },
];
