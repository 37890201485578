import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared';
import {RouterModule, Routes} from '@angular/router';

import {BaoCaoComponent, baoCaoRoute} from './';
import {ChartsModule} from 'ng2-charts';

const ENTITY_STATES = [...baoCaoRoute];

@NgModule({
  declarations: [BaoCaoComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
    ChartsModule,
  ],
})
export class BaoCaoModule {}
