import {HttpParams} from '@angular/common/http';
import { query } from '@angular/core/src/render3';

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (req) {
    Object.keys(req).forEach((key) => {
      if (key !== 'sort' && key !== 'param' ) {
        options = options.set(key, req[key]);
      }
    });
    if (req.sort) {
      req.sort.forEach((val) => {
        options = options.append('sort', val);
      });
    }
    if (req.param) {
      Object.keys(req.param).forEach((q) => {
        options = options.set(`${q}.equals`, req.param[q]);
      });
    }
  }
  return options;
};
