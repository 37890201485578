import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IActivityObject, ActivityObject } from '../activity.model';
import { ActivityService } from '../activity.service';
import { JhiEventManager } from 'ng-jhipster';
import { Title } from '@angular/platform-browser';
import {DOMAIN_IMAGE_URL} from '../../../shared/constants/domain';

@Component({
  selector: 'app-activity-type-object-dialog',
  templateUrl: './activity-type-object-dialog.component.html',
  styleUrls: ['./activity-type-object-dialog.component.scss']
})
export class ActivityTypeObjectDialogComponent implements OnInit {
  activityObject: IActivityObject;
  isSaving: boolean;
  controlType: any;
  filterData: any;
  tableOption: any;
  selectOption: any;
  constructor(
    private dialogRef: MatDialogRef<ActivityTypeObjectDialogComponent>,
    private activityService: ActivityService,
    private eventManager: JhiEventManager,
    private titleService: Title,

  ) {
    this.activityObject = new ActivityObject;
    this.isSaving = false;
    this.controlType = [];
    this.filterData = [];
    this.tableOption = [];
    this.selectOption = [];
  }

  ngOnInit() {
    this.getControlType();
    if (this.activityObject.tbObjectParameterDTO.id !== null) {
      this.titleService.setTitle('Sửa object');
      this.getExtendTableOption(0, true);
    } else {
      this.titleService.setTitle('Thêm object');
      this.getExtendTableOption(0, false);
    }
  }

  save() {
    this.isSaving = true;
    if (this.activityObject.tbObjectParameterDTO.id !== null) {
      this.activityService.updateActivityObject(this.activityObject).subscribe(response => this.onSaveSuccess(response), () => this.onSaveError());
    } else {
      this.activityService.createActivityObject(this.activityObject).subscribe(response => this.onSaveSuccess(response), () => this.onSaveError());
    }
  }
  selectExternal(value) {
    this.selectOption = this.tableOption.filter((item) => item.name === value);
    this.filterData = [];
    this.activityObject.tbActivityExtendTypeDTO.condition = '';
    if (this.selectOption.length > 0 && this.selectOption[0].relationTable !== null) {
      this.getFilter(this.selectOption[0].relationTable);
    }
  }
  getFilter(value) {
    this.activityService.getAllDataTableCommon({tableName: value}).subscribe((response) => {
      this.filterData = response.body;
    });
  }
  getExtendTableOption(type, isUpdate) {
    this.activityService.getExtendTableOption(type).subscribe((response) => {
      this.tableOption = response.body;
      if (isUpdate) {
        this.selectExternal(this.activityObject.tbActivityExtendTypeDTO.externalTable);
      }
    });
  }
  getControlType() {
    this.activityService.getControlTypeOption('object').subscribe((response) => {
      this.controlType = response.body;
    });
  }
  private onSaveSuccess(result: any) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'activityObjectListModification',
      content: 'activityType'
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }

}

@Component({
  selector: 'app-activity-object-popup',
  template: ''
})
export class ActivityTypeObjectPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ActivityTypeObjectDialogComponent>;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ activityId, activityObject }) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(ActivityTypeObjectDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        if (activityObject !== undefined) {
          this.dialogRef.componentInstance.activityObject = activityObject;
          this.dialogRef.componentInstance.activityObject.tbObjectParameterDTO.activityTypeId = activityId;
        }

        this.dialogRef.afterClosed().subscribe(
          result => {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
          },
          reason => {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
          }
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách activity');
    this.dialogRef = null;
  }
}
