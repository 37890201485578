import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../shared';

import {
  CanhTacComponent,
  CanhTacDetailComponent,
  CanhTacDialogComponent,
  HanhDongComponent,
  canhTacRoute,
} from './';

import {CanhTacPopupComponent} from './canh-tac-dialog/canh-tac-dialog.component';
import {
  CanhTacMgmtDeletePopupComponent,
  CanhTacMgmtDeleteDialogComponent,
} from './canh-tac-delete-dialog/canh-tac-delete-dialog.component';
import {TimelineComponent} from './timeline/timeline.component';
import {GallerizeModule} from '@ngx-gallery/gallerize';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GalleryModule} from '@ngx-gallery/core';
import {
  CanhTacExportfileDialogComponent,
  CanhTacExportFilePopupComponent,
} from './canh-tac-exportfile-dialog/canh-tac-exportfile-dialog.component';
import {
  CanhTacManagerDialogComponent,
  CanhtacManagerPopupComponent,
} from './canh-tac-manager-dialog/canh-tac-manager-dialog/canh-tac-manager-dialog.component';
import {
  CanhTacMgmtDeletePersonDialogComponent,
  CanhTacMgmtDeletePersonPopupComponent,
} from './canh-tac-manager-dialog/canh-tac-delete-person-dialog/canh-tac-delete-person-dialog.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {QRCodeModule} from "angularx-qrcode";
import {HanhDongGuideLineComponent} from './hanh-dong-guide-line/hanh-dong-guide-line.component';
import { HoatDongCanhTacComponent } from './hoat-dong-canh-tac/hoat-dong-canh-tac.component';

const ENTITY_STATES = [...canhTacRoute];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
    GallerizeModule,
    LightboxModule,
    GalleryModule,
    QRCodeModule,
    MatSlideToggleModule,
  ],
  declarations: [
    CanhTacComponent,
    CanhTacDetailComponent,
    CanhTacDialogComponent,
    CanhTacPopupComponent,
    CanhTacMgmtDeletePopupComponent,
    CanhTacMgmtDeleteDialogComponent,
    TimelineComponent,
    HanhDongComponent,
    CanhTacExportfileDialogComponent,
    CanhTacExportFilePopupComponent,
    CanhTacManagerDialogComponent,
    CanhtacManagerPopupComponent,
    CanhTacMgmtDeletePersonDialogComponent,
    CanhTacMgmtDeletePersonPopupComponent,
    HanhDongGuideLineComponent,
    HoatDongCanhTacComponent
  ],
  entryComponents: [
    CanhTacDialogComponent,
    CanhTacPopupComponent,
    CanhTacMgmtDeletePopupComponent,
    CanhTacMgmtDeleteDialogComponent,
    TimelineComponent,
    HanhDongComponent,
    CanhTacExportfileDialogComponent,
    CanhTacExportFilePopupComponent,
    CanhTacManagerDialogComponent,
    CanhtacManagerPopupComponent,
    CanhTacMgmtDeletePersonDialogComponent,
    CanhTacMgmtDeletePersonPopupComponent,
  ],
})
export class CanhTacModule {}
