import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'src/app/shared';
import {NortificationComponent} from './nortification.component';
import {RouterModule, Routes} from '@angular/router';
import {Nortification} from './nortification.route';

@NgModule({
  declarations: [NortificationComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(Nortification as Routes),
  ],
})
export class NortificationModule {}
