import {Component, OnInit, ViewChild} from '@angular/core';
import {JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs';
import {NotifierService} from 'angular-notifier';
import {error_code_prefix} from '../constants/messages.constants';

@Component({
  selector: 'app-alert-notifier',
  template: ` <notifier-container></notifier-container>
    <ng-template #notifier let-notification="notification">
      <p class="notifier__notification-message">
        <i
          *ngIf="notification.type == 'success'"
          class="far fa-check-circle"
        ></i>
        <i *ngIf="notification.type == 'error'" class="fas fa-ban"></i>
        &nbsp;<span [innerHTML]="notification.message"></span>
      </p>
      <button
        class="notifier__notification-button"
        title="dismiss"
        type="button"
      >
        <svg
          class="notifier__notification-button-icon"
          height="20"
          viewBox="0 0 24 24"
          width="20"
        >
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </button>
    </ng-template>`,
  styleUrls: ['./alert.component.scss'],
})
export class AlertNotifierComponent implements OnInit {
  httpListener: Subscription;
  httpErrorListener: Subscription;
  @ViewChild('notifier') notifierTemplate;
  constructor(
    private eventManager: JhiEventManager,
    private notifierService: NotifierService,
  ) {}
  ngOnInit() {
    this.httpListener = this.eventManager.subscribe(
      'pmwebApp.app-notifier',
      (response) => {
        const content = response.content;
        const message = content.message;

        this.notifierService.show({
          message: message,
          type: content.type,
          template: this.notifierTemplate,
        });
      },
    );

    this.httpErrorListener = this.eventManager.subscribe(
      'pmwebApp.httpError',
      (response) => {
        const httpErrorResponse = response.content;
        switch (httpErrorResponse.status) {
          // connection refused, server not reachable
          case 0:
            this.addErrorAlert(
              'Không truy cập được máy chủ',
              'error.server.not.reachable',
            );
            break;
          case 400:
            this.addErrorAlert400(httpErrorResponse);
            break;
          case 404:
            this.addErrorAlert('Không tìm thấy dữ liệu', 'error.url.not.found');
            break;
          case 401:
            this.addErrorAlert(
              '<strong>Đăng nhập không thành công!</strong> Vui lòng kiểm tra thông tin đăng nhập của bạn và thử lại.',
            );
            break;
          default:
            if (
              httpErrorResponse.error !== undefined &&
              httpErrorResponse.error !== null
            ) {
              if (
                httpErrorResponse.error !== '' &&
                httpErrorResponse.error.message
              ) {
                this.addErrorAlert(httpErrorResponse.error.message);
              } else {
                this.addErrorAlert(httpErrorResponse.error);
              }
            }
            break;
        }
      },
    );
  }
  addErrorAlert(message, key?, data?) {
    this.notifierService.show({
      message: message,
      type: 'error',
      template: this.notifierTemplate,
    });
  }
  convertErrorMessage(message) {
    if (error_code_prefix.hasOwnProperty(message)) {
      return error_code_prefix[message];
    }
    return message;
  }
  addErrorAlert400(httpErrorResponse) {
    const arr = httpErrorResponse.headers.keys();
    let errorHeader = null;
    let entityKey = null;
    arr.forEach((entry) => {
      if (entry.toLowerCase().endsWith('app-error')) {
        errorHeader = httpErrorResponse.headers.get(entry);
      } else if (entry.toLowerCase().endsWith('app-params')) {
        entityKey = httpErrorResponse.headers.get(entry);
      }
    });
    if (errorHeader) {
      const entityName = entityKey;
      this.addErrorAlert(this.convertErrorMessage(errorHeader), errorHeader, {entityName});
    } else if (
      httpErrorResponse.error !== '' &&
      httpErrorResponse.error.fieldErrors
    ) {
      const fieldErrors = httpErrorResponse.error.fieldErrors;
      for (let i = 0; i < fieldErrors.length; i++) {
        const fieldError = fieldErrors[i];
        if (
          ['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(
            fieldError.message,
          )
        ) {
          fieldError.message = 'Size';
        }
        // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
        const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
        const fieldName =
          convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
        this.addErrorAlert(
          'Lỗi trên trường "' + fieldName + '"',
          'error.' + fieldError.message,
          {fieldName},
        );
      }
    } else if (
      httpErrorResponse.error !== '' &&
      httpErrorResponse.error.violations
    ) {
      this.addValidationErrorAlert(httpErrorResponse);
    } else if (
      httpErrorResponse.error !== '' &&
      httpErrorResponse.error.message
    ) {
      this.addErrorAlert(
        httpErrorResponse.error.message,
        httpErrorResponse.error.message,
        httpErrorResponse.error.params,
      );
    } else {
      this.addErrorAlert(httpErrorResponse.error.title);
    }
  }

  addValidationErrorAlert(httpErrorResponse) {
    const message = httpErrorResponse.error.message;
    // Violation response
    const data = httpErrorResponse.error.violations;
    switch (message) {
      case 'error.validation':
        let errors = '<ol>';
        for (const item of data) {
          errors += `<li>${item.field} ${item.message}</li>`;
        }
        errors += '</ol>';
        this.addErrorAlert(errors);
        break;
    }
  }
}
