
import {DashboardLayoutComponent} from './dashboard-layout/dashboard-layout.component';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Route,
  RouterStateSnapshot,
} from '@angular/router';

import {AuthGuardServiceHome} from '../core/auth/auth-guard-service-home';
import {UserRouteAccessService} from '../core/auth/user-route-access-service';
import {DialogUpdateUnitPopupComponent} from './dialog-update-unit/dialog-update-unit.component';
import {Injectable} from '@angular/core';
import {IDonViUser} from '../entities/don-vi/don-vi.model';
import {HomeService} from './home.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DetailUnitResolve implements Resolve<IDonViUser> {
  constructor(private service: HomeService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return this.service.DetailDonVi().pipe(map((donvi) => donvi.body));
  }
}

export const HOME_ROUTE: Route = {
  path: 'dashboard',
  component: DashboardLayoutComponent,
  data: {
    pageTitle: 'Smart Farm Dashboard',
  },
  resolve: {
  },
  canActivate: [AuthGuardServiceHome, UserRouteAccessService],
};

export const UPDATE_ROUTE: Route = {
  path: 'sua-don-vi',
  component: DialogUpdateUnitPopupComponent,
  resolve: {
    unit: DetailUnitResolve,
  },
  data: {
    authorities: ['can_update_tbcustomers'],
    pageTitle: '',
  },
  canActivate: [UserRouteAccessService],
  outlet: 'popup',
};
