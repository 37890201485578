import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ILoaiHoatDong} from '../../loai-hoat-dong/loai-hoat-dong.model';
import {CanhTacService} from '../../canh-tac/canh-tac.service';
import {HttpResponse} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {IWaterType} from '../../../shared/model/water-type.model';
import {IUser, UserService} from 'src/app/core';
import {Title} from '@angular/platform-browser';
import * as moment from 'moment';
import {TieuChuanService} from '../../tieu-chuan';
import {map} from 'rxjs/operators';
import {IGuidelineDetail} from '../../tieu-chuan/tieu-chuan.model';

@Component({
    selector: 'app-hanh-dong-guide-line',
    templateUrl: './hanh-dong-guide-line.component.html',
    styleUrls: ['./hanh-dong-guide-line.component.scss'],
})
export class HanhDongGuideLineComponent implements OnInit {
    objectData: any;
    action: any;
    loId: any;
    waterType$: IWaterType[];
    user: IUser;
    currentAction: ILoaiHoatDong;
    titlePage: string;
    public moment: any = moment;
    activityTypeName = '';
    constructor(
        private activatedRoute: ActivatedRoute,

        private localStorage: LocalStorageService,
        private service: TieuChuanService
    ) {
    }

    ngOnInit() {
        const code = this.activatedRoute.snapshot.paramMap.get('code');
        const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
        this.loId = this.activatedRoute.snapshot.paramMap.get('lo');
        this.service
            .getActivityGuidelineDetail(code, id).subscribe(
            (res: HttpResponse<IGuidelineDetail>) => {
                const objectData: any  = res.body;
                this.activityTypeName = code;
                this.titlePage = objectData.tbGuidelineActivityDTO.activityTypeDescription;
                this.service.getDisplayObjectParamDynamicForm(objectData.tbGuidelineActivityDTO.tbActivityTypeId).subscribe(
                    (res1: HttpResponse<any>) => {
                        // this.objectData = res.body;
                        this.mappingDataObject(objectData, res1.body.objectParameterDTOList);
                    },
                    (res1: HttpResponse<any>) => {
                        this.objectData = null;
                    }
                );
            },
            (res: HttpResponse<any>) => {
                this.objectData = null;
            }
        );
    }

    mappingDataObject(data: any, param: any): void {
        const tbObjectUpdateDTOList = data.tbGDObjectUpdateDTOList.map((item: any) => {
            const obj = param.find((itm) => itm.id === item.tbObjectParameterId);
            return {
                ...item,
                tbObjectParameterDTO: obj
            };
        });
        this.objectData = {
            tbObjectUpdateDTOList: tbObjectUpdateDTOList,
            tbSuppliesUsingDetailsDTOs: data.tbSuppliesUsingDetailsDTOs
        };
        console.log(this.objectData);
    }
}
