import {Component, AfterViewInit, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';

import {LoginService} from '../../core/login/login.service';
import {StateStorageService} from '../../core/auth/state-storage.service';
import {AuthService} from '../../core/auth/auth.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit {
  data = {
    password: '',
    username: '',
  };
  credentials: any;
  hide = true;
  constructor(
    private eventManager: JhiEventManager,
    private loginService: LoginService,
    private stateStorageService: StateStorageService,
    private router: Router,
    private titleService: Title,
  ) {
    this.credentials = {};
  }

  ngAfterViewInit() {
    this.titleService.setTitle('Đăng nhập');
  }

  login() {
    this.loginService
      .login({
        username: this.data.username,
        password: this.data.password,
        rememberMe: true,
      })
      .then(() => {
        const url = '/dashboard';
        if (url) {
          this.stateStorageService.storeUrl(null);
        }
        this.router.navigateByUrl(url, {
          replaceUrl: true,
        });

        this.eventManager.broadcast({
          name: 'authenticationSuccess',
          content: 'Sending Authentication Success',
        });
      })
      .catch(() => {});
  }
}
