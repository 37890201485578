import {Injectable} from '@angular/core';

import {Principal} from '../auth/principal.service';
import {AuthServerProvider} from '../auth/auth-jwt.service';
import {TrackerService} from '../tracker/tracker.service';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(
    private principal: Principal,
    private trackerService: TrackerService,
    private authServerProvider: AuthServerProvider,
    private localStorage: LocalStorageService,
  ) {}

  login(credentials, callback?): Promise<any> {
    const cb = callback || function () {};

    return new Promise((resolve, reject) => {
      this.authServerProvider.login(credentials).subscribe(
        (data) => {
          this.principal.identity(true).then((account) => {
            // this.trackerService.sendActivity();
            resolve(data);
          });
          return cb();
        },
        (err) => {
          this.logout();
          reject(err);
          return cb(err);
        },
      );
    });
  }

  loginWithToken(jwt: any, rememberMe: any) {
    return this.authServerProvider.loginWithToken(jwt, rememberMe);
  }

  logout() {
    this.authServerProvider.logout().subscribe();
    this.principal.authenticate(null);
    this.localStorage.clear('lo.loId');
    this.localStorage.clear('account.roleId');
    this.localStorage.clear('rules');
  }
}
