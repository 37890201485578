import {FilterOperator} from 'src/app/shared';
import {
  CriteriaSearch,
  Search,
  FilterField,
  IFilterField,
} from '../../shared';


export class EntityLocationSearch extends CriteriaSearch {
  protected req = new Search();
  constructor(
    public query?: string,
    public entityId?: FilterField,
  ) {
    super();
    if (!query) {
      this.query = query ? query : '';
    }
    if (!entityId) {
      this.entityId = new FilterField('tbEntityId', FilterOperator.equals, null);
    }
  }

  valid(): boolean {
    return true;
  }

  addQueryParams(options?: any): void {
    this.setQueryParam('q', this.query || '');
  }

  setFilterForm(controls: any): void {
    if (controls.query) {
    }
  }
}
