import {
    CriteriaSearch,
    Search,
    FilterField,
    IFilterField,
    FilterOperator,
  } from '../../../shared';


  export class VatTuBlockDetailsSearch extends CriteriaSearch {
    protected req = new Search();
    constructor(
        public query?: string,
        public supplyId?: FilterField
    ) {
      super();
      if (!query) {
        this.query = query ? query : '';
      }

      if (!supplyId) {
        this.supplyId = new FilterField('tbSuppliesId', FilterOperator.equals, null);
      }
    }

    valid(): boolean {
      return true;
    }

    addQueryParams(options?: any): void {
        this.setQueryParam('q', this.query || '');
    }

    setFilterForm(controls: any): void {
      if (controls.query) {
      }
    }
  }

