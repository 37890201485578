import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {Principal} from '../../../core';
import {ActivatedRoute} from '@angular/router';
import {ExampleStampService} from '../example-stamp.service';
import {MatTableDataSource} from '@angular/material/table';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from '../../../shared';
import {PageEvent} from '@angular/material/paginator';
import {HttpResponse} from '@angular/common/http';
import {IProduct} from '../../san-pham/san-pham.model';
import {Subscription} from 'rxjs';
import {DialogService} from '../../../shared/confirm-dialog/dialog.service';
import {DOMAIN_IMAGE_URL} from '../../../shared/constants/domain';

@Component({
  selector: 'app-example-stamp',
  templateUrl: './example-stamp.component.html',
  styleUrls: ['./example-stamp.component.scss'],
})
export class ExampleStampComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['id', 'exampleStampName', 'description', 'createdDate', 'exampleStampImage', 'action'];
  dataSource: any;
  pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;
  ELEMENT_DATA: any[] = [];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage = ITEMS_PER_PAGE;
  page = 1;
  currentSearch = '';
  eventSubscriber: Subscription;
  noData: boolean;
  DOMAIN_IMAGE_URL = DOMAIN_IMAGE_URL;
  constructor(
    private titleService: Title,
    private alertService: JhiAlertService,
    private principal: Principal,
    private parseLinks: JhiParseLinks,
    private eventManager: JhiEventManager,
    private activatedRoute: ActivatedRoute,
    private stampService$: ExampleStampService,
    private confirmService: DialogService,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Danh sách mẫu tem');
    this.loadAll();
    this.eventSubscriber = this.eventManager.subscribe(
        'stampListModification',
        () => this.loadAll(),
    );
  }

  ngOnDestroy() {
    if (this.eventSubscriber) {
      this.eventManager.destroy(this.eventSubscriber);
    }
  }
  search(query: string) {
    this.page = 1;
    this.currentSearch = query;
    this.loadAll();
  }
  loadAll() {
    this.stampService$
        .query({
          page: this.page - 1,
          size: this.itemsPerPage,
          sort: ['createdDate,desc']
        }).subscribe(
        (res: HttpResponse<IProduct[]>) => {
          this.totalItems = res.headers.get('X-Total-Count');
          this.queryCount = this.totalItems;
          this.ELEMENT_DATA = res.body;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.noData = res.body.length === 0;
        },
        (res: HttpResponse<any>) => {
          this.alertService.error(res.body.error, res.body.message, null);
        },
    );
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }
}
