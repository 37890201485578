import {DonViRoleSearch} from './don-vi-role.search';
import { HttpResponse } from '@angular/common/http';
import {Component, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JhiEventManager } from 'ng-jhipster';
import { Subscription } from 'rxjs';
import { IKhuVuc } from '../../khu-vuc';
import { IVaiTro, VaiTro } from '../../vai-tro/vai-tro.model';
import { VaiTroService } from '../../vai-tro/vai-tro.service';

@Component({
  selector: 'app-don-vi-role',
  templateUrl: './don-vi-role.component.html',
  styleUrls: ['./don-vi-role.component.scss']
})
export class DonViRoleComponent implements OnInit, OnDestroy {
  dataSource: IVaiTro[];
  eventSubscriber: Subscription;
  @Input() selected: IVaiTro;
  @Output() selectedEvent = new EventEmitter();
  donViId: any;
  searchService = new DonViRoleSearch();
  constructor(
    private readonly route: ActivatedRoute,
    private eventManager: JhiEventManager,
    private service: VaiTroService,
  ) {
    this.selected = new VaiTro();
  }

  ngOnInit() {
    this.eventSubscriber = this.eventManager.subscribe(
      'donViRoleModification',
      () => this.search(),
    );
    this.donViId = this.route.snapshot.paramMap.get('donVi');
    if (this.donViId) {
      this.searchService.tbCustomerId.filterValue = this.donViId;
      this.search();
    }
  }

  ngOnDestroy() {
    if (this.eventSubscriber) {
      this.eventManager.destroy(this.eventSubscriber);
    }
  }

  search(newQueryParams?: any): void {
    this.getList(newQueryParams);
  }

  getList(params?: any): void {
    const filterParams = this.searchService.getFilterParams();
    this.service.roles(filterParams)
      .subscribe((res: HttpResponse<IVaiTro[]>) => {
        if (res) {
          this.dataSource = res.body;
        }
      },
        (res: HttpResponse<IKhuVuc>) => console.log(res),
      );
  }

  selectRole(event, item: IVaiTro) {
    const ctNode = event.currentTarget.nodeName;
    const tNode = event.target.nodeName;
    if (ctNode === 'MAT-LIST-ITEM' && tNode !== 'MAT-ICON') {
      this.selected = {...item};
      this.selectedEvent.emit(item);
    }
  }
}
