import {Route} from '@angular/router';

import {NotificationPopupComponent} from './notification.component';
import {CanhTacControlEnvironmentPopupComponent} from './canh-tac-control-environment-dialog/canh-tac-control-environment-dialog.component';
import {UserRouteAccessService} from "../../core";
import {ControlEnvironmentResolve, DevicesInEntityResolve} from "../../entities/canh-tac";

export const notificationRoute: Route = {
  path: 'notification',
  component: NotificationPopupComponent,
  outlet: 'popup',
};
export const controlEnvRoute: Route = {
  path: 'chi-so-va-dieu-khien/:lo/:nhaMang',
  component: CanhTacControlEnvironmentPopupComponent,
  canActivate: [UserRouteAccessService],
  resolve: {
    env: ControlEnvironmentResolve,
    device: DevicesInEntityResolve,
  },
  data: {
    pageTitle: 'Chỉ số và điều khiển',
  },
  outlet: 'popup',
};
