import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {LoaiHoatDongService} from './loai-hoat-dong.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {ILoaiHoatDong} from './loai-hoat-dong.model';

@Injectable({
  providedIn: 'root'
})
export class LoaiHoatDongListResolver implements Resolve<any> {
  constructor(
    private service: LoaiHoatDongService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ILoaiHoatDong[]> | Promise<ILoaiHoatDong[]> | ILoaiHoatDong[] {
    const req = {size: 30};
    return this.service.query(req)
      .pipe(map((res: HttpResponse<ILoaiHoatDong[]>) => res.body));
  }
}
