import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {IInWareHouse, InWareHouse, ISupplyBlockDetails, SupplyBlockDetails, SupplyStockUpdateAction, VatTu} from '../vat-tu.model';
import {VatTuService} from '../vat-tu.service';
import {JhiEventManager} from 'ng-jhipster';
// @ts-ignore
import moment from 'moment';
import { FilterOperator } from 'src/app/shared';
import { map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-warehouse-storage-dialog',
  templateUrl: './warehouse-storage-dialog.component.html',
  styleUrls: ['./warehouse-storage-dialog.component.scss'],
})
export class WarehouseStorageDialogComponent implements OnInit {
  isSaving: boolean;
  vattu: VatTu;
  block: ISupplyBlockDetails;
  listWareHouse$: any;
  inputWarehouse: IInWareHouse;
  maxData: any;
  listwareHouseById$: any;
  listBlock$: ISupplyBlockDetails[];
  selectedBlock: ISupplyBlockDetails;
  actions = SupplyStockUpdateAction;

  constructor(
    private dialogRef: MatDialogRef<WarehouseStorageDialogComponent>,
    private vatTuService: VatTuService,
    private eventManager: JhiEventManager,
  ) {
    this.isSaving = false;
    this.listWareHouse$ = [];
    this.inputWarehouse = new InWareHouse();
    this.block = new SupplyBlockDetails();
    this.maxData = null;
    this.selectedBlock = new SupplyBlockDetails();
  }

  ngOnInit() {}

  loadForm() {
    if (this.block.id) {
      this.inputWarehouse.action = this.actions.EXPORT;
      this.inputWarehouse.tbWarehouseId = this.block.tbWarehouseId || this.block.tbEntityId;
      this.inputWarehouse.code = `${this.vattu.sku}${this.block.code}`;
      this.selectedBlock = this.block;
    } else {
      this.inputWarehouse.code = `${this.vattu.sku}${this.block.code}`;
    }
  }

  isValidForm(ngForm: NgForm) {
    const isValidExpDate = this.inputWarehouse.expDate > this.inputWarehouse.mfgDate;
    if (this.inputWarehouse.action === this.actions.EXPORT) {
      if (this.inputWarehouse.quantity > this.selectedBlock.quantity) {
          return false;
      }
    }
    return ngForm.form.valid && !this.isSaving && isValidExpDate;
  }

  save() {
    this.isSaving = true;
    this.inputWarehouse.tbSuppliesId = this.vattu.id;
    this.inputWarehouse.updateDate =
      moment(this.inputWarehouse.updateDate).format('YYYY-MM-DDTHH:mm:ss') +
      'Z';
    setTimeout(() => {
      this.vatTuService.createSupplyWareHouse(this.inputWarehouse).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }, 500);
  }


  minExpDate() {
    return moment(this.inputWarehouse.mfgDate).add(1, 'day');
  }

  maxMfgDate() {
    return moment(this.inputWarehouse.expDate).subtract(1, 'day');
  }

  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'vattuListModification',
      content: 'Vattu',
    });
    this.eventManager.broadcast({
      name: 'vaiTroBlockDetailsModification',
      content: this.vattu,
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }

  setLimit(max) {
    this.maxData = max;
  }

  changeRadio() {
    // this.inputWarehouse.tbWarehouseId = null;
    // this.inputWarehouse.updateDate = null;
    // this.inputWarehouse.quantity = null;
    // this.inputWarehouse.description = null;
    this.inputWarehouse = new InWareHouse();
    this.maxData = null;
  }

  onSelectWarehouse(event, item: InWareHouse) {
    this.selectedBlock = new SupplyBlockDetails();
    this.inputWarehouse.code = "";
    this.getBlockDetails();
  }

  onSelectBlock(event, item: ISupplyBlockDetails) {
    this.selectedBlock = item;
  }

  getBlockDetails() {
    const req = {};
    req[`tbSuppliesId.${FilterOperator.equals}`] = this.vattu.id || '';
    req[`tbWarehouseId.${FilterOperator.equals}`] = this.inputWarehouse.tbWarehouseId || '';
    this.vatTuService.queryBlockDetails(req).pipe(
      map(res => res.body)
    ).subscribe(res => {
      this.listBlock$ = res;
    });
  }
}

@Component({
  selector: 'app-warehouse-storage-popup',
  template: '',
})
export class WarehouseStoragePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<WarehouseStorageDialogComponent>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private vatTuService: VatTuService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Cập nhật số lượng vật tư lưu kho');
    this.activatedRoute.data.subscribe(({vattu, block}: Data) => {
      setTimeout(() => {
        if (vattu) {
          const promises: Promise<any>[] = [
            this.vatTuService.suppliesInWarehouses().toPromise(),
            this.vatTuService.wareHouseSupplyById(vattu.id).toPromise(),
          ];
          if (block && block.id) {
            const req = {};
            req[`tbSuppliesId.${FilterOperator.equals}`] = vattu.id || '';
            const observable = this.vatTuService.queryBlockDetails(req).pipe(
              map(res => res.body)
            );
            promises.push(observable.toPromise());
          }
          Promise.all(promises).then(values => {
            const [res1, res2, res3] = values;
            this.dialogRef = this.dialog.open(WarehouseStorageDialogComponent, {
              disableClose: true,
              width: '800px',
            });
            this.dialogRef.componentInstance.vattu = vattu;
            this.dialogRef.componentInstance.block = block;
            this.dialogRef.componentInstance.listWareHouse$ = res1;
            this.dialogRef.componentInstance.listwareHouseById$ = res2;
            if (res3) {
              this.dialogRef.componentInstance.listBlock$ = res3;
            }
            this.dialogRef.componentInstance.loadForm();
            this.dialogRef.afterClosed().subscribe(
              (result) => this.previousState(result),
              (reason) => this.previousState(reason),
            );
          }).catch(reason => console.warn(reason));
        }
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.dialogRef = null;
    this.titleService.setTitle('Danh sách vật tư');
  }

  previousState(result?: any): void {
    const params: any = {
      blockDetailsId: null,
    };
    this.router.navigate([{outlets: {popup: null}}], {
      relativeTo: this.activatedRoute.parent,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
