import {Component, OnInit, Input} from '@angular/core';
import {DOMAIN_IMAGE_URL} from '../constants/domain';
import {Lightbox} from '@ngx-gallery/lightbox';
import {
  GalleryItem,
  ImageItem,
  Gallery,
  ImageSize,
  ThumbnailsPosition,
} from '@ngx-gallery/core';

@Component({
  selector: 'app-list-images',
  templateUrl: './list-images.component.html',
  styleUrls: ['./list-images.component.scss'],
})
export class ListImagesComponent implements OnInit {
  @Input() listImages: any;
  @Input() typeClass = 'activity';
  arrayImage: any;
  DOMAIN_IMAGE_URL: string;
  imageData = [];
  items: GalleryItem[];
  configRef = Math.random().toString(36).substring(10);
  constructor(public gallery: Gallery, public lightbox: Lightbox) {
    this.arrayImage = [];
    this.DOMAIN_IMAGE_URL = DOMAIN_IMAGE_URL;
  }

  ngOnInit() {
    this.loadImages();
  }
  loadImages() {
    if (
      this.listImages !== undefined &&
      this.listImages !== null &&
      this.listImages !== ''
    ) {
      const allImages = this.listImages.split(';');
      let count = 0;
      let temp = 0;
      let arrayTemp = [];
      for (let index = 0; index < allImages.length; index++) {
        arrayTemp[temp] = allImages[index];
        temp = temp + 1;
        if (temp > 1) {
          this.arrayImage[count] = arrayTemp;
          count = count + 1;
          temp = 0;
          arrayTemp = [];
        }
        if (index === allImages.length - 1 && temp < 2) {
          this.arrayImage[count] = arrayTemp;
          count = count + 1;
          temp = 0;
          arrayTemp = [];
        }
        const src = this.DOMAIN_IMAGE_URL + allImages[index];
        const thumb = this.DOMAIN_IMAGE_URL + allImages[index];
        const album = {
          srcUrl: src,
          previewUrl: thumb,
        };

        this.imageData.push(album);
      }
      this.items = this.imageData.map(
        (item) => {
          return new ImageItem({src: item.srcUrl, thumb: item.previewUrl});
        }
      );
      const lightboxRef = this.gallery.ref(this.configRef);
      lightboxRef.setConfig({
        imageSize: ImageSize.Contain,
        thumbPosition: ThumbnailsPosition.Bottom,
        loadingMode: 'indeterminate'
      });
      lightboxRef.load(this.items);
    } else {
      this.imageData = [];
    }
  }
}
