import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MatSelect} from '@angular/material';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {Crops} from '../../canh-tac.model';
import {IUser, UserService} from '../../../../core';
import {CanhTacService} from '../../canh-tac.service';
import {JhiEventManager} from 'ng-jhipster';

@Component({
  selector: 'app-canh-tac-manager-dialog',
  templateUrl: './canh-tac-manager-dialog.component.html',
  styleUrls: ['./canh-tac-manager-dialog.component.scss'],
})
export class CanhTacManagerDialogComponent implements OnInit {
  isSaving: boolean;
  lo: Crops;
  users: IUser[];
  selectMutilData = new FormControl();
  selectedValue = [];
  @ViewChild('allSelected') private allSelected: MatSelect;

  constructor(
    private dialogRef: MatDialogRef<CanhTacManagerDialogComponent>,
    private titleService: Title,
    private userService: UserService,
    private canhTacService: CanhTacService,
    private eventManager: JhiEventManager,
  ) {}
  save() {
    const valueUpdate = {
      cropId: this.lo.id,
      detailUserIds: [...this.selectMutilData.value],
    };
    this.canhTacService.updateCuratorUser(valueUpdate).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'loDetailModification',
        content: 'Lo',
      });
      this.dialogRef.close(true);
    });
  }
  ngOnInit() {
    if (this.lo.id !== null && this.lo.tbDetailUsers.length > 0) {
      this.selectedValue = this.lo.tbDetailUsers.map((a) => a.id);
    }
    this.userService.getListDetailUser().subscribe((res: any) => {
      this.users = res.body ? res.body : [];
    });
  }
}

@Component({
  selector: 'app-canh-tac-manager-manager',
  template: '',
})
export class CanhtacManagerPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<CanhTacManagerDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({lo}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(CanhTacManagerDialogComponent, {
          disableClose: true,
          width: '480px',
        });
        this.dialogRef.componentInstance.lo = lo;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách lô');
    this.dialogRef = null;
  }
}
