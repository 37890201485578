import {Component, OnDestroy, OnInit} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {
  IEnvironment,
  ILimit,
  IStandardDate,
  ITieuChuan,
  TieuChuan,
  IStandardDateDetail,
  StandardDateDetail,
  Other, IPeriod,
} from '../tieu-chuan.model';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {GiongService} from '../../giong';
import {IGiong} from '../../giong/giong.model';
import {TieuChuanService} from '../tieu-chuan.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';
import {forkJoin, Subscription} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-tieu-chuan-detail',
  templateUrl: './tieu-chuan-detail.component.html',
  styleUrls: ['./tieu-chuan-detail.component.scss'],
})
export class TieuChuanDetailComponent implements OnInit, OnDestroy {
  standard: ITieuChuan;
  listSeed: IGiong[];
  listDay: IStandardDate[];
  listPeriod: IPeriod[];
  detailEachDay: any;
  listLimit: ILimit[];
  listEnv: IEnvironment[];
  listActivities: any;
  listPeriodDropdown: IPeriod[];

  selectDate: number;

  numberDay: any;
  rowHover: any;
  selectNursery: any;
  eventSubscriber: Subscription;
  eventSubscriberAct: Subscription;
  dateId: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private giongService: GiongService,
    private tieuChuanService: TieuChuanService,
    private dialog: MatDialog,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
  ) {
    this.standard = new TieuChuan();
    this.listSeed = [];
    this.listDay = [];
    this.detailEachDay = null;
    this.listLimit = [];
    this.listEnv = [];

    this.numberDay = null;
    this.rowHover = null;
    this.selectNursery = null;
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({tieuchuan}) => {
      setTimeout(() => {
        if (tieuchuan) {
          this.standard = tieuchuan.guideline;
          this.listDay = tieuchuan.guidelineDetails;
          this.listPeriod = tieuchuan.guidelinePeriods;
          this.giongService.query().subscribe(
            (res: any) => this.onSuccess(res.body, 'listSeed'),
            (res: any) => this.onError(res.body),
          );
          this.changedDetailDate();
          this.changedDetailActivity();
          this.getListPeriodDropdown();
        }
      });
    });
  }
  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
    this.eventManager.destroy(this.eventSubscriberAct);
  }
  loadEnv(envParameter) {
    this.listEnv = [];
    this.tieuChuanService.envParament().subscribe((res: any) => {
      const listEnv = [];
      if (res.body) {
        res.body.forEach((element: any) => {
          element.upperLimit = 0;
          element.lowerLimit = 0;
          element.tbEnvironmentalParameterId = element.id;
          if (envParameter.length > 0) {
            envParameter.forEach((subItem: any) => {
              if (subItem.tbEnvironmentalParameterId === element.id) {
                element.upperLimit = subItem.upperLimit;
                element.lowerLimit = subItem.lowerLimit;
              }
            });
          }
          listEnv.push(element);
        });
      }
      this.listEnv = listEnv;
    });
  }
  changedDetailDate() {
    this.eventSubscriber = this.eventManager.subscribe(
      'tieuChuanDetailModification',
      () => {
        this.detailEachDay = null;
        this.listActivities = [];
        this.listEnv = [];
        this.selectDate = null;
        this.loadDetail();
        this.getListPeriodDropdown();
      },
    );
  }

  changedDetailActivity() {
    this.eventSubscriberAct = this.eventManager.subscribe(
        'activityDetailModification',
        () => {
          this.detailDate(this.dateId);
        },
    );
  }
  loadDetail() {
    return this.tieuChuanService.detail(this.standard.id).subscribe(
      (res: any) => this.onSuccess(res.body, 'standard'),
      (res: any) => this.onError(res.body),
    );
  }
  copyDate(date: any) {
    const newDate = new StandardDateDetail();
    this.listDay = [newDate, ...this.listDay];
    this.detailDate(date.id);
  }
  getItemsDay(listDay, periodID) {
    return listDay.filter((item) => item.tbGuidelinePeriodId === periodID);
  }
  getListPeriodDropdown() {
    this.tieuChuanService.queryPeriod({'tbGuidelineId.equals': this.standard.id}).subscribe(
        (res: any) => {
          this.listPeriodDropdown = res.body;
        }
    );
  }
  private onSuccess(data: any, type: string) {
    if (type === 'listSeed') {
      data.forEach((element: IGiong) => {
        element.isSelected = false;
        this.standard.tbSupplies.forEach((subItem: any, index) => {
          if (element.id === subItem.id) {
            element.isSelected = true;
          }
        });
        this.listSeed.push(element);
      });
    }
    if (type === 'standard') {
      this.standard = data.guideline;
      this.listDay = data.guidelineDetails;
      this.listPeriod = data.guidelinePeriods;
    }
  }
  detailDate(id: number) {
    this.detailEachDay = null;
    this.dateId = id;
    this.tieuChuanService.findDay(id).subscribe((res: any) => {
      const data = res.body ? res.body : [];
      if (data) {
        this.detailEachDay = data;
        this.selectDate = id;
        this.listActivities = data.activityGuidelines;
        this.loadEnv(data.limits);
      }
    });
  }
  update() {
    if (this.standard.id !== null) {
      const applySeed = [];
      this.listSeed.forEach((element: IGiong) => {
        if (element.isSelected) {
          applySeed.push({id: element.id});
        }
      });
      this.standard.tbSupplies = applySeed;
      this.tieuChuanService
        .updateAdvance({guideline: this.standard})
        .subscribe();
    }
  }
  save() {
    const data = this.detailEachDay;
    data.limits = this.listEnv;
    if (this.detailEachDay.id) {
      this.tieuChuanService.updatedDay(data).subscribe((response) => {
        this.loadDetail();
      });
    } else {
      data.tbGuidelineId = this.standard.id;
      this.tieuChuanService.adddDay(data).subscribe((responrse) => {
        this.loadDetail();
        this.detailEachDay = null;
        this.listActivities = [];
        this.listEnv = [];
        const dataCreate = responrse.body;
        if (dataCreate && dataCreate.id) {
          this.selectDate = dataCreate.id;
          this.detailDate(dataCreate.id);
        }
      });
    }
  }
  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
