import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ITieuChuan, IPeriod, IGuidelineDetail, IStandardDateDetail} from './tieu-chuan.model';
import {SERVER_API_URL} from '../../app.constants';
import {createRequestOption} from '../../shared';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TieuChuanService {
  private resourceUrl = SERVER_API_URL + 'api/tb-guidelines';
  private resourceUrlSearch = SERVER_API_URL + 'api/tb-guidelines';
  private detailResourceUrl = SERVER_API_URL + 'api/tb-guidelines-advance';
  private detailDate = SERVER_API_URL + 'api/tb-guideline-details';
  private periodResourceUrl = SERVER_API_URL + 'api/tb-guideline-periods';
  constructor(private http: HttpClient) {}

  query(req?: any): Observable<HttpResponse<ITieuChuan[]>> {
    const options = createRequestOption(req);
    return this.http.get<ITieuChuan[]>(this.resourceUrl, {
      params: options,
      observe: 'response',
    });
  }

  search(req?: any): Observable<HttpResponse<ITieuChuan[]>> {
    const options = createRequestOption(req);
    return this.http.get<ITieuChuan[]>(this.resourceUrlSearch, {
      params: options,
      observe: 'response',
    });
  }

  create(TC: any): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceUrl, TC, {observe: 'response'});
  }

  update(TC: any): Observable<HttpResponse<any>> {
    return this.http.put(this.resourceUrl, TC, {observe: 'response'});
  }
  updateAdvance(TC: any): Observable<HttpResponse<any>> {
    return this.http.put(this.detailResourceUrl, TC, {observe: 'response'});
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {
      observe: 'response',
    });
  }
  deleteDate(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.detailDate}/${id}`, {
      observe: 'response',
    });
  }
  find(id: number): Observable<HttpResponse<ITieuChuan>> {
    return this.http
      .get<ITieuChuan>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<ITieuChuan>) => res));
  }

  detail(id: number): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(`${this.detailResourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<any>) => res));
  }

  findDay(id: number): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(`${this.detailDate}/${id}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<any>) => res));
  }
  updatedDay(data: any): Observable<HttpResponse<any>> {
    return this.http.put(this.detailDate, data, {observe: 'response'});
  }
  adddDay(data: any): Observable<HttpResponse<any>> {
    return this.http.post(this.detailDate, data, {observe: 'response'});
  }

  envParament(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(`${SERVER_API_URL + 'api/tb-environmental-parameters'}`, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res));
  }
  giaTheType(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(`${SERVER_API_URL + 'api/tb-supplies-by-type/giathe'}`, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res));
  }
  suppliesInWarehouses(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(`${SERVER_API_URL + 'api/list-supplies-in-warehouses/ALL'}`, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res));
  }
  listDeviceOfUserCustomers(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(`${SERVER_API_URL + 'api/listDeviceOfUserCustomers'}`, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res));
  }
  listSuppliesSeed(): Observable<HttpResponse<any>> {
    return this.http
        .get<any>(`${SERVER_API_URL + 'api/list-supplies-in-warehouses/GIONG'}`, {
          observe: 'response',
        })
        .pipe(map((res: HttpResponse<any>) => res));
  }
  listSuppliesFertilizer(): Observable<HttpResponse<any>> {
    return this.http
        .get<any>(`${SERVER_API_URL + 'api/list-supplies-in-warehouses/PHANBON'}`, {
          observe: 'response',
        })
        .pipe(map((res: HttpResponse<any>) => res));
  }
  listSuppliesChemical(): Observable<HttpResponse<any>> {
    return this.http
        .get<any>(`${SERVER_API_URL + 'api/list-supplies-in-warehouses/THUOCBVTV'}`, {
          observe: 'response',
        })
        .pipe(map((res: HttpResponse<any>) => res));
  }
  findPeriod(id: number): Observable<HttpResponse<IPeriod>> {
    return this.http
        .get<IPeriod>(`${this.periodResourceUrl}/${id}`, {observe: 'response'})
        .pipe(map((res: HttpResponse<IPeriod>) => res));
  }
  createPeriod(Data: any): Observable<HttpResponse<IPeriod>> {
    return this.http.post(this.periodResourceUrl, Data, {observe: 'response'});
  }

  updatePeriod(Data: any): Observable<HttpResponse<IPeriod>> {
    return this.http.put(this.periodResourceUrl, Data, {observe: 'response'});
  }
  queryPeriod(req?: any): Observable<HttpResponse<IPeriod[]>> {
    const options = createRequestOption(req);
    return this.http.get<IPeriod[]>(this.periodResourceUrl, {
      params: options,
      observe: 'response',
    });
  }
  deletePeriod(id: number): Observable<HttpResponse<IPeriod>> {
    return this.http.delete(`${this.periodResourceUrl}/${id}`, {
      observe: 'response',
    });
  }
  getActivityTypeDropdown(): Observable<HttpResponse<IPeriod>> {
    return this.http
        .get<IPeriod>(`${SERVER_API_URL}/api/tb-activity-types-dropdown-list`, {observe: 'response'})
        .pipe(map((res: HttpResponse<IPeriod>) => res));
  }
  getActivityGuidelineDetail(activityName: string, activityId: number): Observable<HttpResponse<IGuidelineDetail>> {
    return this.http
        .get<IGuidelineDetail>(`${SERVER_API_URL}/api/get-detail-common-activity-guideline-details/${activityName}/${activityId}`, {observe: 'response'})
        .pipe(map((res: HttpResponse<IGuidelineDetail>) => res));
  }
  createActivityGuidelineDetail(activityName: string, data: any): Observable<HttpResponse<IGuidelineDetail>> {
    return this.http.post(`${SERVER_API_URL}/api/create-common-activity-guideline-details/${activityName}`, data, {observe: 'response'});
  }

  updateActivityGuidelineDetail(activityName: string, data: any): Observable<HttpResponse<IGuidelineDetail>> {
    return this.http.post(`${SERVER_API_URL}/api/update-common-activity-guideline-details/${activityName}`, data, {observe: 'response'});
  }
  getDisplayObjectParamDynamicForm(id: number): Observable<HttpResponse<any>> {
    return this.http
        .get<any>(`${SERVER_API_URL}/api/display-object-param-dynamic-form-guideline-details/${id}`, {observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => res));
  }
  deleteActivityGuidelineDetail(id: any, name: string): Observable<HttpResponse<IPeriod>> {
    return this.http.delete(`${SERVER_API_URL}/api/delete-common-activity-guideline-details/${name}/${id}`, {
      observe: 'response',
    });
  }
  duplicateGuidelineDetail(data: IStandardDateDetail): Observable<HttpResponse<IStandardDateDetail>> {
    return this.http.post(`${SERVER_API_URL}/api/duplicate-tb-guideline-details/${data.id}`, data, {observe: 'response'});
  }
}
