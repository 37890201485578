import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from './../../app.constants';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {IEntityLocation} from './entity-location.model';
import { Injectable } from '@angular/core';

type EntityType = IEntityLocation;
interface Page {
  data: EntityType[];
}
type EntityResponseType = HttpResponse<EntityType>;
type EntityPageResponseType = HttpResponse<Page>;
type EntityArrayResponseType = HttpResponse<EntityType[]>;

@Injectable({
  providedIn: 'root'
})
export class EntityLocationService {
  private resourceUrl = SERVER_API_URL + 'api/tb-entity-location-of-customers';
  private resourceUrlLocation = SERVER_API_URL + 'api/tb-entity-location-of-customers-by-customer';
  constructor(
    private http: HttpClient,
  ) { }

  create(entity: EntityType): Observable<EntityResponseType> {
    const copy = this.toResourceEntity(entity);
    return this.http.post<EntityType>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.toEntity(res)));
  }

  update(entity: EntityType): Observable<EntityResponseType> {
    const copy = this.toResourceEntity(entity);
    return this.http.put<EntityType>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.toEntity(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<EntityType[]>(this.resourceUrl, {params: req, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.toEntities(res)));
  }
  findId(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<EntityType[]>(`${this.resourceUrlLocation}/${id}`, {observe: 'response'})
        .pipe(map((res: EntityArrayResponseType) => this.toEntities(res)));
  }
  delete(id: string): Observable<EntityResponseType> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.toEntity(res)));
  }

  find(id: string): Observable<EntityResponseType> {
    return this.http.get<EntityType>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.toEntity(res)));
  }

  private toResourceEntity(entity: EntityType): any {
    const {
      id, pixelX, pixelY, tbCustomerId, tbEntityId,
    }: EntityType = entity;
    return {id, pixelX, pixelY, tbCustomerId, tbEntityId};
  }

  private toEntity(res: EntityResponseType): EntityResponseType {
    return res;
  }

  private toEntities(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body.length > 0) {
    }
    return res;
  }
}
