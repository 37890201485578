import {DonViService} from './../don-vi.service';
import {DonViSetting, IDonViSetting} from './../don-vi-setting.model';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class DonViSettingResolver implements Resolve<IDonViSetting> {
  constructor(private service: DonViService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['donVi'] ? route.params['donVi'] : null;
    if (id) {
      return this.service
        .getSetting(id, {exceptAlert: true})
        .pipe(map((donVi: HttpResponse<IDonViSetting>) => new DonViSetting(donVi.body)));
    }
    return of(new DonViSetting());
  }
}
