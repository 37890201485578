import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ThuHoach, IThuHoach} from 'src/app/entities/canh-tac/hanh-dong.model';
import {ThuHoachService} from '../thu-hoach.service';
import {HttpResponse} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-chi-tiet-thu-hoach',
  templateUrl: './chi-tiet-thu-hoach.component.html',
  styleUrls: ['./chi-tiet-thu-hoach.component.scss'],
})
export class ChiTietThuHoachComponent implements OnInit {
  objectData: any;
  action: any;
  loId: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private thuHoachService: ThuHoachService,
    private localStorage: LocalStorageService,
    private titleService: Title,
  ) {}

  ngOnInit() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    setTimeout(() => {
      this.loadHanhDong(id);
    }, 10);
  }
  loadHanhDong(id: any) {
    this.titleService.setTitle('Thu Hoạch');
    this.thuHoachService.find(id).subscribe(
      (res: HttpResponse<IThuHoach>) => {
        res.body.activityTypeId = 10;
        res.body.titlePage = 'Thu Hoạch';
        this.onSuccess(res.body);
      },
      (res: HttpResponse<any>) => this.onError(),
    );
  }

  private onSuccess(data: any) {
    this.objectData = data;
  }

  private onError() {
    this.objectData = new ThuHoach();
  }
}
