import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JhiEventManager} from 'ng-jhipster';
import {ActivatedRoute, Router} from '@angular/router';
import {IStamp, Stamp} from '../example-stamp.model';
import {ExampleStampService} from '../example-stamp.service';
import {DOMAIN_IMAGE_URL} from '../../../shared/constants/domain';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-example-stamp-dialog',
  templateUrl: './example-stamp-dialog.component.html',
  styleUrls: ['./example-stamp-dialog.component.scss'],
})
export class ExampleStampDialogComponent implements OnInit {
  isSaving: boolean;
  stampData: IStamp;
  fileDisplay: any;
  fileIcon: any = null;
  imageUploaded = false;

  constructor(
    private dialogRef: MatDialogRef<ExampleStampDialogComponent>,
    private eventManager: JhiEventManager,
    private stampService$: ExampleStampService,
    private notifierService: NotifierService,
  ) {
    this.isSaving = false;
    this.stampData = new Stamp();
  }
  ngOnInit() {}

  save() {
    this.isSaving = true;
    const data = new FormData();
    // data.append('data', JSON.stringify(this.stampData));
    data.append('data',
        new Blob([JSON.stringify( this.stampData )], {
          type: 'application/json'
        }));
    console.log(this.fileIcon);
    if (this.fileIcon) {
      data.append('file', this.fileIcon);
    }
    if (this.stampData.id) {
      this.stampService$.update(data).subscribe(() => {
        this.onSaveSuccess();
      }, () => {
        this.isSaving = false;
      });
    } else {
      this.stampService$.create(data).subscribe(() => {
        this.onSaveSuccess();
      }, () => {
        this.isSaving = false;
      });
    }
  }

  private onSaveSuccess() {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'stampListModification',
      content: 'stamp',
    });
    this.dialogRef.close(true);
  }
  upload(e) {
    this.fileDisplay = null;
    const file = e.target.files[0];
    if (!this.checkFileSize(file, 2 * 1024 * 1024)) {
      this.notifierService.notify('warning', "File ảnh tải lên phải nhỏ hơn 2MB");
      return;
    }
    if (!this.isImage(file)) {
      this.notifierService.notify('warning', "Chỉ cho phép tải lên các tệp tin ảnh (jpg, jpeg, png, gif)");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileDisplay = reader.result;
      this.fileIcon = file;
    };
    this.imageUploaded = true;
  }

  checkFileSize(file, maxSizeInBytes) {
    // default 2MB
    const maxSize = maxSizeInBytes || (2 * 1024 * 1024);
    return file.size <= maxSize;
  }

  isImage(file) {
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
    const extension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(extension);
  }

}

@Component({
  selector: 'app-example-stamp-update',
  template: '',
})
export class ExampleStampPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ExampleStampDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ data }) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(ExampleStampDialogComponent, {
          disableClose: true,
          width: '480px',
        });
        if (data !== undefined) {
          this.dialogRef.componentInstance.stampData = data;
          if (data.exampleStampImage != null) {
            this.dialogRef.componentInstance.fileDisplay =  DOMAIN_IMAGE_URL +  data.exampleStampImage;
            this.dialogRef.componentInstance.imageUploaded = true;
          }
        }

        this.dialogRef.afterClosed().subscribe(
            result => {
              this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
            },
            reason => {
              this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
            }
        );
      }, 0);
    });
  }

  ngOnDestroy() {}
}
