export interface INotification {
  numberUnreadPage?: number;
  numberReadPage?: number;
  numberUnreadTotal?: number;
  numberReadTotal?: number;
  tbNotificationDTOS?: any;
}
export class Notification implements INotification {
  constructor(
    public numberUnreadPage?: number,
    public numberReadPage?: number,
    public numberUnreadTotal?: number,
    public numberReadTotal?: number,
    public tbNotificationDTOS?: any,
  ) {
    this.numberUnreadPage = numberUnreadPage ? numberUnreadPage : 0;
    this.numberReadPage = numberReadPage ? numberReadPage : 0;
    this.numberUnreadTotal = numberUnreadTotal ? numberUnreadTotal : 0;
    this.numberReadTotal = numberReadTotal ? numberReadTotal : 0;
    this.tbNotificationDTOS = tbNotificationDTOS ? tbNotificationDTOS : [];
  }
}
