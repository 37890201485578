import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  IProduct,
  Product,
  IProductBatches,
  ProductBatches,
} from '../san-pham.model';
import {Subscription} from 'rxjs';
import {JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {HttpResponse} from '@angular/common/http';
import {SanPhamService} from '../san-pham.service';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from '../../../shared';
import {MatTableDataSource, PageEvent} from '@angular/material';


@Component({
  selector: 'app-san-pham-detail',
  templateUrl: './san-pham-detail.component.html',
  styleUrls: ['./san-pham-detail.component.scss']
})
export class SanPhamDetailComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'kho',
    'loHang',
    'ngay',
    'nhapXuat',
    'soLuong',
    'ghiChu',
    'actions',
  ];
  sanPham: Product;
  dataSource: any;
  pageSizeOptions: number[];
  ELEMENT_DATA: IProductBatches[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  noData: boolean;
  eventSubscriber: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventManager: JhiEventManager,
    private parseLinks: JhiParseLinks,
    private sanPhamService: SanPhamService,
  ) {
    this.dataSource = null;
    this.ELEMENT_DATA = [];
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.sanPham = new Product();
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({sanPham, pagingParams}) => {
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
      setTimeout(() => {
        if (sanPham !== undefined) {
          this.sanPham = sanPham;
          this.loadAll();
          this.changeListSupply();
        }
      }, 0);
    });
  }

  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }

  changeListSupply() {
    this.eventSubscriber = this.eventManager.subscribe(
      'sanPhamListModification',
      () => {
        this.loadAll();
        this.loadDetail();
      },
    );
  }
  loadAll() {
    const param: any = {};
    if (this.sanPham.id) {
      param.tbProductBlockDetailsId = this.sanPham.id;
    }
    this.sanPhamService
      .getStockUpdateHistories({
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: this.sort(),
        param: param
      })
      .subscribe((res: HttpResponse<IProductBatches[]>) =>
        this.onSuccess(res.body, res.headers, 'historyStockUpdate'),
      );
  }
  loadDetail() {
    this.sanPhamService
      .find(this.sanPham.id)
      .subscribe((res) =>
        this.onSuccess(res.body, res.headers, 'detailProduct'),
      );
  }
  private sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }
  statusConvert(status: number) {
    const statusName = [
      'Nhập', 'Xuất'
    ];
    return statusName[status] ? statusName[status] : 'Không xác định';
  }
  private onSuccess(data: any, headers: any, type: string) {
    if (type === 'historyStockUpdate') {
      this.links = this.parseLinks.parse(headers.get('link'));
      this.totalItems = headers.get('X-Total-Count');
      this.queryCount = this.totalItems;
      this.ELEMENT_DATA = data;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      if (data.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    }
    if (type === 'detailProduct') {
      this.sanPham = data;
    }
  }
  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }
}
