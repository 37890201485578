import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from 'src/app/app.constants';
import {Observable} from 'rxjs';
import {Product, IProduct, IProductBatches, ProductBatches } from './san-pham.model';
import {map} from 'rxjs/operators';
import {createRequestOption} from 'src/app/shared/util/request-util';
import {DATE_FORMAT} from 'src/app/shared/constants/input.constants';

@Injectable({
  providedIn: 'root'
})
export class SanPhamService {
  dateFormat: string = DATE_FORMAT;
  private resourceUrl = SERVER_API_URL + '/api/tb-product-block-details';
  private resourceUrlProductUnits = SERVER_API_URL + '/api/tb-product-units';
  private resourceProductStockUpdate = SERVER_API_URL + '/api/tb-product-stock-updates';
  private resourceProductStockUpdateImportExport = SERVER_API_URL + '/api/tb-product-block-details/create-block-update';
  constructor(private http: HttpClient) { }

  query(req?: any): Observable<HttpResponse<IProduct[]>> {
    const options = createRequestOption(req);
    return this.http.get<IProduct[]>(this.resourceUrl, {
      params: options,
      observe: 'response',
    });
  }

  find(productId: number): Observable<HttpResponse<IProduct>> {
    return this.http
      .get<IProduct>(`${this.resourceUrl}/${productId}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<IProduct>) => res));
  }

  importAndExport(product: any): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceProductStockUpdateImportExport, product, {observe: 'response'});
  }

  getStockUpdateHistories(req?: any): Observable<HttpResponse<any>> {
    const options = createRequestOption(req);
    return this.http.get<IProductBatches[]>(this.resourceProductStockUpdate, {
      params: options,
      observe: 'response',
    });
  }

  delete(productId: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${productId}`, {
      observe: 'response',
    });
  }

  deleteStockUpdateHistories(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceProductStockUpdate}/${id}`, {
      observe: 'response',
    });
  }

  getProductUnits(req?: any): Observable<HttpResponse<any>> {
    const options = createRequestOption(req);
    return this.http.get<IProductBatches[]>(this.resourceUrlProductUnits, {
      params: options,
      observe: 'response',
    });
  }

  listProductUnits(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(this.resourceUrlProductUnits, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res.body));
  }

}
