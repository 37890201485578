import {Moment} from 'moment';

export interface IHanhDong {
  id?: any;
  ngay?: Moment;
  images?: any;
  categoryCode?: string;
  title?: string;
  itemTimeLineId?: any;
  position?: string;
}

export class HanhDong implements IHanhDong {
  constructor(
    public id?: any,
    public ngay?: Moment,
    public images?: any,
    public categoryCode?: string,
    public title?: string,
    public itemTimeLineId?: any,
    public position?: string,
  ) {
    this.id = id ? id : null;
    this.ngay = ngay ? ngay : null;
    this.images = images ? images : [];
    this.categoryCode = categoryCode ? categoryCode : null;
    this.title = title ? title : null;
    this.itemTimeLineId = itemTimeLineId ? itemTimeLineId : null;
    this.position = position ? position : 'left';
  }
}
export interface IUom {
  id?: any;
  titlePage?: any;
  activityId?: any;
  cropId?: any;
  executeDate?: any;
  description?: any;
  executeBy?: any;
  media?: any;
  seedName?: any;
  substrates?: any;
  seedLength?: any;
  quantity?: any;
  seedIncubationTime?: any;
  nurseryDetail?: any;
  activityTypeId?: any;
}
export class Uom implements IUom {
  constructor(
    public id?: any,
    public titlePage?: any,
    public activityId?: any,
    public cropId?: any,
    public executeDate?: any,
    public description?: any,
    public executeBy?: any,
    public media?: any,
    public seedName?: any,
    public substrates?: any,
    public seedLength?: any,
    public quantity?: any,
    public seedIncubationTime?: any,
    public nurseryDetail?: any,
    public activityTypeId?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityId = activityId ? activityId : null;
    this.cropId = cropId ? cropId : null;
    this.executeDate = executeDate ? executeDate : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
    this.media = media ? media : null;
    this.seedName = seedName ? seedName : null;
    this.substrates = substrates ? substrates : null;
    this.seedLength = seedLength ? seedLength : null;
    this.quantity = quantity ? quantity : null;
    this.seedIncubationTime = seedIncubationTime ? seedIncubationTime : null;
    this.nurseryDetail = nurseryDetail ? nurseryDetail : [];
    this.activityTypeId = activityTypeId ? activityTypeId : null;
  }
}
export interface ITrong {
  id?: any;
  titlePage?: any;
  activityTypeId?: any;
  activityId?: any;
  cropId?: any;
  executeDate?: any;
  description?: any;
  executeBy?: any;
  media?: any;
  seedName?: any;
  density?: any;
  quantity?: any;
  suppliesUsing?: any;
}

export class Trong implements ITrong {
  constructor(
    public id?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public activityId?: any,
    public cropId?: any,
    public executeDate?: any,
    public description?: any,
    public executeBy?: any,
    public media?: any,
    public seedName?: any,
    public density?: any,
    public quantity?: any,
    public suppliesUsing?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.activityId = activityId ? activityId : null;
    this.cropId = cropId ? cropId : null;
    this.executeDate = executeDate ? executeDate : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
    this.media = media ? media : null;
    this.seedName = seedName ? seedName : null;
    this.density = density ? density : null;
    this.quantity = quantity ? quantity : null;
    this.suppliesUsing = suppliesUsing ? suppliesUsing : [];
  }
}

export interface IThucTrangCayTrong {
  cropId?: any;
  titlePage?: any;
  activityTypeId?: any;
  executeDate?: any;
  media?: any;
  cropRate?: any;
  numberOfTreeToGrow?: any;
  heightOfTree?: any;
  numberOfLeaf?: any;
  leafSize?: any;
  leafColor?: any;
  internodeLength?: any;
  abilityProduceBuds?: any;
  description?: any;
  executeBy?: any;
}

export class ThucTrangCayTrong implements IThucTrangCayTrong {
  constructor(
    public cropId?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public executeDate?: any,
    public media?: any,
    public cropRate?: any,
    public numberOfTreeToGrow?: any,
    public heightOfTree?: any,
    public numberOfLeaf?: any,
    public leafSize?: any,
    public leafColor?: any,
    public internodeLength?: any,
    public abilityProduceBuds?: any,
    public description?: any,
    public executeBy?: any,
  ) {
    this.cropId = cropId ? cropId : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.cropRate = cropRate ? cropRate : null;
    this.executeDate = executeDate ? executeDate : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
    this.media = media ? media : null;
    this.numberOfTreeToGrow = numberOfTreeToGrow ? numberOfTreeToGrow : null;
    this.heightOfTree = heightOfTree ? heightOfTree : null;
    this.leafSize = leafSize ? leafSize : null;
    this.leafColor = leafColor ? leafColor : null;
    this.internodeLength = internodeLength ? internodeLength : null;
    this.abilityProduceBuds = abilityProduceBuds ? abilityProduceBuds : null;
  }
}

export interface ICapNhatMoiTruong {
  cropId?: any;
  titlePage?: any;
  activityTypeId?: any;
  executeDate?: any;
  media?: any;
  description?: any;
  executeBy?: any;
  pH?: any;
  ec?: any;
}

export class CapNhatMoiTruong implements ICapNhatMoiTruong {
  constructor(
    public cropId?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public executeDate?: any,
    public media?: any,
    public description?: any,
    public executeBy?: any,
    public ec?: any,
    public pH?: any,
  ) {
    this.cropId = cropId ? cropId : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.executeDate = executeDate ? executeDate : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
    this.media = media ? media : null;
    this.ec = ec ? ec : null;
    this.pH = pH ? pH : null;
  }
}

export interface IBonPhan {
  id?: any;
  titlePage?: any;
  activityTypeId?: any;
  activityId?: any;
  cropId?: string;
  media?: any;
  executeDate?: any;
  quarantinePeriod?: any;
  weatherConditions?: any;
  purpose?: any;
  description?: string;
  executeBy?: string;
  suppliesUsing?: any;
}

export class BonPhan implements IBonPhan {
  constructor(
    public id?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public activityId?: any,
    public cropId?: string,
    public media?: any,
    public executeDate?: any,
    public quarantinePeriod?: any,
    public weatherConditions?: any,
    public purpose?: any,
    public description?: string,
    public executeBy?: string,
    public suppliesUsing?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.activityId = activityId ? activityId : null;
    this.cropId = cropId ? cropId : null;
    this.media = media ? media : null;
    this.executeDate = executeDate ? executeDate : null;
    this.quarantinePeriod = quarantinePeriod ? quarantinePeriod : null;
    this.weatherConditions = weatherConditions ? weatherConditions : null;
    this.purpose = purpose ? purpose : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
    this.suppliesUsing = suppliesUsing ? suppliesUsing : [];
  }
}

export interface IPhunThuoc {
  id?: any;
  titlePage?: any;
  activityTypeId?: any;
  activityId?: any;
  cropId?: any;
  media?: any;
  executeDate?: any;
  quarantinePeriod?: any;
  resultAt?: any;
  weatherConditions?: any;
  purpose?: any;
  description?: any;
  executeBy?: any;
  suppliesUsing?: any;
}

export class PhunThuoc implements IPhunThuoc {
  constructor(
    public id?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public activityId?: any,
    public cropId?: any,
    public media?: any,
    public executeDate?: any,
    public quarantinePeriod?: any,
    public resultAt?: any,
    public weatherConditions?: any,
    public purpose?: any,
    public description?: any,
    public executeBy?: any,
    public suppliesUsing?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.activityId = activityId ? activityId : null;
    this.cropId = cropId ? cropId : null;
    this.media = media ? media : null;
    this.executeDate = executeDate ? executeDate : null;
    this.quarantinePeriod = quarantinePeriod ? quarantinePeriod : null;
    this.resultAt = resultAt ? resultAt : null;
    this.weatherConditions = weatherConditions ? weatherConditions : null;
    this.purpose = purpose ? purpose : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
    this.suppliesUsing = suppliesUsing ? suppliesUsing : [];
  }
}

export interface ISuDungNuoc {
  id?: any;
  titlePage?: any;
  activityTypeId?: any;
  activityId?: any;
  cropId?: any;
  media?: any;
  executeDate?: any;
  waterType?: any;
  amount?: any;
  times?: any;
  description?: any;
  executeBy?: any;
}

export class SuDungNuoc implements ISuDungNuoc {
  constructor(
    public id?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public activityId?: any,
    public cropId?: any,
    public media?: any,
    public executeDate?: any,
    public waterType?: any,
    public amount?: any,
    public times?: any,
    public description?: any,
    public executeBy?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.activityId = activityId ? activityId : null;
    this.cropId = cropId ? cropId : null;
    this.media = media ? media : null;
    this.executeDate = executeDate ? executeDate : null;
    this.waterType = waterType ? waterType : null;
    this.amount = amount ? amount : null;
    this.times = times ? times : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
  }
}

export interface IHoatDongKhac {
  id?: any;
  titlePage?: any;
  activityTypeId?: any;
  ageDay?: any;
  cropId?: any;
  totalCost?: any;
  media?: any;
  executeDate?: any;
  description?: any;
  createdDate?: any;
  modifiedDate?: any;
  deletedDate?: any;
  deletedById?: any;
  createdById?: any;
  createdByName?: any;
  modifiedById?: any;
  modifiedByName?: any;
  activityTypeName?: any;
  activityTypeDescription?: any;
}

export class HoatDongKhac implements IHoatDongKhac {
  constructor(
    public id?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public ageDay?: any,
    public cropId?: any,
    public totalCost?: any,
    public media?: any,
    public executeDate?: any,
    public description?: any,
    public createdDate?: any,
    public modifiedDate?: any,
    public deletedDate?: any,
    public deletedById?: any,
    public createdById?: any,
    public createdByName?: any,
    public modifiedById?: any,
    public modifiedByName?: any,
    public activityTypeName?: any,
    public activityTypeDescription?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.ageDay = ageDay ? ageDay : null;
    this.cropId = cropId ? cropId : null;
    this.totalCost = totalCost ? totalCost : null;
    this.media = media ? media : null;
    this.executeDate = executeDate ? executeDate : null;
    this.createdDate = createdDate ? createdDate : null;
    this.modifiedDate = modifiedDate ? modifiedDate : null;
    this.description = description ? description : null;
    this.deletedDate = deletedDate ? deletedDate : null;
    this.deletedById = deletedById ? deletedById : null;
    this.createdById = createdById ? createdById : null;
    this.createdByName = createdByName ? createdByName : null;
    this.modifiedById = modifiedById ? modifiedById : null;
    this.modifiedByName = modifiedByName ? modifiedByName : null;
    this.activityTypeName = activityTypeName ? activityTypeName : null;
    this.activityTypeDescription = activityTypeDescription
      ? activityTypeDescription
      : null;
  }
}
export interface IDieuTraDichBenh {
  cropId?: any;
  titlePage?: any;
  activityTypeId?: any;
  executeDate?: any;
  media?: any;
  typesOfPest?: any;
  harmLevel?: any;
  treePercent?: any;
  location?: any;
  naturalEnemy?: any;
  treatmentMeasures?: any;
  description?: any;
  executeBy?: any;
}

export class DieuTraDichBenh implements IDieuTraDichBenh {
  constructor(
    public cropId?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public executeDate?: any,
    public media?: any,
    public typesOfPest?: any,
    public harmLevel?: any,
    public treePercent?: any,
    public location?: any,
    public naturalEnemy?: any,
    public treatmentMeasures?: any,
    public description?: any,
    public executeBy?: any,
  ) {
    this.cropId = cropId ? cropId : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.executeDate = executeDate ? executeDate : null;
    this.media = media ? media : null;
    this.typesOfPest = typesOfPest ? typesOfPest : null;
    this.harmLevel = harmLevel ? harmLevel : null;
    this.treePercent = treePercent ? treePercent : null;
    this.location = location ? location : null;
    this.naturalEnemy = naturalEnemy ? naturalEnemy : null;
    this.treatmentMeasures = treatmentMeasures ? treatmentMeasures : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
  }
}

export interface IThuHoach {
  id?: any;
  titlePage?: any;
  activityTypeId?: any;
  activityId?: any;
  cropId?: any;
  executeDate?: any;
  executeBy?: any;
  media?: any;
  description?: any;
  collectedQuantityLv1?: any;
  collectedQuantityLv2?: any;
  collectedQuantityLv3?: any;
  removedQuantity?: any;
}

export class ThuHoach implements IThuHoach {
  constructor(
    public id?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public activityId?: any,
    public cropId?: any,
    public executeDate?: any,
    public executeBy?: any,
    public media?: any,
    public description?: any,
    public collectedQuantityLv1?: any,
    public collectedQuantityLv2?: any,
    public collectedQuantityLv3?: any,
    public removedQuantity?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.activityId = activityId ? activityId : null;
    this.cropId = cropId ? cropId : null;
    this.executeDate = executeDate ? executeDate : null;
    this.executeBy = executeBy ? executeBy : null;
    this.media = media ? media : null;
    this.description = description ? description : null;
    this.collectedQuantityLv1 = collectedQuantityLv1
      ? collectedQuantityLv1
      : null;
    this.collectedQuantityLv2 = collectedQuantityLv2
      ? collectedQuantityLv2
      : null;
    this.collectedQuantityLv3 = collectedQuantityLv3
      ? collectedQuantityLv3
      : null;
    this.removedQuantity = removedQuantity ? removedQuantity : null;
  }
}

export interface IXuLiThuHoach {
  id?: any;
  titlePage?: any;
  activityTypeId?: any;
  activityId?: any;
  cropId?: any;
  harvestId?: any;
  media?: any;
  executeDate?: any;
  quantityLv1?: any;
  quantityLv2?: any;
  quantityLv3?: any;
  removedQuantity?: any;
  totalQuantity?: any;
  description?: any;
  executeBy?: any;
  suppliesUsing?: any;
}

export class XuLiThuHoach implements IXuLiThuHoach {
  constructor(
    public id?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public activityId?: any,
    public cropId?: any,
    public harvestId?: any,
    public media?: any,
    public executeDate?: any,
    public quantityLv1?: any,
    public quantityLv2?: any,
    public quantityLv3?: any,
    public removedQuantity?: any,
    public totalQuantity?: any,
    public description?: any,
    public executeBy?: any,
    public suppliesUsing?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.activityId = activityId ? activityId : null;
    this.cropId = cropId ? cropId : null;
    this.harvestId = harvestId ? harvestId : null;
    this.media = media ? media : null;
    this.executeDate = executeDate ? executeDate : null;
    this.quantityLv1 = quantityLv1 ? quantityLv1 : null;
    this.quantityLv2 = quantityLv2 ? quantityLv2 : null;
    this.quantityLv3 = quantityLv3 ? quantityLv3 : null;
    this.removedQuantity = removedQuantity ? removedQuantity : null;
    this.totalQuantity = totalQuantity ? totalQuantity : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
    this.suppliesUsing = suppliesUsing ? suppliesUsing : [];
  }
}

export interface IDongGoi {
  id?: any;
  titlePage?: any;
  activityTypeId?: any;
  activityId?: any;
  cropId?: any;
  harvestId?: any;
  media?: any;
  executeDate?: any;
  quantityLv1?: any;
  quantityLv2?: any;
  quantityLv3?: any;
  removedQuantity?: any;
  description?: any;
  executeBy?: any;
}

export class DongGoi implements IDongGoi {
  constructor(
    public id?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public activityId?: any,
    public cropId?: any,
    public harvestId?: any,
    public media?: any,
    public executeDate?: any,
    public quantityLv1?: any,
    public quantityLv2?: any,
    public quantityLv3?: any,
    public removedQuantity?: any,
    public description?: any,
    public executeBy?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.activityId = activityId ? activityId : null;
    this.cropId = cropId ? cropId : null;
    this.harvestId = harvestId ? harvestId : null;
    this.media = media ? media : null;
    this.executeDate = executeDate ? executeDate : null;
    this.quantityLv1 = quantityLv1 ? quantityLv1 : null;
    this.quantityLv2 = quantityLv2 ? quantityLv2 : null;
    this.quantityLv3 = quantityLv3 ? quantityLv3 : null;
    this.removedQuantity = removedQuantity ? removedQuantity : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
  }
}

export interface IXuatBan {
  id?: any;
  titlePage?: any;
  activityTypeId?: any;
  activityId?: any;
  cropId?: any;
  harvestId?: any;
  media?: any;
  executeDate?: any;
  quantityLv1?: any;
  quantityLv2?: any;
  quantityLv3?: any;
  removedQuantity?: any;
  buyer?: any;
  description?: any;
  executeBy?: any;
}

export class XuatBan implements IXuatBan {
  constructor(
    public id?: any,
    public titlePage?: any,
    public activityTypeId?: any,
    public activityId?: any,
    public cropId?: any,
    public harvestId?: any,
    public media?: any,
    public executeDate?: any,
    public quantityLv1?: any,
    public quantityLv2?: any,
    public quantityLv3?: any,
    public removedQuantity?: any,
    public buyer?: any,
    public description?: any,
    public executeBy?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.activityId = activityId ? activityId : null;
    this.cropId = cropId ? cropId : null;
    this.harvestId = harvestId ? harvestId : null;
    this.media = media ? media : null;
    this.executeDate = executeDate ? executeDate : null;
    this.quantityLv1 = quantityLv1 ? quantityLv1 : null;
    this.quantityLv2 = quantityLv2 ? quantityLv2 : null;
    this.quantityLv3 = quantityLv3 ? quantityLv3 : null;
    this.removedQuantity = removedQuantity ? removedQuantity : null;
    this.buyer = buyer ? buyer : null;
    this.description = description ? description : null;
    this.executeBy = executeBy ? executeBy : null;
  }
}

export interface IKetThucCanhTac {
  id?: any;
  titlePage?: any;
  ageDay?: any;
  cropId?: any;
  totalCost?: any;
  media?: any;
  executeDate?: any;
  description?: any;
  createdDate?: any;
  modifiedDate?: any;
  deletedDate?: any;
  deletedById?: any;
  createdById?: any;
  createdByName?: any;
  modifiedById?: any;
  modifiedByName?: any;
  activityTypeId?: any;
  activityTypeName?: any;
  activityTypeDescription?: any;
}

export class KetThucCanhTac implements IKetThucCanhTac {
  constructor(
    public id?: any,
    public titlePage?: any,
    public ageDay?: any,
    public cropId?: any,
    public totalCost?: any,
    public media?: any,
    public executeDate?: any,
    public description?: any,
    public createdDate?: any,
    public modifiedDate?: any,
    public deletedDate?: any,
    public deletedById?: any,
    public createdById?: any,
    public createdByName?: any,
    public modifiedById?: any,
    public modifiedByName?: any,
    public activityTypeId?: any,
    public activityTypeName?: any,
    public activityTypeDescription?: any,
  ) {
    this.id = id ? id : null;
    this.titlePage = titlePage ? titlePage : null;
    this.activityTypeId = activityTypeId ? activityTypeId : null;
    this.ageDay = ageDay ? ageDay : null;
    this.cropId = cropId ? cropId : null;
    this.totalCost = totalCost ? totalCost : null;
    this.media = media ? media : null;
    this.executeDate = executeDate ? executeDate : null;
    this.createdDate = createdDate ? createdDate : null;
    this.modifiedDate = modifiedDate ? modifiedDate : null;
    this.deletedDate = deletedDate ? deletedDate : null;
    this.deletedById = deletedById ? deletedById : null;
    this.createdById = createdById ? createdById : null;
    this.description = description ? description : null;
    this.createdByName = createdByName ? createdByName : null;
    this.modifiedById = modifiedById ? modifiedById : null;
    this.modifiedByName = modifiedByName ? modifiedByName : null;
    this.activityTypeName = activityTypeName ? activityTypeName : null;
    this.activityTypeDescription = activityTypeDescription
      ? activityTypeDescription
      : null;
  }
}

export interface IHarvest {
  id?: number;
  activitiId?: number;
  cropId?: number;
  seedling?: string;
  totalQuantity?: number;
  totalInStock?: number;
  executeDate?: string;
}

export class Harvest implements IHarvest {
  constructor(
    public id?: number,
    public activitiId?: number,
    public cropId?: number,
    public seedling?: string,
    public totalQuantity?: number,
    public totalInStock?: number,
    public executeDate?: string,
  ) {
    this.id = id ? id : null;
    this.activitiId = activitiId ? activitiId : null;
    this.cropId = cropId ? cropId : null;
    this.seedling = seedling ? seedling : null;
    this.totalQuantity = totalQuantity ? totalQuantity : null;
    this.totalInStock = totalInStock ? totalInStock : null;
    this.executeDate = executeDate ? executeDate : null;
  }
}
