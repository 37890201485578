import { Component, OnInit, OnDestroy } from '@angular/core';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {
  ITEMS_PER_PAGE,
  PAGE_SIZE_OPTIONS,
} from 'src/app/shared/constants/pagination.constants';
import {HttpResponse} from '@angular/common/http';
import {Giong} from '../../../shared/model/giong.model';
import {GiongService} from '../../../shared/services/giong.service';
import {SanPhamService} from '../san-pham.service';
import {Title} from '@angular/platform-browser';
import { IProduct } from '../san-pham.model';

@Component({
  selector: 'app-san-pham',
  templateUrl: './san-pham.component.html',
  styleUrls: ['./san-pham.component.scss']
})
export class SanPhamComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'maLoSanPham',
    'maLoSanXuat',
    'giong',
    'loai',
    'soLuongThuHoach',
    'soLuongTonKho',
    'ngayThuHoach',
    'ngayHetHan',
    'action'
  ];
  dataSource: any;
  pageSizeOptions: number[];
  ELEMENT_DATA: IProduct[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  currentSearch: string;
  routeData: any;
  eventSubscriber: Subscription;
  giongId: any = '';
  giongData: Giong[] = [];
  typeId: any = '';
  typeData: Number[] = [];
  noData: boolean;
  constructor(private sanPhamService: SanPhamService,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private parseLinks: JhiParseLinks,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private giong: GiongService) {
      this.dataSource = null;
      this.ELEMENT_DATA = [];
      this.itemsPerPage = ITEMS_PER_PAGE;
      this.pageSizeOptions = PAGE_SIZE_OPTIONS;
      this.routeData = this.activatedRoute.data.subscribe(({pagingParams}) => {
        this.page = pagingParams.page;
        this.previousPage = pagingParams.page;
        this.reverse = pagingParams.ascending;
        this.predicate = 'harvestDate';
      });

      this.currentSearch =
        this.activatedRoute.snapshot &&
        this.activatedRoute.snapshot.params['search']
          ? this.activatedRoute.snapshot.params['search']
          : '';
      }

  ngOnInit() {
    this.titleService.setTitle('Danh sách sản phẩm');
    this.getGiong();
    this.loadAll();
    this.changedListProduct();
  }

  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }

  loadAll() {
    if (this.currentSearch || this.giongId || this.typeId) {
      const param: any = {};
      // param.page = this.page - 1;
      // param.size = this.itemsPerPage;
      // param.sort = this.sort();
      if (this.currentSearch) {
        param.tbCropCode = this.currentSearch;
      }
      if (this.giongId) {
        param.tbSuppliesId = this.giongId;
      }

      if (this.typeId) {
        param.productLevel = this.typeId;
      }

      this.sanPhamService
        .query({
          page: this.page - 1,
          size: this.itemsPerPage,
          sort: this.sort(),
          param: param
        }).subscribe(
          (res: HttpResponse<IProduct[]>) =>
            this.onSuccess(res.body, res.headers, 'sanPham'),
          (res: HttpResponse<any>) => this.onError(res.body),
        );
      return;
    } else {
      this.sanPhamService
        .query({
          page: this.page - 1,
          size: this.itemsPerPage,
          sort: this.sort(),
        }).subscribe(
          (res: HttpResponse<IProduct[]>) =>
            this.onSuccess(res.body, res.headers, 'sanPham'),
          (res: HttpResponse<any>) => this.onError(res.body),
        );
    }
  }
  changedListProduct() {
    this.eventSubscriber = this.eventManager.subscribe(
      'productListModification',
      () => this.loadAll(),
    );
  }
  private sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }

  clear() {
    this.page = 1;
    this.currentSearch = '';
    this.loadAll();
  }

  search(query: string) {
    if (!query) {
      return this.clear();
    }

    this.page = 1;
    this.currentSearch = query;

    this.loadAll();
  }

  getGiong() {
    this.giong.queryDropdownList().subscribe(
      (res: HttpResponse<Giong[]>) =>
        this.onSuccess(res.body, res.headers, 'giong'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getType() {
    const types = [1, 2, 3];
    this.onSuccess(types, null, 'loai');
  }

  onChangeGiong(e) {
    this.giongId = e.value;
    this.getType();
    this.loadAll();
  }

  onChangeType(e) {
    this.typeId = e.value;
    this.loadAll();
  }

  private onSuccess(data: any, headers: any, type: string) {
    if (type === 'sanPham') {
      this.links = this.parseLinks.parse(headers.get('link'));
      this.totalItems = headers.get('X-Total-Count');
      this.queryCount = this.totalItems;
      this.ELEMENT_DATA = data;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      if (data.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    } else if (type === 'giong') {
      this.giongData = data;
    } else if (type === 'loai') {
      this.typeData = data;
    }
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
