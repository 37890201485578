import {LeafletComponent} from './leaflet/leaflet.component';
import {MatDialogHoverComponent} from './mat-dialog-hover/mat-dialog-hover.component';
import {MatPopoverDirective} from './directives/mat-popover.directive';
import {FullScreenButtonDirective} from './directives/full-screen-button.directive';
import {ShapeComponent} from './shape/shape.component';
import {NgModule} from '@angular/core';

import {AlertComponent} from './alert/alert.component';
import {AlertErrorComponent} from './alert/alert-error.component';
import {SharedLibsModule} from './shared-libs.module';
import {GridComponent} from './grid/grid.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {DialogService} from './confirm-dialog/dialog.service';
import {PadNumberPipe} from './pipes/pad-number.pipe';
import {AlertNotifierComponent} from './alert/alert-notifier.component';
import {MenuListItemComponent} from './menu-list-item/menu-list-item.component';
import {CommonModule} from '@angular/common';
import {LoadingScreenComponent} from './loading-screen/loading-screen.component';
import {DataTableComponent} from './data-table/data-table.component';
import {ListImagesComponent} from './list-images/list-images.component';

@NgModule({
    imports: [CommonModule, SharedLibsModule],
  declarations: [
    AlertComponent,
    AlertErrorComponent,
    AlertNotifierComponent,
    GridComponent,
    ConfirmDialogComponent,
    LoadingScreenComponent,
    DateFormatPipe,
    PadNumberPipe,
    MenuListItemComponent,
    DataTableComponent,
    ListImagesComponent,
    ShapeComponent,
    FullScreenButtonDirective,
    MatDialogHoverComponent,
    MatPopoverDirective,
    LeafletComponent,
  ],
  providers: [DialogService],
  exports: [
    SharedLibsModule,
    AlertComponent,
    AlertErrorComponent,
    AlertNotifierComponent,
    GridComponent,
    ConfirmDialogComponent,
    LoadingScreenComponent,
    DateFormatPipe,
    PadNumberPipe,
    MenuListItemComponent,
    DataTableComponent,
    ListImagesComponent,
    ShapeComponent,
    FullScreenButtonDirective,
    MatPopoverDirective,
    MatDialogHoverComponent,
    LeafletComponent,
  ],
  entryComponents: [ConfirmDialogComponent, MatDialogHoverComponent],
})
export class SharedCommonModule {}
