import {Component, OnInit} from '@angular/core';
import {NavItem} from '../../shared/menu-list-item/nav-item';
import {Router} from '@angular/router';
import {LoginService} from '../../core';
import {LocalStorageService} from 'ngx-webstorage';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {NotifierService} from 'angular-notifier';
import {NotificationService} from '../../shared/services/notification.service';
import {
  INotification,
  Notification,
} from '../../shared/model/notification.model';
import {Subscription} from 'rxjs';
import {JhiEventManager} from 'ng-jhipster';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  title = 'tienphong-ui';
  settingRoute = '/cai-dat-tai-khoan';
  notificationData: INotification;
  navItems: NavItem[] = [
    {
      displayName: 'Dashboard',
      iconName: 'dashboard',
      route: '/dashboard',
      permission: 'can_read_dashboard',
    },
    {
      displayName: 'Quản lý canh tác',
      iconName: 'assignment',
      route: '/danh-sach-lo',
      permission: 'can_read_crops',
    },
    {
      displayName: 'Quản lý kho sản phẩm',
      iconName: 'production_quantity_limits',
      route: '/danh-sach-san-pham',
      permission: 'can_list_product_block',
    },
    {
      displayName: 'Quản lý thu hoạch',
      iconName: 'chrome_reader_mode',
      route: '/danh-sach-thu-hoach',
      permission: 'can_read_harvests',
    },
    {
      displayName: 'Quản lý code',
      iconName: 'qr_code_2',
      route: '/danh-sach-qr-code',
      permission: 'can_read_crops',
    },
    {
      displayName: 'Quản lý mẫu tem',
      iconName: 'qr_code_2',
      route: '/danh-sach-mau-tem',
      permission: 'can_read_crops',
    },
    {
      displayName: 'Quản lý quy trình',
      iconName: 'high_quality',
      route: '/danh-sach-tieu-chuan',
      permission: 'can_tbguidelines',
    },
    {
      displayName: 'Quản lý người dùng',
      iconName: 'recent_actors',
      route: '/danh-sach-nguoi-dung',
      permission: 'can_read_user',
    },
    {
      displayName: 'Quản lý giống',
      iconName: 'spa',
      route: '/danh-sach-giong',
      permission: 'can_list_giong',
    },
    {
      displayName: 'Quản lý vật tư',
      iconName: 'layers',
      route: '/danh-sach-vat-tu',
      permission: 'can_list_supplies',
    },
    {
      displayName: 'Báo cáo thống kê',
      iconName: 'show_chart',
      route: '/bao-cao-thong-ke',
      permission: 'can_report_monthly_activity',
    },
    {
      displayName: 'Quản lý thiết bị',
      iconName: 'build',
      route: '/danh-sach-thiet-bi',
      permission: 'can_list_devices',
    },
    {
      displayName: 'Quản lý đơn vị',
      iconName: 'apartment',
      route: '/danh-sach-don-vi',
      permission: 'can_list_unit_managerment',
    },
    {
      displayName: 'Quản lý hoạt động',
      iconName: 'view_module',
      permission: 'can_read_activity_type',
      route: '/activity',
    },
  ];
  navItemsPermision: NavItem[] = [];
  permissionsSB = [];
  eventSubscriber: Subscription;
  constructor(
    public router: Router,
    private loginService: LoginService,
    private localStorage: LocalStorageService,
    private afMessaging: AngularFireMessaging,
    private notifierService: NotifierService,
    private notificationService: NotificationService,
    private eventManager: JhiEventManager,
  ) {
    this.notificationData = new Notification();
    this.afMessaging.requestToken.subscribe(
      (token) => {
        this.notificationService.registerNotification(token).subscribe(() => {
          this.listen();
        });
      },
      (error) => {
        console.log(
          '%c The notification permission was not granted and blocked instead. ',
          'background: #222; color: #bada55',
          '(messaging/permission-blocked)',
        );
        // console.log(error);
      },
    );
  }

  ngOnInit() {
    this.getListNotification();
    this.changeNotification();
    const roleId = this.localStorage.retrieve('account.roleId');
    setTimeout(() => {
      if (roleId) {
        const permissions = this.localStorage.retrieve('rules');
        if (permissions) {
          this.navItems.map((item: any) => {
            if (item.permission === 'can_report_monthly_activity') {
              if (permissions.includes('can_report_monthly_activity') || permissions.includes('can_report_garden') || permissions.includes('can_report_seedusage') || permissions.includes('can_report_exportwarehouse') || permissions.includes('can_report_importmaterial')) {
                this.navItemsPermision.push(item);
              }
            } else if (permissions.includes(item.permission)) {
              if (item.children && item.children.length > 0) {
                item.children = item.children.filter((child: any) => {
                  return permissions.includes(child.permission);
                });
              }
              this.navItemsPermision.push(item);
            }
          });
        } else {
          this.navItemsPermision = this.navItems;
        }
      } else {
        this.navItemsPermision = this.navItems;
      }
    }, 500);
  }
  listen() {
    this.afMessaging.messages.subscribe((message: any) => {
      this.getListNotification();
    });
  }
  changeNotification() {
    this.eventSubscriber = this.eventManager.subscribe(
      'notificationModification',
      () => this.getListNotification(),
    );
  }
  getListNotification() {
    const params = {
      page: 0,
      size: 1,
      sort: ['sendDate,DESC'],
    };
    this.notificationService
      .getListNotification(params)
      .subscribe((res: any) => {
        this.notificationData = res.body ? res.body : null;
      });
  }
  logout() {
    this.notificationService.unRegisterNotification().subscribe(() => {
      this.loginService.logout();
      this.router.navigate(['/login']);
    });
  }
}
