import { DOCUMENT } from '@angular/common';
import { ComponentRef, Directive, ElementRef, HostListener, Inject, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appMatPopover]'
})
export class MatPopoverDirective {
  private isHover = false;
  private _id = 'mat-popover';
  @Input() popoverRef: ElementRef<any>|{elementRef: ElementRef};
  constructor(
    private e: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) { }


  @HostListener('mouseenter', ['$event']) onMouseEnter($event) {
    if (!this.isHover) {
      this.isHover = true;

      const bodyRect = document.body.getBoundingClientRect(),
      elemRect = this.e.nativeElement.getBoundingClientRect(),
      offsetY   = elemRect.top - bodyRect.top,
      offsetX   = elemRect.left - bodyRect.left;

      let popover = this.document.getElementById(this._id);

      if (popover) {
        console.log(this.popoverRef);
        popover.style.display = 'block';
      } else {
        popover = this.document.createElement('div');
        popover.id = this._id;
        popover.className = 'mat-bg-basic mat-popover';
        // popover.innerHTML = '<mat-card class="mat-card"><mat-card-content class="mat-card-content">This is popover</mat-card-content></mat-card>';
        popover.style.position = 'fixed';
        popover.style['z-index'] = 1100;

        this.document.body.appendChild(popover);
      }

      if (offsetX && offsetY) {
        popover.style.left = `${offsetX}px`;
        popover.style.top = `${offsetY - popover.offsetHeight}px`;
      }

      if (this.popoverRef) {
        if (this.popoverRef instanceof ElementRef) {
          popover.innerHTML = this.popoverRef.nativeElement.innerHTML;
        } else {
          if (this.popoverRef.elementRef) {
            const popoverHtml = this.popoverRef.elementRef.nativeElement.innerHTML;
            popover.innerHTML = `<mat-card class="mat-card">` +
            `<mat-card-content class="mat-card-content">` +
            popoverHtml +
            `</mat-card-content></mat-card>`;
          } else {
            console.warn('[appPopoverDirective] If this is not ElementRef. Not found view child element ref. add ElementRef in this element.');
          }
        }
      }
    }
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave($event) {
    if (this.isHover) {
      this.isHover = false;
      const popover = this.document.getElementById(this._id);
      popover.style.display = 'none';
    }
  }
}
