import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {ActivatedRoute, Router} from '@angular/router';
import {Qrcode} from '../qr-code.model';
import {ICrops} from '../../canh-tac/canh-tac.model';
import {CanhTacService} from '../../canh-tac';
import {QrCodeService} from '../qr-code.service';
import * as moment from 'moment';

@Component({
  selector: 'app-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.scss'],
})
export class QrCodeDialogComponent implements OnInit {
  isSaving: boolean;
  qrcode: Qrcode;
  cropData: ICrops[] = [];
  stampData: any[] = [];
  constructor(
    private dialogRef: MatDialogRef<QrCodeDialogComponent>,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private canhtac: CanhTacService,
    private qrcodeService: QrCodeService
  ) {
    this.isSaving = false;
    this.qrcode = new Qrcode();
    this.canhtac.dropdownListCrop().subscribe((res) => {
      this.cropData = res.body || [];
    });
    this.qrcodeService.dropdownListStamp().subscribe((res) => {
      this.stampData = res.body || [];
    });
  }
  ngOnInit() {}

  save() {
    this.isSaving = true;
    this.qrcode.expiredDate =
        moment(this.qrcode.expiredDate).format('YYYY-MM-DDTHH:mm:ss') +
        'Z';
    this.qrcodeService.create(this.qrcode).subscribe(() => {
      this.onSaveSuccess();
    }, () => {
      this.isSaving = false;
    });
  }

  private onSaveSuccess() {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'qrcodeListModification',
      content: 'qrCode',
    });
    this.dialogRef.close(true);
  }
}

@Component({
  selector: 'app-qr-code-update',
  template: '',
})
export class QrCodePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<QrCodeDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.dialogRef = this.dialog.open(QrCodeDialogComponent, {
        disableClose: true,
        width: '380px',
      });

      this.dialogRef.afterClosed().subscribe(
        (result) => {
          this.router.navigate([{outlets: {popup: null}}], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        },
        (reason) => {
          this.router.navigate([{outlets: {popup: null}}], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        },
      );
    }, 0);
  }

  ngOnDestroy() {}
}
