import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'src/app/app.constants';
import {Observable} from 'rxjs';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {Area, netHouses} from '../model/area.model';
import {createRequestOption} from 'src/app/shared/util/request-util';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  private resourceUrl = SERVER_API_URL + 'api/tb-entities/area';
  private resourceUrlNetHouses = SERVER_API_URL + 'api/tb-entities/net-houses';

  constructor(private http: HttpClient) {}
  listArea(): Observable<HttpResponse<Area[]>> {
    return this.http.get<Area[]>(this.resourceUrl, {
      params: null,
      observe: 'response',
    });
  }
  listNetHousesByArea(id: number): Observable<HttpResponse<netHouses[]>> {
    return this.http.get<netHouses[]>(`${this.resourceUrlNetHouses}/${id}`, {
      observe: 'response',
    });
  }
  listNetHousesAll(): Observable<HttpResponse<netHouses[]>> {
    return this.http.get<netHouses[]>(`${SERVER_API_URL}api/tb-entities/all-net-houses`, {
      observe: 'response',
    });
  }
}
