import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared';
import {TieuChuanComponent} from './tieu-chuan/tieu-chuan.component';
import {
  TieuChuanDialogComponent,
  TieuChuanPopupComponent,
} from './tieu-chuan-dialog/tieu-chuan-dialog.component';
import {TieuChuanRoute} from './tieu-chuan.route';
import {RouterModule, Routes} from '@angular/router';
import {TieuChuanDetailComponent} from './tieu-chuan-detail/tieu-chuan-detail.component';
import {
  TieuChuanDeleteDialogComponent,
  TieuChuanDeletePopupDiaglogComponent,
} from './tieu-chuan-delete-dialog/tieu-chuan-delete-dialog.component';
import {
  TieuChuanDeleteDateDialogComponent,
  TieuChuanDeleteDatePopupDiaglogComponent,
} from './tieu-chuan-delete-date-dialog/tieu-chuan-delete-date-dialog.component';
import {
  GiaiDoanDialogComponent,
  GiaiDoanPopupComponent,
} from './giai-doan-dialog/giai-doan-dialog.component';
import {
  GiaiDoanDeletePopupDiaglogComponent, GiaiDoanDeleteDialogComponent
} from './giai-doan-delete-dialog/giai-doan-delete-dialog.component';
import {
  NgayTuoiDialogComponent, NgayTuoiPopupComponent
} from './ngay-tuoi-dialog/ngay-tuoi-dialog.component';
import {
  ActivityTypePopupComponent, ActivityTypeDialogComponent
} from './activity-type-dialog/activity-type-dialog.component';
import {
  ActivityTypeDeleteDialogComponent, ActivityTypeDeletePopupDialogComponent
} from './activity-type-delete-dialog/activity-type-delete-dialog.component';
import {
  NgayTuoiDuplicateDialogComponent, NgayTuoiDuplicatePopupComponent
} from './ngay-tuoi-duplicate-dialog/ngay-tuoi-duplicate-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {NgxMatDatetimePickerModule} from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
const ENTITY_STATES = [...TieuChuanRoute];

@NgModule({
  declarations: [
    TieuChuanComponent,
    TieuChuanDialogComponent,
    TieuChuanPopupComponent,
    TieuChuanDetailComponent,
    TieuChuanDeleteDialogComponent,
    TieuChuanDeletePopupDiaglogComponent,
    TieuChuanDeleteDateDialogComponent,
    TieuChuanDeleteDatePopupDiaglogComponent,
    GiaiDoanDialogComponent,
    GiaiDoanPopupComponent,
    GiaiDoanDeletePopupDiaglogComponent,
    GiaiDoanDeleteDialogComponent,
    NgayTuoiDialogComponent,
    NgayTuoiPopupComponent,
    ActivityTypePopupComponent,
    ActivityTypeDialogComponent,
    ActivityTypeDeleteDialogComponent,
    ActivityTypeDeletePopupDialogComponent,
    NgayTuoiDuplicateDialogComponent,
    NgayTuoiDuplicatePopupComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
    NgxMatDatetimePickerModule,
    MatDatepickerModule,
    NgxMatMomentModule
  ],
  entryComponents: [
    TieuChuanDialogComponent,
    TieuChuanDeleteDialogComponent,
    TieuChuanDeleteDateDialogComponent,
    GiaiDoanDialogComponent,
    GiaiDoanDeleteDialogComponent,
    NgayTuoiDialogComponent,
    ActivityTypeDialogComponent,
    ActivityTypeDeleteDialogComponent,
    NgayTuoiDuplicateDialogComponent
  ],
})
export class TieuChuanModule {}
