export interface IEntityLocation {
    id?: number;
    pixelX?: number;
    pixelY?: number;
    tbCustomerId?: number;
    tbEntityId?: number;
    tbEntityName?: string;
    dirty?: boolean;
    old?: IEntityLocation;
    deleted?: boolean;

    assign(props?: IEntityLocation): IEntityLocation;
    toResource(): any;
  }

  export class EntityLocation implements IEntityLocation {
    id: number;
    pixelX: number;
    pixelY: number;
    tbCustomerId: number;
    tbEntityId: number;
    tbEntityName: string;
    dirty: boolean;
    old: IEntityLocation;
    deleted: boolean;
    constructor(
      public props?: IEntityLocation|any,
    ) {
      if (typeof props === 'object') {
        this.id = props.id ? props.id : null;
        this.pixelX = props.pixelX ? props.pixelX : null;
        this.pixelY = props.pixelY ? props.pixelY : null;
        this.tbCustomerId = props.tbCustomerId ? props.tbCustomerId : null;
        this.tbEntityId = props.tbEntityId ? props.tbEntityId : null;
        this.tbEntityName = props.tbEntityName ? props.tbEntityName : null;
        this.dirty = props.dirty ? props.dirty : false;
        this.old = props.old ? props.old : null;
        this.deleted = props.deleted ? props.deleted : false;
      }
    }

    assign(props?: IEntityLocation) {
      if (props) {
        for (const i in props) {
          if (typeof this[i] !== 'undefined') {
            this[i] = props[i];
          }
        }
      }
      return this;
    }

    toResource(): any {
      const {
        id, pixelX, pixelY, tbCustomerId, tbEntityId, tbEntityName
      }: IEntityLocation = this;
      return {id, pixelX, pixelY, tbCustomerId, tbEntityId, tbEntityName};
    }
  }
