import {EntityTypes} from './../../entities/loai-khu-vuc/loai-khu-vuc.model';
import {DASHBOARD_IMAGE_DIR} from './../../app.constants';
import {ShapeType} from './../../shared/shape/shape';
import {DashboardHouseDetailDialogComponent} from './../dashboard-house-detail-dialog/dashboard-house-detail-dialog.component';
import {MatDialog} from '@angular/material';
import {DonViUser, CustomerSlug} from './../../entities/don-vi/don-vi.model';
import {IKhuVuc} from './../../entities/khu-vuc/khu-vuc.model';
import {Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges} from '@angular/core';

@Component({
  selector: 'app-dashboard-image',
  templateUrl: './dashboard-image.component.html',
  styleUrls: ['./dashboard-image.component.scss']
})
export class DashboardImageComponent implements OnChanges, AfterViewInit {
  @Input() customer: DonViUser;
  @Input() entities: IKhuVuc[];
  shapeTypes = ShapeType;
  @ViewChild('shapeStage') shapeStage: ElementRef;
  stage: {x?: any, y?: any};
  dashboardImageSrc: string;
  customerSlug: CustomerSlug;
  entityTypes = EntityTypes;
  constructor(
    private dialog: MatDialog
  ) {
    this.stage = {};
    this.customerSlug = CustomerSlug.DEFAULT;
    this.dashboardImageSrc = `${DASHBOARD_IMAGE_DIR}/${this.customerSlug}.png`;
  }

  ngOnChanges() {
    let imageType = 'jpg';
    if (this.customer.slugName) {
      switch (this.customer.slugName) {
        case CustomerSlug.OXANH:
          imageType = 'jpg';
          break;
      }
      // this.dashboardImageSrc = `${DASHBOARD_IMAGE_DIR}/${this.customer.slugName}.${imageType}`;
      this.dashboardImageSrc = `${DASHBOARD_IMAGE_DIR}/Oxanh.jpg`;
    }
  }

  calcStage(e: Event) {
    const x = this.shapeStage.nativeElement.offsetWidth || this.shapeStage.nativeElement.width;
    const y = this.shapeStage.nativeElement.offsetHeight || this.shapeStage.nativeElement.height;
    this.stage = {x, y};
  }

  ngAfterViewInit() {
  }

  openHouseDetailDialog(house: IKhuVuc) {
    if (house.tbCropDTOS && !house.tbCropDTOS.length) {
      return;
    }
    const dialogRef = this.dialog.open(DashboardHouseDetailDialogComponent, {
      width: '300px',
      disableClose: false,
    });
    dialogRef.componentInstance.data = house;
    return dialogRef.afterClosed();
  }
}
