import {LayoutModule} from '@angular/cdk/layout';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Injector, NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  LocalStorageService,
  NgxWebstorageModule,
  SessionStorageService,
} from 'ngx-webstorage';
import {JhiEventManager} from 'ng-jhipster';
import {MatBadgeModule} from '@angular/material/badge';
// @ts-ignore
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  ErrorComponent,
  FooterComponent,
  PageRibbonComponent,
  NotificationPopupComponent,
  NotificationDialogComponent,
  CanhTacControlEnvironmentDialogComponent,
  CanhTacControlEnvironmentPopupComponent,
} from './layouts';
import {SharedModule} from './shared';
import {CoreModule} from './core';
import {AccountModule} from './account/account.module';
import {EntityModule} from './entities/entity.module';
import {NotificationInterceptor} from './blocks/interceptor/notification.interceptor';
import {ErrorHandlerInterceptor} from './blocks/interceptor/errorhandler.interceptor';
import {AuthExpiredInterceptor} from './blocks/interceptor/auth-expired.interceptor';
import {AuthInterceptor} from './blocks/interceptor/auth.interceptor';
import {HomeModule} from './home';
import {LoadingScreenInterceptor} from './blocks/interceptor/loading-screen.interceptor';
import {SidebarComponent} from './layouts/sidebar/sidebar.component';
import {AdminModule} from './admin/admin.module';
import {UnitRegisterModule} from './unit';

// setup firebase
import {AngularFireModule} from '@angular/fire';
import {AngularFireAnalyticsModule} from '@angular/fire/analytics';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
import {AngularFireMessagingModule} from '@angular/fire/messaging';

import {environment} from '../environments/environment';
import {ChartsModule} from 'ng2-charts';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
@NgModule({
  declarations: [
    AppComponent,
    NotificationPopupComponent,
    NotificationDialogComponent,
    CanhTacControlEnvironmentDialogComponent,
    CanhTacControlEnvironmentPopupComponent,
    ErrorComponent,
    PageRibbonComponent,
    FooterComponent,
    SidebarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    LayoutModule,
    NgxWebstorageModule.forRoot({prefix: 'pmw', separator: '-'}),
    SharedModule,
    CoreModule,
    HomeModule,
    AccountModule,
    EntityModule,
    AdminModule,
    UnitRegisterModule,
    MatBadgeModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    ChartsModule,
    InfiniteScrollModule,
    MatSlideToggleModule,
  ],
  entryComponents: [
    NotificationPopupComponent,
    NotificationDialogComponent,
    CanhTacControlEnvironmentDialogComponent,
    CanhTacControlEnvironmentPopupComponent,
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [LocalStorageService, SessionStorageService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
      deps: [Injector],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
      deps: [JhiEventManager],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationInterceptor,
      multi: true,
      deps: [Injector],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      deps: [Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
