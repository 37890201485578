import {Component, OnInit} from '@angular/core';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {IHarvest} from '../../canh-tac/hanh-dong.model';
import {ThuHoachService} from '../thu-hoach.service';
import {JhiAlertService, JhiParseLinks} from 'ng-jhipster';
import {ActivatedRoute} from '@angular/router';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from 'src/app/shared';
import {HttpResponse} from '@angular/common/http';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-thu-hoach',
  templateUrl: './thu-hoach.component.html',
  styleUrls: ['./thu-hoach.component.scss'],
})
export class ThuHoachComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'cropId',
    'seedling',
    'totalQuantity',
    'totalInStock',
    'executeDate',
    'action',
  ];
  dataSource: any;
  pageSizeOptions: number[];
  ELEMENT_DATA: IHarvest[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  currentSearch: string;
  routeData: any;
  noData: boolean;
  constructor(
    private thuHoachService: ThuHoachService,
    private alertService: JhiAlertService,
    private parseLinks: JhiParseLinks,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    this.dataSource = null;
    this.ELEMENT_DATA = [];
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.routeData = this.activatedRoute.data.subscribe(({pagingParams}) => {
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
    });

    this.currentSearch =
      this.activatedRoute.snapshot &&
      this.activatedRoute.snapshot.params['search']
        ? this.activatedRoute.snapshot.params['search']
        : '';
  }

  ngOnInit() {
    this.titleService.setTitle('Danh sách thu hoạch');
    this.loadAll();
  }

  loadAll() {
    if (this.currentSearch) {
      this.thuHoachService
        .search({
          page: this.page - 1,
          query: this.currentSearch,
          size: this.itemsPerPage,
          sort: this.sort(),
        })
        .subscribe(
          (res: HttpResponse<IHarvest[]>) =>
            this.onSuccess(res.body, res.headers),
          (res: HttpResponse<any>) => this.onError(res.body),
        );
      return;
    }
    this.thuHoachService
      .query({
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: this.sort(),
      })
      .subscribe(
        (res: HttpResponse<IHarvest[]>) =>
          this.onSuccess(res.body, res.headers),
        (res: HttpResponse<any>) => this.onError(res.body),
      );
  }

  private sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }

  clear() {
    this.page = 1;
    this.currentSearch = '';
    this.loadAll();
  }

  search(query: any) {
    if (!query) {
      return this.clear();
    }

    this.page = 1;
    this.currentSearch = query;

    this.loadAll();
  }

  private onSuccess(data: any, headers: any) {
    this.links = this.parseLinks.parse(headers.get('link'));
    this.totalItems = headers.get('X-Total-Count');
    this.queryCount = this.totalItems;
    this.ELEMENT_DATA = data;
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    if (data.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
  }

  private onError(error: any) {
    if (error !== undefined) {
      this.alertService.error(error.error, error.message, null);
    }
  }
}
