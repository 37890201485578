import {Component, OnInit} from '@angular/core';
import {IGiong} from '../giong/giong.model';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../shared/services/notification.service';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from '../../shared';
import {HttpResponse} from '@angular/common/http';
import {PageEvent} from '@angular/material';

@Component({
  selector: 'app-nortification',
  templateUrl: './nortification.component.html',
  styleUrls: ['./nortification.component.scss'],
})
export class NortificationComponent implements OnInit {
  dataSource: any;
  pageSizeOptions: number[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  routeData: any;
  eventSubscriber: Subscription;
  constructor(
    private notificationService: NotificationService,
    private alertService: JhiAlertService,
    private activatedRoute: ActivatedRoute,
    private parseLinks: JhiParseLinks,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private router: Router,
  ) {
    this.dataSource = null;
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.routeData = this.activatedRoute.data.subscribe(({pagingParams}) => {
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Danh sách thông báo');
    this.loadAll();
  }
  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }
  loadAll() {
    this.notificationService
      .getListNotification({
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: ['sendDate,DESC'],
      })
      .subscribe(
        (res: HttpResponse<IGiong[]>) => this.onSuccess(res.body, res.headers),
        (res: HttpResponse<any>) => this.onError(res.body),
      );
  }
  private onSuccess(data: any, headers: any) {
    this.links = this.parseLinks.parse(headers.get('link'));
    this.totalItems = headers.get('X-Total-Count');
    this.queryCount = this.totalItems;
    this.dataSource = data.tbNotificationDTOS;
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }

  redirectTo(noti) {
    this.notificationService
      .readNotification({
        id: noti.id,
        isRead: 1,
      })
      .subscribe();
    if (noti.contents === 'PIC_UPDATE') {
      this.router.navigate(['/chi-tiet-lo', noti.tbCropId]);
    } else if (['ACTIVITY_CREATE', 'ACTIVITY_UPDATE'].includes(noti.contents)) {
      this.router.navigate(['/chi-tiet-hanh-dong', noti.activityTypeId, noti.externalId]);
    } else {
      this.router.navigate(['/env-param', noti.tbEntityId]);
    }
  }
}
