import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {CanhTacService} from '../../canh-tac.service';
import {Crops} from '../../canh-tac.model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-canh-tac-mgmt-delete-person-dialog',
  templateUrl: './canh-tac-delete-person-dialog.component.html',
})
export class CanhTacMgmtDeletePersonDialogComponent implements OnInit {
  lo: Crops;
  userId: any;

  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }

  constructor(
    private dialogRef: MatDialogRef<CanhTacMgmtDeletePersonDialogComponent>,
    private canhTacService: CanhTacService,
    private eventManager: JhiEventManager,
  ) {}
  ngOnInit() {}
  confirmDelete() {
    const valueCurent = [];
    this.lo.tbDetailUsers.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.id != this.userId) {
        valueCurent.push(element.id);
      }
    });
    const valueUpdate = {
      cropId: this.lo.id,
      detailUserIds: valueCurent,
    };
    this.canhTacService.updateCuratorUser(valueUpdate).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'loDetailModification',
        content: 'Lo',
      });
      this.dialogRef.close(true);
    });
  }
}

@Component({
  selector: 'app-canh-tac-mgmt-delete-person-popup',
  template: '',
  styleUrls: ['./canh-tac-delete-person-dialog.component.scss'],
})
export class CanhTacMgmtDeletePersonPopupComponent
  implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<CanhTacMgmtDeletePersonDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa người phụ trách');
    this.activatedRoute.data.subscribe(({lo, userId}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(
          CanhTacMgmtDeletePersonDialogComponent,
          {
            disableClose: true,
            width: '380px',
          },
        );
        this.dialogRef.componentInstance.lo = lo;
        this.dialogRef.componentInstance.userId = userId;

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách lô');
    this.dialogRef = null;
  }
}
