import {DonViUser} from './../../entities/don-vi/don-vi.model';
import {IKhuVuc} from './../../entities/khu-vuc/khu-vuc.model';
import {Component, OnInit, Input} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  /** Based on the screen size, switch from standard to one column per row */
  @Input() customer: DonViUser;
  @Input() blocks: IKhuVuc[];
  @Input() warehouses: IKhuVuc[];
  @Input() houses: IKhuVuc[];
  constructor() {
  }

  ngOnInit() {
  }

  findColor(color: number) {
    if (color === 0) {
      return 'red';
    } else if (color === 1) {
      return 'purple';
    } else if (color === 2) {
      return '#ffc000';
    } else if (color === 3) {
      return 'blue';
    } else if (color === 4) {
      return 'green';
    } else {
      return 'purple';
    }
  }

  findName(allname: any) {
    if (allname) {
      const name = [];
      if (Array.isArray(allname)) {
        allname.forEach((x) => {
          name.push(x.fullName);
        });
        return name;
      } else {
        return allname;
      }
    }
  }
}
