import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JhiEventManager} from 'ng-jhipster';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ThietBiService} from '../thiet-bi.service';

@Component({
  selector: 'app-danh-muc-thiet-bi-delete-dialog',
  templateUrl: './danh-muc-thiet-bi-delete-dialog.component.html',
  styleUrls: ['./danh-muc-thiet-bi-delete-dialog.component.scss'],
})
export class DanhMucThietBiDeleteDialogComponent {
  categoryID: any;
  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }
  constructor(
    private dialogRef: MatDialogRef<DanhMucThietBiDeleteDialogComponent>,
    private eventManager: JhiEventManager,
    private thietBiService: ThietBiService,
  ) {}

  confirmDelete() {
    this.thietBiService
      .deleteCategoryDevice(this.categoryID)
      .subscribe((response) => {
        this.eventManager.broadcast({
          name: 'categoryDeviceModification',
          content: 'Thietbi',
        });
        this.dialogRef.close(true);
      });
  }
}

@Component({
  selector: 'app-xoa-danh-muc',
  template: '',
})
export class DanhMucThietBiDeletePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<DanhMucThietBiDeleteDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog,
  ) {}
  ngOnInit() {
    this.titleService.setTitle('Xóa danh mục thiết bị');
    this.activatedRoute.params.subscribe((param) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(DanhMucThietBiDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        this.dialogRef.componentInstance.categoryID = param.category;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.dialogRef = null;
    this.titleService.setTitle('Danh mục thiết bị');
  }
}
