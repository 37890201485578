import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {IGuidelineDetail} from '../tieu-chuan.model';
import {JhiEventManager} from 'ng-jhipster';
import {TieuChuanService} from '../tieu-chuan.service';

@Component({
  selector: 'app-activity-type-delete-dialog',
  templateUrl: './activity-type-delete-dialog.component.html',
})
export class ActivityTypeDeleteDialogComponent {
  guideline: IGuidelineDetail;

  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }
  constructor(
    private dialogRef: MatDialogRef<ActivityTypeDeleteDialogComponent>,
    private eventManager: JhiEventManager,
    private tieuChuanService: TieuChuanService,
  ) {}

  confirmDelete(id: any, name: string) {
    this.tieuChuanService.deleteActivityGuidelineDetail(id, name).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'activityDetailModification',
        content: 'guideline',
      });
      this.dialogRef.close(true);
    });
  }
}

@Component({
  selector: 'app-activity-type-delete-popup',
  template: '',
  styleUrls: ['./activity-type-delete-dialog.component.scss'],
})
export class ActivityTypeDeletePopupDialogComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ActivityTypeDeleteDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa chi tiết hoạt động');
    this.activatedRoute.data.subscribe(({guideline}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(ActivityTypeDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        this.dialogRef.componentInstance.guideline = guideline;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }
  ngOnDestroy() {
    this.titleService.setTitle('Chi tiết quy trình');
    this.dialogRef = null;
  }
}
