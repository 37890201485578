import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {Product} from '../san-pham.model';
import {JhiEventManager} from 'ng-jhipster';
import {SanPhamService} from '../san-pham.service';

@Component({
  selector: 'app-san-pham-delete-dialog',
  templateUrl: './san-pham-delete-dialog.component.html',
})
export class SanPhamDeleteDialogComponent implements OnInit {
  standard: Product;

  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }
  constructor(
    private dialogRef: MatDialogRef<SanPhamDeleteDialogComponent>,
    private eventManager: JhiEventManager,
    private sanPhamService: SanPhamService,
  ) { }

  ngOnInit() {
  }
  confirmDelete(id: any) {
    this.sanPhamService.delete(id).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'sanPhamListModification',
        content: 'SanPham',
      });
      this.dialogRef.close(true);
    });
  }

}

@Component({
  selector: 'app-san-pham-delete-dialog',
  template: '',
})
export class SanPhamDeletePopupDialogComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<SanPhamDeleteDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Xóa sản phẩm');
    this.activatedRoute.data.subscribe(({sanPham}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(SanPhamDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        this.dialogRef.componentInstance.standard = sanPham;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }
  ngOnDestroy() {
    this.titleService.setTitle('Danh sách sản phẩm');
    this.dialogRef = null;
  }

}
