import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IActivityType, ActivityType } from '../activity.model';
import { ActivityService } from '../activity.service';
import { JhiEventManager } from 'ng-jhipster';
import { Title } from '@angular/platform-browser';
import {DOMAIN_IMAGE_URL} from '../../../shared/constants/domain';

@Component({
  selector: 'app-activity-type-dialog',
  templateUrl: './activity-type-dialog.component.html',
  styleUrls: ['./activity-type-dialog.component.scss']
})
export class ActivityTypeDialogComponent implements OnInit {
  activityType: IActivityType;
  isSaving: boolean;
  fileIcon: any;
  fileDisplay = null;
  constructor(
    private dialogRef: MatDialogRef<ActivityTypeDialogComponent>,
    private typeService: ActivityService,
    private eventManager: JhiEventManager,
    private titleService: Title,

  ) {
    this.activityType = new ActivityType;
    this.isSaving = false;
  }

  ngOnInit() {
    if (this.activityType.id !== null) {
      this.titleService.setTitle('Sửa loại hoạt động');
    } else {
      this.titleService.setTitle('Thêm loại hoạt động');
    }
  }

  upload(e) {
    const reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      this.fileDisplay = reader.result;
      this.fileIcon = e.target.files[0];
    };
  }
  save() {
    this.isSaving = true;
    const data = new FormData();
    data.append('strTBActivityTypeDTO', JSON.stringify(this.activityType));
    if (this.fileIcon) {
        data.append('images', this.fileIcon, this.fileIcon.name);
    }
    if (this.activityType.id !== null) {
      this.typeService.update(data).subscribe(response => this.onSaveSuccess(response), () => this.onSaveError());
    } else {
      this.typeService.create(data).subscribe(response => this.onSaveSuccess(response), () => this.onSaveError());
    }
  }

  private onSaveSuccess(result: any) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'activityTypeListModification',
      content: 'activityType'
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }

}

@Component({
  selector: 'app-don-vi-popup',
  template: ''
})
export class ActivityTypePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ActivityTypeDialogComponent>;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ activityType }) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(ActivityTypeDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        if (activityType !== undefined) {
          this.dialogRef.componentInstance.activityType = activityType;
          this.dialogRef.componentInstance.fileDisplay = DOMAIN_IMAGE_URL + activityType.urlLogo;
        }

        this.dialogRef.afterClosed().subscribe(
          result => {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
          },
          reason => {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
          }
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách activity');
    this.dialogRef = null;
  }
}
