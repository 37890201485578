import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IActivityParam, ActivityParam } from '../activity.model';
import { ActivityService } from '../activity.service';
import { JhiEventManager } from 'ng-jhipster';
import { Title } from '@angular/platform-browser';
import {DOMAIN_IMAGE_URL} from '../../../shared/constants/domain';

@Component({
  selector: 'app-activity-type-param-dialog',
  templateUrl: './activity-type-param-dialog.component.html',
  styleUrls: ['./activity-type-param-dialog.component.scss']
})
export class ActivityTypeParamDialogComponent implements OnInit {
  activityParam: IActivityParam;
  isSaving: boolean;
  controlType: any;
  optionUnit: any;
  constructor(
    private dialogRef: MatDialogRef<ActivityTypeParamDialogComponent>,
    private activityService: ActivityService,
    private eventManager: JhiEventManager,
    private titleService: Title,

  ) {
    this.activityParam = new ActivityParam;
    this.isSaving = false;
    this.controlType = [];
    this.optionUnit = [];
  }

  ngOnInit() {
    if (this.activityParam.id !== null) {
      this.titleService.setTitle('Sửa param');
    } else {
      this.titleService.setTitle('Thêm param');
    }
    this.getControlType();
    this.getOptionUnit();
  }

  save() {
    this.isSaving = true;
    if (this.activityParam.id !== null) {
      this.activityService.updateActivityParam(this.activityParam).subscribe(response => this.onSaveSuccess(response), () => this.onSaveError());
    } else {
      this.activityService.createActivityParam(this.activityParam).subscribe(response => this.onSaveSuccess(response), () => this.onSaveError());
    }
  }
  getOptionUnit() {
    this.activityService.getOptionUnit().subscribe((response) => {
      this.optionUnit = response.body;
    });
  }
  getControlType() {
    this.activityService.getControlTypeOption('param').subscribe((response) => {
      this.controlType = response.body;
    });
  }
  private onSaveSuccess(result: any) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'activityParamListModification',
      content: 'activityType'
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }

}

@Component({
  selector: 'app-activity-param-popup',
  template: ''
})
export class ActivityTypeParamPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ActivityTypeParamDialogComponent>;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ activityId, activityParam }) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(ActivityTypeParamDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        if (activityParam !== undefined) {
          this.dialogRef.componentInstance.activityParam = activityParam;
          this.dialogRef.componentInstance.activityParam.activityTypeId = activityId;
        }

        this.dialogRef.afterClosed().subscribe(
          result => {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
          },
          reason => {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
          }
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách activity');
    this.dialogRef = null;
  }
}
