import {VatTuModule} from './../vat-tu/vat-tu.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared';
import {RouterModule, Routes} from '@angular/router';

import {GiongComponent, GiongPopupRoute, giongRoute} from './';
import {
  GiongDialogComponent,
  GiongPopupComponent,
} from './giong-dialog/giong-dialog.component';
import {
  GiongMgmtDeleteDialogComponent,
  GiongMgmtDeletePopupComponent,
} from './giong-delete-dialog/giong-delete-dialog.component';
import {
  GiongImportComponent,
  GiongImportPopupComponent,
} from './giong-import/giong-import.component';
import {GiongDetailComponent} from './giong-detail/giong-detail.component';
import {
  GiongStorageDialogComponent,
  GiongStoragePopupComponent,
} from './giong-storage-dialog/giong-storage-dialog.component';
import {
  GiongDeleteHistoryDialogComponent,
  GiongDeleteHistoryPopupComponent,
} from './giong-delete-history-dialog/giong-delete-history-dialog.component';

const ENTITY_STATES = [...giongRoute, ...GiongPopupRoute];

@NgModule({
  declarations: [
    GiongComponent,
    GiongDialogComponent,
    GiongPopupComponent,
    GiongMgmtDeleteDialogComponent,
    GiongMgmtDeletePopupComponent,
    GiongImportComponent,
    GiongImportPopupComponent,
    GiongDetailComponent,
    GiongStorageDialogComponent,
    GiongStoragePopupComponent,
    GiongDeleteHistoryDialogComponent,
    GiongDeleteHistoryPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
    VatTuModule,
  ],
  entryComponents: [
    GiongDialogComponent,
    GiongPopupComponent,
    GiongMgmtDeleteDialogComponent,
    GiongMgmtDeletePopupComponent,
    GiongImportComponent,
    GiongImportPopupComponent,
    GiongStoragePopupComponent,
    GiongStorageDialogComponent,
    GiongDeleteHistoryPopupComponent,
    GiongDeleteHistoryDialogComponent,
  ],
})
export class GiongModule {}
