import {EntityLocationModule} from './entity-location/entity-location.module';
import {ProductModule} from './product/product.module';
import {KhuVucModule} from './khu-vuc/khu-vuc.module';
import {NgModule} from '@angular/core';

import {AccountModule} from './account/account.module';
import {CanhTacModule} from './canh-tac/canh-tac.module';
import {ThuHoachModule} from './thu-hoach/thu-hoach.module';
import {GiongModule} from './giong/giong.module';
import {VatTuModule} from './vat-tu/vat-tu.module';
import {BaoCaoModule} from './bao-cao/bao-cao.module';
import {QrCodeModule} from './qr-code/qr-code.module';
import {TieuChuanModule} from './tieu-chuan/tieu-chuan.module';
import {ThietBiModule} from './thiet-bi/thiet-bi.module';
import {ThongSoMoiTruongModule} from './thong-so-moi-truong/thong-so-moi-truong.module';
import {NortificationModule} from './nortification/nortification.module';
import {DonViModule} from './don-vi/don-vi.module';
import {ActivityModule} from './activity/activity.module';
import {SanPhamModule} from './san-pham/san-pham.module';
import {ExampleStampModule} from './example-stamp/example-stamp.module';

@NgModule({
  imports: [
    AccountModule,
    ThuHoachModule,
    GiongModule,
    VatTuModule,
    BaoCaoModule,
    CanhTacModule,
    QrCodeModule,
    TieuChuanModule,
    ThietBiModule,
    ThongSoMoiTruongModule,
    NortificationModule,
    DonViModule,
    KhuVucModule,
    ActivityModule,
    ProductModule,
    SanPhamModule,
    EntityLocationModule,
    ExampleStampModule
  ],
  declarations: [],
  entryComponents: [],
  providers: [],
})
export class EntityModule {}
