import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ThietBiService} from '../thiet-bi.service';
import {JhiEventManager} from 'ng-jhipster';

@Component({
  selector: 'app-thiet-bi-delete-dialog',
  templateUrl: './thiet-bi-delete-dialog.component.html',
  styleUrls: ['./thiet-bi-delete-dialog.component.scss'],
})
export class ThietBiDeleteDialogComponent {
  thietbiId: any;
  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }
  constructor(
    private dialogRef: MatDialogRef<ThietBiDeleteDialogComponent>,
    private thietBiService: ThietBiService,
    private eventManager: JhiEventManager,
  ) {}

  confirmDelete() {
    this.thietBiService.delete(this.thietbiId).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'deviceListModification',
        content: 'Thietbi',
      });
      this.dialogRef.close(true);
    });
  }
}

@Component({
  selector: 'app-delete-thiet-bi',
  template: '',
})
export class ThietBiDeletePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ThietBiDeleteDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa thiết bị');
    this.activatedRoute.params.subscribe((param) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(ThietBiDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        this.dialogRef.componentInstance.thietbiId = param.thietbi;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {}
}
