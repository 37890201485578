import {LeafletLayer, ILeafletLayer} from './../../shared/leaflet/leaflet';
import {IKhuVuc} from './../../entities/khu-vuc/khu-vuc.model';
import {DonViUser} from './../../entities/don-vi/don-vi.model';
import {Component, OnInit, Input, ViewChild, ElementRef, EventEmitter} from '@angular/core';

// import * as L from '../../../assets/libs/leaflet/leaflet';
import * as L from 'leaflet';
import { IEntityLocation } from 'src/app/entities/entity-location';

@Component({
  selector: 'app-dashboard-map',
  templateUrl: './dashboard-map.component.html',
  styleUrls: [
    './dashboard-map.component.scss',
    // '../../../assets/scss/map.scss',
  ]
})
export class DashboardMapComponent implements OnInit {
  @Input() entityLocations: IEntityLocation[];
  // @Input() blocks: IKhuVuc[];
  // @Input() warehouses: IKhuVuc[];

  map: {
    setViewMap?: EventEmitter<any>,
    drawCircle?: EventEmitter<any>,
    // drawGeoJson?: EventEmitter<any>,
  } = {
    setViewMap: new EventEmitter(),
    drawCircle: new EventEmitter(),
    // drawGeoJson: new EventEmitter(),
  };
  selectedMapLayer: any;
  panToMap: EventEmitter<any>;
  constructor() {
    this.panToMap = new EventEmitter();
    this.selectedMapLayer = new LeafletLayer();
  }

  ngOnInit() {
    this.panToMap.subscribe((point: IEntityLocation) =>  this.map.setViewMap.emit(point));
  }

  targetMap(lat: string, lng: string) {
    this.panToMap.emit({lat, lng});
  }

  mapLoaded(e) {
    // console.log(e);
    this.drawMapShapes();
  }

  drawMapShapes() {
    this.map.drawCircle.emit(
      this.entityLocations
    )
    // this.map.drawPolyline.emit([
    //   [12.291138, 108.950326],
    //   [12.291166, 108.951900],
    //   [12.288052, 108.951957],
    //   [12.287932, 108.950292],
    //   [12.288101, 108.948425],
    //   [12.288925, 108.948819],
    //   [12.289376, 108.950239],
    //   [12.291138, 108.950326],
    // ]);

    // const json = [{
    //   'type': 'Feature',
    //   'properties': {
    //     'name': 'Net houses A',
    //     'popup': {
    //       "content": "This is Net houses A!",
    //       'params': {a: 1, b: 2},
    //       // 'component': ShapePopoverComponent,
    //     },
    //   },
    //   'geometry': {
    //     'type': 'Polygon',
    //     'coordinates': [[
    //       [108.951253, 12.291117],
    //       [108.951906, 12.291137],
    //       [108.951882, 12.290602],
    //       [108.951249, 12.290610],
    //       [108.951253, 12.291117],
    //     ]],
    //   }
    // }, {
    //   'type': 'Feature',
    //   'properties': {
    //     'name': 'Water Pond',
    //     'popup': {
    //       "content": "This is Water Pond!",
    //       'params': {a: 1, b: 2},
    //       // 'component': ShapePopoverComponent,
    //     },
    //   },
    //   'geometry': {
    //     'type': 'Point',
    //     'coordinates': [108.951366, 12.2902915]
    //   }
    // }];
    // this.map.drawGeoJson.emit(json);
  }

  mapPopupOpen(e) {

  }

  mapLayerClick(layer: ILeafletLayer) {
    this.selectedMapLayer = layer;
  }

  mapSaveLayerPointChanges() {
    this.selectedMapLayer.updateFeaturePoints();
  }
}
