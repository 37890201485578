import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {CanhTacService} from '../canh-tac.service';
import {Lo} from '../canh-tac.model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-canh-tac-mgmt-delete-dialog',
  templateUrl: './canh-tac-delete-dialog.component.html',
})
export class CanhTacMgmtDeleteDialogComponent {
  lo: Lo;

  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }

  constructor(
    private dialogRef: MatDialogRef<CanhTacMgmtDeleteDialogComponent>,
    private canhTacService: CanhTacService,
    private eventManager: JhiEventManager,
  ) {}

  confirmDelete(loId: any) {
    this.canhTacService.delete(loId).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'loListModification',
        content: 'Lo',
      });
      this.dialogRef.close(true);
    });
  }
}

@Component({
  selector: 'app-canh-tac-mgmt-delete-popup',
  template: '',
  styleUrls: ['./canh-tac-delete-dialog.component.scss'],
})
export class CanhTacMgmtDeletePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<CanhTacMgmtDeleteDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa lô');
    this.activatedRoute.data.subscribe(({lo}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(CanhTacMgmtDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });

        this.dialogRef.componentInstance.lo = lo;

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách lô');
    this.dialogRef = null;
  }
}
