import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared';
import {RouterModule, Routes} from '@angular/router';

import {ThuHoachComponent, ChiTietThuHoachComponent, thuHoachRoute} from './';

const ENTITY_STATES = [...thuHoachRoute];

@NgModule({
  declarations: [ThuHoachComponent, ChiTietThuHoachComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
  ],
})
export class ThuHoachModule {}
