import {
  Routes,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {
  VatTuPopupComponent,
} from './vat-tu-dialog/vat-tu-dialog.component';
import {VatTuComponent} from './vat-tu/vat-tu.component';
import {VatTuMgmtDeletePopupComponent} from './vat-tu-delete-dialog/vat-tu-delete-dialog.component';
import {Injectable} from '@angular/core';
import {VatTuService} from './vat-tu.service';
import {HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  IVatTu,
  VatTu,
  IVatTuDetail,
  VatTuDetail,
  SupplyBlockDetails,
  ISupplyBlockDetails,
} from './vat-tu.model';
import {VatTuImportPopupComponent} from './vat-tu-import/vat-tu-import.component';
import {VatTuDetailComponent} from './vat-tu-detail/vat-tu-detail.component';
import {WarehouseStoragePopupComponent} from './warehouse-storage-dialog/warehouse-storage-dialog.component';
import {VatTuDeleteHistoryPopupComponent} from './vat-tu-delete-history-dialog/vat-tu-delete-history-dialog.component';

@Injectable({providedIn: 'root'})
export class VatTuManagementResolve implements Resolve<IVatTu> {
  constructor(private service: VatTuService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['vattu'] ? route.params['vattu'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((vattu: HttpResponse<IVatTu>) => vattu.body));
    }
    return of(new VatTu());
  }
}

@Injectable({providedIn: 'root'})
export class VatTuDetailResolve implements Resolve<IVatTuDetail> {
  constructor(private service: VatTuService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['vattu'] ? route.params['vattu'] : null;
    if (id) {
      return this.service
        .findById(id)
        .pipe(map((vattu: HttpResponse<IVatTuDetail>) => vattu.body));
    }
    return of(new VatTuDetail());
  }
}

@Injectable({providedIn: 'root'})
export class SupplyBlockDetailsResolve implements Resolve<ISupplyBlockDetails> {
  constructor(private service: VatTuService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.queryParamMap.get('blockDetailsId');
    if (id) {
      return this.service
        .findByBlockDetailsId(id)
        .pipe(map((vattu: HttpResponse<ISupplyBlockDetails>) => new SupplyBlockDetails()
          .assign(vattu.body)));
    }
    return of(new SupplyBlockDetails());
  }
}

export const vatTuRoute: Routes = [
  {
    path: 'danh-sach-vat-tu',
    component: VatTuComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_list_supplies'],
      pageTitle: 'Danh sách vật tư',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'chi-tiet-vat-tu/:vattu',
    component: VatTuDetailComponent,
    resolve: {
      vatTu: VatTuDetailResolve,
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_detail_supplies'],
      pageTitle: 'Chi tiết vật tư',
    },
    canActivate: [UserRouteAccessService],
  },
];

export const VatTuPopupRoute: Routes = [
  {
    path: 'them-vat-tu',
    component: VatTuPopupComponent,
    data: {
      authorities: ['can_create_supplies'],
      pageTitle: 'Thêm vật tư',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'import-vat-tu',
    component: VatTuImportPopupComponent,
    data: {
      authorities: ['can_create_supplies'],
      pageTitle: 'Import vật tư',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'sua-vat-tu/:vattu',
    component: VatTuPopupComponent,
    resolve: {
      vattu: VatTuManagementResolve,
    },
    data: {
      authorities: ['can_update_supplies'],
      pageTitle: 'Sửa vật tư',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-vat-tu/:vattu',
    component: VatTuMgmtDeletePopupComponent,
    resolve: {
      vattu: VatTuManagementResolve,
    },
    data: {
      authorities: ['can_delete_supplies'],
      pageTitle: 'Xóa vật tư',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'cap-nhat-vat-tu-luu-kho/:vattu',
    component: WarehouseStoragePopupComponent,
    resolve: {
      vattu: VatTuManagementResolve,
      block: SupplyBlockDetailsResolve,
    },
    data: {
      authorities: ['can_create_warehouse_supplies'],
      pageTitle: 'Cập nhật số lượng vật tư lưu kho',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-lich-su-vat-tu/:vattu',
    component: VatTuDeleteHistoryPopupComponent,
    data: {
      authorities: ['can_delete_warehouse_supplies'],
      pageTitle: 'Xóa lịch sử vật tư lưu kho',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
];
