import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared';
import {RouterModule, Routes} from '@angular/router';

import {VatTuComponent, VatTuPopupRoute, vatTuRoute} from './';
import {
  VatTuDialogComponent,
  VatTuPopupComponent,
} from './vat-tu-dialog/vat-tu-dialog.component';
import {
  VatTuMgmtDeleteDialogComponent,
  VatTuMgmtDeletePopupComponent,
} from './vat-tu-delete-dialog/vat-tu-delete-dialog.component';
import {
  VatTuImportComponent,
  VatTuImportPopupComponent,
} from './vat-tu-import/vat-tu-import.component';
import {VatTuDetailComponent} from './vat-tu-detail/vat-tu-detail.component';
import {
  WarehouseStorageDialogComponent,
  WarehouseStoragePopupComponent,
} from './warehouse-storage-dialog/warehouse-storage-dialog.component';
import {
  VatTuDeleteHistoryDialogComponent,
  VatTuDeleteHistoryPopupComponent,
} from './vat-tu-delete-history-dialog/vat-tu-delete-history-dialog.component';
import { VatTuBlockDetailsComponent } from './vat-tu-block-details/vat-tu-block-details.component';

const ENTITY_STATES = [...vatTuRoute, ...VatTuPopupRoute];

@NgModule({
  declarations: [
    VatTuComponent,
    VatTuDialogComponent,
    VatTuPopupComponent,
    VatTuMgmtDeleteDialogComponent,
    VatTuMgmtDeletePopupComponent,
    VatTuImportComponent,
    VatTuImportPopupComponent,
    VatTuDetailComponent,
    WarehouseStorageDialogComponent,
    WarehouseStoragePopupComponent,
    VatTuDeleteHistoryDialogComponent,
    VatTuDeleteHistoryPopupComponent,
    VatTuBlockDetailsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
  ],
  entryComponents: [
    VatTuDialogComponent,
    VatTuMgmtDeleteDialogComponent,
    VatTuMgmtDeletePopupComponent,
    VatTuImportComponent,
    VatTuImportPopupComponent,
    WarehouseStoragePopupComponent,
    WarehouseStorageDialogComponent,
    VatTuDeleteHistoryDialogComponent,
    VatTuDeleteHistoryPopupComponent,
  ],
  exports: [
    VatTuBlockDetailsComponent,
  ],
})
export class VatTuModule {}
