import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';

import {UserRouteAccessService} from '../../core';
import {IUser, User, UserService} from '../../core';
import {VaiTroService} from 'src/app/entities/vai-tro/vai-tro.service';
import {IVaiTro, VaiTro} from 'src/app/entities/vai-tro/vai-tro.model';
import {UserMgmtComponent} from './user-management/user-management.component';
import {UserManagementUpdatePopupComponent} from './user-management-update/user-management-update.component';
import {UserRolePopupComponent} from './user-role-update/user-role-update.component';
import {UserMgmtDeletePopupComponent} from './user-management-delete-dialog/user-management-delete-dialog.component';
import {UserRoleDeletePopupComponent} from './user-role-delete-dialog/user-role-delete-dialog.component';
import {UserRoleComponent} from './user-role/user-role.component';

// @Injectable({providedIn: 'root'})
// export class UserResolve implements CanActivate {
//   constructor(private principal: Principal) {
//   }

//   canActivate() {
//     return this.principal.identity().then(account => this.principal.hasAnyAuthority(['ROLE_ADMIN']));
//   }
// }

@Injectable({providedIn: 'root'})
export class UserManagementResolve implements Resolve<IUser> {
  constructor(private service: UserService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['login'] ? route.params['login'] : null;
    if (id) {
      return this.service
        .findById(id)
        .pipe(map((user: HttpResponse<User>) => user.body));
    }
    return of(new User());
  }
}

@Injectable({providedIn: 'root'})
export class UserRoleResolve implements Resolve<IVaiTro> {
  constructor(private service: VaiTroService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((role: HttpResponse<VaiTro>) => role.body));
    }
    return of(new VaiTro());
  }
}

@Injectable({providedIn: 'root'})
export class UserAuthoritiesResolve implements Resolve<string[]> {
  constructor(private service: UserService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.service.authorities();
  }
}

export const userManagementRoute: Routes = [
  {
    path: 'danh-sach-nguoi-dung',
    component: UserMgmtComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_read_user'],
      pageTitle: 'Danh sách người dùng',
      defaultSort: 'id,asc',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'phan-quyen-nguoi-dung',
    component: UserRoleComponent,
    data: {
      authorities: ['can_read_role'],
      pageTitle: 'Danh sách phân quyền',
    },
    canActivate: [UserRouteAccessService],
  },
];

export const userManagementPopupRoute: Routes = [
  {
    path: 'them-nguoi-dung',
    component: UserManagementUpdatePopupComponent,
    resolve: {
      user: UserManagementResolve,
      // authorities: UserAuthoritiesResolve,
    },
    data: {
      authorities: ['can_create_user'],
      pageTitle: 'Thêm người dùng',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'sua-nguoi-dung/:login',
    component: UserManagementUpdatePopupComponent,
    resolve: {
      user: UserManagementResolve,
    },
    data: {
      authorities: ['can_update_user'],
      pageTitle: 'Sửa người dùng',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-nguoi-dung/:login',
    component: UserMgmtDeletePopupComponent,
    resolve: {
      user: UserManagementResolve,
    },
    data: {
      authorities: ['can_delete_user'],
      pageTitle: 'Xóa người dùng',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'them-vai-tro',
    component: UserRolePopupComponent,
    resolve: {
      role: UserRoleResolve,
    },
    data: {
      authorities: ['can_create_role'],
      pageTitle: 'Thêm vai trò',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'sua-vai-tro/:id',
    component: UserRolePopupComponent,
    resolve: {
      role: UserRoleResolve,
    },
    data: {
      authorities: ['can_update_role'],
      pageTitle: 'Sửa vai trò',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-vai-tro/:id',
    component: UserRoleDeletePopupComponent,
    resolve: {
      role: UserRoleResolve,
    },
    data: {
      authorities: ['can_delete_role'],
      pageTitle: 'Xóa vai trò',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
];
