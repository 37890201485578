import {Component, OnDestroy, OnInit} from '@angular/core';
import {JhiAlertService} from 'ng-jhipster';

@Component({
  selector: 'app-alert',
  template: ` <div class="alerts" role="alert">
    <div *ngFor="let alert of alerts" [ngClass]="setClasses(alert)">
      <div *ngIf="alert && alert.type && alert.msg" class="{{ alert.type }}}">
        <pre [innerHTML]="alert.msg"></pre>
      </div>
    </div>
  </div>`,
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  alerts: any[];

  constructor(private alertService: JhiAlertService) {}

  ngOnInit() {
    this.alerts = this.alertService.get();
  }

  setClasses(alert) {
    return {
      toast: !!alert.toast,
      [alert.position]: true,
    };
  }

  ngOnDestroy() {
    this.alerts = [];
  }
}
