import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {IPeriod, IStandardDateDetail, StandardDateDetail} from '../tieu-chuan.model';
import {TieuChuanService} from '../tieu-chuan.service';

@Component({
  selector: 'app-tieu-chuan-ngay-tuoi-dialog',
  templateUrl: './ngay-tuoi-dialog.component.html',
  styleUrls: ['./ngay-tuoi-dialog.component.scss'],
})
export class NgayTuoiDialogComponent implements OnInit {
  isSaving: boolean;
  standardDate: IStandardDateDetail;
  listPeriodDropdown: IPeriod[];
  constructor(
    private dialogRef: MatDialogRef<NgayTuoiDialogComponent>,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private tieuChuanService: TieuChuanService,
  ) {
    this.isSaving = false;
    this.standardDate = new StandardDateDetail();
  }

  ngOnInit() {
    this.titleService.setTitle('Thêm ngày tuổi');
    this.getListPeriodDropdown();
  }

  getListPeriodDropdown() {
    this.tieuChuanService.queryPeriod({'tbGuidelineId.equals': this.standardDate.tbGuidelineId}).subscribe(
        (res: any) => {
          this.listPeriodDropdown = res.body;
        }
    );
  }
  save() {
    this.isSaving = true;
    this.tieuChuanService.adddDay(this.standardDate).subscribe(
      (response) => this.onSaveSuccess(),
      () => this.onSaveError(),
    );
  }

  private onSaveSuccess() {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'tieuChuanDetailModification',
      content: 'standardDate',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-tieu-chuan-ngay-tuoi',
  template: '',
})
export class NgayTuoiPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<NgayTuoiDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ngaytuoi}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(NgayTuoiDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        this.dialogRef.componentInstance.standardDate = ngaytuoi;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Chi tiết quy trình');
    this.dialogRef = null;
  }
}
