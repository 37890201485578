import {Moment} from 'moment';
import * as moment from 'moment';

export interface ILo {
  id?: any;
  maLo?: string;
  ngayTrong?: Moment;
  ngayTrongStr?: string;
  dienTich?: any;
  giongId?: any;
  tenThuongMai?: any;
  idTem?: any;
  donViCanhTacId?: any;
  tenDonVi?: any;
  loaiCayId?: any;
  tenLoaiCay?: any;
  note?: string;
  daThuHoach?: boolean;
}
export const typeCrop = {
  TRONG: 0,
  UOM: 1,
};

export interface ICrops {
  id?: any;
  qrCode?: string;
  code?: string;
  areaM2?: any;
  tbCropTypeId?: number;
  startDate?: Moment|any;
  endDate?: Moment;
  status?: number|string;
  description?: string;
  ageDayStartAt?: number;
  createdDate?: Moment;
  modifiedDate?: Moment;
  deletedDate?: Moment;
  tbSuppliesId?: any;
  suppliesName?: string;
  tbGuidelineId?: any;
  tbGuidelinePeriodId?: number;
  tbGuidelinePeriodName?: string;
  netHouseId?: any;
  netHouseName?: string;
  areaId?: any;
  area?: string;
  createdById?: number;
  modifiedById?: any;
  deletedById?: any;
  tbDetailUsers?: any;
  numberPlants?: any;
  numberCurrentPlants?: any;
}
export class Crops implements ICrops {
  constructor(
    public id?: any,
    public qrCode?: string,
    public code?: string,
    public areaM2?: any,
    public tbCropTypeId?: number,
    public startDate?: Moment|any,
    public endDate?: Moment,
    public status?: number|string,
    public description?: string,
    public ageDayStartAt?: number,
    public createdDate?: Moment,
    public modifiedDate?: Moment,
    public deletedDate?: Moment,
    public tbSuppliesId?: any,
    public suppliesName?: string,
    public tbGuidelineId?: any,
    public tbGuidelinePeriodId?: number,
    public tbGuidelinePeriodName?: string,
    public netHouseId?: any,
    public netHouseName?: string,
    public areaId?: any,
    public area?: string,
    public createdById?: number,
    public modifiedById?: any,
    public deletedById?: any,
    public tbDetailUsers?: any,
    public numberPlants?: any,
    public numberCurrentPlants?: any,
  ) {
    this.id = id ? id : null;
    this.qrCode = qrCode ? qrCode : null;
    this.code = code ? code : null;
    this.areaM2 = areaM2 ? areaM2 : null;
    this.tbCropTypeId = tbCropTypeId ? tbCropTypeId : null;
    this.startDate = startDate ? startDate : new Date();
    this.endDate = endDate ? endDate : null;
    this.status = status ? status : null;
    this.description = description ? description : null;
    this.ageDayStartAt = ageDayStartAt ? ageDayStartAt : null;
    this.createdDate = createdDate ? createdDate : null;
    this.modifiedDate = modifiedDate ? modifiedDate : null;
    this.deletedDate = deletedDate ? deletedDate : null;
    this.tbSuppliesId = tbSuppliesId ? tbSuppliesId : null;
    this.suppliesName = suppliesName ? suppliesName : null;
    this.tbGuidelineId = tbGuidelineId ? tbGuidelineId : null;
    this.netHouseId = netHouseId ? netHouseId : null;
    this.netHouseName = netHouseName ? netHouseName : null;
    this.areaId = areaId ? areaId : null;
    this.area = area ? area : null;
    this.createdById = createdById ? createdById : null;
    this.modifiedById = modifiedById ? modifiedById : null;
    this.deletedById = deletedById ? deletedById : null;
    this.numberPlants = numberPlants ? numberPlants : null;
    this.numberCurrentPlants = numberCurrentPlants ? numberCurrentPlants : null;
    this.tbDetailUsers = tbDetailUsers ? tbDetailUsers : [];
    this.tbGuidelinePeriodId = tbGuidelinePeriodId ? tbGuidelinePeriodId : null;
    this.tbGuidelinePeriodName = tbGuidelinePeriodName ? tbGuidelinePeriodName : null;
  }

  assign(props?: ICrops) {
    if (typeof props === 'object') {
      for (const i in props) {
        if (typeof this[i] !== 'undefined') {
          this[i] = props[i];
        }
      }
    }
    return this;
  }
}
export interface IGuidelines {
  id?: any;
  name?: string;
  description?: string;
  createdDate?: any;
  modifiedDate?: any;
  deletedDate?: any;
  createdById?: any;
  modifiedById?: any;
  deletedById?: any;
  tbCustomerId?: any;
}
export class Lo implements ILo {
  constructor(
    public id?: any,
    public maLo?: string,
    public ngayTrong?: Moment,
    public ngayTrongStr?: string,
    public dienTich?: any,
    public giongId?: any,
    public idTem?: any,
    public donViCanhTacId?: any,
    public loaiCayId?: any,
    public note?: string,
    public daThuHoach?: boolean,
    public tenThuongMai?: any,
    public tenDonVi?: any,
    public tenLoaiCay?: any,
  ) {
    this.id = id ? id : null;
    this.maLo = maLo ? maLo : null;
    this.ngayTrong = ngayTrong ? ngayTrong : null;
    this.ngayTrongStr = ngayTrongStr ? ngayTrongStr : null;
    this.dienTich = dienTich ? dienTich : null;
    this.giongId = giongId ? giongId : null;
    this.idTem = idTem ? idTem : null;
    this.donViCanhTacId = donViCanhTacId ? donViCanhTacId : null;
    this.loaiCayId = loaiCayId ? loaiCayId : null;
    this.note = note ? note : null;
    this.daThuHoach = daThuHoach ? daThuHoach : false;
    this.tenThuongMai = tenThuongMai ? tenThuongMai : null;
    this.tenDonVi = tenDonVi ? tenDonVi : null;
    this.tenLoaiCay = tenLoaiCay ? tenLoaiCay : false;
  }
}

export interface ITemLocal {
  id?: any;
  maTem?: string;
  ngayKichHoat?: Moment;
  trangThai?: string;
  inTemId?: any;
}

export class TemLocal implements ITemLocal {
  constructor(
    public id?: any,
    public maTem?: string,
    public ngayKichHoat?: Moment,
    public trangThai?: string,
    public inTemId?: any,
  ) {
    this.id = id ? id : null;
    this.maTem = maTem ? maTem : null;
    this.ngayKichHoat = ngayKichHoat ? ngayKichHoat : null;
    this.trangThai = trangThai ? trangThai : null;
    this.inTemId = inTemId ? inTemId : null;
  }
}

export interface IInTemLocal {
  id?: any;
  maTem?: string;
  ngayKichHoat?: Moment;
  trangThai?: string;
  inTemId?: any;
}

export class InTemLocal implements IInTemLocal {
  constructor(
    public id?: any,
    public maTem?: string,
    public ngayKichHoat?: Moment,
    public trangThai?: string,
    public inTemId?: any,
  ) {
    this.id = id ? id : null;
    this.maTem = maTem ? maTem : null;
    this.ngayKichHoat = ngayKichHoat ? ngayKichHoat : null;
    this.trangThai = trangThai ? trangThai : null;
    this.inTemId = inTemId ? inTemId : null;
  }
}
