import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {IActivityType} from '../activity.model';
import {ActivityService} from '../activity.service';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {ActivatedRoute} from '@angular/router';
import {
  ITEMS_PER_PAGE,
  PAGE_SIZE_OPTIONS,
} from 'src/app/shared/constants/pagination.constants';
import {HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-activity-type-object',
  templateUrl: './activity-type-object.component.html',
  styleUrls: ['./activity-type-object.component.scss'],
})
export class ActivityTypeObjectComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'name',
    'description',
    'tbControlTypeName',
    'tbActivityExtendTypeExternalTable',
    'tbActivityExtendTypeCondition',
    'isGuidelineUsing',
    'action',
  ];
  dataSource: any;
  pageSizeOptions: number[];
  ELEMENT_DATA: IActivityType[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  eventSubscriber: Subscription;
  id: any;
  noData: boolean;
  constructor(
    private activityService: ActivityService,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private parseLinks: JhiParseLinks,
    private activatedRoute: ActivatedRoute,
  ) {
    this.dataSource = null;
    this.ELEMENT_DATA = [];
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.page = 1;
    this.id = (this.activatedRoute.snapshot.params.id) ? this.activatedRoute.snapshot.params.id : '';
  }

  ngOnInit() {
    this.loadAll();
    this.changedListType();
  }

  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }

  changedListType() {
    this.eventSubscriber = this.eventManager.subscribe(
      'activityObjectListModification',
      (response) => this.loadAll(),
    );
  }

  loadAll() {
    this.activityService
      .queryObject(this.id, {
        page: this.page - 1,
        size: this.itemsPerPage,
      })
      .subscribe(
        (res: HttpResponse<IActivityType[]>) => this.onSuccess(res.body, res.headers),
        (res: HttpResponse<any>) => this.onError(res.body),
      );
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.transition();
  }

  clear() {
    this.page = 1;
    this.loadAll();
  }

  private transition() {
    this.loadAll();
  }

  private onSuccess(data: any, headers: any) {
    this.links = this.parseLinks.parse(headers.get('link'));
    this.totalItems = headers.get('X-Total-Count');
    this.queryCount = this.totalItems;
    this.ELEMENT_DATA = data;
    if (data.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
