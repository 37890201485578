import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Resolve, Routes} from '@angular/router';
import {map} from 'rxjs/operators';
import {UserRouteAccessService} from '../../core';
import {IUser} from '../../core/user/user.model';
import {AccountService} from '../../core/auth/account.service';
import {PersonalSettingComponent} from './personal-setting/personal-setting.component';

@Injectable({providedIn: 'root'})
export class PersonalSettingResolve implements Resolve<IUser> {
  constructor(private service: AccountService) {}

  resolve() {
    return this.service
      .get()
      .pipe(map((account: HttpResponse<IUser>) => account.body));
  }
}

export const accountRoute: Routes = [
  {
    path: 'cai-dat-tai-khoan',
    component: PersonalSettingComponent,
    resolve: {
      account: PersonalSettingResolve,
    },
    data: {
      authorities: [],
      pageTitle: 'Cài đặt tài khoản',
    },
    canActivate: [UserRouteAccessService],
  },
];
