import {Component, OnInit} from '@angular/core';
import {Crops, IGuidelines, typeCrop} from '../canh-tac.model';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {CanhTacService} from '../canh-tac.service';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {Title} from '@angular/platform-browser';
import {Area, netHouses} from '../../../shared/model/area.model';
import {AreaService} from '../../../shared/services/area.service';
import {GiongService} from '../../giong';
import {IGiong} from '../../giong/giong.model';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-canh-tac-detail',
  templateUrl: './canh-tac-detail.component.html',
  styleUrls: ['./canh-tac-detail.component.scss'],
})
export class CanhTacDetailComponent implements OnInit {
  lo: Crops;
  loId: any;
  area$: Area[];
  tbCropTypeId$: any;
  netHouses$: netHouses[];
  supPlies$: IGiong[];
  guideLine$: IGuidelines[];
  eventSubscriber: Subscription;
  elementType: 'img';
  constructor(
    private activatedRoute: ActivatedRoute,
    private canhTacService: CanhTacService,
    private eventManager: JhiEventManager,
    private alertService: JhiAlertService,
    private localStorage: LocalStorageService,
    private titleService: Title,
    private giongService: GiongService,
    private areaService: AreaService,
    private router: Router
  ) {
    this.lo = new Crops();
    this.loId = null;
    this.tbCropTypeId$ = [];
  }

  ngOnInit() {
    this.titleService.setTitle('Chi tiết lô');
    this.localStorage.clear('lo.LoId');
    this.changedDetailLo();
    this.activatedRoute.data.subscribe(({lo}) => {
      setTimeout(() => {
        if (lo !== undefined) {
          this.lo = lo;
          this.loId = this.lo.id;
          this.localStorage.store('lo.LoId', this.loId);
          this.areaService.listArea().subscribe((res: any) => {
            this.area$ = res.body ? res.body : [];
          });
          this.canhTacService.guideLine().subscribe((res: any) => {
            this.guideLine$ = res.body ? res.body : [];
          });
          this.giongService.queryDropdownList().subscribe((res: any) => {
            this.supPlies$ = res.body ? res.body : [];
          });
          this.canhTacService.listCropType().subscribe((res: any) => {
            this.tbCropTypeId$ = res.body ? res.body : [];
          });
          if (lo.areaId) {
            this.getNetHouses(lo.areaId);
          }else{
            this.getNetHouses(0);
          }
          // if (this.activatedRoute.snapshot.paramMap.get("env")) {
          //   this.router.navigate([{ outlets: { popup: ['chi-so-va-dieu-khien', this.loId, this.activatedRoute.snapshot.paramMap.get("env")] }}]);
          // }
        }
      }, 0);
    });
  }
  statusConvert(status: any) {
    const statusName = {
      STATUS_ARE_ACTIVE: 'Đanh canh tác',
      STATUS_FINISHED: 'Đã thu hoạch',
    };
    return statusName.hasOwnProperty(status)
      ? statusName[status]
      : 'Chưa cập nhật';
  }
  update() {
    if (this.lo.id !== null) {
      this.subscribeToSaveResponse(
        forkJoin([this.canhTacService.update(this.lo)]),
      );
    }
  }
  changedDetailLo() {
    this.eventSubscriber = this.eventManager.subscribe(
      'loDetailModification',
      () => this.reLoadLo(),
    );
  }
  reLoadLo() {
    this.canhTacService.find(this.loId).subscribe((res: any) => {
      this.lo = res.body ? res.body : null;
    });
  }
  private updateSuccess(result: any) {
    this.eventManager.broadcast({
      name: 'loListModification',
      content: 'Lo',
    });
  }

  private onUpdateError(msg: string) {
    this.alertService.error(msg, null, null);
  }

  private subscribeToSaveResponse(result: Observable<any[]>) {
    result.subscribe(
      ([]) =>
        setTimeout(() => {
          this.updateSuccess("Success to save your're information!");
        }, 0),
      (error: HttpErrorResponse) =>
        setTimeout(() => {
          this.onUpdateError(error.error.title);
        }, 0),
    );
  }
  getNetHouses(id: number) {
    if (id) {
      this.areaService.listNetHousesByArea(id).subscribe((res: any) => {
        this.netHouses$ = res.body ? res.body : [];
      });
    } else {
      this.areaService.listNetHousesAll().subscribe((res: any) => {
        this.netHouses$ = res.body ? res.body : [];
      });
    }
  }
  onAreaChange(event: any) {
    if (event) {
      this.lo.areaId = event;
      this.getNetHouses(event);
    }
  }
  onNetHousesChange(event: any) {
    this.lo.netHouseId = event;
  }
  onSupPliesChange(event: any) {
    this.lo.tbSuppliesId = event;
  }
  onGuideLineChange(event: any) {
    this.lo.tbGuidelineId = event;
  }
  QRpdfCode() {
    const qrcode = document.getElementById('qrcode');
    const child = qrcode.getElementsByTagName('img')[0];
    const doc = new jsPDF();
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    console.log(child);
    const imageData = this.getBase64Image(child);
    doc.addImage(imageData, 'JPG', width / 2 - 50, height / 2 - 50, 100, 100);
    doc.save('QRcode.pdf');
  }

  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/png');
  }
  onViewDetail(event: Event, id: number): void {
    event.preventDefault();
    this.router.navigate(['/danh-sach-cong-viec', id]);
  }

}
