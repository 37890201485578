import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {DonViService} from '../don-vi.service';
import {DonVi} from '../don-vi.model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-don-vi-mgmt-delete-dialog',
  templateUrl: './don-vi-delete-dialog.component.html',
})
export class DonViMgmtDeleteDialogComponent {
  donVi: DonVi;

  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }

  constructor(
    private dialogRef: MatDialogRef<DonViMgmtDeleteDialogComponent>,
    private donViService: DonViService,
    private eventManager: JhiEventManager,
  ) {}

  confirmDelete(donViId) {
    this.donViService.delete(donViId).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'donViListModification',
        content: 'Deleted a Don Vi',
      });
      this.dialogRef.close(true);
    });
  }
}

@Component({
  selector: 'app-don-vi-mgmt-delete-popup',
  template: '',
  styleUrls: ['./don-vi-delete-dialog.component.scss'],
})
export class DonViMgmtDeletePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<DonViMgmtDeleteDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa đơn vị');
    this.activatedRoute.data.subscribe(({donVi}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(DonViMgmtDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });

        this.dialogRef.componentInstance.donVi = donVi;

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách đơn vị');
    this.dialogRef = null;
  }
}
