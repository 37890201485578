import {
  Routes,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {CanhTacComponent} from './canh-tac/canh-tac.component';
import {CanhTacDetailComponent} from './canh-tac-detail/canh-tac-detail.component';
import {CanhTacPopupComponent} from './canh-tac-dialog/canh-tac-dialog.component';
import {Injectable} from '@angular/core';
import {ICrops, Crops} from './canh-tac.model';
import {CanhTacService} from './canh-tac.service';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {CanhTacMgmtDeletePopupComponent} from './canh-tac-delete-dialog/canh-tac-delete-dialog.component';
import {HanhDongComponent} from './hanh-dong/hanh-dong.component';
import {CanhTacExportFilePopupComponent} from './canh-tac-exportfile-dialog/canh-tac-exportfile-dialog.component';
import {CanhtacManagerPopupComponent} from './canh-tac-manager-dialog/canh-tac-manager-dialog/canh-tac-manager-dialog.component';
import {CanhTacMgmtDeletePersonPopupComponent} from './canh-tac-manager-dialog/canh-tac-delete-person-dialog/canh-tac-delete-person-dialog.component';
import {HanhDongGuideLineComponent} from './hanh-dong-guide-line/hanh-dong-guide-line.component';
import {QrCodeDetailActivityComponent} from '../qr-code/qr-code-detail-activity/qr-code-detail-activity.component';
import {QrCodeTimelineResolve} from '../qr-code';
import {HoatDongCanhTacComponent} from './hoat-dong-canh-tac/hoat-dong-canh-tac.component';


@Injectable({providedIn: 'root'})
export class LoAdvanceManagementResolve implements Resolve<ICrops> {
  constructor(private service: CanhTacService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['lo'] ? route.params['lo'] : null;
    if (id) {
      return this.service
          .timeLine(id)
          .pipe(map((lo: HttpResponse<any>) => {
            const data = lo.body.tbCropDTO;
            data.numberPlants = lo.body.numberPlants;
            data.numberCurrentPlants = lo.body.numberCurrentPlants;
            return data;
          }));
    }
    return of(new Crops());
  }
}

@Injectable({providedIn: 'root'})
export class LoManagementResolve implements Resolve<ICrops> {
  constructor(private service: CanhTacService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['lo'] ? route.params['lo'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((lo: HttpResponse<ICrops>) => lo.body));
    }
    return of(new Crops());
  }
}

@Injectable({providedIn: 'root'})
export class UserManagementResolve implements Resolve<ICrops> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return route.params['id'] ? route.params['id'] : null;
  }
}

@Injectable({providedIn: 'root'})
export class ControlEnvironmentResolve implements Resolve<any> {
  constructor(private service: CanhTacService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['lo'] ? route.params['lo'] : null;
    if (id) {
      return this.service
        .listEnvUpdate(id)
        .pipe(map((lo: HttpResponse<any>) => lo.body));
    }
    return;
  }
}

@Injectable({providedIn: 'root'})
export class DevicesInEntityResolve implements Resolve<any> {
  constructor(private service: CanhTacService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['nhaMang'] ? route.params['nhaMang'] : null;
    if (id) {
      return this.service
          .listDevicesInEntity(id)
          .pipe(map((lo: HttpResponse<any>) => lo.body));
    }
    return;
  }
}
@Injectable({providedIn: 'root'})
export class EntityResolvePagingParams implements Resolve<any> {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return {
      page: 1,
      predicate: 'createdDate',
      ascending: false
    };
  }
}
export const canhTacRoute: Routes = [
  {
    path: 'danh-sach-lo',
    component: CanhTacComponent,
    resolve: {
      pagingParams: EntityResolvePagingParams,
    },
    data: {
      authorities: ['can_read_crops'],
      pageTitle: 'Danh sách lô'
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'chi-tiet-lo/:lo',
    component: CanhTacDetailComponent,
    resolve: {
      lo: LoAdvanceManagementResolve
    },
    data: {
      authorities: ['can_read_detail_crops'],
      pageTitle: 'Chi tiết lô',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'chi-tiet-hanh-dong/:code/:hd',
    component: HanhDongComponent,
    resolve: {},
    data: {
      authorities: ['can_read_detail_crops'],
      pageTitle: 'Chi tiết hành động',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'chi-tiet-quy-trinh/:code/:id/:lo',
    component: HanhDongGuideLineComponent,
    resolve: {},
    data: {
      authorities: ['can_read_detail_crops'],
      pageTitle: 'Chi tiết hành động',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'them-lo',
    component: CanhTacPopupComponent,
    data: {
      authorities: ['can_create_crops'],
      pageTitle: 'Thêm lô',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-lo/:lo',
    component: CanhTacMgmtDeletePopupComponent,
    resolve: {
      lo: LoManagementResolve,
    },
    data: {
      authorities: ['can_delete_crops'],
      pageTitle: 'Xóa lô',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xuat-file/:lo',
    component: CanhTacExportFilePopupComponent,
    resolve: {
      lo: LoManagementResolve,
    },
    data: {
      authorities: ['can_export_nursery', 'can_export_cultivate'],
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'nguoi-phu-trach/:lo',
    component: CanhtacManagerPopupComponent,
    canActivate: [UserRouteAccessService],
    resolve: {
      lo: LoManagementResolve,
    },
    outlet: 'popup',
  },
  {
    path: 'xoa-nguoi-phu-trach/:lo/:id',
    component: CanhTacMgmtDeletePersonPopupComponent,
    canActivate: [UserRouteAccessService],
    resolve: {
      lo: LoManagementResolve,
      userId: UserManagementResolve,
    },
    outlet: 'popup',
  },
  {
    path: 'danh-sach-cong-viec/:crop-id',
    component: HoatDongCanhTacComponent,
    data: {
      pageTitle: 'Danh sách công việc',
    },
    canActivate: [UserRouteAccessService],
  },
];
