import {
  CriteriaSearch,
  Search,
  FilterField,
  IFilterField,
} from '../../../shared';


export class KhuVucSearch extends CriteriaSearch {
  protected req = new Search();
  constructor(
    public query?: string,
  ) {
    super();
    if (!query) {
      this.query = query ? query : '';
    }
  }

  valid(): boolean {
    return true;
  }

  addQueryParams(options?: any): void {
    this.setQueryParam('q', this.query || '');
  }

  setFilterForm(controls: any): void {
    if (controls.query) {
    }
  }
}
