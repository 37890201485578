import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mat-dialog-hover',
  templateUrl: './mat-dialog-hover.component.html',
  styleUrls: ['./mat-dialog-hover.component.scss']
})
export class MatDialogHoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
