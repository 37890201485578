import {ICrops} from './../../../entities/canh-tac/canh-tac.model';
import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-dashboard-crop-tile',
  templateUrl: './dashboard-crop-tile.component.html',
  styleUrls: ['./dashboard-crop-tile.component.scss']
})
export class DashboardCropTileComponent implements OnInit {
  @Input() data: ICrops;
  @Input() width: number;
  @Input() height: number;
  constructor() { }

  ngOnInit() {
  }

}
