export interface IDataLabels {
  enabled?: boolean;
}

export class DataLabels implements IDataLabels {
  constructor(public enabled?: boolean) {
    this.enabled = enabled ? enabled : true;
  }
}

export interface IInfo {
  name?: string;
  color?: string;
}

export class Info implements IInfo {
  constructor(public name?: string, public color?: string) {
    this.name = name ? name : null;
    this.color = color ? color : null;
  }
}

export interface IStatictis {
  name?: string;
  y?: string;
  dataLabels?: DataLabels;
  color?: string;
}

export class Statictis implements IStatictis {
  constructor(
    public name?: string,
    public y?: string,
    public dataLabels?: DataLabels,
    public color?: string,
  ) {
    this.name = name ? name : null;
    this.y = y ? y : null;
    this.dataLabels = dataLabels ? dataLabels : new DataLabels();
    this.color = color ? color : null;
  }
}

export interface IHoatDongServer {
  date?: string;
  countActivity?: number;
}

export class HoatDongServer implements IHoatDongServer {
  constructor(public date?: string, public countActivity?: number) {
    this.date = date ? date : null;
    this.countActivity = countActivity ? countActivity : 0;
  }
}

export interface IStatictisHoatDong {
  x?: number;
  y?: number;
}

export class StatictisHoatDong implements IStatictisHoatDong {
  constructor(public x?: number, public y?: number) {
    this.x = x ? x : 0;
    this.y = y ? y : 0;
  }
}

export interface IUnit {
  id?: any;
  unitName?: any;
  phone?: string;
  email?: string;
  allArea?: any;
  country?: string;
  city?: string;
  district?: string;
  subdistrict?: string;
  address?: string;
}

export class Unit implements IUnit {
  constructor(
    public id?: any,
    public unitName?: any,
    public phone?: string,
    public email?: string,
    public allArea?: any,
    public country?: string,
    public city?: string,
    public district?: string,
    public subdistrict?: string,
    public address?: string,
  ) {
    this.id = id ? id : null;
    this.unitName = unitName ? unitName : null;
    this.phone = phone ? phone : null;
    this.email = email ? email : null;
    this.allArea = allArea ? allArea : null;
    this.country = country ? country : null;
    this.city = city ? city : null;
    this.district = district ? district : null;
    this.subdistrict = subdistrict ? subdistrict : null;
    this.address = address ? address : null;
  }
}
