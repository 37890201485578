import {
  CriteriaSearch,
  Search,
  FilterField,
  IFilterField,
  FilterOperator,
} from '../../../shared';


export class DonViRoleSearch extends CriteriaSearch {
  protected req = new Search();
  constructor(
    public query?: string,
    public tbCustomerId?: FilterField
  ) {
    super();
    if (!query) {
      this.query = query ? query : '';
    }
    if (!tbCustomerId) {
      this.tbCustomerId = new FilterField('tbCustomerId', FilterOperator.equals, null);
    }
  }

  valid(): boolean {
    return true;
  }

  addQueryParams(options?: any): void {
    this.setQueryParam('q', this.query || '');
  }

  setFilterForm(controls: any): void {
    if (controls.query) {
    }
  }
}
