import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {PasswordResetFinishService} from './password-reset-finish.service';
import {isPlatformBrowser} from '@angular/common';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-password-reset-finish',
  templateUrl: './password-reset-finish.component.html',
  styleUrls: ['./password-reset-finish.scss'],
})
export class PasswordResetFinishComponent implements OnInit, AfterViewInit {
  confirmPassword: string;
  doNotMatch: string;
  error: string;
  keyMissing: boolean;
  resetAccount: any;
  success: string;
  key: string;
  hide = true;
  constructor(
    private passwordResetFinishService: PasswordResetFinishService,
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Đặt lại mật khẩu');
    this.route.queryParams.subscribe((params) => {
      this.key = params['key'];
    });
    this.resetAccount = {};
    this.keyMissing = !this.key;
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('#password') != null) {
      // this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#password'), 'focus', []);
      const eleRef = this.elementRef.nativeElement.querySelector('#password');
      if (isPlatformBrowser(this.platformId)) {
        eleRef.nativeElement['focus']();
      }
    }
  }

  finishReset() {
    this.doNotMatch = null;
    this.error = null;
    if (this.resetAccount.password !== this.confirmPassword) {
      this.doNotMatch = 'ERROR';
    } else {
      this.passwordResetFinishService
        .save({key: this.key, newPassword: this.resetAccount.password})
        .subscribe(
          () => {
            this.success = 'OK';
            this.router.navigate(['/login']);
          },
          () => {
            this.success = null;
            this.error = 'ERROR';
          },
        );
    }
  }

  login() {
    this.router.navigate(['/login']);
  }
}
