import {Component, OnInit} from '@angular/core';
import {CanhTacService} from 'src/app/entities/canh-tac/canh-tac.service';
import {ActivatedRoute} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {JhiAlertService} from 'ng-jhipster';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  durationData: any[] = [];
  durationActivity: any[] = [];
  loID = 0;

  constructor(
    private canhTacService: CanhTacService,
    private activatedRoute: ActivatedRoute,
    private alertService: JhiAlertService,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({lo}) => {
      setTimeout(() => {
        if (lo !== undefined) {
          this.durationData = [];
          this.loadTimeLine(lo.id);
          this.loID = lo.id;
        }
      }, 0);
    });
  }

  loadTimeLine(id) {
    this.canhTacService.timeLine(id).subscribe(
      (res: HttpResponse<any[]>) => this.onSuccess(res.body),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  private onSuccess(data: any) {
    const activitiesArr = data.activityTimeline.activities;
    const guidelineDetailArr = data.activityTimeline.guidelineDetails;
    const guidelinePeriods = data.activityTimeline.guidelinePeriods;
    if ( guidelinePeriods ) {
      guidelinePeriods.forEach((item) => {
        const arrTimeline = [];
        for (let i = item.ageDayStart; i <= item.ageDayEnd; i++) {
          const activities = activitiesArr
              ? activitiesArr.filter((act) => act.ageDay === i)
              : [];
          const guidelineDetails = guidelineDetailArr
              ? guidelineDetailArr.filter(
                  (gui) => gui.guidelineDetail.tbGuidelinePeriodId === item.id
                      && gui.guidelineDetail.ageDayStart === i && gui.activityGuidelines.length > 0
              )
              : [];
          if (activities.length > 0 || guidelineDetails.length > 0) {
            arrTimeline.push({
              activities: activities,
              date: i,
              guidelineDetails: guidelineDetails.length > 0
                        ? guidelineDetails[0].activityGuidelines
                        : []
            });
          }
        }
        this.durationData.push({
          periodName: item,
          data: arrTimeline,
        });
      });
    } else {
      this.durationActivity = activitiesArr;
    }
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
