import {DonViComponent} from './don-vi/don-vi.component';
import {donViRolePopupRoute, donViPopupRoute, donViRoute, donViSettingPopupRoute} from './don-vi.route';
import {KhuVucModule} from './../khu-vuc/khu-vuc.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared';
import {RouterModule, Routes} from '@angular/router';
import {
  DonViMgmtDeleteDialogComponent,
  DonViMgmtDeletePopupComponent,
} from './don-vi-delete-dialog/don-vi-delete-dialog.component';
import { DonViDialogComponent, DonViPopupComponent } from './don-vi-dialog/don-vi-dialog.component';
import { DonViDetailComponent } from './don-vi-detail/don-vi-detail.component';
import { DonViRoleComponent } from './don-vi-role/don-vi-role.component';
import { DonViRuleComponent } from './don-vi-rule/don-vi-rule.component';
import {DonViSettingDialogComponent, DonViSettingPopupComponent} from './don-vi-setting-dialog/don-vi-setting-dialog.component';
import {DonViRoleDialogComponent, DonViRolePopupComponent} from './don-vi-role-dialog/don-vi-role-dialog.component';

const ENTITY_STATES: Routes = [...donViRoute, ...donViPopupRoute, ...donViRolePopupRoute, ...donViSettingPopupRoute];

@NgModule({
  declarations: [
    DonViComponent,
    DonViDetailComponent,
    DonViDialogComponent,
    DonViPopupComponent,
    DonViMgmtDeleteDialogComponent,
    DonViMgmtDeletePopupComponent,
    DonViRoleComponent,
    DonViRuleComponent,
    DonViRoleDialogComponent,
    DonViRolePopupComponent,
    DonViSettingDialogComponent,
    DonViSettingPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES),
    KhuVucModule,
  ],
  entryComponents: [
    DonViDialogComponent,
    DonViRoleDialogComponent,
    DonViSettingDialogComponent,
    DonViMgmtDeleteDialogComponent,
    DonViMgmtDeletePopupComponent,
  ],
})
export class DonViModule {}
