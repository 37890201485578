import {JhiEventManager} from 'ng-jhipster';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private eventManager: JhiEventManager) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const exceptAlert = request.params.get('exceptAlert');
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse && !exceptAlert) {
            if (
              !(
                err.status === 401 &&
                (err.message === '' ||
                  (err.url && err.url.includes('/api/account')))
              )
            ) {
              if (this.eventManager !== undefined) {
                this.eventManager.broadcast({
                  name: 'pmwebApp.httpError',
                  content: err,
                });
              }
            }
          }
        },
      ),
    );
  }
}
