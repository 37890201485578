import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {
  Grow,
  IGuidelineDetail,
  Manuring,
  Nursery,
  Other,
  Spray,
  SuppliesUsing,
  SuppliesUsingEquipment,
  Water
} from '../tieu-chuan.model';
import {TieuChuanService} from '../tieu-chuan.service';
import {IActivityType} from '../../activity/activity.model';
import moment from "moment";

@Component({
  selector: 'app-activity-type-dialog',
  templateUrl: './activity-type-dialog.component.html',
  styleUrls: ['./activity-type-dialog.component.scss'],
})
export class ActivityTypeDialogComponent implements OnInit {
  // @ViewChild('picker') picker: any;

  isSaving: boolean;
  guideline: IGuidelineDetail;
  activityTypeDropdown: IActivityType[];
  activityParam: any;
  objectList: any;
  deviceOfUserCustomers: any;
  listChemical: any;
  listFertilizer: any;
  listSeed: any;
  substratesData: any;
  constructor(
    private dialogRef: MatDialogRef<ActivityTypeDialogComponent>,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private tieuChuanService: TieuChuanService,
  ) {
    this.isSaving = false;
    this.activityTypeDropdown = [];
    this.activityParam = [];
    this.objectList = [];
    this.deviceOfUserCustomers = [];
    this.listChemical = [];
    this.listFertilizer = [];
    this.listSeed = [];
    this.substratesData = [];
  }

  ngOnInit() {
    this.tieuChuanService.getActivityTypeDropdown().subscribe((res: any) => {
      this.activityTypeDropdown = res.body ? res.body : [];
    });
    this.tieuChuanService.giaTheType().subscribe((res: any) => {
      this.substratesData = res.body ? res.body : [];
    });
    // this.tieuChuanService.suppliesInWarehouses().subscribe((res: any) => {
    //   this.suppliesInWarehouses = res.body ? res.body : [];
    // });
    this.tieuChuanService.listDeviceOfUserCustomers().subscribe((res: any) => {
      this.deviceOfUserCustomers = res.body ? res.body : [];
    });
    this.tieuChuanService.listSuppliesSeed().subscribe((res: any) => {
      this.listSeed = res.body ? res.body : [];
    });
    this.tieuChuanService.listSuppliesFertilizer().subscribe((res: any) => {
      this.listFertilizer = res.body ? res.body : [];
    });
    this.tieuChuanService.listSuppliesChemical().subscribe((res: any) => {
      this.listChemical = res.body ? res.body : [];
    });
    if (this.guideline.id !== null) {
      this.objectList = [...this.guideline.tbGDObjectUpdateDTOList];
      this.guideline.tbGDObjectUpdateDTOList = [];
      this.titleService.setTitle('Sửa hoạt động');
      this.onChangeActivity(this.guideline.tbGuidelineActivityDTO.tbActivityTypeId, false);
    } else {
      this.titleService.setTitle('Thêm hoạt động');
    }
  }
  onChangeActivity(event: any, isDropdown: boolean) {
    this.activityTypeDropdown.forEach((item) => {
      if ( event === item.id) {
        this.guideline.tbGuidelineActivityDTO.activityTypeName = item.name;
        return true;
      }
    });
    this.tieuChuanService.getDisplayObjectParamDynamicForm(event).subscribe((res: any) => {
      this.activityParam = res.body ? res.body : [];
      this.guideline.tbGDObjectUpdateDTOList = [];
      if (isDropdown) {
        this.guideline.tbSuppliesUsingDetailsDTOs = [];
      }
      res.body.objectParameterDTOList.forEach((item) => {
        const data = (this.objectList.length > 0) ? this.objectList.filter((obj) => obj.tbObjectParameterId === item.id) :  [];
        if (data.length > 0) {
          this.guideline.tbGDObjectUpdateDTOList.push({
            tbObjectParameterId: data[0].tbObjectParameterId,
            index: data[0].index,
            description: item.description,
            name: item.name,
            isMandatory: item.isMandatory,
            tbControlTypeName: item.tbControlTypeName,
            unit: item.unit,
            id: data[0].id,
            tbGuidelineActivityId: data[0].tbGuidelineActivityId,
            tbActivityExtendTypeExternalTable: item.tbActivityExtendTypeExternalTable,
            tbActivityExtendTypeCondition: item.tbActivityExtendTypeCondition
          });
        } else {
          this.guideline.tbGDObjectUpdateDTOList.push({
            tbObjectParameterId: item.id,
            index: "",
            description: item.description,
            name: item.name,
            isMandatory: item.isMandatory,
            tbControlTypeName: item.tbControlTypeName,
            unit: item.unit,
            tbActivityExtendTypeExternalTable: item.tbActivityExtendTypeExternalTable,
            tbActivityExtendTypeCondition: item.tbActivityExtendTypeCondition
          });
        }
      });
    });
  }
  save() {
    this.isSaving = true;
    const data = {...this.guideline};
    data.tbGDObjectUpdateDTOList.forEach((item, i) => {
      if (this.conditionInputDate(item.tbControlTypeName) && item.index !== null) {
        data.tbGDObjectUpdateDTOList[i].index = moment(item.index).toISOString();
      }
    });
    if (data.id !== null) {
      this.tieuChuanService.updateActivityGuidelineDetail(data.tbGuidelineActivityDTO.activityTypeName, data).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    } else {
      this.tieuChuanService.createActivityGuidelineDetail(data.tbGuidelineActivityDTO.activityTypeName, data).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }
  }
  addUsing() {
    const data = new SuppliesUsingEquipment();
    this.guideline.tbSuppliesUsingDetailsDTOs = [...this.guideline.tbSuppliesUsingDetailsDTOs, data];
  }
  removeUsing(index: number) {
    this.guideline.tbSuppliesUsingDetailsDTOs.splice(index, 1);
  }
  conditionExtend(name) {
    const arrExtend = ['ACTIVE_TYPE_SPRAYING_PESTICIDES', 'ACTIVE_TYPE_FERTILIZE', 'ACTIVE_TYPE_PLANTING'];
    return arrExtend.includes(name);
  }
  conditionInputObject(type) {
    const arrType = ['radiobutton', 'dropdown'];
    return arrType.includes(type);
  }
  conditionInputDate(type) {
    const arrType = ['date', 'datetime', 'time'];
    return arrType.includes(type);
  }
  conditionDropdown(param, condition) {
    if (param.tbActivityExtendTypeExternalTable === "tb_supplies" && param.tbActivityExtendTypeCondition === condition) {
      return true;
    } else {
      return false;
    }
  }
  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'activityDetailModification',
      content: 'guideline',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-activity-type-popup',
  template: '',
})
export class ActivityTypePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ActivityTypeDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({guideline}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(ActivityTypeDialogComponent, {
          disableClose: true,
          width: '500px',
        });
        this.dialogRef.componentInstance.guideline = guideline;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Chi tiết quy trình');
    this.dialogRef = null;
  }
}
