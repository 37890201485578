import {
  Routes,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {GiongPopupComponent} from './giong-dialog/giong-dialog.component';
import {GiongComponent} from './giong/giong.component';
import {GiongMgmtDeletePopupComponent} from './giong-delete-dialog/giong-delete-dialog.component';
import {Injectable} from '@angular/core';
import {IGiong, Giong, IGiongDetail, GiongDetail} from './giong.model';
import {GiongService} from './giong.service';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {GiongImportPopupComponent} from './giong-import/giong-import.component';
import {GiongDetailComponent} from './giong-detail/giong-detail.component';
import {GiongStoragePopupComponent} from './giong-storage-dialog/giong-storage-dialog.component';
import {GiongDeleteHistoryPopupComponent} from './giong-delete-history-dialog/giong-delete-history-dialog.component';
import {SupplyBlockDetailsResolve} from '../vat-tu';

@Injectable({providedIn: 'root'})
export class GiongManagementResolve implements Resolve<IGiongDetail> {
  constructor(private service: GiongService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['giong'] ? route.params['giong'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((giong: HttpResponse<IGiongDetail>) => giong.body));
    }
    return of(new GiongDetail());
  }
}

export const giongRoute: Routes = [
  {
    path: 'danh-sach-giong',
    component: GiongComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_list_giong'],
      pageTitle: 'Danh sách giống',
    },
    canActivate: [UserRouteAccessService],
  },
];

export const GiongPopupRoute: Routes = [
  {
    path: 'them-giong',
    component: GiongPopupComponent,
    data: {
      authorities: ['can_create_giong'],
      pageTitle: 'Thêm giống',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'import-giong',
    component: GiongImportPopupComponent,
    data: {
      authorities: ['can_create_giong'],
      pageTitle: 'Import giống',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'sua-giong/:giong',
    component: GiongPopupComponent,
    resolve: {
      giong: GiongManagementResolve,
    },
    data: {
      authorities: ['can_update_giong'],
      pageTitle: 'Sửa giống',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-giong/:giong',
    component: GiongMgmtDeletePopupComponent,
    resolve: {
      giong: GiongManagementResolve,
    },
    data: {
      authorities: ['can_delete_giong'],
      pageTitle: 'Xóa giống',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'chi-tiet-giong/:giong',
    component: GiongDetailComponent,
    resolve: {
      giong: GiongManagementResolve,
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_detail_giong'],
      pageTitle: 'Chi tiết giống',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'cap-nhat-giong-luu-kho/:giong',
    component: GiongStoragePopupComponent,
    resolve: {
      giong: GiongManagementResolve,
      block: SupplyBlockDetailsResolve,
    },
    data: {
      authorities: ['can_create_warehouse_giong'],
      pageTitle: 'Cập nhật số lượng giống lưu kho',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-lich-su-giong/:giong',
    component: GiongDeleteHistoryPopupComponent,
    data: {
      authorities: ['can_delete_warehouse_giong'],
      pageTitle: 'Xóa lịch sử giống lưu kho',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
];
