import {LoaiHoatDongService} from './../../loai-hoat-dong/loai-hoat-dong.service';
import {LoaiLoService} from './../../loai-lo/loai-lo.service';
import {map} from 'rxjs/operators';
import {DonViService} from './../don-vi.service';
import {DonViSetting, IDonViSetting} from './../don-vi-setting.model';
import {ILoaiHoatDong} from './../../loai-hoat-dong/loai-hoat-dong.model';
import {ILoaiLo, LoaiLo} from './../../loai-lo/loai-lo.model';
import {DonVi, IDonVi} from './../don-vi.model';
import {JhiEventManager} from 'ng-jhipster';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router, Data} from '@angular/router';
import {MatDialogRef} from '@angular/material';
import {Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked, HostListener} from '@angular/core';

@Component({
  selector: 'app-don-vi-setting-dialog',
  templateUrl: './don-vi-setting-dialog.component.html',
  styleUrls: ['./don-vi-setting-dialog.component.scss']
})
export class DonViSettingDialogComponent implements OnInit {
  entity: IDonVi;
  entitySetting: IDonViSetting;
  cropTypes: ILoaiLo[];
  activityTypes: ILoaiHoatDong[];
  selectedCropType: ILoaiLo;

  activityTypeMoreSize: number;
  cropTypeMoreSize: number;
  constructor(
    private dialogRef: MatDialogRef<DonViSettingDialogComponent>,
    private eventManager: JhiEventManager,
    private service: DonViService,
    private cropTypeService: LoaiLoService,
    private activityTypeService: LoaiHoatDongService,
  ) {
    this.entity = new DonVi();
    this.cropTypes = [];
    this.activityTypes = [];
    this.selectedCropType = new LoaiLo();
  }

  ngOnInit() {
    if (this.cropTypes.length > 0) {
      this.selectedCropType = this.cropTypes[0];
      this.selectCropType(this.selectedCropType.tbCropTypeChecked, this.selectedCropType);
    }
  }

  calculateData() {
    this.activityTypeMoreSize = this.activityTypes ? this.activityTypes.length : 0;
    this.cropTypeMoreSize = this.cropTypes ? this.cropTypes.length : 0;
  }

  clear() {
    this.dialogRef.close('cancel');
  }

  save() {
    if (this.entity.id !== null) {
      const req = {
        tbCustomerId: this.entity.id,
        activityTypeByCropTypeDTOList: this.cropTypes.map(i => {
          const tbCropTypeChecked = i.tbCropTypeChecked || false;
          const tbActivityTypeIds = i.tbActivityTypeIds || [];
          return {
            tbCropTypeId: i.id,
            tbCropTypeChecked,
            tbActivityTypeIds,
          };
        })
        // .filter(i => i.tbCropTypeChecked && i.tbActivityTypeIds.length),
      };
      if (!req.activityTypeByCropTypeDTOList.length) {
        return;
      }
      this.service.updateSetting(req).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError(),
      );
    }
  }

  private onSaveSuccess() {
    this.dialogRef.close(true);
  }

  private onSaveError() {
  }

  selectCropType(event, item: ILoaiLo) {
    if (typeof item.tbActivityTypeIds === 'undefined') {
      item.tbActivityTypeIds = [];
    }
    this.selectedCropType = item;
    this.activityTypes.forEach(i => {
      i.isSelected = this.selectedCropType.tbActivityTypeIds.includes(i.id);
    });
  }

  checkCropType(event, item: ILoaiLo) {
    if (!event) {
      item.tbActivityTypeIds = [];
      this.activityTypes.forEach(i => {
        i.isSelected = event;
      });
    }
  }

  checkActivityType(event, item: ILoaiHoatDong) {
    if (!this.selectedCropType.id) {
      item.isSelected = !event;
      return;
    } else {
      if (event) {
        this.selectedCropType.tbActivityTypeIds.push(item.id);
      } else {
        this.selectedCropType.tbActivityTypeIds = this.selectedCropType.tbActivityTypeIds.filter(i => i !== item.id);
      }
    }
  }

  /**
   * Lazy load on scrolling
   */
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
      // visible height + pixel scrolled >= total height
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        console.log('Scroll End');
        if (this.cropTypeMoreSize <= this.cropTypes.length) {
          this.cropTypeMoreSize += 10;
        } else {
          this.cropTypeMoreSize = this.cropTypes.length;
        }

        if (this.activityTypeMoreSize <= this.activityTypes.length) {
          this.activityTypeMoreSize += 10;
        } else {
          this.activityTypeMoreSize = this.activityTypes.length;
        }

        if (this.cropTypes.length < this.cropTypeMoreSize) {
          this.cropTypeService.query({size: this.cropTypeMoreSize}).subscribe(res => {
            if (res && res.body) {
              this.cropTypes = res.body;
            }
          });
        }
        if (this.activityTypes.length < this.activityTypeMoreSize) {
          this.activityTypeService.query({size: this.activityTypeMoreSize}).subscribe(res => {
            if (res && res.body) {
              this.activityTypes = res.body;
            }
          });
        }
      }
  }
}


@Component({
  selector: 'app-don-vi-setting-popup',
  template: '',
})
export class DonViSettingPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<DonViSettingDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({donVi, loaiLoList, loaiHoatDongList, donViSetting}: Data) => {
      setTimeout(() => {
        const donViId = this.activatedRoute.snapshot.paramMap.get('donViId');
        this.dialogRef = this.dialog.open(DonViSettingDialogComponent, {
          disableClose: true,
          width: '600px',
        });
        const entity = donVi || new DonVi();
        const entitySetting = donViSetting as DonViSetting || new DonViSetting();
        const cropTypes = loaiLoList || [];
        const activityTypes = loaiHoatDongList as ILoaiHoatDong[] || [];
        this.dialogRef.componentInstance.entity = entity;
        this.dialogRef.componentInstance.entitySetting = donViSetting;
        this.dialogRef.componentInstance.cropTypes = cropTypes.map((i: ILoaiLo) => {
          const trackedCropType = entitySetting.activityTypeByCropTypeDTOList.find(y => y.tbCropTypeId === i.id);
          i.tbCropTypeChecked = trackedCropType && trackedCropType.tbCropTypeChecked;
          i.tbActivityTypeIds = activityTypes.filter(y =>
            trackedCropType && trackedCropType.tbActivityTypeGetList[y.name])
            .map(y => y.id);

          return i;
        });
        this.dialogRef.componentInstance.activityTypes = activityTypes;
        this.dialogRef.componentInstance.calculateData();
        this.dialogRef.afterClosed().subscribe(
          (result) => this.previousState(result),
          (reason) => this.previousState(reason),
        );
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.dialogRef = null;
  }

  previousState(result?: any): void {
    const params: any = {
      id: null,
    };
    this.router.navigate([{outlets: {popup: null}}], {
      relativeTo: this.activatedRoute.parent,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
