import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared';
import {ExampleStampComponent} from './example-stamp/example-stamp.component';
import {ExampleStampRoute} from './example-stamp.route';
import {RouterModule, Routes} from '@angular/router';
import {
  ExampleStampDialogComponent,
  ExampleStampPopupComponent,
} from './example-stamp-dialog/example-stamp-dialog.component';
import {
  ExampleStampDeleteDialogComponent,
  ExampleStampDeletePopupComponent
} from "./example-stamp-delete-dialog/example-stamp-delete-dialog.component";

const ENTITY_STATES = [...ExampleStampRoute];

@NgModule({
  declarations: [
    ExampleStampComponent,
    ExampleStampDialogComponent,
    ExampleStampDeletePopupComponent,
    ExampleStampDeleteDialogComponent,
    ExampleStampPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
  ],
  entryComponents: [ExampleStampDialogComponent, ExampleStampDeleteDialogComponent],
})
export class ExampleStampModule {}
