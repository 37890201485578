import {SharedModule} from 'src/app/shared';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EntityLocationDialogComponent, EntityLocationPopupComponent} from './entity-location-dialog/entity-location-dialog.component';

@NgModule({
  declarations: [
    EntityLocationDialogComponent,
    EntityLocationPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  entryComponents: [
    EntityLocationDialogComponent,
    EntityLocationPopupComponent,
  ],
})
export class EntityLocationModule { }
