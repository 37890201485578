import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'src/app/app.constants';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  IUom,
  IThuHoach,
  ITrong,
  IThucTrangCayTrong,
  ICapNhatMoiTruong,
  IBonPhan,
  ISuDungNuoc,
  IPhunThuoc,
  IDieuTraDichBenh,
  IXuLiThuHoach,
  IDongGoi,
  IXuatBan,
  IKetThucCanhTac,
  IHoatDongKhac,
} from '../canh-tac/hanh-dong.model';
import {createRequestOption} from 'src/app/shared';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThuHoachService {
  private resourceUrl = SERVER_API_URL + 'api/thu-hoaches';
  private resourceSearhUrl = SERVER_API_URL + 'api/_search/tb-harvests';
  private resourceActivityUrl = SERVER_API_URL + '/api';
  constructor(private http: HttpClient) {}

  query(req?: any): Observable<HttpResponse<IThuHoach[]>> {
    const options = createRequestOption(req);
    return this.http.get<IThuHoach[]>(SERVER_API_URL + 'api/tb-harvests', {
      params: options,
      observe: 'response',
    });
  }

  getActivesThuHoach(
    req?: any,
    thuHoachId?: any,
  ): Observable<HttpResponse<any[]>> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(
      `api/item-time-lines/getSauThuHoach/${thuHoachId}`,
      {params: options, observe: 'response'},
    );
  }
  findUom(uomId: number): Observable<HttpResponse<IThuHoach>> {
    return this.http
      .get<IUom>(`${this.resourceActivityUrl}/activity-nursery/${uomId}`, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<IUom>) => res));
  }
  find(thuHoachId: number): Observable<HttpResponse<IThuHoach>> {
    return this.http
      .get<IThuHoach>(
        `${this.resourceActivityUrl}/activity-harvest/${thuHoachId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<IThuHoach>) => res));
  }

  findTrong(trongId: number): Observable<HttpResponse<ITrong>> {
    return this.http
      .get<ITrong>(`${this.resourceActivityUrl}/activity-planting/${trongId}`, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<ITrong>) => res));
  }

  findThuctrangCayTrong(
    cropId: number,
  ): Observable<HttpResponse<IThucTrangCayTrong>> {
    return this.http
      .get<IThucTrangCayTrong>(
        `${this.resourceActivityUrl}/activity-crop-status/${cropId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<IThucTrangCayTrong>) => res));
  }

  findCapNhatMoiTruong(
    cropId: number,
  ): Observable<HttpResponse<ICapNhatMoiTruong>> {
    return this.http
      .get<ICapNhatMoiTruong>(
        `${this.resourceActivityUrl}/activity-environment-update/${cropId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<ICapNhatMoiTruong>) => res));
  }

  findBonPhan(bonPhanId: number): Observable<HttpResponse<IBonPhan>> {
    return this.http
      .get<IBonPhan>(
        `${this.resourceActivityUrl}/activity-manuring/${bonPhanId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<ITrong>) => res));
  }

  findPhunThuocBVTV(phunThuocId: number): Observable<HttpResponse<IBonPhan>> {
    return this.http
      .get<IPhunThuoc>(
        `${this.resourceActivityUrl}/activity-spraying/${phunThuocId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<IPhunThuoc>) => res));
  }

  findSuDungNuoc(suDungNuocId: number): Observable<HttpResponse<ISuDungNuoc>> {
    return this.http
      .get<ISuDungNuoc>(
        `${this.resourceActivityUrl}/activity-water-usage/${suDungNuocId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<ISuDungNuoc>) => res));
  }

  findHoatDongKhac(
    hoatDongKhacId: number,
  ): Observable<HttpResponse<IHoatDongKhac>> {
    return this.http
      .get<ISuDungNuoc>(
        `${this.resourceActivityUrl}/activity-other/${hoatDongKhacId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<IHoatDongKhac>) => res));
  }
  findDieuTraDichBenh(
    dichBenhId: number,
  ): Observable<HttpResponse<IDieuTraDichBenh>> {
    return this.http
      .get<IDieuTraDichBenh>(
        `${this.resourceActivityUrl}/activity-pests-investigation/${dichBenhId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<IDieuTraDichBenh>) => res));
  }

  findXuLiThuHoach(
    xuliThuHoachId: number,
  ): Observable<HttpResponse<IXuLiThuHoach>> {
    return this.http
      .get<IXuLiThuHoach>(
        `${this.resourceActivityUrl}/activity-process/${xuliThuHoachId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<IXuLiThuHoach>) => res));
  }

  findDongGoi(dongGoiId: number): Observable<HttpResponse<IDongGoi>> {
    return this.http
      .get<IDongGoi>(
        `${this.resourceActivityUrl}/activity-packing/${dongGoiId}`,
        {observe: 'response'},
      )
      .pipe(map((res: HttpResponse<IDongGoi>) => res));
  }

  findXuatBan(xuatBanId: number): Observable<HttpResponse<IXuatBan>> {
    return this.http
      .get<IXuatBan>(`${this.resourceActivityUrl}/activity-sell/${xuatBanId}`, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<IXuatBan>) => res));
  }

  findKetThucCanhTac(
    ketThucCanhTacId: number,
  ): Observable<HttpResponse<IKetThucCanhTac>> {
    return this.http
      .get<IKetThucCanhTac>(
        `${this.resourceActivityUrl}/activity-end/${ketThucCanhTacId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<IKetThucCanhTac>) => res));
  }

  search(req?: any): Observable<HttpResponse<IThuHoach[]>> {
    const options = createRequestOption(req);
    return this.http.get<IThuHoach[]>(this.resourceSearhUrl, {
      params: options,
      observe: 'response',
    });
  }

  delete(thuHoachId: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${thuHoachId}`, {
      observe: 'response',
    });
  }

  create(thuHoach: any): Observable<HttpResponse<any>> {
    return this.http.post(SERVER_API_URL + 'api/thu-hoaches', thuHoach, {
      observe: 'response',
    });
  }

  update(thuHoach: any): Observable<HttpResponse<any>> {
    return this.http.put(SERVER_API_URL + 'api/thu-hoaches', thuHoach, {
      observe: 'response',
    });
  }
}
