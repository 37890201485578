import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';
import {createRequestOption} from '../../shared';
import {ISupply, IVatTu, IVatTuDetail, IHistorySupplies, ISupplyBlockDetails} from './vat-tu.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VatTuService {
  private resourceUrl = SERVER_API_URL + 'api/tb-supplies';
  private resuourceDetail = SERVER_API_URL + 'api/detailSupplies';
  private resuourceDetailHistory = SERVER_API_URL + 'api/historySupplies';
  private resourceDeleteHistory = SERVER_API_URL + 'api/tb-stock-updates';
  private resourceBlockDetails = SERVER_API_URL + 'api/tb-block-details';
  constructor(private http: HttpClient) {}

  query(req?: any): Observable<HttpResponse<IVatTu[]>> {
    const options = createRequestOption(req);
    return this.http.get<IVatTu[]>(this.resourceUrl, {
      params: options,
      observe: 'response',
    });
  }

  queryBlockDetails(req?: any): Observable<HttpResponse<ISupplyBlockDetails[]>> {
    return this.http.get<ISupplyBlockDetails[]>(this.resourceBlockDetails, {
      params: req,
      observe: 'response',
    });
  }

  queryBlockDetailsOptions(req?: any): Observable<HttpResponse<ISupplyBlockDetails[]>> {
    return this.http.get<ISupplyBlockDetails[]>(`${this.resourceBlockDetails}/options`, {
      params: req,
      observe: 'response',
    });
  }

  find(vatTuId: number): Observable<HttpResponse<IVatTu>> {
    return this.http
      .get<IVatTu>(`${this.resourceUrl}/${vatTuId}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<IVatTu>) => res));
  }
  findById(vatTuId: number): Observable<HttpResponse<IVatTuDetail>> {
    return this.http
      .get<IVatTuDetail>(`${this.resuourceDetail}/${vatTuId}`, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<IVatTuDetail>) => res));
  }

  findByBlockDetailsId(blockDetailsId: any): Observable<HttpResponse<ISupplyBlockDetails>> {
    return this.http
      .get<ISupplyBlockDetails>(`${this.resourceBlockDetails}/${blockDetailsId}`, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<ISupplyBlockDetails>) => res));
  }
  delete(vatTuId: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${vatTuId}`, {
      observe: 'response',
    });
  }

  deleteHistory(vatTuId: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceDeleteHistory}/${vatTuId}`, {
      observe: 'response',
    });
  }
  create(VT: any): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceUrl, VT, {observe: 'response'});
  }

  update(VT: any): Observable<HttpResponse<any>> {
    return this.http.put(this.resourceUrl, VT, {observe: 'response'});
  }

  import(fileToUpload: File): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();
    formData.append('xlsxCsvFile', fileToUpload, fileToUpload.name);
    return this.http.post(SERVER_API_URL + 'api/vat-tus/import', formData, {
      observe: 'response',
    });
  }

  supplyType(): Observable<HttpResponse<ISupply>> {
    return this.http
      .get<ISupply>(SERVER_API_URL + 'api/list-supplies-types-create', {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<ISupply>) => res));
  }
  historySupplies(
    vatTuId: number,
    req?: any,
  ): Observable<HttpResponse<IHistorySupplies[]>> {
    const options = createRequestOption(req);
    return this.http.get<IHistorySupplies[]>(
      `${this.resuourceDetailHistory}/${vatTuId}`,
      {
        params: options,
        observe: 'response',
      },
    );
  }
  suppliesInWarehouses(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(SERVER_API_URL + 'api/tb-warehouses', {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res.body));
  }

  createSupplyWareHouse(supplyWarehouse: any): Observable<HttpResponse<any>> {
    return this.http.post(
      SERVER_API_URL + 'api/createTBSuppliesInWarehouse',
      supplyWarehouse,
      {observe: 'response'},
    );
  }

  listUnit(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(SERVER_API_URL + 'api/unit/all', {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res.body));
  }

  wareHouseSupplyById(vatTuId: any): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(
        SERVER_API_URL +
          `api/list-supplies-in-warehouses-by-suppliesId/${vatTuId}`,
        {
          observe: 'response',
        },
      )
      .pipe(map((res: HttpResponse<any>) => res.body));
  }


}
