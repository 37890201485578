import {map} from 'rxjs/operators';
import {FilterOperator} from 'src/app/shared';
import {ISupplyBlockDetails, SupplyBlockDetails} from './../../vat-tu/vat-tu.model';
import {Component, OnDestroy, OnInit, ElementRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JhiEventManager} from 'ng-jhipster';
import {GiongService} from '../giong.service';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Giong, GiongDetail} from '../giong.model';
import {IInWareHouse, InWareHouse, SupplyStockUpdateAction} from '../../vat-tu/vat-tu.model';
import {VatTuService} from '../../vat-tu';
// @ts-ignore
import * as moment from 'moment';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-giong-storage-dialog',
  templateUrl: './giong-storage-dialog.component.html',
  styleUrls: ['./giong-storage-dialog.component.scss'],
})
export class GiongStorageDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<GiongStorageDialogComponent>,
    private giongService: GiongService,
    private eventManager: JhiEventManager,
    private vatTuService: VatTuService,
  ) {
    this.isSaving = false;
    this.inputWarehouse = new InWareHouse();
    this.block = new SupplyBlockDetails();
    this.listWareHouse$ = [];
    this.selectedBlock = new SupplyBlockDetails();
  }
  giong: GiongDetail;
  block: ISupplyBlockDetails;
  isSaving: boolean;
  listWareHouse$: any;
  inputWarehouse: IInWareHouse;
  listwareHouseById$: any;
  listBlock$: ISupplyBlockDetails[];
  maxData: any;
  actions = SupplyStockUpdateAction;
  selectedBlock: ISupplyBlockDetails;

  ngOnInit() {
  }

  loadForm() {
    if (this.block.id) {
      this.inputWarehouse.action = this.actions.EXPORT;
      this.inputWarehouse.tbWarehouseId = this.block.tbWarehouseId || this.block.tbEntityId;
      this.inputWarehouse.code = `${this.giong.tbSuppliesDTO.sku}${this.block.code}`;
      this.selectedBlock = this.block;
    } else {
      this.inputWarehouse.code = `${this.giong.tbSuppliesDTO.sku}${this.block.code}`;
    }
  }

  save() {
    this.isSaving = true;
    this.inputWarehouse.tbSuppliesId = this.giong.tbSuppliesDTO.id;
    this.inputWarehouse.updateDate =
      moment(this.inputWarehouse.updateDate).format('YYYY-MM-DDTHH:mm:ss') +
      'Z';

    setTimeout(() => {
      this.vatTuService.createSupplyWareHouse(this.inputWarehouse).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }, 500);
  }

  minExpDate() {
    return moment(this.inputWarehouse.mfgDate).add(1, 'day');
  }

  maxMfgDate() {
    return moment(this.inputWarehouse.expDate).subtract(1, 'day');
  }

  isValidForm(ngForm: NgForm) {
    const isValidExpDate = this.inputWarehouse.expDate > this.inputWarehouse.mfgDate;
    if (this.inputWarehouse.action === this.actions.EXPORT) {
      if (this.inputWarehouse.quantity > this.selectedBlock.quantity) {
          return false;
      }
    }
    return ngForm.form.valid && !this.isSaving && isValidExpDate;
  }

  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'giongListModification',
      content: 'Giong',
    });
    this.eventManager.broadcast({
      name: 'vaiTroBlockDetailsModification',
      content: this.giong,
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }

  changeRadio() {
    this.inputWarehouse = new InWareHouse();
    // this.inputWarehouse.tbWarehouseId = null;
    // this.inputWarehouse.updateDate = null;
    // this.inputWarehouse.quantity = null;
    // this.inputWarehouse.description = null;
    this.maxData = null;
  }

  onSelectWarehouse(event, item: InWareHouse) {
    this.selectedBlock = new SupplyBlockDetails();
    this.inputWarehouse.code = "";
    this.getBlockDetails();
  }

  onSelectBlock(event, item: ISupplyBlockDetails) {
    this.selectedBlock = item;
  }

  getBlockDetails() {
    const req = {};
    req[`tbSuppliesId.${FilterOperator.equals}`] = this.giong.tbSuppliesDTO.id || '';
    req[`tbWarehouseId.${FilterOperator.equals}`] = this.inputWarehouse.tbWarehouseId || '';
    this.vatTuService.queryBlockDetails(req).pipe(
      map(res => res.body)
    ).subscribe(res => {
      this.listBlock$ = res;
    });
  }
}

@Component({
  selector: 'app-storage-dialog',
  template: '',
})
export class GiongStoragePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<GiongStorageDialogComponent>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private giongService: GiongService,
    private vatTuService: VatTuService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Cập nhật số lượng giống lưu kho');
    this.activatedRoute.data.subscribe(({giong, block}: Data) => {
      setTimeout(() => {
        if (giong) {
          const promises: Promise<any>[] = [
            this.giongService.listWarehouse().toPromise(),
            this.vatTuService.wareHouseSupplyById(giong.tbSuppliesDTO.id).toPromise(),
          ];
          if (block && block.id) {
            const req = {};
            req[`tbSuppliesId.${FilterOperator.equals}`] = giong.tbSuppliesDTO.id || '';
            const observable = this.vatTuService.queryBlockDetails(req).pipe(
              map(res => res.body)
            );
            promises.push(observable.toPromise());
          }
          Promise.all(promises).then(values => {
            const [res1, res2, res3] = values;
            this.dialogRef = this.dialog.open(GiongStorageDialogComponent, {
              disableClose: true,
              width: '800px',
            });
            this.dialogRef.componentInstance.giong = giong;
            this.dialogRef.componentInstance.block = block;
            this.dialogRef.componentInstance.listWareHouse$ = res1;
            this.dialogRef.componentInstance.listwareHouseById$ = res2;
            if (res3) {
              this.dialogRef.componentInstance.listBlock$ = res3;
            }
            this.dialogRef.componentInstance.loadForm();
            this.dialogRef.afterClosed().subscribe(
              (result) => this.previousState(result),
              (reason) => this.previousState(reason),
            );
          }).catch(reason => console.warn(reason));
        }
      }, 0);
    });
  }

  ngOnDestroy() {}

  previousState(result?: any): void {
    const params: any = {
      blockDetailsId: null,
    };
    this.router.navigate([{outlets: {popup: null}}], {
      relativeTo: this.activatedRoute.parent,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
