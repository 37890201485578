export enum ShapeType {
    MAT_BUTTON = 'mat-button',
    MAT_MINI_FAB_BUTTON = 'mat-mini-fab',
    FREE_FORM = 'free-form',
    ICON = 'icon',
    TEXT = 'text',
    POLYGON = 'polygon',
  }


  export const shapeTypes = [
    {key: ShapeType.TEXT, label: 'Text'},
    {key: ShapeType.MAT_BUTTON, label: 'MAT button'},
    {key: ShapeType.MAT_MINI_FAB_BUTTON, label: 'MAT mini fab button'},
    {key: ShapeType.ICON, label: 'Icon'},
    {key: ShapeType.FREE_FORM, label: 'Freeform'},
    {key: ShapeType.POLYGON, label: 'Polygon'},
  ];


  export interface ILocation {
    x: number;
    pixelX: number;
    latitude: number;

    y: number;
    pixelY: number;
    longitude: number;
  }
