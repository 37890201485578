import {DashboardTypesResolve} from './../../home/dashboard-type/dashboard-type.resolve';
import {DonViSettingResolver} from './don-vi-setting-dialog/don-vi-setting.resolver';
import {LoaiLoListResolver} from './../loai-lo/loai-lo-list.resolver';
import {LoaiHoatDongListResolver} from './../loai-hoat-dong/loai-hoat-dong-list.resolver';
import {DonViSettingPopupComponent} from './don-vi-setting-dialog/don-vi-setting-dialog.component';
import {UserRoleDeletePopupComponent} from './../../admin/user-management/user-role-delete-dialog/user-role-delete-dialog.component';
import {DonViRolePopupComponent} from './don-vi-role-dialog/don-vi-role-dialog.component';
import {VaiTroResolve} from './../vai-tro/vai-tro.resolver';
import {
  Routes,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {DonViComponent} from './don-vi/don-vi.component';
import {Injectable} from '@angular/core';
import {DonViService} from './don-vi.service';
import {DonViUser, IDonViUser} from './don-vi.model';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {DonViMgmtDeletePopupComponent} from './don-vi-delete-dialog/don-vi-delete-dialog.component';
import { DonViDetailComponent } from './don-vi-detail/don-vi-detail.component';
import { DonViPopupComponent } from './don-vi-dialog/don-vi-dialog.component';
import {entityLocationRoutes} from '../entity-location/entity-location.route';
import { plainToClass } from 'class-transformer';

@Injectable({providedIn: 'root'})
export class DonViManagementResolve implements Resolve<IDonViUser> {
  constructor(private service: DonViService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['donVi'] ? route.params['donVi'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((donVi: HttpResponse<IDonViUser>) => plainToClass(DonViUser, donVi.body)));
    }
    return of(new DonViUser());
  }
}

export const donViRoute: Routes = [
  {
    path: 'danh-sach-don-vi',
    component: DonViComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_list_unit_managerment'],
      pageTitle: 'Danh sách đơn vị',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'chi-tiet-don-vi/:donVi',
    component: DonViDetailComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
      donVi: DonViManagementResolve,
      dashboardTypes: DashboardTypesResolve,
    },
    data: {
      authorities: ['can_detail_unit_managerment'],
      pageTitle: 'Chi tiết đơn vị'
    },
    canActivate: [UserRouteAccessService],
    children: entityLocationRoutes,
  },
];

export const donViPopupRoute: Routes = [
  {
    path: 'them-don-vi',
    component: DonViPopupComponent,
    data: {
      authorities: ['can_add_unit_managerment'],
      pageTitle: 'Thêm đơn vị'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'sua-don-vi/:donVi',
    component: DonViPopupComponent,
    resolve: {
      donVi: DonViManagementResolve,
      dashboardTypes: DashboardTypesResolve,
    },
    data: {
      authorities: ['can_update_unit_managerment'],
      pageTitle: 'Sửa đơn vị'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'xoa-don-vi/:donVi',
    component: DonViMgmtDeletePopupComponent,
    resolve: {
      donVi: DonViManagementResolve
    },
    data: {
      authorities: ['can_delete_unit_managerment'],
      pageTitle: 'Xóa đơn vị'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
];


export const donViRolePopupRoute: Routes = [
  {
    path: 'them-vai-tro-don-vi/:donViId',
    component: DonViRolePopupComponent,
    resolve: {
      role: VaiTroResolve,
    },
    data: {
      authorities: ['can_create_role'],
      pageTitle: 'Thêm vai trò',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'don-vi/:donViId/sua-vai-tro/:id',
    component: DonViRolePopupComponent,
    resolve: {
      role: VaiTroResolve,
    },
    data: {
      authorities: ['can_update_role'],
      pageTitle: 'Sửa vai trò',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'don-vi/:donViId/xoa-vai-tro/:id',
    component: UserRoleDeletePopupComponent,
    resolve: {
      role: VaiTroResolve,
    },
    data: {
      authorities: ['can_delete_role'],
      pageTitle: 'Xóa vai trò',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
];


export const donViSettingPopupRoute: Routes = [
  {
    path: 'don-vi/:donVi/cai-dat',
    component: DonViSettingPopupComponent,
    resolve: {
      loaiHoatDongList: LoaiHoatDongListResolver,
      loaiLoList: LoaiLoListResolver,
      donVi: DonViManagementResolve,
      donViSetting: DonViSettingResolver,
    },
    data: {
      authorities: ['can_update_role'],
      pageTitle: 'Sửa vai trò',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
];
