import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {Principal} from '../../../core';
import {ActivatedRoute} from '@angular/router';
import {QrCodeService} from '../qr-code.service';
import {MatTableDataSource} from '@angular/material/table';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from '../../../shared';
import {PageEvent} from '@angular/material/paginator';
import {HttpResponse} from '@angular/common/http';
import {IProduct} from '../../san-pham/san-pham.model';
import {Subscription} from 'rxjs';
import {statusConvert} from "../qr-code.model";
import {DialogService} from '../../../shared/confirm-dialog/dialog.service';
import {exportFileData} from '../../../shared/util/export-file';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['id', 'productName', 'maLo', 'soLuong', 'ngayTao', 'trangThai', 'action'];
  dataSource: any;
  pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;
  ELEMENT_DATA: any[] = [];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage = ITEMS_PER_PAGE;
  page = 1;
  currentSearch = '';
  eventSubscriber: Subscription;
  noData: boolean;
  statusConvert = statusConvert;
  constructor(
    private titleService: Title,
    private alertService: JhiAlertService,
    private principal: Principal,
    private parseLinks: JhiParseLinks,
    private eventManager: JhiEventManager,
    private activatedRoute: ActivatedRoute,
    private qrcodeService: QrCodeService,
    private confirmService: DialogService,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Danh sách tạo mã QR-code');
    this.loadAll();
    this.eventSubscriber = this.eventManager.subscribe(
        'qrcodeListModification',
        () => this.loadAll(),
    );
  }

  ngOnDestroy() {
    if (this.eventSubscriber) {
      this.eventManager.destroy(this.eventSubscriber);
    }
  }
  search(query: string) {
    this.page = 1;
    this.currentSearch = query;
    this.loadAll();
  }
  loadAll() {
    this.qrcodeService
        .query({
          page: this.page - 1,
          size: this.itemsPerPage,
          sort: ['createdDate,desc']
        }, {
          productName: this.currentSearch
        }).subscribe(
        (res: HttpResponse<IProduct[]>) => {
          this.totalItems = res.headers.get('X-Total-Count');
          this.queryCount = this.totalItems;
          this.ELEMENT_DATA = res.body;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.noData = res.body.length === 0;
        },
        (res: HttpResponse<any>) => {
          this.alertService.error(res.body.error, res.body.message, null);
        },
    );
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }

  private onSuccess(data: any, headers: any, type: string) {
    this.totalItems = headers.get('X-Total-Count');
    this.queryCount = this.totalItems;
    this.ELEMENT_DATA = data;
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.noData = data.length === 0;
  }

  changeStatusCode(qrCode: any, status: string) {
    this.confirmService.confirm(`${status === "ACTIVE" ? "Kích hoạt" : "Hủy"} mã QR `, `Bạn muốn ${status === "ACTIVE" ? "kích hoạt" : "hủy"} tất cả mã QR của lần tạo này?`)
        .subscribe(res => {
          if (res) {
            this.qrcodeService.updateTBCode(qrCode.id, status ).subscribe((response) => {
              console.log(response);
            });
          }
        });
  }

  exportFile(item: any, isPdf: boolean): void {
    if (isPdf) {
      this.qrcodeService.exportPdfQuery(item.id).subscribe((res) => {
        exportFileData(res.body, res.headers);
      });
    } else {
      this.qrcodeService.exportQuery(item.code).subscribe((res) => {
        exportFileData(res.body, res.headers);
      });
    }
  }
}
