import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivityService} from '../activity.service';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ActivitySetting, IActivitySetting, IFormChildList, FormChildList, ParamSetting, IParam} from '../activity.model';

@Component({
  selector: 'app-activity-type-detail',
  templateUrl: './activity-type-detail.component.html',
  styleUrls: ['./activity-type-detail.component.scss']
})
export class ActivityTypeDetailComponent implements OnInit, OnDestroy {
  activitySetting: IActivitySetting;
  extendFormChild: IFormChildList[];
  controlType: any;
  optionUnit: any;
  constructor(
      private activityService: ActivityService,
      private alertService: JhiAlertService,
      private eventManager: JhiEventManager,
      private parseLinks: JhiParseLinks,
      private activatedRoute: ActivatedRoute,
      private titleService: Title,
  ) {
    this.activitySetting = new ActivitySetting();
    this.extendFormChild = [];
    this.controlType = [];
    this.optionUnit = [];
  }
  ngOnInit() {
    this.titleService.setTitle('Chi tiết hoạt động');
  }
  ngOnDestroy() {
  }
}
