import {LoaiKhuVucService} from './loai-khu-vuc.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    LoaiKhuVucService,
  ],
})
export class LoaiKhuVucModule { }
