import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  ISupply,
  IVatTu,
  VatTu,
  IVatTuDetail,
  VatTuDetail,
  IHistorySupplies,
} from '../vat-tu.model';
import {Subscription} from 'rxjs';
import {JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {HttpResponse} from '@angular/common/http';
import {VatTuService} from '../vat-tu.service';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from '../../../shared';
import {MatTableDataSource, PageEvent} from '@angular/material';

@Component({
  selector: 'app-vat-tu-detail',
  templateUrl: './vat-tu-detail.component.html',
  styleUrls: ['./vat-tu-detail.component.scss'],
})
export class VatTuDetailComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'tbWarehouseName',
    'updateDate',
    'action',
    'quantity',
    'description',
    'actions',
  ];
  vatTu: VatTuDetail;
  dataSource: any;
  pageSizeOptions: number[];
  ELEMENT_DATA: IHistorySupplies[];
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  noData: boolean;
  eventSubscriber: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private eventManager: JhiEventManager,
    private parseLinks: JhiParseLinks,
    private vatTuService: VatTuService,
  ) {
    this.dataSource = null;
    this.ELEMENT_DATA = [];
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.vatTu = new VatTuDetail();
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({vatTu, pagingParams}) => {
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
      setTimeout(() => {
        if (vatTu !== undefined) {
          this.vatTu = vatTu;
          this.loadAll();
          this.changeListSupply();
        }
      }, 0);
    });
  }
  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }
  changeListSupply() {
    this.eventSubscriber = this.eventManager.subscribe(
      'vattuListModification',
      () => {
        this.loadAll();
        this.loadDetail();
      },
    );
  }
  loadAll() {
    this.vatTuService
      .historySupplies(this.vatTu.tbSuppliesDTO.id, {
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: this.sort(),
      })
      .subscribe((res: HttpResponse<IHistorySupplies[]>) =>
        this.onSuccess(res.body, res.headers, 'historySupplies'),
      );
  }
  loadDetail() {
    this.vatTuService
      .findById(this.vatTu.tbSuppliesDTO.id)
      .subscribe((res) =>
        this.onSuccess(res.body, res.headers, 'detailSupplies'),
      );
  }
  private onSuccess(data: any, headers: any, type: string) {
    if (type === 'historySupplies') {
      this.links = this.parseLinks.parse(headers.get('link'));
      this.totalItems = headers.get('X-Total-Count');
      this.queryCount = this.totalItems;
      this.ELEMENT_DATA = data;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      if (data.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    }
    if (type === 'detailSupplies') {
      this.vatTu = data;
    }
  }
  private sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }
  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }
}
