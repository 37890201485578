export interface IQrcode {
  id?: any;
  expiredDate?: string;
  quantity?: number;
  description?: string;
  tBCropId?: any;
  tbExampleStampId?: any;
  productName?: string;
}


export class Qrcode implements IQrcode {
  constructor(
    public id?: any,
    public expiredDate?: string,
    public quantity?: number,
    public description?: string,
    public tBCropId?: any,
    public tbExampleStampId?: any,
    public productName?: string
  ) {
    this.id = id ? id : null;
    this.tBCropId = tBCropId || null;
    this.productName = productName || null;
    this.tbExampleStampId = tbExampleStampId || null;
    this.expiredDate = expiredDate ? expiredDate : null;
    this.quantity = quantity ? quantity : null;
    this.description = description ? description : null;
  }
}

export interface IQrcreate {
  code?: string;
  status?: string;
  lastScan?: string;
  numScan?: number;
}

export class Qrcreate implements IQrcreate {
  constructor(
    public code?: string,
    public status?: string,
    public lastScan?: string,
    public numScan?: number,
  ) {
    this.code = code ? code : null;
    this.status = status ? status : null;
    this.lastScan = lastScan ? lastScan : null;
    this.numScan = numScan ? numScan : null;
  }
}

export function statusConvert(status: any) {
  const statusName = {
    NEW: 'Mới',
    ACTIVE: 'Kích hoạt',
    EXPIRED: 'Hết hạn',
    CANCELED: 'Hủy',
  };
  return statusName.hasOwnProperty(status)
      ? statusName[status]
      : 'Chưa cập nhật';
}
