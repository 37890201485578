import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {Principal} from '../../core';
import {Permission} from 'src/app/core/user/permisions.service';
import {LocalStorageService} from 'ngx-webstorage';

@Directive({
  selector: '[appHasAnyAuthorityDisablement]',
})
export class HasAnyAuthorityDisablementDirective {
  private authorities: string[];

  constructor(
    private principal: Principal,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private permission: Permission,
    private localStorage: LocalStorageService,
  ) {}
  @Input()
  set appHasAnyAuthorityDisablement(value: string | string[]) {
    this.authorities = typeof value === 'string' ? [value] : value;
    this.disableView();
    // Get notified each time authentication state changes.
    this.principal
      .getAuthenticationState()
      .subscribe((identity) => this.disableView());
  }

  private disableView(): void {
    const roleId = this.localStorage.retrieve('account.roleId');
    if (roleId) {
      this.permission.permissionUser(roleId);
      this.principal
        .hasAnyAuthority(this.authorities, this.permission.listPermission)
        .then((result) => {
          if (!result) {
            this.renderer.addClass(this.elementRef.nativeElement, 'disabled');
            this.renderer.setAttribute(
              this.elementRef.nativeElement,
              'disabled',
              'disabled',
            );
          }
          // this.viewContainerRef.createEmbeddedView(this.templateRef);
        });
    }
  }
}
