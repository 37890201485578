import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {Giong} from '../giong.model';
import {GiongService} from '../giong.service';
import {JhiEventManager} from 'ng-jhipster';
import {DATE_FORMAT} from 'src/app/shared/constants/input.constants';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-giong-dialog',
  templateUrl: './giong-dialog.component.html',
  styleUrls: ['./giong-dialog.component.scss'],
})
export class GiongDialogComponent implements OnInit {
  giong: Giong;
  isSaving: boolean;
  dateFormat: string = DATE_FORMAT;
  listUnit$: any;
  constructor(
    private dialogRef: MatDialogRef<GiongDialogComponent>,
    private giongService: GiongService,
    private eventManager: JhiEventManager,
    private titleService: Title,
  ) {
    this.isSaving = false;
    this.listUnit$ = [];
    this.giong = new Giong();
  }

  ngOnInit() {
    if (this.giong.id !== null) {
      this.titleService.setTitle('Sửa giống');
    } else {
      this.titleService.setTitle('Thêm giống');
    }
  }

  save() {
    this.isSaving = true;
    this.giong.tbSuppliesTypeId = 1;
    if (this.giong.id !== null) {
      this.giongService.update(this.giong).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    } else {
      this.giongService.create(this.giong).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }
  }

  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'giongListModification',
      content: 'Giong',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-giong-popup',
  template: '',
})
export class GiongPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<GiongDialogComponent>;
  dateFormat: string = DATE_FORMAT;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
    private giongService: GiongService,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({giong}) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(GiongDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        if (giong !== undefined) {
          this.dialogRef.componentInstance.giong = giong.tbSuppliesDTO;
        }
        this.giongService.listUnit().subscribe((res: any) => {
          this.dialogRef.componentInstance.listUnit$ = res;
        });

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách giống');
    this.dialogRef = null;
  }
}
