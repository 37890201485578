import {Component, OnDestroy, OnInit} from '@angular/core';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from '../../../shared';
import {MatTableDataSource} from '@angular/material/table';
import {Title} from '@angular/platform-browser';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {Principal} from '../../../core';
import {ActivatedRoute} from '@angular/router';
import {PageEvent} from '@angular/material/paginator';
import {Subscription} from 'rxjs';
import {TieuChuanService} from '../tieu-chuan.service';
import {HttpResponse} from '@angular/common/http';
import {ITieuChuan} from '../tieu-chuan.model';

@Component({
  selector: 'app-tieu-chuan',
  templateUrl: './tieu-chuan.component.html',
  styleUrls: ['./tieu-chuan.component.scss'],
})
export class TieuChuanComponent implements OnInit, OnDestroy {
  currentSearch: string;
  dataSource: any;
  displayedColumns: string[] = [
    'maTieuChuan',
    'tenTieuChuan',
    'thoiGianCanhTac',
    'ngayTao',
    'action',
  ];
  ELEMENT_DATA: any;
  totalItems: any;
  itemsPerPage: any;
  pageSizeOptions: number[];
  eventSubscriber: Subscription;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  routeData: any;
  links: any;
  queryCount: any;
  noData: boolean;
  constructor(
    private titleService: Title,
    private alertService: JhiAlertService,
    private principal: Principal,
    private parseLinks: JhiParseLinks,
    private eventManager: JhiEventManager,
    private tieuchuanService: TieuChuanService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.dataSource = null;
    this.ELEMENT_DATA = [];
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.routeData = this.activatedRoute.data.subscribe(({pagingParams}) => {
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
    });

    this.currentSearch =
      this.activatedRoute.snapshot &&
      this.activatedRoute.snapshot.params['search']
        ? this.activatedRoute.snapshot.params['search']
        : '';
  }

  ngOnInit() {
    this.titleService.setTitle('Danh sách quy trình');
    this.loadAll();
    this.changedListStandard();
  }

  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }

  changedListStandard() {
    this.eventSubscriber = this.eventManager.subscribe(
      'tieuChuanListModification',
      () => this.loadAll(),
    );
  }

  search(query: string) {
    if (!query) {
      return this.clear();
    }
    this.page = 1;
    this.currentSearch = query;
    this.loadAll();
  }

  clear() {
    this.page = 1;
    this.currentSearch = '';
    this.loadAll();
  }

  loadAll() {
    if (this.currentSearch === '') {
      this.tieuchuanService
        .query({
          page: this.page - 1,
          size: this.itemsPerPage,
          sort: this.sort(),
        })
        .subscribe(
          (res: HttpResponse<ITieuChuan[]>) =>
            this.onSuccess(res.body, res.headers),
          (res: HttpResponse<any>) => this.onError(res.body),
        );
    } else {
      this.tieuchuanService
        .search({
          page: this.page - 1,
          size: this.itemsPerPage,
          query: this.currentSearch,
        })
        .subscribe(
          (res: HttpResponse<ITieuChuan[]>) =>
            this.onSuccess(res.body, res.headers),
          (res: HttpResponse<any>) => this.onError(res.body),
        );
    }
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }

  private onSuccess(data: any, headers: any) {
    this.links = this.parseLinks.parse(headers.get('link'));
    this.totalItems = headers.get('X-Total-Count');
    this.queryCount = this.totalItems;
    this.ELEMENT_DATA = data;
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    if (data.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
  }

  private sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
