import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductStorageHistoryDialogComponent } from './product-storage-history-dialog/product-storage-history-dialog.component';
import { ProductStorageDialogComponent } from './product-storage-dialog/product-storage-dialog.component';

@NgModule({
  declarations: [ProductDetailComponent, ProductStorageHistoryDialogComponent, ProductStorageDialogComponent],
  imports: [
    CommonModule
  ]
})
export class ProductModule { }
