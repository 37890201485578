export const enum FilterOperator {
  contains = 'contains',
  equals = 'equals',
  greaterOrEqualThan = 'greaterOrEqualThan',
  lessThan = 'lessThan',
  lessOrEqualThan = 'lessOrEqualThan',
  in = 'in',
}

export interface IFilterField {
  fieldName?: string;
  filterValue?: any;
  filterOperator?: FilterOperator;
  filterParamStr: string;
}

export class FilterField {
  constructor(
    public fieldName?: string,
    public filterOperator?: FilterOperator,
    public filterValue?: any,
  ) {
    if (!filterOperator) {
      this.filterOperator = FilterOperator.contains;
    }
  }

  public get filterParamStr() {
    if (!this.fieldName || !this.filterValue || this.filterValue === 'null') {
      return '';
    }

    return `${this.fieldName}.${this.filterOperator}`;
  }
}
