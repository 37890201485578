import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes} from '@angular/router';
import {TieuChuanComponent} from './tieu-chuan/tieu-chuan.component';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {TieuChuanPopupComponent} from './tieu-chuan-dialog/tieu-chuan-dialog.component';
import {TieuChuanDetailComponent} from './tieu-chuan-detail/tieu-chuan-detail.component';
import {
  GuidelineDetail,
  IGuidelineDetail,
  IPeriod,
  IStandardDateDetail,
  ITieuChuan,
  Period,
  StandardDateDetail,
  TieuChuan
} from './tieu-chuan.model';
import {TieuChuanService} from './tieu-chuan.service';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {TieuChuanDeletePopupDiaglogComponent} from './tieu-chuan-delete-dialog/tieu-chuan-delete-dialog.component';

import {TieuChuanDeleteDatePopupDiaglogComponent} from './tieu-chuan-delete-date-dialog/tieu-chuan-delete-date-dialog.component';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {GiaiDoanPopupComponent} from './giai-doan-dialog/giai-doan-dialog.component';
import {GiaiDoanDeletePopupDiaglogComponent} from './giai-doan-delete-dialog/giai-doan-delete-dialog.component';
import {NgayTuoiPopupComponent} from './ngay-tuoi-dialog/ngay-tuoi-dialog.component';

import {ActivityTypePopupComponent} from './activity-type-dialog/activity-type-dialog.component';
import {ActivityTypeDeletePopupDialogComponent} from './activity-type-delete-dialog/activity-type-delete-dialog.component';

import {NgayTuoiDuplicatePopupComponent} from './ngay-tuoi-duplicate-dialog/ngay-tuoi-duplicate-dialog.component';

@Injectable({providedIn: 'root'})
export class TieuChuanManagementResolve implements Resolve<ITieuChuan> {
  constructor(private service: TieuChuanService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((tieuchuan: HttpResponse<ITieuChuan>) => tieuchuan.body));
    }
    return of(new TieuChuan());
  }
}

@Injectable({providedIn: 'root'})
export class DetailTieuChuanResolve implements Resolve<any> {
  constructor(private service: TieuChuanService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service
        .detail(id)
        .pipe(map((tieuchuan: HttpResponse<any>) => tieuchuan.body));
    }
    return of(new TieuChuan());
  }
}
@Injectable({providedIn: 'root'})
export class TieuChuanDetailDatetResolve implements Resolve<any> {
  constructor(private service: TieuChuanService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id && id !== 'null') {
      return this.service
        .findDay(id)
        .pipe(map((tieuchuan: HttpResponse<any>) => tieuchuan.body));
    }
    return of(new StandardDateDetail());
  }
}
@Injectable({providedIn: 'root'})
export class GiaiDoanManagementResolve implements Resolve<IPeriod> {
  constructor(private service: TieuChuanService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id && id !== 'null') {
      return this.service
          .findPeriod(id)
          .pipe(map((giaidoan: HttpResponse<IPeriod>) => giaidoan.body));
    }
    // tslint:disable-next-line:radix
    const standard = route.params['standard'] ? parseInt(route.params['standard']) : null;
    const period = new Period();
    period.tbGuidelineId = standard;
    return of(period);
  }
}

@Injectable({providedIn: 'root'})
export class NgayTuoiManagementResolve implements Resolve<IStandardDateDetail> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const standard = route.params['standard'] ? route.params['standard'] : null;
    const standardDate = new StandardDateDetail();
    standardDate.tbGuidelineId = standard;
    return of(standardDate);
  }
}

@Injectable({providedIn: 'root'})
export class GuidelineDetailManagementResolve implements Resolve<IGuidelineDetail> {
  constructor(private service: TieuChuanService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const activityName = route.params['activityName'] ? route.params['activityName'] : null;
    const guidelineId = route.params['guidelineId'] ? route.params['guidelineId'] : null;
    const activityId = route.params['activityId'] ? route.params['activityId'] : null;
    if (activityName && activityId) {
      return this.service
          .getActivityGuidelineDetail(activityName, activityId)
          .pipe(map((guideline: HttpResponse<IGuidelineDetail>) => guideline.body));
    }
    const guidelineDetail = new GuidelineDetail();
    guidelineDetail.tbGuidelineActivityDTO.tbGuidelineDetailsId = guidelineId;
    return of(guidelineDetail);
  }
}

@Injectable({providedIn: 'root'})
export class NgayTuoiDuplicateManagementResolve implements Resolve<IStandardDateDetail> {
  // @ts-ignore
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const dateId = route.params['dateId'] ? route.params['dateId'] : null;
    const guideId = route.params['guideId'] ? route.params['guideId'] : null;
    const standardDate = new StandardDateDetail();
    standardDate.id = dateId;
    standardDate.tbGuidelineId = guideId;
    return of(standardDate);
  }
}
export const TieuChuanRoute: Routes = [
  {
    path: 'danh-sach-tieu-chuan',
    component: TieuChuanComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_tbguidelines'],
      pageTitle: 'Danh sách quy trình',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'tao-ma-tieu-chuan',
    component: TieuChuanPopupComponent,
    data: {
      authorities: ['can_create_tbguidelines'],
      pageTitle: 'Tạo mã quy trình',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'chi-tiet-tieu-chuan/:id',
    component: TieuChuanDetailComponent,
    resolve: {
      tieuchuan: DetailTieuChuanResolve,
    },
    data: {
      authorities: ['can_detail_tbguidelines'],
      pageTitle: 'Chi tiết quy trình',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'xoa-tieu-chuan/:id',
    component: TieuChuanDeletePopupDiaglogComponent,
    resolve: {
      tieuchuan: TieuChuanManagementResolve,
    },
    data: {
      authorities: ['can_delete_tbguidelines'],
      pageTitle: 'Xóa quy trình',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-chi-tiet-ngay/:id',
    component: TieuChuanDeleteDatePopupDiaglogComponent,
    resolve: {
      tieuchuan: TieuChuanDetailDatetResolve,
    },
    data: {
      authorities: ['can_delete_date_tbguidelines'],
      pageTitle: 'Xóa chi tiết ngày quy trình',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-chi-tiet-ngay/null',
    component: TieuChuanDeleteDatePopupDiaglogComponent,
    resolve: {
      tieuchuan: TieuChuanDetailDatetResolve,
    },
    data: {
      authorities: ['can_delete_date_tbguidelines'],
      pageTitle: 'Xóa chi tiết ngày quy trình',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'sua-tieu-chuan/:id',
    component: TieuChuanPopupComponent,
    resolve: {
      tieuchuan: TieuChuanManagementResolve,
    },
    data: {
      authorities: ['can_update_tbguidelines'],
      pageTitle: 'Sửa quy trình',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'them-giai-doan/:standard',
    component: GiaiDoanPopupComponent,
    resolve: {
      giaidoan: GiaiDoanManagementResolve,
    },
    data: {
      authorities: ['can_post_periods'],
      pageTitle: 'Thêm giai đoạn',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'sua-giai-doan/:id',
    component: GiaiDoanPopupComponent,
    resolve: {
      giaidoan: GiaiDoanManagementResolve,
    },
    data: {
      authorities: ['can_update_periods'],
      pageTitle: 'Sửa giai đoạn',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-giai-doan/:id',
    component: GiaiDoanDeletePopupDiaglogComponent,
    resolve: {
      giaidoan: GiaiDoanManagementResolve,
    },
    data: {
      authorities: ['can_delete_periods'],
      pageTitle: 'Xóa giai đoạn',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'them-ngay-tuoi/:standard',
    component: NgayTuoiPopupComponent,
    resolve: {
      ngaytuoi: NgayTuoiManagementResolve,
    },
    data: {
      authorities: ['can_create_date_tbguidelines'],
      pageTitle: 'Thêm ngày tuổi',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'them-guideline-detail/:guidelineId',
    component: ActivityTypePopupComponent,
    resolve: {
      guideline: GuidelineDetailManagementResolve,
    },
    data: {
      authorities: ['can_detail_tbguidelines'],
      pageTitle: 'Thêm chi tiết hoạt động',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'sua-guideline-detail/:guidelineId/:activityName/:activityId',
    component: ActivityTypePopupComponent,
    resolve: {
      guideline: GuidelineDetailManagementResolve,
    },
    data: {
      authorities: ['can_detail_tbguidelines'],
      pageTitle: 'Sửa chi tiết hoạt động',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-guideline-detail/:activityName/:activityId',
    component: ActivityTypeDeletePopupDialogComponent,
    resolve: {
      guideline: GuidelineDetailManagementResolve,
    },
    data: {
      authorities: ['can_detail_tbguidelines'],
      pageTitle: 'Xóa chi tiết hoạt động',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'duplicate-ngay-tuoi/:guideId/:dateId',
    component: NgayTuoiDuplicatePopupComponent,
    resolve: {
      ngaytuoi: NgayTuoiDuplicateManagementResolve,
    },
    data: {
      authorities: ['can_duplicate_tbguidelinedetails'],
      pageTitle: 'Copy ngày tuổi',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  }
];
