import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {SharedModule} from '../shared';
import {UnitRegisterComponent} from './register/unit-register.component';
import {unitRegisterRouter} from './unit-register.route';
const ENTITY_STATES = [...unitRegisterRouter];

@NgModule({
  declarations: [UnitRegisterComponent],
  imports: [SharedModule, RouterModule.forChild(ENTITY_STATES as Routes)],
  entryComponents: [UnitRegisterComponent],
})
export class UnitRegisterModule {}
