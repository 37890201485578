import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-thong-so-moi-truong',
  templateUrl: './thong-so-moi-truong.component.html',
  styleUrls: ['./thong-so-moi-truong.component.scss'],
})
export class ThongSoMoiTruongComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
