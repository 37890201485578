export const CREATE_SUCCESS = 'Tạo mới thành công';
export const CREATE_FAIL = 'Tạo mới thất bại';
export const UPDATE_SUCCESS = 'Cập nhật thành công';
export const UPDATE_FAIL = 'Cập nhật thất bại';
export const DELETE_SUCCESS = 'Xóa thành công';
export const DELETE_FAIL = 'Xóa thất bại';
export const error_code_prefix = {
    'error.1000': 'Tài khoản hoặc mật khẩu không đúng',
    'error.1001': 'Người dùng đã tồn tại',
    'error.1002': 'Số điện thoại đã tồn tại',
    'error.1003': 'Email đã tồn tại',
    'error.1004': 'Email không tồn tại',
    'error.1005': 'Tạo mới người dùng không thê tồn tại id',
    'error.1006': 'Người dùng không tìm thấy',
    'error.1007': 'Người dùng đang login không tim thấy',
    'error.1008': 'Không có người dùng được tìm thấy cho reset key này',
    'error.1009': 'Không có người dùng được tìm thấy cho activation key này',
    'error.1010': 'Tạo mới nhập xuất kho không thể tồn tại id',
    'error.1011': 'Supplies Id không được phép trống',
    'error.1012': 'Warehouse Id không được phép trống',
    'error.1013': 'Current user không được phép nhập xuất kho với suppliy này.',
    'error.1014': 'Số lượng xuất kho vượt quá số lượng trong kho',
    'error.1015': 'Số lượng nhập kho phải lớn hơn 0',
    'error.1016': 'Tên vật tư đã tồn tại trong hệ thống',
    'error.1017': 'Mã SKU đã tồn tại trong hệ thống',
    'error.1018': 'Người dùng hiện tại không thể truy cập lô này',
    'error.1019': 'Người dùng hiện tại không thể truy cập đơn vị này',
    'error.1020': 'Người dùng hiện tại không thể truy cập  này hướng dẫn này',
    'error.1021': 'Số lượng vật tư lớn hơn trong kho',
    'error.1022': 'Người dùng hiện tại không thuộc về bất kì đơn vị nào',
    'error.1023': 'Người dùng hiện tại chưa có role',
    'error.1024': 'Role dùng tạo người dùng không có',
    'error.1025': 'Role dùng cập nhật người dùng không có',
    'error.1026': 'Người dùng hiện tại không có quyền tạo người dùng với role này',
    'error.1027': 'Người dùng hiện tại không có quyền cập nhật người dùng với role này',
    'error.1028': 'SuppliesInWarehouse không tìm thấy',
    'error.1029': 'Phân quyền vai trò không có dữ liệu',
    'error.1030': 'Vật tư không tìm thấy',
    'error.1031': 'Loại lô không tìm thấy',
    'error.1032': 'Thiết bị đã tồn tại',
    'error.1033': 'Thiết bị không tìm thấy',
    'error.1034': 'Chi tiết tiêu chuẩn đã tồn tại ngày tuổi này',
    'error.1035': 'Chi tiết tiêu chuẩn không có ngày tuổi bắt đầu',
    'error.1036': 'Chi tiết tiêu chuẩn ngày tuổi bắt đầu lớn hơn ngày tuổi kết thúc',
    'error.1037': 'Chi tiết tiêu chuẩn ngày tuổi nhỏ hơn ngày tuổi bắt đầu của giai đoạn',
    'error.1038': 'Chi tiết tiêu chuẩn ngày tuổi lớn hơn ngày tuổi kết thúc của giai đoạn',
    'error.1039': 'Cập nhật người phụ trách lô, Lô id không được null',
    'error.1040': 'Cài đặt lô và hoạt động, customer id không được null',
    'error.1041': 'Thông số môi trường có chuỗi dữ liệu không đúng',
    'error.1042': 'Thiết bị có chuỗi dữ liệu không đúng',
    'error.1043': 'Thiệt bị có loại thiết bị id không được null',
    'error.1044': 'Giai đoạn không có ngày tuổi bắt đầu',
    'error.1045': 'Giai đoạn có ngày tuổi bắt đầu lớn hơn ngày tuổi kết thúc',
    'error.1046': 'Tiêu chuẩn có lô đang sử dụng',
    'error.1047': 'Lô đã tồn tại',
    'error.1048': 'Lô không tồn tại',
    'error.1049': 'Vui lòng nhập tên loại hoạt động',
    'error.1050': 'Loại hoạt động không tồn tại',
    'error.1051': 'Control không tồn tại',
    'error.1052': 'Không thể xóa loại hoạt động mặc định',
    'error.1053': 'Loại lô hoặc đơn vị không tồn tại',
    'error.1054': 'Đơn vị không tồn tại',
    'error.1055': 'Lô hàng đã tồn tại cho sản phẩm khác hoặc tồn tại trong kho khác',
    'error.1056': 'Giống hoặc vật tư không thuộc đơn vị này',
    'error.1057': 'Người dùng hiện tại không thể cập nhật lô hàng này',
    'error.1058': 'Người dùng hiện tại không thể cập nhật thiết bị này'
};
