import {Injectable} from '@angular/core';
import {createRequestOption, DATE_FORMAT} from '../../shared';
import {SERVER_API_URL} from '../../app.constants';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IProduct, IProductBatches} from '../san-pham/san-pham.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExampleStampService {
  private resourceStampUrl = SERVER_API_URL + '/api/tb-example-stamp';
  constructor(private http: HttpClient) { }

  query(req: any): Observable<HttpResponse<any>> {
    const options = createRequestOption(req);
    return this.http.get(`${this.resourceStampUrl}`,
        {
          params: options,
          observe: 'response'
        },
    );
  }

  find(productId: number): Observable<HttpResponse<IProduct>> {
    return this.http
        .get<IProduct>(`${this.resourceStampUrl}/${productId}`, {observe: 'response'})
        .pipe(map((res: HttpResponse<IProduct>) => res));
  }

  create(data: any): Observable<HttpResponse<any>> {
    return this.http.post(this.resourceStampUrl, data,
        {observe: 'response'},
    );
  }

  delete(productId: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceStampUrl}/${productId}`, {
      observe: 'response',
    });
  }

  update(data: any): Observable<any> {
    return this.http.put<any>(this.resourceStampUrl, data, {observe: 'response'});
  }

  dropdownListStamp(): Observable<HttpResponse<any>> {
    return this.http
        .get<IProduct>(`${this.resourceStampUrl}/dropdown-list`, {observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => res));
  }
}
