import {DOCUMENT} from '@angular/common';
import {Directive, ElementRef, HostListener, Inject, Input} from '@angular/core';

@Directive({
  selector: '[appFullScreenButton]',
  exportAs: 'fullScreenButton',
})
export class FullScreenButtonDirective {
  public isFullscreen = false;
  private previousHtml = 'Full';
  @Input() targetElement: ElementRef;
  constructor(
    private e: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @HostListener('click', ['$event']) onClick($event) {
    this.isFullscreen = !this.isFullscreen;
    if (this.isFullscreen) {
      this.document.body.classList.add('fullscreen-mode');
      this.previousHtml = this.e.nativeElement.innerHtml;
      this.e.nativeElement.innerHtml = 'Close';
    } else {
      this.document.body.classList.remove('fullscreen-mode');
      this.e.nativeElement.innerHtml = this.previousHtml;
    }
    this.toggleElementByClass(this.isFullscreen, 'show', 'fullscreen');
    this.toggleElementByClass(this.isFullscreen, 'show', 'fullscreen-overlay');
  }

  private toggleElementByClass(value: boolean, classValue: string, className: string) {
    const elements = this.document.getElementsByClassName(className);
    for (const i of elements as any) {
      if (value) {
        i.classList.add(classValue);
      } else {
        i.classList.remove(classValue);
      }
    }
  }
}
