import {Moment} from 'moment';
import * as moment from 'moment';
export enum SupplyStockUpdateAction {
    IMPORT,
    EXPORT
}
export interface IWarehouseProduct {
    updateDate?:            any;
    action?:                number;
    quantity?:              number;
    description?:           string;
    harvestDate?:           any;
    expDate?:               any;
    productLevel?:          number;
    productUnitId?:         number;
    cropId?:                number;
    warehouseId?:           number;
    entityId?:              number;
    productBlockDetailsId?: number;
}

export class WarehouseProduct implements IWarehouseProduct {
    constructor(
        public updateDate?:            any,
        public action?:                number,
        public quantity?:              number,
        public description?:           string,
        public harvestDate?:           any,
        public expDate?:               any,
        public productLevel?:          number,
        public productUnitId?:         number,
        public cropId?:                number,
        public warehouseId?:           number,
        public entityId?:           number,
        public productBlockDetailsId?: number
    ) {
        this.updateDate = updateDate ? updateDate : new Date();
        this.action = action ? action : null;
        this.quantity = quantity ? quantity : null;
        this.description = description ? description : null;
        this.harvestDate = harvestDate ? harvestDate : new Date();
        this.expDate = expDate ? expDate : moment(this.updateDate).add(7, 'days').toDate();
        this.productLevel = productLevel ? productLevel : null;
        this.productUnitId = productUnitId ? productUnitId : null;
        this.cropId = cropId ? cropId : null;
        this.productBlockDetailsId = productBlockDetailsId ? productBlockDetailsId : null;
        this.warehouseId = warehouseId ? warehouseId : null;
        this.entityId = entityId ? entityId : null;
    }
}

export interface IImportWarehouseProduct {
    updateDate?:            Moment;
    action?:                number;
    quantity?:              number;
    description?:           string;
    harvestDate?:           Moment;
    expDate?:               Moment;
    productLevel?:          number;
    productUnitId?:         number;
    cropId?:                number;
    warehouseId?:           number;
    productBlockDetailsId?: number;
}

export class ImportWarehouseProduct implements IImportWarehouseProduct {
    constructor(
        public updateDate?:            Moment,
        public action?:                number,
        public quantity?:              number,
        public description?:           string,
        public harvestDate?:           Moment,
        public expDate?:               Moment,
        public productLevel?:          number,
        public productUnitId?:         number,
        public cropId?:                number,
        public warehouseId?:           number,
        public productBlockDetailsId?: number
    ) {
        this.updateDate = updateDate ? updateDate : null;
        this.action = action ? action : null;
        this.quantity = quantity ? quantity : null;
        this.description = description ? description : null;
        this.harvestDate = harvestDate ? harvestDate : null;
        this.expDate = expDate ? expDate : null;
        this.productLevel = productLevel ? productLevel : null;
        this.productUnitId = productUnitId ? productUnitId : null;
        this.cropId = cropId ? cropId : null;
        this.productBlockDetailsId = productBlockDetailsId ? productBlockDetailsId : null;
        this.warehouseId = warehouseId ? warehouseId : null;
    }
}

export interface IProduct {
    id?: number;
    quantity?: number;
    code?: string;
    harvestDate?: Moment;
    expDate?: Moment;
    productLevel?: number;
    tbProductUnitId?: number;
    tbProductUnitName?: string;
    tbCropId?: number;
    tbCropCode?: string;
    tbSuppliesName?: string;
    tbWarehouseId?: number;
    tbWarehouseName?: string;
    tbEntityId?: number;
    tbEntityName?: string;
    description?: string;
}

export class Product implements IProduct {
    constructor(
        public id?: number,
        public quantity?: number,
        public code?: string,
        public harvestDate?: Moment,
        public expDate?: Moment,
        public productLevel?: number,
        public tbProductUnitId?: number,
        public tbProductUnitName?: string,
        public tbCropId?: number,
        public tbCropCode?: string,
        public tbSuppliesName?: string,
        public tbWarehouseId?: number,
        public tbWarehouseName?: string,
        public tbEntityId?: number,
        public tbEntityName?: string,
        public description?: string
    ) {
        this.id = id ? id : null;
        this.quantity = quantity ? quantity : null;
        this.code = code ? code : null;
        this.harvestDate = harvestDate ? harvestDate : null;
        this.expDate = expDate ? expDate : null;
        this.productLevel = productLevel ? productLevel : null;
        this.tbProductUnitId = tbProductUnitId ? tbProductUnitId : null;
        this.tbProductUnitName = tbProductUnitName ? tbProductUnitName : null;
        this.tbCropId = tbCropId ? tbCropId : null;
        this.tbCropCode = tbCropCode ? tbCropCode : null;
        this.tbSuppliesName = tbSuppliesName ? tbSuppliesName : null;
        this.tbWarehouseId = tbWarehouseId ? tbWarehouseId : null;
        this.tbWarehouseName = tbWarehouseName ? tbWarehouseName : null;
        this.tbEntityId = tbEntityId ? tbEntityId : null;
        this.tbEntityName = tbEntityName ? tbEntityName : null;
        this.description = description ? description : null;
    }
}

export interface IProductBatches {
    id?:                                 number;
    updateDate?:                         Moment;
    action?:                             number;
    quantity?:                           number;
    description?:                        string;
    updateBy?:                           number;
    tbproductBlockDetailsId?:            number;
    tbProductBlockDetailsCode?:          string;
    tbProductBlockDetailsQuantity?:      number;
    tbProductBlockDetailsEntityName?: string;
    tbActivityId?:                       number;
}

export class ProductBatches implements IProductBatches {
    constructor(
        public id?:                                 number,
        public updateDate?:                         Moment,
        public action?:                             number,
        public quantity?:                           number,
        public description?:                        string,
        public updateBy?:                           number,
        public tbproductBlockDetailsId?:            number,
        public tbProductBlockDetailsCode?:          string,
        public tbProductBlockDetailsQuantity?:      number,
        public tbProductBlockDetailsEntityName?: string,
        public tbActivityId?:                       number
    ) {
        this.id = id ? id : null;
        this.updateDate = updateDate ? updateDate : null;
        this.action = action ? action : null;
        this.quantity = quantity ? quantity : null;
        this.description = description ? description : null;
        this.updateBy = updateBy ? updateBy : null;
        this.tbproductBlockDetailsId = tbproductBlockDetailsId ? tbproductBlockDetailsId : null;
        this.tbProductBlockDetailsCode = tbProductBlockDetailsCode ? tbProductBlockDetailsCode : null;
        this.tbProductBlockDetailsQuantity = tbProductBlockDetailsQuantity ? tbProductBlockDetailsQuantity : null;
        this.tbProductBlockDetailsEntityName = tbProductBlockDetailsEntityName ? tbProductBlockDetailsEntityName : null;
        this.tbActivityId = tbActivityId ? tbActivityId : null;
    }
}
