import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared';
import {RouterModule, Routes} from '@angular/router';

import {ActivityTypeComponent, activityRoute, donViPopupRoute} from './';
import {
  ActivityTypeMgmtDeleteDialogComponent,
  ActivityTypeMgmtDeletePopupComponent,
} from './activity-type-delete-dialog/activity-type-delete-dialog.component';
import { ActivityTypeDialogComponent, ActivityTypePopupComponent } from './activity-type-dialog/activity-type-dialog.component';
import { ActivityTypeDetailComponent } from './activity-type-detail/activity-type-detail.component';
import { ActivityTypeParamComponent } from './activity-type-param/activity-type-param.component';
import { ActivityTypeParamPopupComponent, ActivityTypeParamDialogComponent } from './activity-type-param-dialog/activity-type-param-dialog.component';
import { ActivityDeleteParamPopupComponent, ActivityDeleteParamDialogComponent } from './activity-type-param-delete-dialog/activity-type-delete-param-dialog.component';
import { ActivityTypeObjectComponent } from './activity-type-object/activity-type-object.component';
import { ActivityTypeObjectDialogComponent, ActivityTypeObjectPopupComponent } from './activity-type-object-dialog/activity-type-object-dialog.component';
import { ActivityDeleteObjectDialogComponent, ActivityDeleteObjectPopupComponent } from './activity-type-object-delete-dialog/activity-type-delete-object-dialog.component';
import { ActivityTypeChildComponent } from './activity-type-child/activity-type-child.component';
import { ActivityTypeChildDialogComponent, ActivityTypeChildPopupComponent } from './activity-type-child-dialog/activity-type-child-dialog.component';
import { ActivityDeleteChildDialogComponent, ActivityDeleteChildPopupComponent } from './activity-type-child-delete-dialog/activity-type-delete-child-dialog.component';

const ENTITY_STATES = [...activityRoute, ...donViPopupRoute];

@NgModule({
  declarations: [
    ActivityTypeComponent,
    ActivityTypeDetailComponent,
    ActivityTypeDialogComponent,
    ActivityTypePopupComponent,
    ActivityTypeMgmtDeleteDialogComponent,
    ActivityTypeMgmtDeletePopupComponent,
    ActivityTypeParamComponent,
    ActivityTypeParamPopupComponent,
    ActivityTypeParamDialogComponent,
    ActivityDeleteParamPopupComponent,
    ActivityDeleteParamDialogComponent,
    ActivityTypeObjectComponent,
    ActivityTypeObjectDialogComponent,
    ActivityTypeObjectPopupComponent,
    ActivityDeleteObjectDialogComponent,
    ActivityDeleteObjectPopupComponent,
    ActivityTypeChildComponent,
    ActivityTypeChildDialogComponent,
    ActivityTypeChildPopupComponent,
    ActivityDeleteChildDialogComponent,
    ActivityDeleteChildPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ENTITY_STATES as Routes),
  ],
  entryComponents: [
    ActivityTypeDialogComponent,
    ActivityTypeMgmtDeleteDialogComponent,
    ActivityTypeParamDialogComponent,
    ActivityDeleteParamDialogComponent,
    ActivityTypeObjectDialogComponent,
    ActivityDeleteObjectDialogComponent,
    ActivityTypeChildDialogComponent,
    ActivityDeleteChildDialogComponent,
  ],
})
export class ActivityModule {}
