import {Component, OnInit, AfterViewInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DonViService} from 'src/app/entities/don-vi';
import {UnitRegister} from '../unit-register.model';

@Component({
  selector: 'app-unit-register',
  templateUrl: './unit-register.component.html',
  styleUrls: ['./unit-register.scss'],
})
export class UnitRegisterComponent implements OnInit, AfterViewInit {
  unitRegister: UnitRegister;
  isSaving: boolean;
  constructor(
    private titleService: Title,
    private donviService: DonViService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Đăng ký đơn vị');
    this.unitRegister = new UnitRegister();
    this.isSaving = false;
  }

  ngAfterViewInit() {}

  requestRegister() {
    this.donviService.register(this.unitRegister).subscribe(
      (response) => this.onSaveSuccess(),
      () => this.onSaveError(),
    );
  }

  clear() {
    this.unitRegister = new UnitRegister();
  }
  private onSaveSuccess() {
    this.isSaving = true;
    // this.router.navigate(['/login']);
    this.clear();
  }

  private onSaveError() {
    this.isSaving = false;
  }
}
