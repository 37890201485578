import {Routes} from '@angular/router';
import {NortificationComponent} from './nortification.component';
import {UserRouteAccessService} from '../../core';
import {JhiResolvePagingParams} from 'ng-jhipster';

export const Nortification: Routes = [
  {
    path: 'notifications',
    component: NortificationComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_list_breed_managerment'],
      pageTitle: 'Thông báo',
    },
    canActivate: [UserRouteAccessService],
  },
];
