import {Routes} from '@angular/router';
import {UnitRegisterComponent} from './register/unit-register.component';

export const unitRegisterRouter: Routes = [
  {
    path: 'dang-ky-don-vi',
    component: UnitRegisterComponent,
    data: {
      pageTitle: 'Đăng ký đơn vị',
    },
  },
];
