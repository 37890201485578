import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'src/app/app.constants';
import {Observable} from 'rxjs';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {IDonVi} from './don-vi.model';
import {createRequestOption} from 'src/app/shared/util/request-util';
import {ILo} from '../canh-tac/canh-tac.model';

@Injectable({
  providedIn: 'root',
})
export class DonViService {
  private resourceUrl = SERVER_API_URL + 'api/tb-customers';
  private resourceRejectUrl =
    SERVER_API_URL + 'api/duyet-tu-choi-tb-customers';
  private resourceUrlLos = SERVER_API_URL + 'api/losHienTaiByDonViCanhTac';
  private resourceSettingUrl = SERVER_API_URL + 'api/setting-crop-and-activity';
  constructor(private http: HttpClient) {}

  query(req?: any): Observable<HttpResponse<IDonVi[]>> {
    const options = createRequestOption(req);
    return this.http.get<IDonVi[]>(SERVER_API_URL + 'api/tb-customers', {
      params: options,
      observe: 'response',
    });
  }

  count(): Observable<HttpResponse<any>> {
    return this.http.get<any>(SERVER_API_URL + 'api/tb-customers/count', {
      observe: 'response',
    });
  }

  find(donViId: number): Observable<HttpResponse<IDonVi>> {
    return this.http
      .get<IDonVi>(`${this.resourceUrl}/${donViId}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<IDonVi>) => res));
  }

  search(req?: any): Observable<HttpResponse<IDonVi[]>> {
    const options = createRequestOption(req);
    return this.http.get<IDonVi[]>(
      SERVER_API_URL + 'api/tb-customers',
      {params: options, observe: 'response'},
    );
  }

  delete(donViId: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${donViId}`, {
      observe: 'response',
    });
  }

  create(donVi: any): Observable<HttpResponse<any>> {
    return this.http.post(SERVER_API_URL + 'api/tb-customers', donVi, {
      observe: 'response',
    });
  }

  register(donVi: any): Observable<HttpResponse<any>> {
    return this.http.post(
      SERVER_API_URL + 'api/register-tb-customers',
      donVi,
      {observe: 'response'},
    );
  }

  reject(donViId: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceRejectUrl}/${donViId}`, {
      observe: 'response',
    });
  }

  approved(donVi: any): Observable<HttpResponse<any>> {
    return this.http.put(
      SERVER_API_URL + 'api/duyet-dong-y-tb-customers',
      donVi,
      {observe: 'response'},
    );
  }

  update(donVi: any): Observable<HttpResponse<any>> {
    return this.http.put(SERVER_API_URL + 'api/tb-customers', donVi, {
      observe: 'response',
    });
  }

  losByDonVi(req?: any, donViId?: any): Observable<HttpResponse<ILo[]>> {
    const options = createRequestOption(req);
    return this.http.get<ILo[]>(`${this.resourceUrlLos}/${donViId}`, {
      params: options,
      observe: 'response',
    });
  }

  donVisApproved(req?: any): Observable<HttpResponse<IDonVi[]>> {
    const options = createRequestOption(req);
    return this.http.get<IDonVi[]>(
      SERVER_API_URL + 'api/approved-tb-customers',
      {params: options, observe: 'response'},
    );
  }

  donvis(): Observable<HttpResponse<IDonVi[]>> {
    return this.http.get<IDonVi[]>(SERVER_API_URL + 'api/tb-customers', {
      observe: 'response',
    });
  }
  donviByRole(roleId: number): Observable<HttpResponse<IDonVi[]>> {
    return this.http.get<IDonVi[]>(SERVER_API_URL + 'api/tb-customers-dropdown-list/' + roleId, {
      observe: 'response',
    });
  }
  getListAllCustomers(): Observable<HttpResponse<any[]>> {
    return this.http.get<ILo[]>(this.resourceUrl, {
      observe: 'response',
    })
    .pipe(map((res: HttpResponse<any>) => res.body));
  }

  updateSetting(body?: any) {
    return this.http.put(SERVER_API_URL + this.resourceSettingUrl, body, {
      observe: 'response',
    });
  }

  getSetting(customerId?: any, req?: any) {
    return this.http.get<any>(SERVER_API_URL + `${this.resourceSettingUrl}-by-customer/${customerId}`, {
      observe: 'response', params: req,
    });
  }
  uploadFile(customerId: any, formData: any): Observable<any> {
    return this.http.put<any>(SERVER_API_URL + this.resourceUrl + `/${customerId}/upload-cert`, formData);
  }

  dropdown(): Observable<HttpResponse<IDonVi[]>> {
    return this.http.get<IDonVi[]>(SERVER_API_URL + 'api/tb-customers/dropdown', {
      observe: 'response',
    });
  }
}
