import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {VatTuService} from '../vat-tu.service';
import {VatTu} from '../vat-tu.model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-vat-tu-mgmt-delete-dialog',
  templateUrl: './vat-tu-delete-dialog.component.html',
})
export class VatTuMgmtDeleteDialogComponent {
  vattu: VatTu;

  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }

  constructor(
    private dialogRef: MatDialogRef<VatTuMgmtDeleteDialogComponent>,
    private vattuService: VatTuService,
    private eventManager: JhiEventManager,
  ) {}

  confirmDelete(vatTuId: number) {
    this.vattuService.delete(vatTuId).subscribe((response) => {
      this.eventManager.broadcast({
        name: 'vattuListModification',
        content: 'VatTu',
      });
      this.dialogRef.close(true);
    });
  }
}

@Component({
  selector: 'app-vat-tu-mgmt-delete-popup',
  template: '',
  styleUrls: ['./vat-tu-delete-dialog.component.scss'],
})
export class VatTuMgmtDeletePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<VatTuMgmtDeleteDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa vật tư');
    this.activatedRoute.data.subscribe(({vattu}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(VatTuMgmtDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });

        this.dialogRef.componentInstance.vattu = vattu;

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.dialogRef = null;
    this.titleService.setTitle('Danh sách vật tư');
  }
}
