import {Routes} from '@angular/router';
import {ThongSoMoiTruongComponent} from './thong-so-moi-truong.component';
import {UserRouteAccessService} from '../../core';

export const thongSoMT: Routes = [
  {
    path: 'env-param/:id',
    component: ThongSoMoiTruongComponent,
    canActivate: [UserRouteAccessService],
  },
];
