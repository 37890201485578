import {
  Routes,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {ActivityTypeComponent} from './activity-type/activity-type.component';
import {Injectable} from '@angular/core';
import {ActivityService} from './activity.service';
import {IActivityType, ActivityType, IActivitySetting, ActivitySetting, IActivityParam, ActivityParam, IActivityObject, ActivityObject, IActivityChild, ActivityChild} from './activity.model';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ActivityTypeMgmtDeletePopupComponent} from './activity-type-delete-dialog/activity-type-delete-dialog.component';
import { ActivityTypeDetailComponent } from './activity-type-detail/activity-type-detail.component';
import { ActivityTypePopupComponent } from './activity-type-dialog/activity-type-dialog.component';
import { ActivityTypeParamPopupComponent } from './activity-type-param-dialog/activity-type-param-dialog.component';
import { ActivityDeleteParamPopupComponent } from './activity-type-param-delete-dialog/activity-type-delete-param-dialog.component';
import { ActivityTypeObjectPopupComponent } from './activity-type-object-dialog/activity-type-object-dialog.component';
import { ActivityDeleteObjectPopupComponent } from './activity-type-object-delete-dialog/activity-type-delete-object-dialog.component';
import { ActivityTypeChildPopupComponent } from './activity-type-child-dialog/activity-type-child-dialog.component';
import { ActivityDeleteChildPopupComponent } from './activity-type-child-delete-dialog/activity-type-delete-child-dialog.component';

@Injectable({providedIn: 'root'})
export class ActivityTypeManagementResolve implements Resolve<IActivityType> {
  constructor(private service: ActivityService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((type: HttpResponse<IActivityType>) => type.body));
    }
    return of(new ActivityType());
  }
}

@Injectable({providedIn: 'root'})
export class ActivityParamManagementResolve implements Resolve<IActivityParam> {
  constructor(private service: ActivityService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['paramId'] ? route.params['paramId'] : null;
    if (id) {
      return this.service
          .findActivityParam(id)
          .pipe(map((param: HttpResponse<IActivityParam>) => param.body));
    }
    return of(new ActivityParam());
  }
}

@Injectable({providedIn: 'root'})
export class ActivityChildManagementResolve implements Resolve<IActivityChild> {
  constructor(private service: ActivityService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['paramId'] ? route.params['paramId'] : null;
    if (id) {
      return this.service
          .findActivityChild(id)
          .pipe(map((param: HttpResponse<IActivityChild>) => param.body));
    }
    return of(new ActivityChild());
  }
}

@Injectable({providedIn: 'root'})
export class ActivityObjectManagementResolve implements Resolve<IActivityObject> {
  constructor(private service: ActivityService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['paramId'] ? route.params['paramId'] : null;
    if (id) {
      return this.service
          .findActivityObject(id)
          .pipe(map((param: HttpResponse<IActivityObject>) => {
            const res: any = param.body;
            const data = new ActivityObject();
            data.tbActivityExtendTypeDTO.condition = res.tbActivityExtendTypeCondition;
            data.tbActivityExtendTypeDTO.externalTable = res.tbActivityExtendTypeExternalTable;
            data.tbActivityExtendTypeDTO.id = res.tbActivityExtendTypeId;
            data.tbObjectParameterDTO.activityTypeId = res.activityTypeId;
            data.tbObjectParameterDTO.isGuidelineUsing = res.isGuidelineUsing;
            data.tbObjectParameterDTO.name = res.name;
            data.tbObjectParameterDTO.description = res.description;
            data.tbObjectParameterDTO.id = res.id;
            data.tbObjectParameterDTO.tbControlTypeId = res.tbControlTypeId;
            return data;
          }));
    }
    return of(new ActivityObject());
  }
}

@Injectable({providedIn: 'root'})
export class ActivityTypeIdManagementResolve implements Resolve<IActivityType> {
  constructor(private service: ActivityService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service
          .find(id)
          .pipe(map((type: HttpResponse<IActivityType>) => type.body.id));
    }
    return null;
  }
}

@Injectable({providedIn: 'root'})
export class ActivityTypeDetailResolve implements Resolve<IActivitySetting> {
  constructor(private service: ActivityService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service
          .findSetting(id)
          .pipe(map((type: HttpResponse<IActivitySetting>) => type.body));
    }
    return of(new ActivitySetting());
  }
}
export const activityRoute: Routes = [
  {
    path: 'activity',
    component: ActivityTypeComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_read_activity_type'],
      pageTitle: 'Danh sách activity',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'chi-tiet-activity/:id',
    component: ActivityTypeDetailComponent,
    data: {
      authorities: ['can_detail_activity_type'],
      pageTitle: 'Chi tiết activity'
    },
    canActivate: [UserRouteAccessService]
  },
];

export const donViPopupRoute: Routes = [
  {
    path: 'them-activity',
    component: ActivityTypePopupComponent,
    data: {
      authorities: ['can_add_activity_type'],
      pageTitle: 'Thêm activity'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'sua-activity/:id',
    component: ActivityTypePopupComponent,
    resolve: {
      activityType: ActivityTypeManagementResolve
    },
    data: {
      authorities: ['can_update_activity_type'],
      pageTitle: 'Sửa activity'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'xoa-activity/:id',
    component: ActivityTypeMgmtDeletePopupComponent,
    resolve: {
      activityType: ActivityTypeManagementResolve
    },
    data: {
      authorities: ['can_delete_activity_type'],
      pageTitle: 'Xóa activity'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'them-activity-param/:id',
    component: ActivityTypeParamPopupComponent,
    resolve: {
      activityId: ActivityTypeIdManagementResolve,
      activityParam: ActivityParamManagementResolve
    },
    data: {
      authorities: ['can_add_activity_type'],
      pageTitle: 'Thêm activity param'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'sua-activity-param/:id/:paramId',
    component: ActivityTypeParamPopupComponent,
    resolve: {
      activityId: ActivityTypeIdManagementResolve,
      activityParam: ActivityParamManagementResolve
    },
    data: {
      authorities: ['can_update_activity_type'],
      pageTitle: 'Sửa activity param'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'xoa-activity-param/:paramId',
    component: ActivityDeleteParamPopupComponent,
    resolve: {
      activityParam: ActivityParamManagementResolve
    },
    data: {
      authorities: ['can_delete_activity_type'],
      pageTitle: 'Xóa activity param'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'them-activity-object/:id',
    component: ActivityTypeObjectPopupComponent,
    resolve: {
      activityId: ActivityTypeIdManagementResolve,
      activityObject: ActivityObjectManagementResolve
    },
    data: {
      authorities: ['can_add_activity_type'],
      pageTitle: 'Thêm activity object'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'sua-activity-object/:id/:paramId',
    component: ActivityTypeObjectPopupComponent,
    resolve: {
      activityId: ActivityTypeIdManagementResolve,
      activityObject: ActivityObjectManagementResolve
    },
    data: {
      authorities: ['can_update_activity_type'],
      pageTitle: 'Sửa activity param'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'xoa-activity-object/:paramId',
    component: ActivityDeleteObjectPopupComponent,
    resolve: {
      activityObject: ActivityObjectManagementResolve
    },
    data: {
      authorities: ['can_delete_activity_type'],
      pageTitle: 'Xóa activity param'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'them-activity-child/:id',
    component: ActivityTypeChildPopupComponent,
    resolve: {
      activityId: ActivityTypeIdManagementResolve,
      activityChild: ActivityChildManagementResolve
    },
    data: {
      authorities: ['can_add_activity_type'],
      pageTitle: 'Thêm activity object'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'sua-activity-child/:id/:paramId',
    component: ActivityTypeChildPopupComponent,
    resolve: {
      activityId: ActivityTypeIdManagementResolve,
      activityChild: ActivityChildManagementResolve
    },
    data: {
      authorities: ['can_update_activity_type'],
      pageTitle: 'Sửa activity param'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
  {
    path: 'xoa-activity-child/:paramId',
    component: ActivityDeleteChildPopupComponent,
    resolve: {
      activityChild: ActivityChildManagementResolve
    },
    data: {
      authorities: ['can_delete_activity_type'],
      pageTitle: 'Xóa activity param'
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup'
  },
];
