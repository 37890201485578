export interface IStamp {
  id?: any;
  exampleStampName?: string;
  description?: string;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  size?: number;
  exampleStampImage?: any;
}


export class Stamp implements IStamp {
  constructor(
    public id?: any,
    public exampleStampName?: string,
    public description?: string,
    public width?: number,
    public height?: number,
    public x?: number,
    public y?: number,
    public size?: number,
    public exampleStampImage?: any
  ) {
    this.id = id ? id : null;
    this.exampleStampName = exampleStampName || null;
    this.width = width || 250;
    this.height = height || 200;
    this.x = x ? x : 30;
    this.y = y ? y : 30;
    this.size = size ? size : 100;
    this.description = description ? description : null;
  }
}
