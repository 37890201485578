import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_API_URL} from 'src/app/app.constants';
import {Observable} from 'rxjs';
import {createRequestOption} from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class BaoCaoService {
  constructor(private http: HttpClient) {}

  reportSeedUseage() {
    return this.http.get<any>(SERVER_API_URL + 'api/report/seed-usage', {
      observe: 'response',
    });
  }

  reportMonthly(month: number, year: number) {
    return this.http.get<any>(
      SERVER_API_URL + `api/report/monthly-activity/${month}/${year}`,
      {
        observe: 'response',
      },
    );
  }

  reportGardenSupervisor(req: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(`${SERVER_API_URL}api/report/garden-supervision`, {
      params: options,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  reportExportWareHouse(req: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(
      `${SERVER_API_URL}api/report/import-export-warehouse/`,
      {
        params: options,
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  reportImportMaterial(req: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(`${SERVER_API_URL}api/report/import-material`, {
      params: options,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  reportImportExportSuppliesInWarehouse(req: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(`${SERVER_API_URL}api/report/import-export-supplies-in-warehouse`, {
      params: options,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
