import {IPermissionGroup} from './../../../entities/vai-tro/vai-tro.model';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IVaiTro, IPermission} from '../../../entities/vai-tro/vai-tro.model';
import {VaiTroService} from '../../../entities/vai-tro/vai-tro.service';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss'],
})
export class UserRoleComponent implements OnInit, OnDestroy {
  roles: any;
  listRoles: IVaiTro[];
  listPermissionByRole: IPermissionGroup[];
  selectRole: number;
  eventSubscriber: Subscription;
  constructor(
    private vaiTroService: VaiTroService,
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
  ) {
    this.listRoles = [];
    this.listPermissionByRole = [];
  }

  ngOnInit() {
    this.loadAllVaiTro();
    this.changedvaiTro();
  }
  ngOnDestroy() {
    this.eventManager.destroy(this.eventSubscriber);
  }
  changedvaiTro() {
    this.eventSubscriber = this.eventManager.subscribe(
      'userRoleModification',
      () => this.loadAllVaiTro(),
    );
  }
  loadAllVaiTro() {
    this.vaiTroService.roles().subscribe(
      (res: HttpResponse<IVaiTro[]>) => this.onSuccess(res.body, 'role'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }
  selectShowRolePermission(roleId: number) {
    this.selectRole = roleId;
    this.listPermissionByRole = [];
    this.vaiTroService.permissionByRole(roleId).subscribe(
      (res: HttpResponse<IPermission[]>) =>
        this.onSuccess(res.body, 'permission'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }
  parentCheck(parentObj) {
    for (let i = 0; i < parentObj.permissionDTOList.length; i++) {
      parentObj.permissionDTOList[i].hasPermission = parentObj.isSelected;
    }
  }
  childCheck(parentObj, childObj) {
    parentObj.isSelected = childObj.every(function (itemChild: any) {
      return itemChild.hasPermission === true;
    });
  }
  savePermission() {
    const listId = [];
    this.listPermissionByRole.forEach((element: any) => {
      element.permissionDTOList.forEach((subItem: any) => {
        if (subItem.hasPermission) {
          listId.push(subItem.casbinDTOList[0].id);
        }
      });
    });
    this.vaiTroService.updatePermissionByRole({listId, v4: true}).subscribe();
  }
  private onSuccess(data: any, type: string) {
    if (type === 'role') {
      this.listRoles = data;
    }
    if (type === 'permission') {
      data.forEach((element: any) => {
        if (element.permissionDTOList.length > 0) {
          element.isSelected = true;
          element.permissionDTOList.forEach((subItem: any, index) => {
            if (
              subItem.casbinDTOList.length > 0 &&
              subItem.casbinDTOList[0].v4 === 'true'
            ) {
              element.permissionDTOList[index].hasPermission = true;
            } else {
              element.isSelected = false;
              element.permissionDTOList[index].hasPermission = false;
            }
          });
          this.listPermissionByRole.push(element);
        }
      });
    }
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
