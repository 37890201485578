import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CanhTacService} from '../../../entities/canh-tac';
import {JhiEventManager} from 'ng-jhipster';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {ThietBiService} from "../../../entities/thiet-bi";

@Component({
  selector: 'app-canh-tac-control-environment-dialog',
  templateUrl: './canh-tac-control-environment-dialog.component.html',
  styleUrls: ['./canh-tac-control-environment-dialog.component.scss'],
})
export class CanhTacControlEnvironmentDialogComponent implements OnInit, OnDestroy {
  listEnv: any;
  listDevice: any;
  nhaMangId: any;
  loId: any;
  id: any;
  constructor(
    private dialogRef: MatDialogRef<CanhTacControlEnvironmentDialogComponent>,
    private thietbiService: ThietBiService,
    private canhtacService: CanhTacService,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
  ) {
    this.listEnv = [];
    this.listDevice = [];
  }

  ngOnInit() {
    this.id = setInterval(() => {
      this.loadEnvRealtime();
    }, 5000);
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  save() {}

  loadDevice() {
    this.canhtacService.listDevicesInEntity(this.nhaMangId).subscribe((res) => {
      this.listDevice = res.body;
    });
  }
  loadEnvRealtime() {
    this.canhtacService.listEnvUpdateRealtime(this.loId).subscribe((res) => {
      this.listEnv = res.body;
    });
  }
  ConvertStringToNumber(input: string) {
    return Number(input);
  }
  changeStatus(id: number, status: any) {
    if (status === '1' || status === 1) {
      this.thietbiService.changeStatusDevice(id, 0).subscribe(
          (response) => {
            this.loadDevice();
          },
          (error) => {
            this.loadDevice();
          },
      );
    }
    if (status === 0 || status === '0') {
      this.thietbiService.changeStatusDevice(id, 1).subscribe(
          (response) => {
            this.loadDevice();
          },
          () => {
            this.loadDevice();
          },
      );
    }
  }
}

@Component({
  selector: 'app-canh-tac-control-environment-popup',
  template: '',
})
export class CanhTacControlEnvironmentPopupComponent
  implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<CanhTacControlEnvironmentDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}
  ngOnInit() {
    this.titleService.setTitle('Chỉ số và điều khiển');
      this.activatedRoute.data.subscribe(({env, device}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(
          CanhTacControlEnvironmentDialogComponent,
          {
            disableClose: true,
            width: '900px',
          },
        );
        this.activatedRoute.params.subscribe((param) => {
          this.dialogRef.componentInstance.nhaMangId = param.nhaMang;
          this.dialogRef.componentInstance.loId = param.lo;
        });
        if (env) {
          this.dialogRef.componentInstance.listEnv = env;
        }
        if (device) {
          this.dialogRef.componentInstance.listDevice = device;
        }
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Chi tiết lô');
    this.dialogRef = null;
  }
}
