import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';
import {MatDialogRef} from '@angular/material';
import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {IStamp, Stamp} from '../example-stamp.model';
import {ExampleStampService} from '../example-stamp.service';

@Component({
  selector: 'app-example-stamp-delete-dialog',
  templateUrl: './example-stamp-delete-dialog.component.html',
  styleUrls: ['./example-stamp-delete-dialog.component.scss']
})
export class ExampleStampDeleteDialogComponent implements OnInit {
  entity: IStamp;
  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }
  constructor(
    private dialogRef: MatDialogRef<ExampleStampDeleteDialogComponent>,
    private eventManager: JhiEventManager,
    private service: ExampleStampService,
  ) { }

  ngOnInit() {
  }


  confirmDelete(id: any) {
    this.service.delete(id).subscribe((res) => {
      this.eventManager.broadcast({
        name: 'stampListModification',
        content: this.entity,
      });
      this.dialogRef.close(true);
    });
  }
}


@Component({
  selector: 'app-example-stamp-delete-popup',
  template: '',
})
export class ExampleStampDeletePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ExampleStampDeleteDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({data}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(ExampleStampDeleteDialogComponent, {
          disableClose: true,
          width: '380px',
        });
        this.dialogRef.componentInstance.entity = data || new Stamp();
        this.dialogRef.afterClosed().subscribe(
          (result) => this.previousState(result),
          (reason) => this.previousState(reason),
        );
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.dialogRef = null;
  }

  previousState(result?: any): void {
    const params: any = {
      id: null,
    };
    this.router.navigate([{outlets: {popup: null}}], {
      relativeTo: this.activatedRoute.parent,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
