import {KhuVucResolve} from './../khu-vuc/khu-vuc.route';
import {UserRouteAccessService} from './../../core/auth/user-route-access-service';
import {EntityLocationPopupComponent} from './entity-location-dialog/entity-location-dialog.component';
import {Routes} from '@angular/router';
import {EntityLocationsResolve} from './entity-localtions.resolve';

export const entityLocationRoutes: Routes = [
    {
      path: 'entity-locations/:id',
      canActivate: [UserRouteAccessService],
      component: EntityLocationPopupComponent,
      resolve: {
        entityLocations: EntityLocationsResolve,
        entity: KhuVucResolve,
      },
      data: {
        pageTitle: 'Danh sách tọa độ khu vực',
        authorities: ['can_detail_unit_managerment', 'can_update_unit_managerment'],
      },
    },
];
