import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'src/app/app.constants';
import {Observable} from 'rxjs';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {
  IActivityType,
  IActivitySetting,
  IFormChildList,
  IActivityParam,
  IActivityObject,
  IActivityChild
} from './activity.model';
import {createRequestOption} from 'src/app/shared/util/request-util';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private resourceUrl = SERVER_API_URL + 'api/tb-activity-types';
  private resourceUpdateUrl = SERVER_API_URL + 'api/update-tb-activity-types';
  private resourceUrlSearch = SERVER_API_URL + 'api/tb-activity-types';
  private resourceUrlObjectParamList = SERVER_API_URL + 'api/tb-object-parameters-param-list';
  private resourceUrlObjectParam = SERVER_API_URL + 'api/tb-object-parameters';
  private resourceUrlObjectList = SERVER_API_URL + 'api/tb-object-param-has-extend-type-list';
  private resourceUrlObject = SERVER_API_URL + 'api/tb-object-param-has-extend-type';
  private resourceUrlChildList = SERVER_API_URL + 'api/tb-activity-extend-types-child-list';
  private resourceUrlChild = SERVER_API_URL + 'api/tb-activity-extend-types';

  constructor(private http: HttpClient) {}
  query(req?: any): Observable<HttpResponse<IActivityType[]>> {
    const options = createRequestOption(req);
    return this.http.get<IActivityType[]>(`${this.resourceUrl}`, {
      params: options,
      observe: 'response',
    });
  }

  find(id: number): Observable<HttpResponse<IActivityType>> {
    return this.http
      .get<IActivityType>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<IActivityType>) => res));
  }
  findSetting(id: number): Observable<HttpResponse<IActivitySetting>> {
    return this.http
        .get<IActivitySetting>(`${SERVER_API_URL}api/activity-types-dynamic-by-activity-type/${id}`, {observe: 'response'})
        .pipe(map((res: HttpResponse<IActivitySetting>) => res));
  }
  search(req?: any): Observable<HttpResponse<IActivityType[]>> {
    const options = createRequestOption(req);
    return this.http.get<IActivityType[]>(`${this.resourceUrlSearch}`,
      {params: options, observe: 'response'},
    );
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {
      observe: 'response',
    });
  }

  create(data: any): Observable<HttpResponse<IActivityType>> {
    return this.http.post(`${this.resourceUrl}`, data, {
      observe: 'response',
    });
  }

  update(data: any): Observable<HttpResponse<IActivityType>> {
    return this.http.post(`${this.resourceUpdateUrl}`, data, {
      observe: 'response',
    });
  }
  getExtendTableOption(type: number): Observable<HttpResponse<IFormChildList[]>> {
    return this.http.get<IFormChildList[]>(`${SERVER_API_URL}api/extend-table-option/${type}`, {
      observe: 'response',
    });
  }
  getControlType(): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(`${SERVER_API_URL}api/tb-control-types`, {
      observe: 'response',
    });
  }
  getControlTypeOption(slug: string): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(`${SERVER_API_URL}api/tb-control-types-option/${slug}`, {
      observe: 'response',
    });
  }
  getOptionUnit(): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(`${SERVER_API_URL}api/get-all-units`, {
      observe: 'response',
    });
  }

  getAllDataTableCommon(req: any): Observable<HttpResponse<any[]>> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${SERVER_API_URL}api/get-all-data-from-table-common`, {
      params: req,
      observe: 'response',
    });
  }
  queryParam(id: any, req?: any): Observable<HttpResponse<IActivityType[]>> {
    const options = createRequestOption(req);
    return this.http.get<IActivityType[]>(`${this.resourceUrlObjectParamList}/${id}`, {
      params: options,
      observe: 'response',
    });
  }

  findActivityParam(id: number): Observable<HttpResponse<IActivityParam>> {
    return this.http
        .get<IActivityType>(`${this.resourceUrlObjectParam}/${id}`, {observe: 'response'})
        .pipe(map((res: HttpResponse<IActivityParam>) => res));
  }

  createActivityParam(data: any): Observable<HttpResponse<IActivityType>> {
    return this.http.post(`${this.resourceUrlObjectParam}`, data, {
      observe: 'response',
    });
  }

  updateActivityParam(data: any): Observable<HttpResponse<IActivityType>> {
    return this.http.put(`${this.resourceUrlObjectParam}`, data, {
      observe: 'response',
    });
  }

  deleteActivityParam(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrlObjectParam}/${id}`, {
      observe: 'response',
    });
  }

  queryObject(id: any, req?: any): Observable<HttpResponse<IActivityType[]>> {
    const options = createRequestOption(req);
    return this.http.get<IActivityType[]>(`${this.resourceUrlObjectList}/${id}`, {
      params: options,
      observe: 'response',
    });
  }

  findActivityObject(id: number): Observable<HttpResponse<IActivityObject>> {
    return this.http
        .get<IActivityObject>(`${this.resourceUrlObject}/${id}`, {observe: 'response'})
        .pipe(map((res: HttpResponse<IActivityObject>) => res));
  }

  createActivityObject(data: any): Observable<HttpResponse<IActivityType>> {
    return this.http.post(`${this.resourceUrlObject}`, data, {
      observe: 'response',
    });
  }

  updateActivityObject(data: any): Observable<HttpResponse<IActivityType>> {
    return this.http.put(`${this.resourceUrlObject}`, data, {
      observe: 'response',
    });
  }

  deleteActivityObject(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrlObject}/${id}`, {
      observe: 'response',
    });
  }

  queryChild(id: any, req?: any): Observable<HttpResponse<IActivityChild[]>> {
    const options = createRequestOption(req);
    return this.http.get<IActivityType[]>(`${this.resourceUrlChildList}/${id}`, {
      params: options,
      observe: 'response',
    });
  }

  findActivityChild(id: number): Observable<HttpResponse<IActivityChild>> {
    return this.http
        .get<IActivityChild>(`${this.resourceUrlChild}/${id}`, {observe: 'response'})
        .pipe(map((res: HttpResponse<IActivityChild>) => res));
  }

  createActivityChild(data: any): Observable<HttpResponse<IActivityChild>> {
    return this.http.post(`${this.resourceUrlChild}`, data, {
      observe: 'response',
    });
  }

  updateActivityChild(data: any): Observable<HttpResponse<IActivityChild>> {
    return this.http.put(`${this.resourceUrlChild}`, data, {
      observe: 'response',
    });
  }

  deleteActivityChild(id: number): Observable<HttpResponse<IActivityChild>> {
    return this.http.delete(`${this.resourceUrlChild}/${id}`, {
      observe: 'response',
    });
  }
}
