import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatDialogRef, MatDialog} from '@angular/material';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {LocationService} from '../../shared/services/location.service';
import {
  Country,
  City,
  District,
  SubDistrict,
} from '../../shared/model/location.model';
import {Unit} from '../statictis.model';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {HttpResponse} from '@angular/common/http';
import {DonViUser} from '../../entities/don-vi/don-vi.model';
import {HomeService} from '../home.service';

@Component({
  selector: 'app-dialog-update',
  templateUrl: './dialog-update-unit.component.html',
  styleUrls: ['./dialog-update-unit.component.scss'],
})
export class DialogUpdateUnitComponent implements OnInit {
  listCountry: Country[] = [];
  listCity: City[] = [];
  listDistrict: District[] = [];
  listSubDistrict: SubDistrict[] = [];
  DonViUser: DonViUser;
  unit: Unit;
  isSaving: boolean;
  constructor(
    private dialogRef: MatDialogRef<DialogUpdateUnitComponent>,
    private location: LocationService,
    private alertService: JhiAlertService,
    private homeService: HomeService,
    private eventManager: JhiEventManager,
  ) {
    this.unit = new Unit();
  }

  ngOnInit() {
    this.onSuccessDonViUser(this.DonViUser);
  }

  getCountry() {
    this.location.listCountry().subscribe(
      (res: HttpResponse<Country[]>) => this.onSuccess(res.body, 'country'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getCity(id) {
    this.location.listCity(id).subscribe(
      (res: HttpResponse<City[]>) => this.onSuccess(res.body, 'city'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getDistrict(id) {
    this.location.listDistrict(id).subscribe(
      (res: HttpResponse<District[]>) => this.onSuccess(res.body, 'district'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getSubDistrict(id) {
    this.location.listSubDistrict(id).subscribe(
      (res: HttpResponse<SubDistrict[]>) =>
        this.onSuccess(res.body, 'subdistrict'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }
  countryEffect(country) {
    if (country) {
      this.getCity(country);
      this.listDistrict = [];
      this.listSubDistrict = [];
      this.DonViUser.cityId = null;
      this.DonViUser.districtId = null;
      this.DonViUser.wardId = null;
    } else {
      this.listCity = [];
      this.listDistrict = [];
      this.listSubDistrict = [];
      this.DonViUser.cityId = null;
      this.DonViUser.districtId = null;
      this.DonViUser.wardId = null;
    }
  }

  cityEffect(city) {
    if (city) {
      this.getDistrict(city);
      this.DonViUser.districtId = null;
      this.DonViUser.wardId = null;
      this.listSubDistrict = [];
    } else {
      this.DonViUser.districtId = null;
      this.DonViUser.wardId = null;
      this.listSubDistrict = [];
      this.listDistrict = [];
    }
  }

  districtEffect(district) {
    if (district) {
      this.getSubDistrict(district);
    } else {
      this.DonViUser.wardId = null;
      this.DonViUser.districtId = null;
      this.listSubDistrict = [];
    }
  }

  private onSuccess(data: any, type: string) {
    if (type === 'country') {
      this.listCountry = data;
    } else if (type === 'city') {
      this.listCity = data;
    } else if (type === 'district') {
      this.listDistrict = data;
    } else if (type === 'subdistrict') {
      this.listSubDistrict = data;
    }
  }

  private onSuccessDonViUser(data: any) {
    this.getCountry();
    if (data.countryId) {
      this.getCity(data.countryId);
    }
    if (data.cityId) {
      this.getDistrict(data.cityId);
    }
    if (data.districtId) {
      this.getSubDistrict(data.districtId);
    }
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }

  save() {
    this.isSaving = true;
    this.homeService.UpdateDonVi(this.DonViUser).subscribe(
      (response) => this.onSaveSuccess(response),
      () => this.onSaveError(),
    );
  }
  private onSaveSuccess(result: any) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'donViListModification',
      content: 'DonVi',
    });
    this.dialogRef.close(true);
  }
  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-dialog-update',
  template: '',
})
export class DialogUpdateUnitPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<DialogUpdateUnitComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({unit}: Data) => {
      // this.dialogRef.componentInstance.DonViUser = unit;
      setTimeout(() => {
        this.dialogRef = this.dialog.open(DialogUpdateUnitComponent, {
          disableClose: true,
          width: '500px',
        });
        this.dialogRef.componentInstance.DonViUser = unit;

        // if (donVi !== undefined) {
        //   this.dialogRef.componentInstance.donVi = donVi;
        // }

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {}
}
