import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IActivityChild, ActivityChild } from '../activity.model';
import { ActivityService } from '../activity.service';
import { JhiEventManager } from 'ng-jhipster';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-activity-type-child-dialog',
  templateUrl: './activity-type-child-dialog.component.html',
  styleUrls: ['./activity-type-child-dialog.component.scss']
})
export class ActivityTypeChildDialogComponent implements OnInit {
  activityChild: IActivityChild;
  isSaving: boolean;
  extendTable: any;
  constructor(
    private dialogRef: MatDialogRef<ActivityTypeChildDialogComponent>,
    private activityService: ActivityService,
    private eventManager: JhiEventManager,
    private titleService: Title,

  ) {
    this.activityChild = new ActivityChild;
    this.isSaving = false;
    this.extendTable = [];
  }

  ngOnInit() {
    if (this.activityChild.id !== null) {
      this.titleService.setTitle('Sửa danh sách con');
    } else {
      this.titleService.setTitle('Thêm danh sách con');
    }
    this.getExtendTableOption();
  }

  save() {
    this.isSaving = true;
    if (this.activityChild.id !== null) {
      this.activityService.updateActivityChild(this.activityChild).subscribe(response => this.onSaveSuccess(response), () => this.onSaveError());
    } else {
      this.activityService.createActivityChild(this.activityChild).subscribe(response => this.onSaveSuccess(response), () => this.onSaveError());
    }
  }
  getExtendTableOption() {
    this.activityService.getExtendTableOption(1).subscribe((response) => {
      this.extendTable = response.body;
    });
  }
  private onSaveSuccess(result: any) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'activityChildListModification',
      content: 'activityType'
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }

}

@Component({
  selector: 'app-activity-child-popup',
  template: ''
})
export class ActivityTypeChildPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<ActivityTypeChildDialogComponent>;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ activityId, activityChild }) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(ActivityTypeChildDialogComponent, {
          disableClose: true,
          width: '380px',
        });
          this.dialogRef.componentInstance.activityChild = activityChild;
          this.dialogRef.componentInstance.activityChild.activityTypeId = activityId;

        this.dialogRef.afterClosed().subscribe(
          result => {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
          },
          reason => {
            this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true, queryParamsHandling: 'merge' });
          }
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Chi tiết hoạt động');
    this.dialogRef = null;
  }
}
