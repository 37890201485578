import {HttpResponse} from '@angular/common/http';
import {DashboardTypeService} from '../dashboard-type/dashboard-type.service';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import {IDashboardType} from './dashboard-type';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DashboardTypesResolve implements Resolve<any[]> {
  constructor(
    private dashboardTypeService: DashboardTypeService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state?: RouterStateSnapshot, ): Observable<any> | Promise<any> {
    return  this.dashboardTypeService.query()
        .pipe(map((res: HttpResponse<IDashboardType[]>) => res.body));
  }
}
