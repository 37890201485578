import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
  @Input() pager = {
    page: 1,
    limit: 20,
    collectionSize: 0,
    maxSize: 1,
  };
  @Input() columns: any[];
  @Input() dataProvider: any[];
  @Output() ngOnPageChange: EventEmitter<any> = new EventEmitter();
  @Output() ngOnOrderChange: EventEmitter<any> = new EventEmitter();
  @Output() ngOnRowSelect: EventEmitter<any> = new EventEmitter();
  @Input() templateAction: TemplateRef<any>;
  @Input() templateContent: TemplateRef<any>;
  @Input() templateOrder: TemplateRef<any>;
  @Input() enablePagination = true;
  @Input() options: any;
  @Input() headOptions: any;
  private sort: string;
  private dir: string;
  constructor() {}

  ngOnInit() {}

  pageChange(event) {
    this.ngOnPageChange.emit(event);
  }

  setOrder(event, sort) {
    if (this.sort !== sort) {
      this.sort = sort;
      this.dir = 'asc';
    } else {
      if (this.dir === 'asc') {
        this.dir = 'desc';
      } else if (this.dir === 'desc') {
        this.dir = '';
        this.sort = '';
      } else if (this.dir === '') {
        this.dir = 'asc';
      }
    }
    this.ngOnOrderChange.emit({
      event: event,
      sort: this.sort,
      dir: this.dir,
    });
  }

  rowSelect(event, item: any) {
    event.item = item;
    this.ngOnRowSelect.emit(event);
  }
}
