import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes} from '@angular/router';
import {ExampleStampComponent} from './example-stamp/example-stamp.component';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {ExampleStampPopupComponent} from './example-stamp-dialog/example-stamp-dialog.component';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {ExampleStampService} from './example-stamp.service';
import {IStamp, Stamp} from './example-stamp.model';
import {ExampleStampDeletePopupComponent} from './example-stamp-delete-dialog/example-stamp-delete-dialog.component';

@Injectable({providedIn: 'root'})

export class StampDetailResolve implements Resolve<IStamp> {
  constructor(private service: ExampleStampService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['code'] ? route.params['code'] : null;

    if (id) {
      return this.service
          .find(id)
          .pipe(map((response: HttpResponse<IStamp>) => response.body));
    }
    return of(new Stamp());
  }
}

export const ExampleStampRoute: Routes = [
  {
    path: 'danh-sach-mau-tem',
    component: ExampleStampComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      pageTitle: 'Danh sách mẫu tem',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'tao-mau-tem',
    component: ExampleStampPopupComponent,
    data: {
      pageTitle: 'Tạo mẫu tem',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'sua-mau-tem/:code',
    component: ExampleStampPopupComponent,
    data: {
      pageTitle: 'Sửa mẫu tem',
    },
    canActivate: [UserRouteAccessService],
    resolve: {
      data: StampDetailResolve,
    },
    outlet: 'popup'
  },
  {
    path: 'xoa-mau-tem/:code',
    component: ExampleStampDeletePopupComponent,
    data: {
      pageTitle: 'Xóa mẫu tem',
    },
    canActivate: [UserRouteAccessService],
    resolve: {
      data: StampDetailResolve,
    },
    outlet: 'popup'
  }
];
