import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';

import {User, UserService} from '../../../core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-user-mgmt-delete-dialog',
  templateUrl: './user-management-delete-dialog.component.html',
})
export class UserMgmtDeleteDialogComponent {
  user: User;
  @HostListener('window:keyup.esc')
  private onKeyUp() {
    this.dialogRef.close();
  }

  constructor(
    private dialogRef: MatDialogRef<UserMgmtDeleteDialogComponent>,
    private userService: UserService,
    private eventManager: JhiEventManager,
  ) {}

  confirmDelete(login: any) {
    this.userService.delete(login).subscribe((res) => {
      this.eventManager.broadcast({
        name: 'userListModification',
        content: 'User',
      });
      this.dialogRef.close(true);
    });
  }
}

@Component({
  selector: 'app-user-mgmt-delete-popup',
  template: '',
})
export class UserMgmtDeletePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<UserMgmtDeleteDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Xóa người dùng');
    this.activatedRoute.data.subscribe(({user}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(UserMgmtDeleteDialogComponent, {
          disableClose: true,
          width: '500px',
        });

        this.dialogRef.componentInstance.user = user;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách người dùng');
    this.dialogRef = null;
  }
}
