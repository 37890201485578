import {Component, OnInit} from '@angular/core';
import {ProfileService} from './profile.service';
import {ProfileInfo} from './profile-info.model';

@Component({
  selector: 'app-page-ribbon',
  template: ` <div class="ribbon" *ngIf="ribbonEnv">
    <a href="">{{ ribbonEnv }}</a>
  </div>`,
  styleUrls: ['page-ribbon.scss'],
})
export class PageRibbonComponent implements OnInit {
  profileInfo: ProfileInfo;
  ribbonEnv: string;

  constructor(private profileService: ProfileService) {}

  ngOnInit() {
    // this.profileService.getProfileInfo().then(profileInfo => {
    //   this.profileInfo = profileInfo;
    //   this.ribbonEnv = profileInfo.ribbonEnv;
    // });
  }
}
