import {Component, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MatTableDataSource} from '@angular/material/table';
import {ThietBiService} from '../thiet-bi.service';
import {HttpResponse} from '@angular/common/http';
import {IEquipmentHistory} from '../thiet-bi.model';
import moment from 'moment';

@Component({
  selector: 'app-history-activity-thiet-bi',
  templateUrl: './history-activity-thiet-bi.component.html',
  styleUrls: ['./history-activity-thiet-bi.component.scss'],
})
export class HistoryActivityThietBiComponent implements OnInit {
  displayedColumns: string[] = ['thietBi', 'thoiGian', 'moTa', 'trangThai'];
  ELEMENT_DATA: any;
  tbEquipmentId: any;
  dataSource: any;
  totalItems: any;
  itemsPerPage: any;
  pageSizeOptions: number[];
  routeData: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  links: any;
  queryCount: any;
  startDate: any;
  endDate: any;
  minDate: any;
  check: boolean;
  constructor(
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private parseLinks: JhiParseLinks,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private thietbiService: ThietBiService,
  ) {
    this.minDate = new Date();
    this.tbEquipmentId = '';
    this.startDate = '';
    this.endDate = '';
    this.check = true;
    this.routeData = this.activatedRoute.data.subscribe(({pagingParams}) => {
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      const paramSearch = params['equipmentId'];
      if (paramSearch) {
        this.tbEquipmentId = Number(paramSearch);
      }
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Lịch sử hoạt động');
    this.loadAll();
  }

  setStartDate(e) {
    this.minDate = e;
    this.startDate = moment(this.startDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    this.check = false;
  }

  setEndDate(e) {
    this.endDate = moment(this.endDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    this.loadAll();
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }

  loadAll() {
    this.thietbiService
      .historyDevice({
        equipmentInWarehouseId: this.tbEquipmentId,
        startTime: this.startDate,
        endTime: this.endDate,
      })
      .subscribe(
        (res: HttpResponse<IEquipmentHistory[]>) =>
          this.onSuccess(res.body, res.headers),
        (res: HttpResponse<any>) => this.onError(res.body),
      );
  }

  private onSuccess(data: any, headers: any) {
    this.links = this.parseLinks.parse(headers.get('link'));
    this.totalItems = headers.get('X-Total-Count');
    this.queryCount = this.totalItems;
    this.ELEMENT_DATA = data;
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }
  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
  ConvertStringToNumber(input: string) {
    const numeric = Number(input);
    return numeric;
  }
}
