import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {IUser, Principal, UserService} from '../../../core';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {MatDialog, MatDialogRef, MatOption, MatSelect} from '@angular/material';
import {VaiTroService} from 'src/app/entities/vai-tro/vai-tro.service';
import {DonViService} from 'src/app/entities/don-vi/don-vi.service';
import {IDonVi, DonVi} from 'src/app/entities/don-vi/don-vi.model';
import {HttpResponse} from '@angular/common/http';
import {IVaiTro} from 'src/app/entities/vai-tro/vai-tro.model';
import {Title} from '@angular/platform-browser';
import {LocalStorageService} from 'ngx-webstorage';
import {LocationService} from 'src/app/shared/services/location.service';
import {
  Country,
  City,
  District,
  SubDistrict,
} from 'src/app/shared/model/location.model';

enum VAI_TRO {
  ADMIN = 1,
  QUAN_LY = 2,
  KTV = 3,
  QUAN_LY_DV = 4,
  NHAN_VIEN_DV = 5,
}

@Component({
  selector: 'app-user-management-update-dialog',
  templateUrl: './user-management-update.component.html',
  styleUrls: ['./user-management-update.component.scss'],
})
export class UserManagementUpdateDialogComponent implements OnInit {
  currentAccount: IUser;
  user: IUser;
  donVis: IDonVi[];
  listRoles: IVaiTro[];
  authorities: any[];
  isSaving: boolean;
  plants = [];
  isMultiple: boolean;
  iShowDonVi = false;
  listCountry: Country[] = [];
  listCity: City[] = [];
  listDistrict: District[] = [];
  listSubDistrict: SubDistrict[] = [];
  @ViewChild('allSelected') private allSelected: MatSelect;
  constructor(
    private dialogRef: MatDialogRef<UserManagementUpdateDialogComponent>,
    private principal: Principal,
    private userService: UserService,
    private eventManager: JhiEventManager,
    private titleService: Title,
    private location: LocationService,
    private alertService: JhiAlertService,
    private donViService: DonViService,

  ) {
    this.isSaving = false;
    this.isMultiple = false;
    this.listRoles = [];
    this.donVis = [];
  }

  ngOnInit() {
    this.principal.identity().then((account) => {
      this.currentAccount = account;
      if (this.scopeOnly() && !this.user.scope) {
        this.user.scope = this.currentAccount.scope;
      }
    });
    if (this.user.id !== null) {
      this.titleService.setTitle('Sửa người dùng');
      this.loadAllDonVi(this.user.roleId, false);
    } else {
      this.titleService.setTitle('Thêm người dùng');
    }
    this.getCountry();
    if (this.user.countryId) {
      this.getCity(this.user.countryId);
    }
    if (this.user.cityId) {
      this.getDistrict(this.user.cityId);
    }
    if (this.user.districtId) {
      this.getSubDistrict(this.user.districtId);
    }
  }

  clear() {
    this.dialogRef.close('cancel');
  }

  save() {
    this.isSaving = true;
    if (this.user.id !== null) {
      this.userService.update(this.user).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError(),
      );
    } else {
      this.userService.create(this.user).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError(),
      );
    }
  }

  scopeOnly() {
    // TODO: 27022019 Need to fix scope
    // return this.currentAccount.scope !== 'ALL';
    return true;
  }

  onVaiTroChange(event: number) {
    this.loadAllDonVi(event, true);
  }

  private onSaveSuccess() {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'userListModification',
      content: 'User',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }
  getCountry() {
    this.location.listCountry().subscribe(
      (res: HttpResponse<Country[]>) => this.onSuccess(res.body, 'country'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getCity(id) {
    this.location.listCity(id).subscribe(
      (res: HttpResponse<City[]>) => this.onSuccess(res.body, 'city'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getDistrict(id) {
    this.location.listDistrict(id).subscribe(
      (res: HttpResponse<District[]>) => this.onSuccess(res.body, 'district'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  getSubDistrict(id) {
    this.location.listSubDistrict(id).subscribe(
      (res: HttpResponse<SubDistrict[]>) =>
        this.onSuccess(res.body, 'subdistrict'),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  private onSuccess(data: any, type: string) {
    if (type === 'country') {
      this.listCountry = data;
    } else if (type === 'city') {
      this.listCity = data;
    } else if (type === 'district') {
      this.listDistrict = data;
    } else if (type === 'subdistrict') {
      this.listSubDistrict = data;
    }
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }

  loadAllDonVi(roleId: number, changeValue: boolean) {
    this.donViService.donviByRole(roleId).subscribe(
        (res: HttpResponse<IDonVi[]>) => {
          const data = res.body;
          if (
              data.length > 0
          ) {
            this.donVis = data;
            if (changeValue) {
              this.user.customerId = data[0].id;
            }
            this.iShowDonVi = true;
          } else {
            this.donVis = [];
            this.iShowDonVi = false;
            this.user.customerId = null;
          }
        },
        (res: HttpResponse<any>) => this.onError(res.body),
    );
  }
  countryEffect(country) {
    if (country) {
      this.getCity(country);
      this.listDistrict = [];
      this.listSubDistrict = [];
      this.user.cityId = null;
      this.user.districtId = null;
      this.user.wardId = null;
    } else {
      this.listCity = [];
      this.listDistrict = [];
      this.listSubDistrict = [];
      this.user.cityId = null;
      this.user.districtId = null;
      this.user.wardId = null;
    }
  }

  cityEffect(city) {
    if (city) {
      this.getDistrict(city);
      this.user.districtId = null;
      this.user.wardId = null;
      this.listSubDistrict = [];
    } else {
      this.user.districtId = null;
      this.user.wardId = null;
      this.listSubDistrict = [];
      this.listDistrict = [];
    }
  }

  districtEffect(district) {
    if (district) {
      this.getSubDistrict(district);
      this.user.wardId = null;
    } else {
      this.user.districtId = null;
      this.user.wardId = null;
      this.listSubDistrict = [];
    }
  }
}

@Component({
  selector: 'app-user-management-update-popup',
  template: '',
})
export class UserManagementUpdatePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<UserManagementUpdateDialogComponent>;

  constructor(
    private vaiTroService: VaiTroService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: JhiAlertService,
    private dialog: MatDialog,
    private titleService: Title,
    private localStorage: LocalStorageService,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({user, authorities}: Data) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(UserManagementUpdateDialogComponent, {
          disableClose: true,
          width: '500px',
        });
        this.dialogRef.componentInstance.user = user;
        this.loadAllVaiTro();
        this.dialogRef.componentInstance.authorities = authorities;
        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
            this.dialogRef = null;
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
            this.dialogRef = null;
          },
        );
      }, 0);
    });
  }

  loadAllVaiTro() {
    this.vaiTroService.rolesDropdown().subscribe(
      (res: HttpResponse<IVaiTro[]>) => this.onSuccess(res.body),
      (res: HttpResponse<any>) => this.onError(res.body),
    );
  }

  private onSuccess(data: any) {
    this.dialogRef.componentInstance.listRoles = data;
  }

  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }

  ngOnDestroy(): void {
    this.titleService.setTitle('Danh sách người dùng');
    this.dialogRef = null;
  }
}
