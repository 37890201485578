import {Component, OnInit, OnDestroy} from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MatDatepickerInputEvent,
} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {of} from 'rxjs';
import {Crops, typeCrop} from '../canh-tac.model';

import {Title} from '@angular/platform-browser';
import moment from 'moment';
import {CanhTacService} from '../canh-tac.service';
import {HttpResponse} from '@angular/common/http';
import saveAs from 'file-saver';
@Component({
  selector: 'app-canh-tac-exportfile-dialog',
  templateUrl: './canh-tac-exportfile-dialog.component.html',
  styleUrls: ['./canh-tac-exportfile-dialog.component.scss'],
})
export class CanhTacExportfileDialogComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  check: boolean;
  startDate: Date;
  endDate: Date;
  lo: Crops;
  isSaving: boolean;

  constructor(
    private dialogRef: MatDialogRef<CanhTacExportfileDialogComponent>,
    private titleService: Title,
    private canhTacService: CanhTacService,
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear);
    this.maxDate = new Date();
    this.check = true;
    this.lo = new Crops();
    this.isSaving = false;
  }

  ngOnInit() {
    if (this.lo.id !== null) {
      this.titleService.setTitle('Xuất báo cáo');
    }
  }

  startDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minDate = new Date(event.value);
    this.minDate.setDate(this.minDate.getDate());
    this.startDate = this.minDate;
    this.check = false;
  }

  endDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = new Date(event.value);
    // this.maxDate = this.endDate;
  }

  save() {
    if (this.startDate && this.endDate && this.lo.id !== null) {
      const param = {
        startDate: moment(this.startDate).format('DD-MM-YYYY'),
        endDate: moment(this.endDate).format('DD-MM-YYYY'),
      };
      if (this.lo.tbCropTypeId === typeCrop.UOM) {
        this.canhTacService.exportUom(this.lo.id, param).subscribe(
          (resp: HttpResponse<Blob>) => this.onSaveSuccess(resp),
          () => this.onSaveError(),
        );
      } else if (this.lo.tbCropTypeId === typeCrop.TRONG) {
        this.canhTacService.exportTrong(this.lo.id, param).subscribe(
          (resp: HttpResponse<Blob>) => this.onSaveSuccess(resp),
          () => this.onSaveError(),
        );
      }
    }
  }
  private onSaveSuccess(result) {
    const contentDisposition = result.headers.get('content-disposition');
    const filename = contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .trim();
    if (filename) {
      saveAs(result.body, filename);
    } else {
      alert(' Không tìm thấy filename');
    }
    this.isSaving = false;
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }
}

@Component({
  selector: 'app-canhtac-exportfile',
  template: '',
})
export class CanhTacExportFilePopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<CanhTacExportfileDialogComponent>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({lo}) => {
      setTimeout(() => {
        this.dialogRef = this.dialog.open(CanhTacExportfileDialogComponent, {
          disableClose: true,
          width: '400px',
        });
        if (lo !== undefined) {
          this.dialogRef.componentInstance.lo = lo;
        }

        this.dialogRef.afterClosed().subscribe(
          (result) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
          (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {
              replaceUrl: true,
              queryParamsHandling: 'merge',
            });
          },
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách lô');
  }
}
