import {Component, OnInit} from '@angular/core';
import {ITEMS_PER_PAGE, PAGE_SIZE_OPTIONS} from '../../../shared';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {ThietBiService} from '../thiet-bi.service';
import {MatTableDataSource} from '@angular/material/table';
import {HttpResponse} from '@angular/common/http';
import {IDevice} from '../thiet-bi.model';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-thiet-bi',
  templateUrl: './thiet-bi.component.html',
  styleUrls: ['./thiet-bi.component.scss'],
})
export class ThietBiComponent implements OnInit {
  displayedColumns: string[] = [
    'maThietBi',
    'tenThietBi',
    'loaiThietBi',
    'nhaSanXuat',
    'viTri',
    'tinhTrang',
    'toogle',
    'action',
  ];
  ELEMENT_DATA: any;
  currentSearch: string;
  typeId: any;
  tbEquipmentId: any;
  listFeatureDevice$: any;
  listCategoryDevice$: any;
  dataSource: any;
  totalItems: any;
  itemsPerPage: any;
  pageSizeOptions: number[];
  eventSubscriber: Subscription;
  page: any;
  routeData: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  links: any;
  queryCount: any;
  noData: boolean;
  constructor(
    private alertService: JhiAlertService,
    private eventManager: JhiEventManager,
    private parseLinks: JhiParseLinks,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private thietbiService: ThietBiService,
  ) {
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.tbEquipmentId = '';
    this.typeId = '';
    this.currentSearch = '';
    this.routeData = this.activatedRoute.data.subscribe(({pagingParams}) => {
      this.page = pagingParams.page;
      this.previousPage = pagingParams.page;
      this.reverse = pagingParams.ascending;
      this.predicate = pagingParams.predicate;
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      const paramCateId = params['cate_id'];
      const paramTypeId = params['type_id'];
      if (paramCateId) {
        this.tbEquipmentId = Number(paramCateId);
      }
      if (paramTypeId) {
        this.typeId = Number(paramTypeId);
        this.onChangeFeatureDevice(this.typeId);
      }
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Danh sách thiết bị');
    this.loadAll();
    this.changeListDevice();
    this.getTypeDevice();
  }
  getTypeDevice() {
    this.thietbiService.typeDevice().subscribe((res: any) => {
      this.listFeatureDevice$ = res.body;
    });
  }
  search(query) {
    if (!query) {
      return this.clear();
    }

    this.page = 1;
    this.currentSearch = query;

    this.loadAll();
  }
  ConvertStringToNumber(input: string) {
    const numeric = Number(input);
    return numeric;
  }

  changeListDevice() {
    this.eventSubscriber = this.eventManager.subscribe(
      'deviceListModification',
      () => this.loadAll(),
    );
  }
  changeStatus(id: number, status: any) {
    if (status === '1' || status === 1) {
      this.thietbiService.changeStatusDevice(id, 0).subscribe(
        (response) => {
          this.loadAll();
        },
        (error) => {
          this.loadAll();
        },
      );
    }
    if (status === 0 || status === '0') {
      this.thietbiService.changeStatusDevice(id, 1).subscribe(
        (response) => {
          this.loadAll();
        },
        () => {
          this.loadAll();
        },
      );
    }
  }
  // onChangeSuppliesType(e) {
  //   this.tbEquipmentId = e.value;
  //   this.loadAll();
  // }

  onChangeFeatureDevice(value) {
    if (value === '') {
      this.listCategoryDevice$ = [];
      this.tbEquipmentId = "";
    } else {
      this.thietbiService.listCategoryDevice({
        equipmentTypeId: this.typeId
      }).subscribe((res: any) => {
        this.listCategoryDevice$ = res.body;
      });
    }
    this.loadAll();
  }

  onChangeCategoryDevice(e) {
    // this.tbEquipmentId = e.value;
    this.loadAll();
  }

  loadAll() {
    this.thietbiService
      .query({
        page: this.page - 1,
        q: this.currentSearch,
        size: this.itemsPerPage,
        sort: this.sort(),
        equipmentId: this.tbEquipmentId,
        equipmentTypeId: this.typeId
      })
      .subscribe(
        (res: HttpResponse<IDevice[]>) => this.onSuccess(res.body, res.headers),
        (res: HttpResponse<any>) => this.onError(res.body),
      );
  }

  loadPage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadAll();
  }

  clear() {
    this.page = 1;
    this.currentSearch = '';

    this.loadAll();
  }

  private sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  private onSuccess(data: any, headers: any) {
    this.links = this.parseLinks.parse(headers.get('link'));
    this.totalItems = headers.get('X-Total-Count');
    this.queryCount = this.totalItems;
    this.ELEMENT_DATA = data;
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    if (data.length === 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
  }
  private onError(error: any) {
    this.alertService.error(error.error, error.message, null);
  }
}
