import {DashboardHouseDetailDialogComponent} from './../../dashboard-house-detail-dialog/dashboard-house-detail-dialog.component';
import {IKhuVuc} from './../../../entities/khu-vuc/khu-vuc.model';
import {Component, OnInit, Input} from '@angular/core';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-house',
  templateUrl: './house.component.html',
  styleUrls: ['./house.component.scss']
})
export class HouseComponent implements OnInit {
  @Input() data: IKhuVuc;
  @Input() width: number;
  @Input() height: number;
  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    const test = 1;
  }

  findColor(color: number) {
    if (color === 0) {
      return 'red';
    } else if (color === 1) {
      return 'purple';
    } else if (color === 2) {
      return '#ffc000';
    } else if (color === 3) {
      return 'blue';
    } else if (color === 4) {
      return 'green';
    } else {
      return 'purple';
    }
  }

  isEnable() {
    return this.data.tbCropDTOS && this.data.tbCropDTOS.length > 0;
  }

  openHouseDetailDialog() {
    if (!this.isEnable()) {
      return;
    }
    const dialogRef = this.dialog.open(DashboardHouseDetailDialogComponent, {
      width: '300px',
      disableClose: false,
    });
    dialogRef.componentInstance.data = this.data;
    return dialogRef.afterClosed();
  }
}
